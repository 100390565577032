import React from 'react'
import { LeftArrow } from '~/assets/icons'
import { StyleStore } from '~/code/config'
import { Link } from '~/code/components'
import translations from '../../translations'
import styles from './styles.scss'
import { TranslationBuilder } from '~/code/components/Translation'
import { CHANGE_PAYMENT_METHOD } from '~/code/constants/playwright-ids'

export const PaymentMethodsLink: React.FC<{ onClick(): void }> = (props) => {
    return  <Link key={TranslationBuilder.lang} className={ styles.link } onClick={ () => props.onClick() }>
        <div data-test-id={CHANGE_PAYMENT_METHOD} data-id={'change-payment-method'}><LeftArrow color={ StyleStore.style.primaryColor } /> { translations().changePaymentMethod }</div>
    </Link>
}
