import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    back: string
    label: string
    placeholder: string
    inputButton: string
    text: string
    link: string
    button: string
    invalidEmail: string
    successText: string
    errorText: string
}>()
