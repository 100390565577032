// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vydEt_G6cfhq0fOcML5U3{font-family:\"Open Sans\";font-size:12px;color:#9b9b9b;text-align:center}.vydEt_G6cfhq0fOcML5U3 a,.vydEt_G6cfhq0fOcML5U3 a:hover{color:#555}", ""]);
// Exports
exports.locals = {
	"EcospendText": "vydEt_G6cfhq0fOcML5U3"
};
module.exports = exports;
