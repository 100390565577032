import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { ClickToPayProps } from './props'
import styles from './ClickToPay.scss'
import { TranslationBuilder } from '~/code/components/Translation'
import classNames from 'classnames'
export const ClickToPayComponent = observer((props: ClickToPayProps) => {
    const { store } = props
    const buttonContainer = useRef()

    useEffect(() => {
        store.loadLibrary(buttonContainer.current)
    }, [])


    return <div className={ classNames(styles.ClickToPayButton, TranslationBuilder.lang) } ref={buttonContainer}/>
})
