import React from 'react'
import translations from './translations'

translations.add('is', {
    back: 'skipta um banka',
    cancel: 'Hætta við',
    continue: 'Halda áfram',
    infoText: `Við munum biðja bankann þinn um að deila nafni þínu, flokkunarkóða og reikningsnúmeri.
        Við munum aðeins nota þessar upplýsingar ef þú biður um endurgreiðslu fyrir þessa færslu.`,
    permissionText: 'Ecospend Technologies Limited þarf leyfi þitt til að hefja greiðslufyrirmæli frá bankanum þínum á öruggan hátt.',
    privacyPolicyText: (termsUrl: string, privacyUrl: string) => <>
        Með því að smella á halda áfram samþykkir þú skilmála <a href={termsUrl} target='_blank'>okkar & skilyrði</a> og <a href={privacyUrl} target='_blank'>persónuverndarstefnuna</a>
    </>
})
