// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._26yvzcdkOVUA0ePvIwY2sP{display:block;width:40px;height:40px;margin:0 auto 20px}._1O8-itrYTqAJD8YnZ5JQa4{margin:2px 0 0 5px}._1z6xjyhfA9Z7OyqUhRKOXI{width:32px;height:32px;display:flex;align-items:center;border-radius:50%;justify-content:center;border:4px solid #4bb9aa;margin:0 auto 20px}._1z6xjyhfA9Z7OyqUhRKOXI svg{width:17px !important;height:12px !important}.CBA3Ib4izQpU8tOagzZ36{font-family:\"Open Sans\";font-size:24px;font-weight:normal;margin:0 0 10px 0;text-align:center}.EVJQxo57bIDLCtq9dvvDB{color:#dc3545 !important;text-transform:uppercase}._2ZPC30FuuQfYlrl80LWzc_{font-size:14px !important;margin:0 0 30px}.cYwDCQMXkQbFSAoP2Gzr9,.Lh3qBcGdX4Cv_V3vysKwG{margin-bottom:12px}.Lh3qBcGdX4Cv_V3vysKwG{padding:12px 20px !important;text-align:center}._3iyd7kMHCkOV7-wnNTSLgg{display:flex;justify-content:center;align-items:center;font-size:18px}.rPODU403VZlPtJr9ORoMQ{display:flex;max-width:400px;margin:0 auto}.Lfj81OGmyncyE0qC14L82{font-size:14px;width:100%;padding:10px 15px;text-transform:none}._1hUAqfaSsh6Eu03tUuq-KG{font-size:10px;margin:24px 0 0 0}._2REy_DKkUquo7ZQmiC3HVA{margin-bottom:35px}.ygSce2LCUB-Ud8hFB4sqb{margin:0 0 12px;font-size:12px;color:#9b9b9b;font-family:\"Open Sans\",sans-serif;font-weight:400;text-align:center}.RTkI1UEHvugjjbcZJks0k{white-space:nowrap}@media screen and (max-width: 767.9px){._2018nTgK8mEpLt2VGuvoWh{padding-top:24px}}", ""]);
// Exports
exports.locals = {
	"rejectIcon": "_26yvzcdkOVUA0ePvIwY2sP",
	"copyText": "_1O8-itrYTqAJD8YnZ5JQa4",
	"checkIcon": "_1z6xjyhfA9Z7OyqUhRKOXI",
	"title": "CBA3Ib4izQpU8tOagzZ36",
	"errorTitle": "EVJQxo57bIDLCtq9dvvDB",
	"subtitle": "_2ZPC30FuuQfYlrl80LWzc_",
	"block": "cYwDCQMXkQbFSAoP2Gzr9",
	"largeBlock": "Lh3qBcGdX4Cv_V3vysKwG",
	"transactionContent": "_3iyd7kMHCkOV7-wnNTSLgg",
	"buttons": "rPODU403VZlPtJr9ORoMQ",
	"button": "Lfj81OGmyncyE0qC14L82",
	"footertext": "_1hUAqfaSsh6Eu03tUuq-KG",
	"payeeBlock": "_2REy_DKkUquo7ZQmiC3HVA",
	"description": "ygSce2LCUB-Ud8hFB4sqb",
	"nowrap": "RTkI1UEHvugjjbcZJks0k",
	"EcospendResultPage": "_2018nTgK8mEpLt2VGuvoWh"
};
module.exports = exports;
