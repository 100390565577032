import React from 'react'
import { ActionButton, Image } from '~/code/components'
import { ConfigStore, LogStore, StyleStore } from '~/code/config'
import translations from './translations'
import { FailureProps } from './props'
import styles from './Failure.scss'
import { PageType, PaymentMethod } from '~/code/models'

export default function (props: FailureProps) {
    const renderHeader = () => {
        switch (ConfigStore.pageType) {
            case PageType.TOKENIZATION:
                return translations().tokenizationReject
            case PageType.VERIFICATION:
            case PageType.PAYBYLINK_VERIFICATION:
                return translations().verificationReject
            default:
                return translations().paymentReject
        }
    }

    // TODO description should come from payment method stores and should not be defined here
    const renderDescription = () => {
        if (ConfigStore.paymentMethod === PaymentMethod.PayPal) {
            return translations().payPalWasNotCompleted
        } else if (ConfigStore.paymentMethod === PaymentMethod.PayByBank) {
            return translations().payByBankWasNotCompleted
        } else if (ConfigStore.paymentMethod === PaymentMethod.GooglePay) {
            return translations().googlePayWasNotCompleted
        } else if (ConfigStore.paymentMethod === PaymentMethod.ApplePay) {
            return translations().applePayWasNotCompleted
        } else if (ConfigStore.paymentMethod === PaymentMethod.Ecospend) {
            return translations().ecospendWasNotCompleted
        } else if (ConfigStore.paymentMethod === PaymentMethod.Klarna) {
            return translations().klarnaWasNotCompleted
        } else if (ConfigStore.paymentMethod === PaymentMethod.ClickToPay) {
            return translations().clickToPayWasNotCompleted
        }

        if (props?.error?.code) {
            const code = props.error.code
            if ( (3010 <= code && code <= 3018) || code === 4020) {
                return translations().hasNotAuthorisedPleaseCheck
            } else if (code === 4132) {
                return ConfigStore.allowedAttempts === 0 ? translations().hasNotAuthorisedPleaseRetry : translations().hasNotAuthorisedPleaseCheck
            } else if (code ===  4001) {
                return translations().hasBeenTechnicalError
            } else if (3010 <= code && code <= 3018) {
                return translations().hasBeenTechnicalError
            }
        }

        if (props?.error?.payload && props?.error?.payload?.errorCode) {
            if (props.error.payload.errorCode === 2002) {
                return translations().invalidUnsupportedPaymentMethod
            }
        }

        return translations().hasNotAuthorisedPleaseCheck
    }

    const tryAgainButton = () => {
        return (
            <div data-id={'try-again-button'}>
                <ActionButton
                    className={ styles.BackToPaymentForm }
                    colorStyle={ StyleStore.style }
                    onClick={ () => {
                        props.tryAgain()
                    } }
                >
                    { translations().tryAgain }
                </ActionButton>
            </div>
        )
    }

    const returnToBackButton = () => {
        return (
            <div data-id={'go-back-to-form-button'}>
                <ActionButton
                    className={ styles.BackToPaymentForm }
                    onClick={ () => props.returnToBack() }
                >
                    { translations().returnToBack }
                </ActionButton>
            </div>
        )
    }

    const renderActionButton = () => {
        if (ConfigStore.allowedAttempts === 0) {
            if (
                ([PageType.PAYBYLINK, PageType.PAYBYLINK_VERIFICATION].includes(ConfigStore.pageType) && !props.paymentData?.paymentSettings?.returnUrl) || 
                (ConfigStore.paymentMethod === PaymentMethod.PayByBank && !props.paymentData?.paymentSettings?.failureReturnUrl)
            ) {
                return null
            }
            return returnToBackButton()
        }

        return tryAgainButton()
    }

    const renderAction = () => {
        return (
            <div className={ styles.Action }>
                { renderActionButton() }
            </div>
        )
    }

    return (
        <div className={ styles.Failure } data-id={'failure'}>
            <div className={ styles.Content }>
                <Image className={ styles.RejectIcon } src={ 'reject.svg' } />
                <div className={ styles.Status }> { renderHeader() }</div>
                {
                    LogStore.errorCode && (
                        <div className={ styles.ErrorCode }>
                            { `#${ LogStore.errorCode }`}
                        </div>
                    )
                }
                <div className={ styles.Description }>
                    <div>{ renderDescription() }</div>
                </div>
            </div>
            { renderAction() }
        </div>
    )
}
