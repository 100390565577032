import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { ErrorBoundary } from 'react-error-boundary'
import { Processing } from '~/code/pages'
import { UPIThreeDSecureProps } from './props'
import styles from './UPIThreeDSecure.scss'
import translations from './translations'

export const UPIThreeDSecure = observer((props: UPIThreeDSecureProps) => {

    const { store } = props

    useEffect(() => {
        store.setErrorMessage('')
        store.addListeners()

        return () => { store.removeListeners() }
    }, [])

    return (
        <ErrorBoundary
            FallbackComponent={() => null}
            onError={error => store.setErrorMessage(error.message)}
        >
            {store.isLoading && <Processing />}
            {!store.isSafari && (
                <div>
                    <iframe
                        ref={ store.iframe }
                        name='upi_3ds_frame'
                        srcDoc={ store.srcDoc }
                        id='upi_3ds_frame'
                        className={ `${ styles.Iframe } ${ store.iframeLoaded ? styles.Show : styles.Hidden }` }
                    >
                        <p>{translations().yourBrowserDoesNotSupportIFrames}</p>
                    </iframe>
                </div>
            )}
            
        </ErrorBoundary>
    )

})
