import { SelectItem } from '~/code/models'
import translations from './../translations'
import { Reason } from './../models'

export function getReasonList (): SelectItem[] {
    return [
        {
            label: translations().reason.pageDidNotLoad,
            value: Reason.PAGE_DID_NOT_LOAD
        },
        {
            label: translations().reason.didNotReceiveConfirmationCode,
            value: Reason.DID_NOT_RECEIVE_CONFIRMATION_CODE
        }
    ]
}
