import React from 'react'
import translations from './translations'

translations.add('en', {
    paymentSuccessful: 'Payment successful',
    tokenizationSuccessful: 'Card registration successful',
    verificationSuccessful: 'Card verification successful',
    autoRedirectAfter: 'You will be automatically redirected to the site after',
    seconds: 'sec',
    backToStore: 'Return to Site',
    yourPaymentWasSuccessfulWith: (paymentMethod: string, paymentDescription?: string) => {
        return (<>Your payment was successfully processed using <strong>{paymentMethod} {paymentDescription}</strong></>)
    }
})
