import React from 'react'
import styles from './ActionButton.scss'
import classNames from 'classnames'
import { StyleStore } from '~/code/config'
import { ActionButtonProps } from './props'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    Button: {
        borderRadius: (style) => `${ style.actionButtonBorderRadius }px`
    }
})

export const ActionButton = (props: ActionButtonProps) => {
        const { colorStyle = StyleStore.getDefaultStyle(), ...buttonProps } = props
        const classes = useStyles(colorStyle)

        return (
            <button
                { ...buttonProps }
                className={ classNames(styles.ActionButton, props.className,  classes.Button, {[styles.disabled]: props.disabled}) }>
                { props.children }
            </button>
        )
}
