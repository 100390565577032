import translations from './translations'

translations.add('en', {
    orderNumber: 'Order number',
    orderDescription: 'Order description',
    total: 'Total',
    includeDonation: 'incl. donation of',
    descriptor: 'Your card will be charged by',
    companyName: 'Company name',
    companyAddress: 'Registered Office'
})
