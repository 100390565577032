import { CreditCardType } from '~/code/pages/CardData/models'
import translations from '../translations'


export function getCreditCardTypeByScheme (schemeId = '', schemeName = '') {
    return (schemeId && getCreditCardTypeBySchemeId(schemeId)) ||
        (schemeName && getCreditCardTypeBySchemeName(schemeName?.toLowerCase())) ||
        (schemeName && getCreditCardTypeBySchemeNameRegex(schemeName?.toLowerCase())) ||
        ''
}

export function getCreditCardTypeBySchemeName (schemaName) {
    if (['americanexpress', 'amex', 'american express', 'ae', 'amex', 'amex cpc'].includes(schemaName)) {
        return CreditCardType.AMERICAN_EXPRESS
    } else if (['diners', 'dci'].includes(schemaName)) {
        return CreditCardType.DINERS_CLUB
    } else if (['discover'].includes(schemaName)) {
        return CreditCardType.DISCOVER
    } else if (['jcb'].includes(schemaName)) {
        return CreditCardType.JCB
    } else if (['uk maestro', 'maestro'].includes(schemaName)) {
        return CreditCardType.MAESTRO
    } else if (['mastercard', 'master card', 'mc', 'master', 'mastercard one', 'mastercard debit'].includes(schemaName)) {
        return CreditCardType.MASTERCARD
    } else if (['visa', 'visa atm only', 'visa cpc (mi only)', 'visa cpc (vat)', 'visa debit', 'visa electron', 'visa purchasing'].includes(schemaName)) {
        return CreditCardType.VISA
    } else if (['flexecash'].includes(schemaName)) {
        return CreditCardType.FLEXECASH
    } else if (['unionpay', 'union pay', 'upi', 'unionpay(amex)', 'unionpay(diners)', 'unionpay(jcb)', 'unionpay(maestro)', 'unionpay(mastercard)', 'unionpay(visa)'].includes(schemaName)) {
        return CreditCardType.UNIONPAY
    }

    return ''
}

export function getCreditCardTypeBySchemeNameRegex (schemaName = '') {
    if (schemaName?.includes('unionpay') || schemaName?.includes('upi')) { // union has more priority // maybe has collision
        return CreditCardType.UNIONPAY
    } else if (schemaName?.includes('diners') || schemaName?.includes('dci')) {
        return CreditCardType.DINERS_CLUB
    } else if (schemaName?.includes('discover')) {
        return CreditCardType.DISCOVER
    } else if (schemaName?.includes('jcb')) {
        return CreditCardType.JCB
    } else if (schemaName?.includes('maestro')) {
        return CreditCardType.MAESTRO
    } else if (schemaName?.includes('mc') || schemaName?.includes('master')) {
        return CreditCardType.MASTERCARD
    } else if (schemaName?.includes('visa')) {
        return CreditCardType.VISA
    } else if (schemaName?.includes('flexecash')) {
        return CreditCardType.FLEXECASH
    }

    return ''

}

export function getCreditCardTypeBySchemeId (schemaId) {
    switch (schemaId) {
        case '1':
        case '2':
            return CreditCardType.AMERICAN_EXPRESS
        case '3':
            return CreditCardType.DINERS_CLUB
        case '4':
            return CreditCardType.DISCOVER
        case '6':
            return CreditCardType.JCB
        case '7':
        case '17':
            return CreditCardType.MAESTRO
        case '8':
        case '9':
        case '10':
            return CreditCardType.MASTERCARD
        case '11':
        case '12':
        case '13':
        case '14':
        case '15':
        case '16':
        case '25':
            return CreditCardType.VISA
        case '18':
        case '19':
        case '20':
        case '21':
        case '22':
        case '23':
        case '24':
            return CreditCardType.UNIONPAY
        default:
            return ''

    }
}

export const getCreditCardBrandNameByType = (cardType: CreditCardType) => {
    switch (cardType) {
        case CreditCardType.VISA:
            return translations().visa
        case CreditCardType.MASTERCARD:
            return translations().mastercard
        case CreditCardType.AMERICAN_EXPRESS:
        case CreditCardType.AMEX:
            return translations().amex
        case CreditCardType.DISCOVER:
            return translations().discover
        case CreditCardType.DINERS_CLUB:
            return translations().dinersClub
        case CreditCardType.UNIONPAY:
            return translations().unionPay
        case CreditCardType.JCB:
            return translations().jcb
        default:
            return ''
    }
}

