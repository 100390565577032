import React from 'react'
import { observer } from 'mobx-react'
import styles from './EmbeddedWidget.scss'
import { EmbeddedWidgetProps } from './props'
import { ConfigStore, StyleStore } from '~/code/config'
import { Image, PaymentMethodList } from '~/code/components'
import translations from './translations'
import { PaymentDataV2 } from '~/code/models'

@observer
export class EmbeddedWidget extends React.Component<EmbeddedWidgetProps> {

    render (): React.ReactElement {
        const { store } = this.props
        return (
            <div
                className={ styles.EmbeddedWidget }
            >
                <div className={ styles.Content }
                     style={{
                         borderColor: StyleStore.style.embeddedWidget.primaryColor || StyleStore.style.primaryColor
                     }}
                >
                    <PaymentMethodList
                        paymentMethods={this.props.config?.paymentMethods}
                        paymentData={this.props.store.paymentData as PaymentDataV2}
                        onSelectItem={ (v) => {
                            this.props.store.onSelectPaymentMethod(v)
                        }}
                        cardDataStore={this.props.cardDataStore}
                        paypalStore={ this.props.paypalStore }
                        payByBankStore={ this.props.payByBankStore }
                        applePayStore={ this.props.applePayStore }
                        googlePayStore={ this.props.googlePayStore }
                        ecospendStore={ this.props.ecospendStore }
                        astroPayStore={ this.props.astroPayStore }
                        klarnaStore={ this.props.klarnaStore }
                        alipayStore={ this.props.alipayStore }
                        alipayPlusStore={ this.props.alipayPlusStore }
                        weChatPayStore={ this.props.weChatPayStore }
                        clickToPayComponentStore={ this.props.clickToPayComponentStore }
                        {
                            ...(
                                ConfigStore.embeddedWidget && ConfigStore.embeddedWidget.maxVisiblePaymentMethods ?
                                    { maxItems: ConfigStore.embeddedWidget.maxVisiblePaymentMethods } : {}
                               )
                        }
                    />
                    {
                        StyleStore.style.embeddedWidget && StyleStore.style.embeddedWidget.showPoweredByBlock && (
                            <div className={ styles.PoweredByBlock }>
                                <div className={ styles.PoweredBy }>
                                    { `${ translations().poweredBy }: ` }
                                </div>
                                <Image className={ styles.DnaLogo } src={ 'logo-gray.svg' }/>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}
