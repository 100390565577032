// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._6mreIyw36JxWX7-Tu1fiI{display:flex}._1bkyu-SnBBaZSnVhuPtqI_{width:32px;height:24px}._1bkyu-SnBBaZSnVhuPtqI_:not(:last-child){margin-right:7px}._1v6-ClMwg_fPyrPj-Yc5_C{border-left:2px solid #000;height:24px;margin-right:8px}.small ._1bkyu-SnBBaZSnVhuPtqI_{width:28.5px;height:19px}.small ._1bkyu-SnBBaZSnVhuPtqI_:not(:last-child){margin-right:4px}.small ._1v6-ClMwg_fPyrPj-Yc5_C{height:19px;margin-right:5px}", ""]);
// Exports
exports.locals = {
	"root": "_6mreIyw36JxWX7-Tu1fiI",
	"image": "_1bkyu-SnBBaZSnVhuPtqI_",
	"verticalLine": "_1v6-ClMwg_fPyrPj-Yc5_C"
};
module.exports = exports;
