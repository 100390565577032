import { listenAttempts } from './listeners'

export function openPopupWindow(url: string, windowName: string, onWindowClose: () => void, options?: { maxWidth?: number, maxHeight?: number }) {
    const isDesktop = window.outerWidth > 768
    let width = isDesktop ? window.outerWidth * 0.8 : window.outerWidth
    let height = isDesktop ? window.outerHeight * 0.9 : window.outerHeight
    if (options?.maxWidth && width > options.maxWidth) {
        width = options.maxWidth
    }
    if (options?.maxHeight && height > options.maxHeight) {
        height = options.maxHeight
    }
    const top = Math.ceil((window.outerHeight - height) / 2)
    const left = Math.ceil((window.outerWidth - width) / 2)
    const openedWindow = window.open(url, windowName, `width=${width},height=${height},left=${left},top=${top}`)
    listenPopupWindowClose(openedWindow, onWindowClose)
    return openedWindow
}

export function getQueryVariables(w: Window) {
    const query = w.location.search.substring(1)
    const items = query.split('&')
    const result = {}
    for (const item of items) {
        const pair = item.split('=')
        result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }
    return result
}

export function listenPopupWindowClose(popupWindow: Window, onClose: () => void) {
    const timer = setInterval(() => {
        if (!popupWindow || popupWindow.closed) {
            clearInterval(timer)
            onClose()
        }
    }, 1000)
}

export const listenPopupReturn = <T = any>(popupWindow: Window, url: string) => listenAttempts<T>(
    async () => {
        try {
            const { origin, pathname } = popupWindow.location
            return { isOk: origin + pathname === url, data: getQueryVariables(popupWindow) as T }
        } catch {
            return { isOk: false, data: null }
        }
    }
)

export function getBrowserName() {
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1 ) {
        return 'Opera'
    }
    else if (navigator.userAgent.indexOf('Edg') !== -1 ) {
        return 'Edge'
    }
    else if (navigator.userAgent.indexOf('Chrome') !== -1 ) {
        return 'Chrome'
    }
    else if (navigator.userAgent.indexOf('Safari') !== -1) {
        return 'Safari'
    }
    else if (navigator.userAgent.indexOf('Firefox') !== -1 ) {
        return 'Firefox'
    }
    else if ((navigator.userAgent.indexOf('MSIE') !== -1 ) || (!!document.documentMode === true )) {
        return 'IE'
    }  
    else {
        return 'unknown'
    }
}