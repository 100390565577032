import React from 'react'
import { observer } from 'mobx-react'
import styles from './ThreeDSecure.scss'
import { ThreeDSecureProps } from './props'
import { Processing } from '~/code/pages'
import { ErrorBoundary } from 'react-error-boundary'
import translations from './translations'

@observer
export default class extends React.Component<ThreeDSecureProps, any> {

    public firstVersionFields() {
        const { store } = this.props
        return (
            <>
                <input type='hidden' name='PaReq' defaultValue={ store.secure3Dfield.paReq } />
                <input type='hidden' name='MD'  defaultValue={ store.secure3Dfield.md }></input>
                <input type='hidden' name='TermUrl' defaultValue={ store.secure3Dfield.termUrl }></input>
            </>
        )
    }

    public secondVersionFields() {
        const { store } = this.props
        return (
            <>
                <input type='hidden' name='creq' defaultValue={ store.secure3Dfield.creq } />
                <input type='hidden' name='threeDSSessionData'  defaultValue={ store.secure3Dfield.threeDSSessionData }></input>
            </>
        )
    }

    render (): React.ReactElement {
        const { store } = this.props
        return (
            <ErrorBoundary
                FallbackComponent={() => null}
                onError={error => store.setErrorMessage(error.message)}
            >
                <div className={ styles.ThreeDSecure }>
                    <div className={ `${ !store.iframeLoaded ? styles.Show : styles.Hidden }` }>
                        <Processing />
                    </div>
                    <div>
                        <form
                            target={ 'output_frame' }
                            ref={ (node) => { store.form = node } }
                            method='POST'
                            action={ store.secure3Dfield.actionUrl }
                        >
                            {
                                store.secure3Dfield.version === '2' ? this.secondVersionFields() : this.firstVersionFields()
                            }
                        </form>
                    </div>
                    <iframe
                        ref={ (node) => { store.iframe = node } }
                        name='output_frame'
                        src={ store.secure3Dfield.actionUrl }
                        id='output_frame'
                        className={ `${ styles.Iframe } ${ store.iframeLoaded ? styles.Show : styles.Hidden }` }
                    >
                        <p>{translations().yourBrowserDoesNotSupportIFrames}</p>
                    </iframe>
                </div>
            </ErrorBoundary>
        )
    }

    componentDidMount (): void {
        const { store } = this.props
        store.setErrorMessage('')
        store.addListeners()
    }

    componentWillUnmount (): void {
        const { store } = this.props
        store.removeListeners()
    }

}
