import { toJS } from 'mobx'
import { ConfigStore } from '~/code/config'
import { minusTwoNumbers } from './maths'
import { generateAmount, generateInvoiceId } from './generators'
import { inIframe, isBase64ImageUr, isDevelopmentMode, isFullUrl, isTestMode } from './checkers'
import { AdjustmentBands, AuthData, DonationConfiguration, IntegrationScope } from '~/code/models'
import constants from '~/code/config/constants'
import { DonationMode } from '~/code/models/DonationMode'
import { BrowserDetails } from '~/code/models/V2/BrowserDetails'

export function getPaymentFormScope(): IntegrationScope {
    return inIframe() ? IntegrationScope.INTEGRATION_EMBEDDED : IntegrationScope.INTEGRATION_HOSTED
}

export function getTestUser() {
    return  {
        ...constants.testUserInfo,
        invoiceId: generateInvoiceId(),
        amount: generateAmount()
    }
}

export function getProdUser(): AuthData {
    return {
        client_id: 'almacafe',
        client_secret: 'rF587AV9Ms94qN2QShFzVR3vFnWkhjbAK3sG',
        terminal: '35baee61-93df-40d9-b32c-31b867ed1988',
        currency: 'GBP',
        invoiceId: generateInvoiceId(),
        amount: 24
    }
}

export function getUserCredentials(): AuthData {
    if (isTestMode() || isDevelopmentMode()) return getTestUser()
    return getProdUser()
}

export function getScopes(): string {
    const config = ConfigStore.scopes
    const scopes = [ constants.scope ]
    if (config.useRedirect || config.allowHosted) scopes.push(IntegrationScope.INTEGRATION_HOSTED) // TODO: old versions will be delete
    if (config.useIframe || config.allowEmbedded) scopes.push(IntegrationScope.INTEGRATION_EMBEDDED)
    if (config.useCustomModal || config.allowSeamless) scopes.push(IntegrationScope.INTEGRATION_SEAMLESS)

    return scopes.join(' ')
}

export function getImageUrl(name) {
    if (isFullUrl(name) || isBase64ImageUr(name) || name.includes('/img/')) return name
    return ConfigStore.getUrl().paymentPageUrl + '/img/' + name
}

export function getBase64ImageUrl (type, url) {
    // check if type and url are not empty
    if (!type || !url) {
        return ''
    }
    return `data:${ type };base64,${ url }`
}

export function getChangedEmbeddedFields () {
    const fields = {}
    for (const [key, value] of Object.entries(toJS(ConfigStore.embeddedWidget))) {
        if (!constants.defaultEmbeddedWidget[key] || String(constants.defaultEmbeddedWidget[key]) !== value) {
            fields[key] = value
        }
    }

    return fields
}

export function getDonationAmountByDonationMode (donation: DonationConfiguration, amount: number): number {
    switch (donation.donationMode) {
        case DonationMode.TopUp:
            return getDonationTopUpAmount(donation.adjustmentBands, amount)
        case DonationMode.RoundUp:
            return getDonationRoundUpAmount(donation.adjustmentBands, amount)
        default:
            return 0
    }
}

function getDonationTopUpAmount (adjustmentBands: AdjustmentBands[], amount) {
    const band = adjustmentBands.find(el => el.bandFloorValue < amount && amount <= el.bandCeilValue)
    return band ? band.adjustmentValue : 0
}

function getDonationRoundUpAmount (adjustmentBands: AdjustmentBands[], amount) {
    const band = adjustmentBands.find(el => el.bandFloorValue < amount && amount <= el.bandCeilValue)
    const decimal = minusTwoNumbers(amount, Math.floor(amount))
    if (!band || !decimal) return 0
    return decimal < band.adjustmentValue ? minusTwoNumbers(band.adjustmentValue, decimal) : minusTwoNumbers(Math.ceil(amount), amount)
}

const currencySymbols = {
    'AFN': '\u060b', // ؋
    'AMD': '\u058f', // ֏
    'ANG': '\u0192', // ƒ
    'AWG': '\u0192', // ƒ
    'AZN': '\u20bc', // ₼
    'BDT': '\u09f3', // ৳
    'BTC': '\u0243', // Ƀ
    'CNY': '\u00a5', // ¥
    'CRC': '\u20a1', // ₡
    'HUF': 'ƒ',
    'EUR': '\u20ac', // €
    'FKP': '\u00a3', // £
    'GBP': '\u00a3', // £
    'GHS': '\u20b5', // ₵
    'GIP': '\u00a3', // £
    'IDR': '₨',
    'ILS': '\u20aa', // ₪
    'INR': '\u20b9', // ₹
    'IRR': '\ufdfc', // ﷼
    'JPY': '\u00a5', // ¥
    'KHR': '\u17db', // ៛
    'KPW': '\u20a9', // ₩
    'KRW': '\u20a9', // ₩
    'KZT': '\u20b8', // ₸
    'LAK': '\u20ad', // ₭
    'LKR': '\u20a8', // ₨
    'MNT': '\u20ae', // ₮
    'MTL': '\u20a4', // ₤
    'MUR': '\u20a8', // ₨
    'NGN': '\u20a6', // ₦
    'NPR': '\u20a8', // ₨
    'OMR': '\ufdfc', // ﷼
    'PHP': '\u20b1', // ₱
    'PKR': '\u20a8', // ₨
    'PYG': '\u20b2', // ₲
    'QAR': '\ufdfc', // ﷼
    'RUB': '\u20BD', // ₽
    'SAR': '\ufdfc', // ﷼
    'SCR': '\u20a8', // ₨
    'SDG': '\u00a3', // £
    'SHP': '\u00a3', // £
    'SVC': '\u20a1', // ₡
    'SYP': '\u00a3', // £
    'THB': '\u0e3f', // ฿
    'TRY': '₺',
    'UAH': '\u20b4', // ₴
    'USD': '\u0024', // $
    'VND': '\u20ab', // ₫
    'YER': '\ufdfc'  // ﷼
}

export function getSymbolFromCurrency (name) {
    return currencySymbols[name] ? currencySymbols[name] : name
}

function getScreenColorDepth (): string {
    // If the value is not in the accepted values, it will be resolved to the first accepted value lower from the one
    const possibleOptions = [1, 4, 8, 15, 16, 24, 32, 48]
    const screenColorDepth = window && window.screen ? window.screen.colorDepth : 0
    let i = 0

    while (possibleOptions[++i] <= screenColorDepth);

    return String(possibleOptions[--i])
}

function getChallengeWindowSize () {
    const screenWidth = window && window.screen ? String(window.screen.width) : 0
    if (screenWidth > 768) {
        return '03'
    } else if (screenWidth > 425) {
        return '02'
    }
    return '01'
}

export function getBrowserInfo(): BrowserDetails {

    const screenWidth = window && window.screen ? String(window.screen.width) : ''
    const screenHeight = window && window.screen ? String(window.screen.height) : ''

    const userAgent = window && window.navigator ? window.navigator.userAgent : ''
    const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false

    let language = ''
    if (window && window.navigator) {
        language = window.navigator.language
            ? window.navigator.language
            // @ts-ignore
            : window.navigator.browserLanguage // Else is for IE <+ 10
    }

    const d = new Date()
    const timeZone = String(d.getTimezoneOffset())

    const browserInfo = {
        screenWidth,
        screenHeight,
        screenColorDepth: getScreenColorDepth(),
        userAgent,
        timeZone,
        language,
        javaEnabled,
        challengeWindowSize: getChallengeWindowSize(),
        acceptHeader: 'text/html'
    }

    return browserInfo
}

export const getNotEmptyFields = (obj: any) => {
    if (!obj) return {}
    return Object.entries(obj).reduce((prev, [key, value]) => {
        if (value === null || value === undefined) return prev       
        return {
            ...prev,
            [key]: value
        }
    }, {})
}
