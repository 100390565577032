import React, { forwardRef, LegacyRef } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { StyleStore } from '~/code/config'
import { TextAreaProps } from './props'
import styles from './TextArea.scss'


const useStyles = createUseStyles({
    Element: {
        '&:focus': {
            borderColor: () => StyleStore.style.primaryColor
        }
    }
})

export default observer(forwardRef((props: TextAreaProps = {}, forwardedRef: LegacyRef<any>) => {
    const { errorText, warningText, ...textAreaProps } = props
    const classes = useStyles()

    const getTextAreaClassname = () => {
        if (errorText) {
            return styles.HasError
        } else if (warningText) {
            return styles.HasWarning
        }
        return ''
    }

    const renderMessage = () => {
        if (errorText) {
            return (
                <div className={ styles.Error }>
                    { errorText }
                </div>
            )
        } else if (warningText) {
            return ( <div className={ styles.Warning }>
                { warningText }
            </div> )
        }
        return null
    }

    return (
        <div
            className={ classNames(styles.TextArea, { [styles.HasValue]: props.value }, getTextAreaClassname()) }
        >
            <textarea
                ref={ forwardedRef }
                { ...textAreaProps }
                className={ classNames(styles.Element, classes.Element, props.className) }
            />
            {
                renderMessage()
            }
        </div>
    )
}))
