import { error } from '~/code/services/logger'

let closeTransactionCallback: () => void

function onBeforePageClose(evt) {
    const message = 'Card data is not saved. You will lose the changes if you leave the page.'
    if (typeof evt === 'undefined') {
        evt = window.event
    }
    if (evt) {
        evt.returnValue = message
    }
    closeTransactionCallback && closeTransactionCallback()
    return message
}

export function addHandleBeforePageClose (callback?) {
    closeTransactionCallback = callback
    window.addEventListener('beforeunload', onBeforePageClose)
}

export function removeHandleBeforePageClose () {
    try {
        window.removeEventListener('beforeunload', onBeforePageClose)
        window.parent.removeEventListener('beforeunload', onBeforePageClose)
    } catch (e) {
        error(e)
    }
}

// APPLE PAY RELATED POST MESSAGES HANDLERS
export function addApplePayPostMessageHandler (onPOSTMessage) {
    window.addEventListener('message', onPOSTMessage)
}

export function removeApplePayPostMessageHandler (onPOSTMessage) {
    window.removeEventListener('message', onPOSTMessage)
}

export const disableIosTextFieldZoom = () => {
    const el = document.querySelector('meta[name=viewport]')

    if (el !== null) {
        let content = el.getAttribute('content')
        const re = /maximum\-scale=[0-9\.]+/g

        if (re.test(content)) {
            content = content.replace(re, 'maximum-scale=1.0')
        } else {
            content = [content, 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'].join(', ')
        }

        el.setAttribute('content', content)
    }
}
