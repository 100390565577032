// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3JL1CPfiHfP7N4vPcSPnjP{text-align:center;width:100%;margin:20px 0 0}._1y1I4yx49FTcwCnuuTrzUW{min-width:100%;padding-right:62px !important}._1ifqS19-rvPsS2gv3oVd0d,._3hKsxjY5WxTfwG3BOKY716,._2JDujEzOcXXLC3IgNfMHYB{margin:0 0 15px;font-size:14px;color:#9b9b9b;font-family:\"Open Sans\",sans-serif;font-weight:400;text-align:center}._2JDujEzOcXXLC3IgNfMHYB{display:block;margin:15px 0 0}._2V8V_gpRJ5GVc7MsZIBt8I{margin:0 0 15px}.Fon4HDSBbG0yDkTkkNWal{font-family:\"Poppins\",sans-serif;font-weight:500;color:#9b9b9b;cursor:pointer}._3hKsxjY5WxTfwG3BOKY716{color:#dc3545 !important;margin:15px 0 0}._3wx5hMfV3LT-7Wr5B6lnPN{margin-right:-24px}", ""]);
// Exports
exports.locals = {
	"root": "_3JL1CPfiHfP7N4vPcSPnjP",
	"input": "_1y1I4yx49FTcwCnuuTrzUW",
	"text": "_1ifqS19-rvPsS2gv3oVd0d",
	"error": "_3hKsxjY5WxTfwG3BOKY716",
	"link": "_2JDujEzOcXXLC3IgNfMHYB",
	"back": "_2V8V_gpRJ5GVc7MsZIBt8I",
	"button": "Fon4HDSBbG0yDkTkkNWal",
	"loader": "_3wx5hMfV3LT-7Wr5B6lnPN"
};
module.exports = exports;
