import React, { useState, useEffect } from 'react'
import { TIME_OUT_TIME, WARN_BEFORE_TIME } from './models/constants'
import { TimeoutCountdownProps } from './props'
import { TimeoutWarningMessage } from './components'
import { TimedOutMessageModal } from '~/code/pages/PaymentForm/components/TimeoutCountdown/components/TimedOutMessageModal/TimedOutMessageModal'
import { useTimer } from '~/code/pages/PaymentForm/components/TimeoutCountdown/services/useTimer'
import { isEmptyOrZero } from '~/code/pages/PaymentForm/components/TimeoutCountdown/services/utils'
import { ConfigStore } from '~/code/config'

export const TimeoutCountdown: React.FC<TimeoutCountdownProps> = (props) => {
    const timeOut = isEmptyOrZero(props.timeOut) ? TIME_OUT_TIME : props.timeOut

    const {
        warnBefore = WARN_BEFORE_TIME,
        shouldShow = true
    } = props

    const [showWarningMessage, setShowWarningMessage] = useState(false)
    const [showTimedOutMessageModal, setShowTimedOutMessageModal] = useState(false)

    const onCancelSession = () => {
        setShowWarningMessage(false)
        setShowTimedOutMessageModal(true)
    }

    const { start: startTimer, count } = useTimer(timeOut)

    useEffect(() => {
        startTimer()
    }, [])

    useEffect(() => {
        ConfigStore.setField('leftTimeForPaymentTimeout', count)
        if (count <= warnBefore && !showWarningMessage) {
            setShowWarningMessage(true)
        }
        if (count < 1) {
            onCancelSession()
        }
    }, [count])


    return <div>
        { showWarningMessage &&
            shouldShow &&
            <TimeoutWarningMessage
                countDownFrom={count}
                onTimeout={onCancelSession}
            />
        }
        {
            <TimedOutMessageModal
                isOpen={showTimedOutMessageModal && shouldShow}
                close={props.onCancelSession}
                closeButtonText={props.timedOutMessageModalCloseButtonText}
            />
        }
    </div>
}
