import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { ThreeDotsLoader } from '~/code/components'
import styles from './SpinThreeDotsLoader.scss'
import { SpinThreeDotsLoaderProps } from './props'

export default observer( ({ className, isLoading, children } : SpinThreeDotsLoaderProps) => {
    return (
        <div className={ classNames(styles.SpinThreeDotsLoader, { [styles.Loading]: isLoading }, className) }>
            <ThreeDotsLoader className={ styles.Loader } />
            { children }
        </div>
    )
})
