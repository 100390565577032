import React from 'react'

const CopyIcon: React.FC<{ color?: string }> = ({ color = '#8D99A7' }) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.627 12.3848H11.9375C13.0977 12.3848 13.6836 11.7812 13.6836 10.6152V5.25977H10.3789C9.80469 5.25977 9.4707 4.91992 9.4707 4.35156V1H6.58203C5.42188 1 4.83008 1.59766 4.83008 2.76953V3.02734H5.55664C6.36523 3.02734 6.99219 3.25 7.625 3.88281L10.7656 7.02344C11.3984 7.66211 11.627 8.30664 11.627 9.0918V12.3848ZM10.502 4.54492H13.4199C13.3906 4.36328 13.2676 4.20508 13.0684 4.00586L10.7188 1.61523C10.5254 1.41602 10.3613 1.28711 10.1797 1.26367L10.1855 4.22852C10.1855 4.45117 10.2734 4.54492 10.502 4.54492ZM3.74609 15.1504H9.10742C10.2676 15.1504 10.8535 14.5527 10.8535 13.3867V8.94531H6.80469C6.08984 8.94531 5.75586 8.60547 5.75586 7.89648V3.79492H3.74609C2.5918 3.79492 2 4.39258 2 5.56445V13.3867C2 14.5586 2.5918 15.1504 3.74609 15.1504ZM6.82227 8.17188H10.5664C10.5137 7.94922 10.3555 7.73828 10.0977 7.48047L7.2207 4.55078C6.97461 4.29883 6.75195 4.14062 6.5293 4.08203V7.87891C6.5293 8.07227 6.62891 8.17188 6.82227 8.17188Z" fill={color}/>
    </svg>
)

export default CopyIcon

