export enum CreditCardType {
    VISA = 'visa',
    MASTERCARD = 'mastercard',
    AMERICAN_EXPRESS = 'american-express',
    AMEX = 'amex',
    DINERS_CLUB = 'diners-club',
    DISCOVER = 'discover',
    JCB = 'jcb',
    UNIONPAY = 'unionpay',
    MAESTRO = 'maestro',
    ELO = 'elo',
    MIR = 'mir',
    HIPER = 'hiper',
    HIPERCARD = 'hipercard',
    FLEXECASH = 'flexecash'
}
