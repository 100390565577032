import React from 'react'
import classNames from 'classnames'
import styles from './Tooltip.scss'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'
export default ({ children, tooltip, overlayClassName = '', ...props }) => (
    <Tooltip destroyTooltipOnHide={ true } {...props} overlay={ tooltip } overlayClassName={ classNames(styles.Tooltip, overlayClassName) }>
        { children }
    </Tooltip>
)
