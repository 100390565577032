import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { ConfigStore } from '~/code/config'
import { SearchSVG } from '~/assets/icons'
import { Input, SpinThreeDotsLoader } from '~/code/components'
import translations from './translations'
import { EcospendBankListProps } from './props'
import styles from './EcospendBankList.scss'

export const EcospendBankList = observer((props: EcospendBankListProps) => {
    const { store } = props
    const arrowImage = `url(${ConfigStore.getUrl().paymentPageUrl}/img/right-arrow.svg)`
    useEffect(() => {
        if (!store.isBanksLoaded) {
            store.fetchBanks()
        }
    }, [])

    return <SpinThreeDotsLoader isLoading={store.isCreatingOrder}>
        <>
        <Input
            name='searchText'
            type='text'
            placeholder={ translations().selectBank }
            onChange={ (e) => store.setSearchText(e.target.value) }
            className={ styles.input }
            value={ store.searchText }
            showPlaceholderWhenNotFocus={ true }
            iconClassName={ styles.inputIcon }
            renderIcon={ () => <SearchSVG /> }
        />

        <div className={styles.banks}>
            { store.filteredBanks.map((bank) => <div
                key={bank.bank_id}
                className={classNames(styles.bankContainer, {[styles.disabled]: !bank.service_status})}
            >
                <div
                    onClick={ () => {
                        store.setSelectedBank(bank)
                        store.createOrder()
                    } }
                    className={styles.bank}
                    title={bank.name}
                >
                    <img src={bank.logo} alt={bank.name} />
                    <div className={styles.bankText}>{bank.friendly_name}</div>
                    <div className={styles.arrow} style={{backgroundImage: arrowImage}} />
                </div>
                { !bank.service_status && <div className={styles.bankDisabled} /> }
            </div>)}
        </div>
    </>
    </SpinThreeDotsLoader>
})
