import translations from './translations'

translations.add('pt', {
    headerMessage: 'This page is used to confirm payment by your card issuer',
    sorryToSee: 'We are sorry to see you experience issues with this payment.',
    selectReason: 'Please select a reason that describes the issue you are facing as this will help to improve our service',
    reason: {
        pageDidNotLoad: 'Confirmation page did not load',
        didNotReceiveConfirmationCode: 'Did not receive a confirmation code'
    },
    submit: 'Submit',
    thankYou: 'Thank you',
    yourFeedbackExtremelyValuable: 'Your feedback is extremely valuable for us',
    contactYourCardProvider: 'Please contact your card provider for further assistance or',
    reSubmitPayment: 'RESUBMIT PAYMENT',
    additionalInfo: {
        line1: 'Please know that your personal data is collected and stored safely and securely.',
        line2: 'Under no circumstance would we gain access to this data.',
        line3: 'We are only collecting information regarding why your transaction has failed.',
        line4: 'If your transaction fails, please contact your card provider, who will be able to deal with this, and not DNA Payments.'
    },
    yourVariantOrComment: 'Your variant or comment',
    pleaseSelectReasonOrComment: 'Please select a reason or write a comment',
    errorReportIssue: 'Error reporting an issue'
})
