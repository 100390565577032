import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { GooglePayProps } from './props'
import styles from './GooglePay.scss'

export const GooglePay = observer((props: GooglePayProps) => {
    const { store } = props
    const buttonContainer = useRef()

    useEffect(() => {
        store.loadGooglePay(buttonContainer.current)
    }, [])

    return (
        <div className={styles.GooglePay} id={'google-pay-btn'} ref={buttonContainer}/>
    )
})
