import React from 'react'
import classNames from 'classnames'
import { EcospendBlock, EcospendField } from '..'
import ecospendTranslations from '../../translations'
import translations from './translations'
import { EcospendPayeeBlockProps } from './props'
import styles from './EcospendPayeeBlock.scss'

export const EcospendPayeeBlock: React.FC<EcospendPayeeBlockProps> = ({ store, className }) => (
    <div className={classNames(styles.EcospendPayeeBlock, className)}>
        <EcospendBlock className={styles.block}>
            <EcospendField label={translations().payee} content={store.payee || ecospendTranslations().unknown} />
        </EcospendBlock>
        <EcospendBlock className={styles.block}>
            <EcospendField label={translations().processor} content={translations().dnaPayments} />
        </EcospendBlock>
    </div>
)