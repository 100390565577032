import { action, computed } from 'mobx'
import { CloseConfirmationModalParentStore } from './models'

export class CloseConfirmationModalStore {
    private parentStore: CloseConfirmationModalParentStore
    constructor (parentStore: CloseConfirmationModalParentStore) {
        this.parentStore = parentStore
    }

    @computed
    public get isOpen() {
        return this.parentStore.isCloseConfirmationModalOpen
    }

    @action
    public cancel() {
        this.parentStore.cancelCloseConfirmationModal()
    }

    @action
    public close() {
        this.parentStore.cancelCloseConfirmationModal()
        this.parentStore._closePaymentWidget(true)
    }
}
