// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3jxpD8iyeTo-KkejXXaKtl{width:100%;font-family:\"Poppins\",sans-serif;font-size:14px;line-height:21px;font-style:normal;font-weight:400;cursor:pointer}._3jxpD8iyeTo-KkejXXaKtl ._3z3WXi6n6ax2OZzXyYMj5A{display:flex;align-items:center}._3jxpD8iyeTo-KkejXXaKtl ._3z3WXi6n6ax2OZzXyYMj5A svg{margin-right:3px}._3jxpD8iyeTo-KkejXXaKtl._3JyoiyLyIT-AwxD6rN8-om ._3z3WXi6n6ax2OZzXyYMj5A{transition:200ms;text-decoration:underline}._3jxpD8iyeTo-KkejXXaKtl._3JyoiyLyIT-AwxD6rN8-om ._3z3WXi6n6ax2OZzXyYMj5A:hover{text-decoration:none;text-decoration-color:rgba(0,0,0,0)}._3jxpD8iyeTo-KkejXXaKtl._3RaOEEMWANDlz45cnNFPD_{display:inline}._3jxpD8iyeTo-KkejXXaKtl._3RaOEEMWANDlz45cnNFPD_ ._3z3WXi6n6ax2OZzXyYMj5A{display:inline}._3jxpD8iyeTo-KkejXXaKtl._1CtBohUfNUN8z-Cksj4hNO{cursor:default}", ""]);
// Exports
exports.locals = {
	"Link": "_3jxpD8iyeTo-KkejXXaKtl",
	"Content": "_3z3WXi6n6ax2OZzXyYMj5A",
	"HasUnderline": "_3JyoiyLyIT-AwxD6rN8-om",
	"Inline": "_3RaOEEMWANDlz45cnNFPD_",
	"IsNotClickable": "_1CtBohUfNUN8z-Cksj4hNO"
};
module.exports = exports;
