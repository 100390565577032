import { ConfigStore } from '../config'
import { CardItem } from '../models/CardItem'
import { CreditCardType } from '../pages/CardData/models'
import { getCreditCardTypeByScheme } from '../pages/CardData/services/getters'

export const isCSCRequired = (cardItem: CardItem, store: CSCConfigStoreType = ConfigStore) =>
    cardItem.isCSCRequired || cardItem.cscState === 'required' || store.isCSCRequiredForTokenPayments

export const isCSCHidden = (cardItem: CardItem, store: CSCConfigStoreType = ConfigStore) => !isCSCRequired(cardItem, store) && cardItem.cscState === 'hidden'

type CSCConfigStoreType = {
    isCSCRequiredForTokenPayments: boolean
    isCSCRequiredForNonTokenPayments: boolean
}

export const getCardSchemeList: (() => CreditCardType[]) = () => {
    if (!ConfigStore?.acceptedCardSchemes) {
        return [
            CreditCardType.VISA,
            CreditCardType.MASTERCARD,
            CreditCardType.AMEX
        ]
    }
    const schemes: any[] = ConfigStore.acceptedCardSchemes.map((el) => getCreditCardTypeByScheme(null, el.cardSchemeName))

    // make sure to send only the following card brands: mastercard, visa, amex, discover
    const result = schemes.filter(item =>
        (
            item === CreditCardType.MASTERCARD ||
            item === CreditCardType.VISA ||
            item === CreditCardType.AMERICAN_EXPRESS ||
            item === CreditCardType.AMEX ||
            item === CreditCardType.DISCOVER
        )
    ).map(item => {
        if (item === CreditCardType.AMERICAN_EXPRESS) {
            return CreditCardType.AMEX
        }
        return item
    })

    return Array.from(new Set(result))
}
