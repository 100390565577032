// email pattern by RFC 5322 specification 
const tester = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const emailValidate = (email: string) => {
  const res = extractEmailAccountAndAddress(email)
  if (!res) return false

  const [account, address] = res 

  if (account.length > 64) return false

  if (address.length > 255) return false

  const domainParts = address.split('.')
  if (domainParts.some((part) => part.length > 63)) return false

  if (!tester.test(email)) return false

  return true
}

export const extractEmailAccountAndAddress = (email: string) => {
  if (!email) return null

  const emailParts = email.split('@')

  if (emailParts.length !== 2) return null

  const account = emailParts[0]
  const address = emailParts[1]

  return [account, address]
}