import translations from './translations'

translations.add('es', {
    paymentReject: 'Pago fallido',
    tokenizationReject: 'Tokenización fallida',
    verificationReject: 'Verificación fallida',
    hasNotAuthorisedPleaseCheck: 'La tarjeta no ha sido autorizada, verifique los detalles y vuelva a intentarlo o póngase en contacto con su banco.',
    hasNotAuthorisedPleaseRetry: 'La tarjeta no ha sido autorizada, vuelva a intentarlo.',
    hasBeenTechnicalError: 'Ha habido un error técnico y tu tarjeta no ha sido autorizada.',
    payPalWasNotCompleted: 'La transacción de PayPal no se ha completado, vuelva a intentarlo o seleccione otro método de pago.',
    payByBankWasNotCompleted: 'La transacción de la aplicación Pay by bank no se ha completado, vuelva a intentarlo o seleccione otro método de pago.',
    googlePayWasNotCompleted: 'La transacción de Google Pay no se ha completado. Vuelva a intentarlo o seleccione otro método de pago.',
    applePayWasNotCompleted: 'La transacción de Apple Pay no se ha completado, vuelva a intentarlo o seleccione otro método de pago.',
    ecospendWasNotCompleted: 'La transacción de Open Banking no se ha completado, vuelva a intentarlo o seleccione otro método de pago.',
    klarnaWasNotCompleted: 'El pago de Klarna no se ha realizado correctamente, vuelva a intentarlo o seleccione otro método de pago.',
    clickToPayWasNotCompleted: 'El pago de Click To Pay no se ha realizado correctamente, vuelva a intentarlo o seleccione otro método de pago.',
    invalidUnsupportedPaymentMethod: 'Método de pago no válido / no compatible',
    tryAgain: 'Intentar otra vez',
    returnToBack: 'Volver al sitio'
})
