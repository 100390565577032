export const PLEASE_ENTER_EMAIL = 'pleaseEnterEmail'
export const PLEASE_ENTER_VALID_EMAIL = 'pleaseEnterValidEmail'
export const EMAIL_MUST_NOT_BE_GREATER_THAN_256 = 'emailMustNotBeGreaterThan256'
export const PLEASE_ENTER_FIRST_NAME = 'pleaseEnterFirstName'
export const FIRST_NAME_MUST_NOT_BE_GREATER_THAN_32 = 'firstNameMustNotBeGreaterThan32'
export const PLEASE_ENTER_LAST_NAME = 'pleaseEnterLastName'
export const LAST_NAME_MUST_NOT_BE_GREATER_THAN_32 = 'lastNameMustNotBeGreaterThan32'
export const PLEASE_SELECT_COUNTRY = 'pleaseSelectCountry'
export const COUNTRY_MUST_NOT_BE_GREATER_THAN_2 = 'countryMustNotBeGreaterThan2'
export const PLEASE_ENTER_STREET = 'pleaseEnterStreet'
export const STREET_MUST_NOT_BE_GREATER_THAN_50 = 'streetMustNotBeGreaterThan50'
export const PLEASE_ENTER_CITY = 'pleaseEnterCity'
export const CITY_MUST_NOT_BE_GREATER_THAN_50 = 'cityMustNotBeGreaterThan50'
export const PLEASE_ENTER_POSTAL_CODE = 'pleaseEnterPostalCode'
export const POSTAL_CODE_MUST_NOT_BE_GREATER_THAN_13 = 'postalCodeMustNotBeGreaterThan13'
export const PLEASE_ENTER_MOBILE_PHONE = 'pleaseEnterMobilePhone'
export const MOBILE_PHONE_MUST_NOT_BE_GREATER_THAN_16 = 'mobilePhoneMustNotBeGreaterThan16'
export const MOBILE_PHONE_MUST_NOT_BE_LESS_THAN_8 = 'mobilePhoneMustNotBeLessThan8'
