// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3UY8CO0E6OWz3aoLjrFAhZ{font-size:12px;font-family:\"Open Sans\";color:#9b9b9b;margin-bottom:4px}.orEXxcHJzi2DyLd549uXP{font-size:13px;font-family:\"Poppins\"}", ""]);
// Exports
exports.locals = {
	"label": "_3UY8CO0E6OWz3aoLjrFAhZ",
	"content": "orEXxcHJzi2DyLd549uXP"
};
module.exports = exports;
