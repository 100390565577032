import translations from './translations'

translations.add('is', {
    paymentReject: 'Greiðsla mistókst',
    tokenizationReject: 'Tákngreining mistókst',
    verificationReject: 'Staðfesting mistókst',
    hasNotAuthorisedPleaseCheck: 'Kortið þitt hefur ekki verið heimilað, vinsamlegast athugaðu upplýsingarnar og reyndu aftur eða hafðu samband við bankann þinn.',
    hasNotAuthorisedPleaseRetry: 'Kortið þitt hefur ekki verið heimilað, vinsamlegast reyndu aftur.',
    hasBeenTechnicalError: 'Tæknileg villa kom upp og kortið þitt hefur ekki verið heimilað.',
    payPalWasNotCompleted: 'PayPal færslunni þinni var ekki lokið, vinsamlegast reyndu aftur eða veldu annan greiðslumáta.',
    payByBankWasNotCompleted: 'Greiðsla með bankaforritinu þínu var ekki lokið, vinsamlegast reyndu aftur eða veldu annan greiðslumáta.',
    googlePayWasNotCompleted: 'Google Pay færslunni þinni var ekki lokið, vinsamlegast reyndu aftur eða veldu annan greiðslumáta.',
    applePayWasNotCompleted: 'Apple Pay færslunni þinni var ekki lokið, vinsamlegast reyndu aftur eða veldu annan greiðslumáta.',
    ecospendWasNotCompleted: 'Opnum bankaviðskiptum þínum var ekki lokið, vinsamlegast reyndu aftur eða veldu annan greiðslumáta.',
    klarnaWasNotCompleted: 'Klarna greiðslan þín tókst ekki, vinsamlegast reyndu aftur eða veldu annan greiðslumáta.',
    clickToPayWasNotCompleted: 'Click To Pay greiðslan þín tókst ekki, vinsamlegast reyndu aftur eða veldu annan greiðslumáta.',
    invalidUnsupportedPaymentMethod: 'Ógildur / óstuddur greiðslumáti',
    tryAgain: 'Reyndu aftur',
    returnToBack: 'Fara aftur á síðuna'
})
