import translations from './translations'

translations.add('es', {
    cardNumber: 'Número de tarjeta',
    cardHolderName: 'Nombre del titular de la tarjeta',
    expiryDate: 'Fecha de caducidad',
    mm: 'MM',
    yy: 'AA',
    invalidCardNumber: 'Por favor, introduzca un número de tarjeta válido',
    notSupportScheme: 'Sistema de tarjeta no compatible',
    invalidCardHolderName: 'Introduzca un nombre de titular de tarjeta válido',
    longCardHolderName: 'Se ha superado la longitud máxima de 45 caracteres',
    invalidExpiryDate: 'Introduzca una fecha de caducidad válida',
    invalidCvv: 'Introduzca un código de seguridad válido',
    backCardList: 'Volver a la lista de tarjetas',
    yourClickToPayPaymentUnsuccessful: 'El pago Click To Pay no se ha realizado correctamente. Inténtelo de nuevo más tarde',
    loading: 'Cargando...',
    visa: 'Visa',
    mastercard: 'Mastercard',
    amex: 'Amex',
    discover: 'Discover',
    dinersClub: 'Diners Club',
    unionPay: 'UnionPay',
    jcb: 'JCB',
    saveCardDetails: 'Guarde los datos de mi tarjeta con el comerciante para realizar pagos futuros más rápidos'
})
