import { createRef } from 'react'
import { action, computed, observable, reaction, toJS } from 'mobx'
import { isEmpty } from 'lodash'
import { getVisibleField } from './services'
import { InputField, InputFieldName, InputFieldType as PersonalInfoInputFieldType, OrderPersonalInfoParentStore } from './models'
import { ConfigStore } from '~/code/config'
import { PaymentMethod } from '~/code/models'


class OrderPersonalInfoStore {
    parentStore: OrderPersonalInfoParentStore

    constructor (parentStore: OrderPersonalInfoParentStore) {
        this.parentStore = parentStore
        this.generateReactionOnPersonalInfoFields()
    }

    public generateReactionOnPersonalInfoFields() {
        reaction(
            () => {
                return {
                    personalInfoFields: toJS(this.getRequiredFields())
                }
            },
            (value) => {
                const paymentDataFields = this.getPaymentDataFields()
                this.fields = getVisibleField(this.getRequiredFields(), paymentDataFields)
            })
    }

    public getPaymentDataFields(): { [p: string]: string } {
        const customerDetails = this.parentStore?.paymentData?.customerDetails
        return {
            [InputFieldName.AccountFirstName]: customerDetails?.billingAddress?.firstName,
            [InputFieldName.AccountLastName]: customerDetails?.billingAddress?.lastName,
            [InputFieldName.AccountCity]: customerDetails?.billingAddress?.city,
            [InputFieldName.AccountCountry]: customerDetails?.billingAddress?.country,
            [InputFieldName.AccountPostalCode]: customerDetails?.billingAddress?.postalCode,
            [InputFieldName.AccountStreet1]: customerDetails?.billingAddress?.addressLine1,
            [InputFieldName.AccountEmail]: customerDetails?.email
        }
    }

    public getRequiredFields() {
        if (ConfigStore.paymentMethod === PaymentMethod.ClickToPay) {
            return {
                [InputFieldName.AccountFirstName]: true,
                [InputFieldName.AccountLastName]: true,
                [InputFieldName.AccountCity]: true,
                [InputFieldName.AccountCountry]: true,
                [InputFieldName.AccountPostalCode]: true,
                [InputFieldName.AccountStreet1]: true,
                [InputFieldName.AccountEmail]: true
            }
        }
        return ConfigStore.personalInfoFields
    }

    @computed
    public get transactionType () {
        return this.parentStore?.paymentData?.transactionType
    }

    @observable
    public fields: { [key: string]: InputField }

    @observable
    public container = createRef<HTMLDivElement>()

    @computed
    public get isValidForm() {
        if (isEmpty(this.fields)) return true
        if (this.parentStore.selectedCard?.useStoredBillingData) return true

        const fields = Object.entries(this.fields).filter(([key, value]) => {
            const error = value.validate ? value.validate(value.value) : undefined
            if (error) {
                return true
            }
            return false
        })
        return fields.length === 0
    }

    public getIsValidForm(showError: boolean = false) {
        if (isEmpty(this.fields)) return true
        if (this.parentStore.selectedCard?.useStoredBillingData) return true

        const fields = Object.entries(this.fields).filter(([key, value]) => {
            const errorCode = value.validate ? value.validate(value.value) : undefined
            if (errorCode) {
                if (showError) {
                    value.errorCode = errorCode
                }
                return true
            }
            return false
        })
        return fields.length === 0
    }

    getFieldValues() {
        const fields = {}
        if (isEmpty(this.fields)) return {}
        Object.entries(this.fields).forEach(([fieldName, field]) => {
            if (field.type === PersonalInfoInputFieldType.Select) {
                fields[fieldName] = field.value.value
                return
            }
            fields[fieldName] = field.value
        })
        return fields
    }

    @action
    public onPay() {
        if ( this.getIsValidForm(true) ) {
            this.parentStore.onPay({invokedFrom: 'OrderPersonalInfoStore.onPay'})
        }
    }

    @action
    public focusOnFirstInput() {
        const input = this.container.current.querySelector('input')
        input && input.focus()
    }
}

export { OrderPersonalInfoStore }
