import React, { useEffect, useRef } from 'react'
import { ConfigStore } from '~/code/config'
import classNames from 'classnames'
import translations from './translations'
import { ClickToPayFrameProps } from './props'
import styles from './styles.scss'
import { CreditCardType } from '~/code/pages/CardData/models'

export const ClickToPayFrame: React.FC<ClickToPayFrameProps> = (props) => {
    const { onSetWindow, cardBrand } = props
    const refFrame = useRef<HTMLIFrameElement>()

    useEffect(() => {
        onSetWindow(refFrame.current?.contentWindow)
    }, [])

    return (
        <div className={styles.root}>
            <iframe
                ref={refFrame}
                name='click_to_pay_frame'
                id='click_to_pay_frame'
                src={ConfigStore.getUrl().paymentPageUrl + '/processing.html'}
                className={classNames({
                    [styles.discover]: cardBrand === CreditCardType.DISCOVER,
                    [styles.visa]: cardBrand === CreditCardType.VISA,
                    [styles.amex]: cardBrand === CreditCardType.AMEX
                })}
            >
                <p>{translations().yourBrowserDoesNotSupportIFrames}</p>
            </iframe>
        </div>
    )
}
