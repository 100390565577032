import loadScript from 'load-script'

export const loadScriptPromise = (url: string, options?: any) => new Promise((resolve, reject) => {
  loadScript(url, options || {}, (error, result) => {
    if (error) {
      reject(error)
    } else {
      resolve(result)
    }
  })
})

export * from './generators'
export * from './fetchers'
export * from './checkers'
export * from './converters'
export * from './getters'
export * from './handlers'
export * from './maths'
export * from './listeners'
export * from './timer-utils'

