import translations from './translations'

translations.add('is', {
    lookupCards: 'Leitaðu að kortunum þínum',
    pleaseEnterEmailOrPhone: 'Vinsamlegast sláðu inn netfangið sem þú skráðir á Click To Pay',
    pleaseEnterEmail: 'Vinsamlegast sláðu inn netfang',
    pleaseEnterValidEmail: 'Vinsamlegast sláðu inn gilt netfang',
    emailMustNotBeGreaterThan256: 'Netfang má ekki vera lengra en 256 stafir',
    pleaseEnterPhone: 'Vinsamlegast sláðu inn símanúmer',
    mobilePhoneMustNotBeGreaterThan16: 'Símanúmer má ekki vera lengra en 16 stafir',
    mobilePhoneMustNotBeLessThan8: 'Símanúmer má ekki vera styttra en 8 stafir',
    phone: 'Símanúmer',
    email: 'Netfang',
    back: 'TIL BAKA',
    signOut: 'ÚTSKRÁ',
    lookup: 'LEIT'
})
