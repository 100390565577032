// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1NF-8M8hlYMN-UyrHM2H4_{display:flex;justify-content:flex-end;align-items:flex-start;margin-top:24px;padding:0}._1NF-8M8hlYMN-UyrHM2H4_._2w6bRxrdt5jBsV5uf1ffeC{margin-top:0}@media(min-width: 768px){._1NF-8M8hlYMN-UyrHM2H4_{padding:0;margin-top:0;flex-direction:column;padding:0 16px}}._1NF-8M8hlYMN-UyrHM2H4_ ._2ZR4ZdcZ1fFZa-87Gyp_FB{margin-top:12px;display:flex;align-items:baseline}._1NF-8M8hlYMN-UyrHM2H4_ ._2ZR4ZdcZ1fFZa-87Gyp_FB:first-child{margin-top:0px}@media(min-width: 768px){._1NF-8M8hlYMN-UyrHM2H4_ ._2ZR4ZdcZ1fFZa-87Gyp_FB{flex-direction:column;align-items:flex-start}}._1NF-8M8hlYMN-UyrHM2H4_ ._2RKYATt2335WUgK9KJUnRa{color:#9b9b9b;font-size:12px;font-family:\"Open Sans\",sans-serif;font-weight:400;display:flex;width:40%;margin-right:10px}@media(min-width: 768px){._1NF-8M8hlYMN-UyrHM2H4_ ._2RKYATt2335WUgK9KJUnRa{width:100%}}._1NF-8M8hlYMN-UyrHM2H4_ ._2fJblkdWQVbUT5zUMj4kEY{margin-top:0px;font-family:\"Poppins\",sans-serif;width:60%}@media(min-width: 768px){._1NF-8M8hlYMN-UyrHM2H4_ ._2fJblkdWQVbUT5zUMj4kEY{margin-top:5px;width:100%}}._1NF-8M8hlYMN-UyrHM2H4_ .EdEeM5yOBn8a1g1Z25IJ_{width:100%;font-family:\"Open Sans\",sans-serif;word-break:break-word;color:#000;font-weight:400;font-style:normal;font-size:14px}._1NF-8M8hlYMN-UyrHM2H4_ ._2dPP9EEYZiGd88V7Juf2_M{font-family:\"Poppins\",sans-serif;font-weight:700;font-size:24px;color:#4bb9aa}._1NF-8M8hlYMN-UyrHM2H4_ ._2dPP9EEYZiGd88V7Juf2_M ._13biVYN7IggoIzfOOklAen{font-family:\"Poppins\",sans-serif;font-style:normal;font-weight:normal;font-size:10px;line-height:15px;color:#8c8c8c}._1NF-8M8hlYMN-UyrHM2H4_ ._2dPP9EEYZiGd88V7Juf2_M ._3UUEX82ugkBV53Kuup3sK8{word-break:break-all}", ""]);
// Exports
exports.locals = {
	"OrderInfo": "_1NF-8M8hlYMN-UyrHM2H4_",
	"HasDonation": "_2w6bRxrdt5jBsV5uf1ffeC",
	"Field": "_2ZR4ZdcZ1fFZa-87Gyp_FB",
	"FieldTitle": "_2RKYATt2335WUgK9KJUnRa",
	"FieldContent": "_2fJblkdWQVbUT5zUMj4kEY",
	"Content": "EdEeM5yOBn8a1g1Z25IJ_",
	"AmountValue": "_2dPP9EEYZiGd88V7Juf2_M",
	"IncludeDonationText": "_13biVYN7IggoIzfOOklAen",
	"AmountTotal": "_3UUEX82ugkBV53Kuup3sK8"
};
module.exports = exports;
