export enum Page {
    ECOSPEND = 'ECOSPEND',
    ECOSPEND_SUCCESS = 'ECOSPEND_SUCCESS',
    ECOSPEND_FAILURE = 'ECOSPEND_FAILURE',
    PAYMENT_METHODS_LIST = 'PAYMENT_METHODS_LIST',
    CARD_DATA = 'CARD_DATA',
    CARD_LIST = 'CARD_LIST',
    CARD_ITEM = 'CARD_ITEM',
    ORDER_PERSONAL_INFO = 'ORDER_PERSONAL_INFO',
    KLARNA_ORDER_PERSONAL_INFO = 'KLARNA_ORDER_PERSONAL_INFO',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    PROCESSING = 'PROCESSING',
    THREE_D_SECURE = 'THREE_D_SECURE',
    UPI_THREE_D_SECURE = 'THREE_D_SECURE',
    ERROR = 'ERROR',
    FORBIDDEN = 'FORBIDDEN'
}
