import translations from './translations'

translations.add('pt', {
    lookupCards: 'Procure os seus cartões',
    pleaseEnterEmailOrPhone: 'Insira o e-mail que registou no Click To Pay',
    pleaseEnterEmail: 'Insira um e-mail',
    pleaseEnterValidEmail: 'Insira um e-mail válido',
    emailMustNotBeGreaterThan256: 'O e-mail não pode ter mais de 256 caracteres',
    pleaseEnterPhone: 'Insira um número de telemóvel',
    mobilePhoneMustNotBeGreaterThan16: 'O número de telemóvel não pode ter mais de 16 caracteres',
    mobilePhoneMustNotBeLessThan8: 'O número de telemóvel não pode ter menos de 8 caracteres',
    email: 'E-mail',
    phone: 'Telemóvel',
    back: 'VOLTAR',
    signOut: 'SAIR',
    lookup: 'PROCURAR'
})
