// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_wvW3xKFTN1m6_WCFAg-c{background-color:#ffb3c7;height:46px;width:100%;border-radius:5px;display:flex;justify-content:center;cursor:pointer}._1_wvW3xKFTN1m6_WCFAg-c ._26jw6sdA_8CIlVV1Yq_g53{width:80px;height:46px}", ""]);
// Exports
exports.locals = {
	"Klarna": "_1_wvW3xKFTN1m6_WCFAg-c",
	"image": "_26jw6sdA_8CIlVV1Yq_g53"
};
module.exports = exports;
