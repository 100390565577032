import { action, computed, observable } from 'mobx'
import { IGooglePayParentStore } from './models'
import { ConfigStore } from '~/code/config'
import { PaymentMethod } from '~/code/models'
import { extractAllowedCardNetworks } from '~/code/services'
import { log } from '~/code/services/logger'
import loadScript from 'load-script'
import { GooglePayEvents } from '~/code/pages/GooglePay/models/GooglePayEvents'
import { PaymentMethodStatus } from '~/code/models/PaymentMethodStatus'

export class GooglePayStore {
    public parentStore: IGooglePayParentStore

    @observable
    public isLoading: boolean = false

    @observable
    public isProcessingPayment = false

    @observable
    public isPaymentSuccessful: boolean = false

    @observable
    public availabilityState: 'unknown' | 'available' | 'unavailable' = 'unknown'

    public currentPaymentState: 'beforeButtonLoad' | 'afterButtonLoad' | 'duringPayment'

    constructor (parentStore: IGooglePayParentStore) {
        this.parentStore = parentStore
        this.currentPaymentState = 'beforeButtonLoad'
        this.startLoading()
    }

    @computed
    public get paymentData() {
        return this.parentStore.paymentData
    }

    @action
    public startLoading() {
        this.isLoading = true
    }

    @action
    public stopLoading() {
        this.isLoading = false
    }

    public onClick = () => {
        log('Google Pay button has been clicked')
        this.currentPaymentState = 'duringPayment'
        this.startPaymentProcessing()
    }

    public onError = (error) => {
        log('Error has occurred', error)
        if (this.currentPaymentState === 'beforeButtonLoad' || this.currentPaymentState === 'afterButtonLoad') {
            ConfigStore.setPaymentStatus(PaymentMethod.GooglePay, PaymentMethodStatus.FAILED)
        } else {
            ConfigStore.setField('paymentMethod', PaymentMethod.GooglePay)
            this.stopPaymentProcessing()
            this.parentStore.stopPaymentProcessing(false)
            this.parentStore.handleFailureResult(error)
        }
    }

    public onCancel = () => {
        log('The payment has been cancelled')
        this.stopPaymentProcessing()
        this.currentPaymentState = 'afterButtonLoad'
    }

    public onPaymentSuccess = (result) => {
        log('Google Payment has successfully been processed', result)
        this.isPaymentSuccessful = true
        this.currentPaymentState = 'afterButtonLoad'
        ConfigStore.setField('paymentMethod', PaymentMethod.GooglePay)
        this.parentStore.transactionId = result.id
        this.stopPaymentProcessing()
        this.parentStore.handleSuccessResult()
    }

    private  events: GooglePayEvents = {
        onClick: this.onClick,
        onError: this.onError,
        onPaymentSuccess: this.onPaymentSuccess,
        onCancel: this.onCancel
    }

    @action
    public loadGooglePay = async (buttonContainer) => {
        loadScript(GOOGLE_PAY_LIB_URL, {}, (error, result) => {
            if (error) {
                log('error', 'GooglePayStore.loadGooglePay', error)
                ConfigStore.setPaymentStatus(PaymentMethod.GooglePay, PaymentMethodStatus.FAILED)
                this.stopLoading()
            } else {
                const allowedCardNetworks = extractAllowedCardNetworks(ConfigStore?.acceptedCardSchemes)
                let component = null

                try {
                    component = window.DNAPayments?.GooglePayComponent?.create(
                        buttonContainer,
                        this.paymentData,
                        this.events,
                        this.paymentData.auth.access_token,
                        allowedCardNetworks)
                    this.stopLoading()
                    this.currentPaymentState = 'afterButtonLoad'
                } catch (error) {
                    log('error', 'GooglePayStore.loadGooglePay', error)
                    ConfigStore.setPaymentStatus(PaymentMethod.GooglePay, PaymentMethodStatus.FAILED)
                    this.stopLoading()
                }

                return component
            }
        })
    }

    @action
    public startPaymentProcessing = () => {
        this.isProcessingPayment = true
        this.parentStore.startPaymentProcessing()
    }

    @action
    public stopPaymentProcessing = () => {
        this.isProcessingPayment = false
        this.parentStore.stopPaymentProcessing(this.isPaymentSuccessful, true)
    }
}
