import React from 'react'
import classNames from 'classnames'
import { EcospendFieldProps } from './props'
import styles from './EcospendField.scss'

export const EcospendField: React.FC<EcospendFieldProps> = (props) => {
    const { label, content, className } = props
    return <div className={classNames(className)}>
        <div className={styles.label}>{label}</div>
        <div className={styles.content}>{content}</div>
    </div>
}
