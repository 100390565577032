// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".yx75SZ478bsBIa91N2LSh ._2mNoqY_lV_G9vYBnVjWbqJ{position:relative;display:flex;background-color:#fff;justify-content:space-between;align-items:stretch;flex-direction:column}@media(min-width: 768px){.yx75SZ478bsBIa91N2LSh ._2mNoqY_lV_G9vYBnVjWbqJ{flex-direction:row}}.yx75SZ478bsBIa91N2LSh ._2mNoqY_lV_G9vYBnVjWbqJ ._1HpfPdgrYgu7PtOZ3XameF{position:absolute;display:flex;right:0;top:50%;transform:translate(0, -50%);margin-top:-25px;font-family:\"Poppins\",sans-serif}.yx75SZ478bsBIa91N2LSh ._2mNoqY_lV_G9vYBnVjWbqJ ._1HpfPdgrYgu7PtOZ3XameF ._3fizb9W8CHtKyUJTshYH7Z{position:absolute;right:0;box-sizing:border-box;background:#f5925e;padding:5px 2px 5px 4px;color:#fff;font-size:10px;line-height:10px;font-weight:700;border-top-left-radius:4px;border-bottom-left-radius:4px}.yx75SZ478bsBIa91N2LSh ._2mNoqY_lV_G9vYBnVjWbqJ ._1HpfPdgrYgu7PtOZ3XameF ._3fizb9W8CHtKyUJTshYH7Z>div{line-height:14px}@media(min-width: 768px){.yx75SZ478bsBIa91N2LSh ._2mNoqY_lV_G9vYBnVjWbqJ ._1HpfPdgrYgu7PtOZ3XameF ._3fizb9W8CHtKyUJTshYH7Z{padding:5px 3px}}.yx75SZ478bsBIa91N2LSh ._3AifQVvBnCeyQi-ERd3xoK{width:100%;padding:0 16px;margin:0 0 16px;box-sizing:border-box;min-height:180px}@media(min-width: 768px){.yx75SZ478bsBIa91N2LSh ._3AifQVvBnCeyQi-ERd3xoK{width:65%;padding:0 24px;margin:24px 0}.yx75SZ478bsBIa91N2LSh ._3AifQVvBnCeyQi-ERd3xoK._34WvnB2Nusmzp08VVAEesl{width:100%}}._3qvbbSsJSp5hMvuCh-JRu3{padding:0 16px;margin:8px 0 16px}@media(min-width: 768px){._3qvbbSsJSp5hMvuCh-JRu3{padding:0 24px;margin:24px 0}}", ""]);
// Exports
exports.locals = {
	"PaymentForm": "yx75SZ478bsBIa91N2LSh",
	"Main": "_2mNoqY_lV_G9vYBnVjWbqJ",
	"TestLabel": "_1HpfPdgrYgu7PtOZ3XameF",
	"TestLabelContent": "_3fizb9W8CHtKyUJTshYH7Z",
	"Content": "_3AifQVvBnCeyQi-ERd3xoK",
	"ContentFullWidth": "_34WvnB2Nusmzp08VVAEesl",
	"side": "_3qvbbSsJSp5hMvuCh-JRu3"
};
module.exports = exports;
