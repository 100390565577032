// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._21SiSWvlPhw5BCy9V9REoe{display:flex;justify-content:space-between;align-items:center;font-family:\"Open Sans\",sans-serif;margin-top:24px;box-sizing:border-box}@media(min-width: 768px){._21SiSWvlPhw5BCy9V9REoe{justify-content:flex-end}}@media(min-width: 768px){._21SiSWvlPhw5BCy9V9REoe._2zKqAjMtraDPMHrq188IFw{justify-content:space-between}}._21SiSWvlPhw5BCy9V9REoe._2zKqAjMtraDPMHrq188IFw ._3RTHQX8grKwrOcPxpdaLLB{text-align:left;margin-left:13px}.B-v2GLz6qfLbOYtBXldlG{display:flex}._3RTHQX8grKwrOcPxpdaLLB{display:flex;flex-direction:column;text-align:left;margin-right:12px;color:#9b9b9b;font-size:13px;line-height:20px;font-weight:400}@media(min-width: 768px){._3RTHQX8grKwrOcPxpdaLLB{text-align:right}}._3RTHQX8grKwrOcPxpdaLLB a{text-decoration:underline;color:#9b9b9b}", ""]);
// Exports
exports.locals = {
	"root": "_21SiSWvlPhw5BCy9V9REoe",
	"hasCheckbox": "_2zKqAjMtraDPMHrq188IFw",
	"text": "_3RTHQX8grKwrOcPxpdaLLB",
	"block": "B-v2GLz6qfLbOYtBXldlG"
};
module.exports = exports;
