import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import styles from './RadioList.scss'
import { RadioListProps } from './props'

export default observer((props: RadioListProps) => {
    const { onChange, items, value, className } = props
    return (
        <div
            className={ classNames(styles.RadioList, className) }
        >
            {
              items.map((i) => {
                  return (
                      <div
                          className={ classNames(styles.Item, { [styles.Selected]: value === i.value }) }
                          key={ i.value }
                          onClick={() => {
                              if (i.value === value) {
                                  return onChange(null)
                              }
                              return onChange(i.value)
                          }}
                      >
                          { i.label }
                      </div>
                  )
              })
            }
        </div>
    )
})
