import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    paymentSuccessful: string,
    tokenizationSuccessful: string,
    verificationSuccessful: string,
    autoRedirectAfter: string,
    seconds: string,
    backToStore: string
    yourPaymentWasSuccessfulWith: (paymentMethod: string, paymentDescription: string) => JSX.Element
}>()
