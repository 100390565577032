import React from 'react'
import classNames from 'classnames'
import { StyleStore } from '~/code/config'
import { LinkProps } from './props'
import styles from './Link.scss'
import { createUseStyles } from 'react-jss'
import { lighten } from '~/code/services'

const useStyles = createUseStyles({
    Link: {
        color: (style) => style.color,
        '&:hover': {
            color: (style) => lighten(style.color, 10)
        }
    },
    Content: {
        textDecorationColor: (style) => style.color
    }
})

export const Link = ((props: LinkProps) => {
    const { hasUnderline = false, inline = false, color = StyleStore.style.primaryColor, isNotClickable } = props
    const classes = useStyles({ color })

    return (
        <div
            className={ classNames(styles.Link, classes.Link, { [styles.HasUnderline]: hasUnderline }, { [styles.Inline]: inline }, { [styles.IsNotClickable]: isNotClickable }, props.className) }
            onClick={ isNotClickable ? null : () => props.onClick() }
            data-test-id={ props['data-test-id'] }
        >
            <div className={ classNames(styles.Content, classes.Content) }>
                { props.children }
            </div>
        </div>
    )
})
