import React from 'react'
import translations from './translations'

translations.add('pt', {
    back: 'mudar de banco',
    cancel: 'Cancelar',
    continue: 'Continuar',
    infoText: `Iremos solicitar ao seu banco que partilhe o seu nome, o sort code e o número da conta. 
    Só usaremos estes dados se solicitar um reembolso por esta transação.`,
    permissionText: 'A Ecospend Technologies Limited precisa da sua permissão para iniciar com segurança uma ordem de pagamento do seu banco.',
    privacyPolicyText: (termsUrl: string, privacyUrl: string) => <>
        Ao clicar em continuar, aceita os nossos <a href={termsUrl} target='_blank'>termose condições</a> e a <a href={privacyUrl} target='_blank'>política de privacidade</a>
    </>
})
