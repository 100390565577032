import { emailValidate, extractEmailAccountAndAddress } from '~/code/services/validators/email-validate'
import { isValidTLD, extractTldFromDomain } from '~/code/services/validators/tld-validate'

export const validateEmailAndItsDomain = (email: string) => {
    if (!emailValidate(email)) {
        return false
    }
    
    const [, address] = extractEmailAccountAndAddress(email)
    const tld = extractTldFromDomain(address)

    return isValidTLD(tld)
}
