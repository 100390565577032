// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._362X4HYnfYHYYkg_qlFcjy{min-height:250px}._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP{font-family:\"Open Sans\",sans-serif;font-size:16px;display:grid;grid-auto-rows:1fr;grid-column-gap:10px;grid-row-gap:10px;word-break:break-word}@media(min-width: 768px){._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP{grid-template-columns:repeat(2, 1fr)}}._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP .CJQrgl9y2diRKsOJkiJaf{min-height:90px;box-sizing:border-box;grid-column:1/3}@media(min-width: 768px){._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP .CJQrgl9y2diRKsOJkiJaf{grid-column:auto/auto}}._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP .CJQrgl9y2diRKsOJkiJaf:last-child:not(:nth-child(even)){grid-column:1/3}._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP .CJQrgl9y2diRKsOJkiJaf ._2-kfLAvoIzmO1amcD7eRBa{height:100%;cursor:pointer;border:1px solid rgba(52,71,84,.4);transition:box-shadow .1s;border-radius:4px;display:flex;flex-direction:column;padding:12px 12px 8px;box-sizing:border-box;align-self:stretch}._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP .CJQrgl9y2diRKsOJkiJaf ._2-kfLAvoIzmO1amcD7eRBa:hover{box-shadow:0px 1px 8px 0px rgba(0,0,0,.1)}._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP ._17e2z8JkOtUw69FGtxm-93{font-style:normal;font-weight:bold;font-size:14px;line-height:21px;color:#344754}._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP ._3RmgJuUh92NtlVlxbucUaP,._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP ._6Vh7XEU4IwzBgKH0_UngF{font-size:12px;line-height:18px;color:rgba(52,71,84,.4)}._362X4HYnfYHYYkg_qlFcjy ._3DJ8-i2aYognw7xWPic3XP .ZmuTP0XS7y_jn-Z1VoRB2{width:100%;margin-top:auto;align-self:flex-end;display:flex;justify-content:space-between;align-items:center}._362X4HYnfYHYYkg_qlFcjy .kIoD_H3AyQg4AiZilFrCe{margin-top:30px}", ""]);
// Exports
exports.locals = {
	"CardList": "_362X4HYnfYHYYkg_qlFcjy",
	"CardListContent": "_3DJ8-i2aYognw7xWPic3XP",
	"CardItem": "CJQrgl9y2diRKsOJkiJaf",
	"CardItemContent": "_2-kfLAvoIzmO1amcD7eRBa",
	"CardName": "_17e2z8JkOtUw69FGtxm-93",
	"CardNumber": "_3RmgJuUh92NtlVlxbucUaP",
	"CardExpiryDate": "_6Vh7XEU4IwzBgKH0_UngF",
	"CardItemFooter": "ZmuTP0XS7y_jn-Z1VoRB2",
	"PayNewCardLink": "kIoD_H3AyQg4AiZilFrCe"
};
module.exports = exports;
