// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ChmEnPENtL1eqbelMqkYc{font-family:\"Open Sans\",sans-serif}._3ChmEnPENtL1eqbelMqkYc ._1tOkofqHphYU9K_h5BidNW{display:flex;justify-content:center;align-items:center;padding-top:20px}._3ChmEnPENtL1eqbelMqkYc ._1tOkofqHphYU9K_h5BidNW ._3hNZmv352ASQYbbXJUx-Js{padding-left:4px;font-weight:700}._3ChmEnPENtL1eqbelMqkYc .eB4q2eENwHV9lE_7J8Bzj{text-align:center;padding-top:20px}._3ChmEnPENtL1eqbelMqkYc ._3mVUs9LMcXQnPTgQkcZyeq{text-align:center;padding-top:20px}._3ChmEnPENtL1eqbelMqkYc ._3mVUs9LMcXQnPTgQkcZyeq ._2p4xQxJhH3ax9gDjF7Hna5{padding:9px 29px}@media(min-width: 768px){._3ChmEnPENtL1eqbelMqkYc ._3mVUs9LMcXQnPTgQkcZyeq ._2p4xQxJhH3ax9gDjF7Hna5{padding:9px 34px}}", ""]);
// Exports
exports.locals = {
	"Content": "_3ChmEnPENtL1eqbelMqkYc",
	"Header": "_1tOkofqHphYU9K_h5BidNW",
	"StatusText": "_3hNZmv352ASQYbbXJUx-Js",
	"Body": "eB4q2eENwHV9lE_7J8Bzj",
	"Action": "_3mVUs9LMcXQnPTgQkcZyeq",
	"CloseButton": "_2p4xQxJhH3ax9gDjF7Hna5"
};
module.exports = exports;
