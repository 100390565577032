export function stringToArrayBuffer(str){
    const buf = new ArrayBuffer(str.length)
    const bufView = new Uint8Array(buf)
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i)
    }
    return buf
}

export function arrayBufferToString(str){
    const byteArray = new Uint8Array(str)
    let byteString = ''
    for (let i = 0; i < byteArray.byteLength; i++) {
        byteString += String.fromCodePoint(byteArray[i])
    }
    return byteString
}

export function encryptDataWithPublicKey(data, key, label) {
    data = stringToArrayBuffer(data)
    return window.crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP',
            label: new TextEncoder().encode(label)
        },
        key, // from generateKey or importKey above
        data // ArrayBuffer of data you want to encrypt
    )
}
