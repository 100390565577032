import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { PayByBankProps } from './props'
import styles from './PayByBank.scss'
export const PayByBank = observer((props: PayByBankProps) => {
    const { store } = props
    const buttonContainer = useRef()

    useEffect(() => {
        store.loadPBBA(buttonContainer.current)
    }, [])


    return <div className={ `${styles.PayByBank} pbb-btn` } ref={buttonContainer}>
        <div className={ `${styles.invisibleButton}` }/>
    </div>
})
