import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    orderNumber: string,
    orderDescription: string,
    total: string
    includeDonation: string
    descriptor: string
    companyName: string
    companyAddress: string
}>()
