import React from 'react'
import classNames from 'classnames'
import { Checkbox } from '../Checkbox'
import { CheckboxBlockProps } from './props'
import styles from './styles.scss'

export const CheckboxBlock: React.FC<CheckboxBlockProps> = (props) => {
    const { className, checked, children, onChange } = props

    return (
        <div className={ classNames(styles.root, className) }>
            <Checkbox
                checked={ checked }
                onClick={ () => onChange && onChange(!checked) }
                disabled={ props.disabled }
            />
            <span className={styles.content}>
                {children}
            </span>
        </div>
    )
}
