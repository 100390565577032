import React from 'react'
import { observer } from 'mobx-react'
import { StyleStore } from '~/code/config'
import classNames from 'classnames'
import styles from './ThreeDotsLoader.scss'

export default observer( ({ className, backgroundColor = StyleStore.style.primaryColor } : { className?: string, backgroundColor?: string}) => {
    return (
        <div className={ classNames(styles.ThreeDotsLoader, className) }>
            <div
                style={{
                    backgroundColor
                }}
                className={ styles.Bounce1 }
            />
            <div
                style={{
                    backgroundColor
                }}
                className={ styles.Bounce2 }
            />
            <div
                style={{
                    backgroundColor
                }}
            />
        </div>
    )
})
