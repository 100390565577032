// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1UpdlzNB14MrIpO1HUw_MI{display:flex}._2IMN-7MOR5XZTxtAasimt5{margin-right:15px}._2IMN-7MOR5XZTxtAasimt5:first-child{flex-grow:1}._2IMN-7MOR5XZTxtAasimt5:last-child{margin-right:0}@media screen and (max-width: 767.9px){._1UpdlzNB14MrIpO1HUw_MI{flex-wrap:wrap}._2IMN-7MOR5XZTxtAasimt5:nth-child(1){width:100%;margin:0 0 8px}._2IMN-7MOR5XZTxtAasimt5:nth-child(2),._2IMN-7MOR5XZTxtAasimt5:nth-child(3){width:calc(50% - 15px)}}", ""]);
// Exports
exports.locals = {
	"EcospendBankBlock": "_1UpdlzNB14MrIpO1HUw_MI",
	"bankField": "_2IMN-7MOR5XZTxtAasimt5"
};
module.exports = exports;
