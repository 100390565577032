import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    successTitle: string
    successMessage: string
    cancelMessage: string
    failMessage: string
    rejectMessage: string
    transactionId: string
    autoRedirectAfter: string
    seconds: string
    tryAgain: string
    returnToBack: string
}>()
