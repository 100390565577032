import translations from './translations'

translations.add('es', {
    orderNumber: 'Número de pedido',
    orderDescription: 'Descripción del pedido',
    total: 'Total',
    includeDonation: 'incluida donación de',
    descriptor: 'El cargo en la tarjeta será efectuado por',
    companyName: 'Nombre de empresa',
    companyAddress: 'Domicilio social'
})
