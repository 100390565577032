// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._17h5F9HuougRGeYWn50IWS{margin:15px auto 24px;text-align:center;line-height:1}._10R3dEWoaovW6u26eQ2F_i{font-family:\"Poppins\";font-size:30px;margin-right:4px}._1RH0VrnmWno_v3PQHTWXdt{color:#000;font-family:\"Poppins\";font-size:40px;letter-spacing:-2px}._3ps_Rcm3TNdHNDBO5Fwn-Z{margin:0 0 30px;font-size:13px !important}._2fgPEftxpv48x7l4-CAD2X{margin-bottom:12px}._2XtJoRIUTm3NRRyqY5U3dS{padding:25px 20px !important}.BbNgRU9rckjBlqSd65dp8{display:flex;max-width:400px;margin:0 auto}._3rB55KaEx5QfNkdVTN14FG,.bWfNoLEjk0Zx475q26iGw{font-size:14px;width:100%;padding:10px 15px;text-transform:capitalize;flex-grow:1}.bWfNoLEjk0Zx475q26iGw{margin-right:15px}._2VwDxyfqpRO6JUvhVLDha{margin:0 0 10px}p._1VU8oSYpyQ8KI0Tis1n0bF{font-size:10px;margin:15px auto 0;max-width:320px}._3vqW2qYeWigSxaqLpn1YF_{font-size:10px;margin:24px 0 0 0}", ""]);
// Exports
exports.locals = {
	"amountWrapper": "_17h5F9HuougRGeYWn50IWS",
	"currency": "_10R3dEWoaovW6u26eQ2F_i",
	"amount": "_1RH0VrnmWno_v3PQHTWXdt",
	"infoText": "_3ps_Rcm3TNdHNDBO5Fwn-Z",
	"block": "_2fgPEftxpv48x7l4-CAD2X",
	"largeBlock": "_2XtJoRIUTm3NRRyqY5U3dS",
	"buttons": "BbNgRU9rckjBlqSd65dp8",
	"continue": "_3rB55KaEx5QfNkdVTN14FG",
	"cancel": "bWfNoLEjk0Zx475q26iGw",
	"continueText": "_2VwDxyfqpRO6JUvhVLDha",
	"smalltext": "_1VU8oSYpyQ8KI0Tis1n0bF",
	"footertext": "_3vqW2qYeWigSxaqLpn1YF_"
};
module.exports = exports;
