export enum PaymentMethod {
    BankCard = 'BankCard',
    Ecospend = 'Ecospend',
    PayPal = 'PayPal',
    PayByBank = 'PayByBank',
    ApplePay = 'ApplePay',
    GooglePay = 'GooglePay',
    Klarna = 'Klarna',
    ClickToPay = 'ClickToPay',
    AstroPay = 'AstroPay',
    Alipay = 'Alipay',
    AlipayPlus = 'AlipayPlus',
    WeChatPay = 'WeChatPay'
}
