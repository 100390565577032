// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2I0f5zRlU92DdX44A5H8TD{display:flex;align-items:center;justify-content:space-between}._2I0f5zRlU92DdX44A5H8TD src-mark{--src-padding-vertical: 0;--src-padding-horizontal: 0}._23NEVwdw1830nH3q4C57gi{word-break:break-word;font-family:\"Open Sans\",sans-serif;color:#dc3545;font-size:12px}._3pc5E85HEEy7d9ffpx8mop{width:150px}._2y-ddeV16WiUmYLxOkU8qj div{display:block !important;text-align:right;font-size:13px}", ""]);
// Exports
exports.locals = {
	"root": "_2I0f5zRlU92DdX44A5H8TD",
	"error": "_23NEVwdw1830nH3q4C57gi",
	"cardSchemeImages": "_3pc5E85HEEy7d9ffpx8mop",
	"lookupCardsLink": "_2y-ddeV16WiUmYLxOkU8qj"
};
module.exports = exports;
