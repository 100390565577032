import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Button } from '~/code/components'
import { StyleStore } from '~/code/config'

import { CardPaymentButton } from '../../../CardPaymentButton'

import translations from './translations'
import { ClickToPayCardListProps } from './props'
import styles from './styles.scss'

export const ClickToPayCardList: React.FC<ClickToPayCardListProps> = (props) => {
    const { cards, locale, cardBrands, onContinue, onSignOut } = props

    const refSrcCardList = useRef<any>()
    const [selectedCardId, setSelectedCardId] = useState<string>(null)

    const handleContinue = () => onContinue(selectedCardId)

    const handleEvent = useCallback((event) => {
        switch (event.type) {
            case 'clickAddCardLink':
                setSelectedCardId(null)
                break
            case 'clickSignOutLink':
                onSignOut()
                break
            case 'selectSrcDigitalCardId':
                setSelectedCardId(event.detail)
                break
        }
    }, [])

    useEffect(() => {
        const instance = refSrcCardList.current
        if (instance) {
            instance.loadCards(cards)

            instance.addEventListener('clickAddCardLink', handleEvent)
            instance.addEventListener('clickSignOutLink', handleEvent)
            instance.addEventListener('selectSrcDigitalCardId', handleEvent)
        }

        return () => {
            if (instance) {
                instance.removeEventListener('clickAddCardLink', handleEvent)
                instance.removeEventListener('clickSignOutLink', handleEvent)
                instance.removeEventListener('selectSrcDigitalCardId', handleEvent)
            }
        }
    }, [])

    return <div>
        <div>
            <src-card-list
                ref={refSrcCardList}
                locale={locale}
                card-brands={cardBrands}
                card-selection-type='radioButton'
                display-add-card='true'
                display-preferred-card='true'
                display-Sign-Out='true'
            >
            </src-card-list>
        </div>

        {selectedCardId ? (
            <CardPaymentButton
                onPay={handleContinue}
            />
        ) : (
            <div className={styles.footer}>
                <Button
                    colorStyle={ StyleStore.style }
                    data-id={'next-button'}
                    onClick={handleContinue}
                >
                    { translations().next }
                </Button>
            </div>
        )}
        
    </div>
}
