import React, { useCallback, useEffect, useRef } from 'react'
import { ClickToPayChannelType, ClickToPayValidationChannelIdType } from '../../models'
import { ClickToPayOtpChannelProps } from './props'
import styles from './styles.scss'

export const ClickToPayOtpChannel: React.FC<ClickToPayOtpChannelProps> = (props) => {
    const { locale, cardBrands, channels, onContinue } = props

    const refOtpChannel = useRef<any>()
    const refSelectedChannel = useRef<ClickToPayValidationChannelIdType>(null)

    const handleEvent = useCallback((event) => {
        switch (event.type) {
            case 'selectChannel':
                refSelectedChannel.current = (event.detail as ClickToPayChannelType).validationChannelId
                break
            case 'continue':
                onContinue(refSelectedChannel.current)
                break
        }
    }, [])

    useEffect(() => {
        const instance = refOtpChannel.current
        if (instance) {
            instance.identityValidationChannels = channels
            instance.addEventListener('selectChannel', handleEvent)
            instance.addEventListener('continue', handleEvent)
        }

        return () => {
            if (instance) {
                instance.removeEventListener('selectChannel', handleEvent)
                instance.removeEventListener('continue', handleEvent)
            }
        }
    }, [])

    return (
        <div className={styles.root}>
            <src-otp-channel-selection
                ref={refOtpChannel}
                locale={locale}
                card-brands={cardBrands}
            >
            </src-otp-channel-selection>
        </div>
    )
}
