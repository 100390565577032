// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2x0nf-vXaSIMHWNG-oL86F{padding:0;border-radius:20px;overflow:hidden;width:100%;max-width:500px;min-height:400px}@media(min-width: 768px){._2x0nf-vXaSIMHWNG-oL86F{width:570px;max-width:570px}}._26ZDq_OpvqPo-0ENgjlJL2{background:rgba(0,0,0,.7);z-index:2147000000}", ""]);
// Exports
exports.locals = {
	"Content": "_2x0nf-vXaSIMHWNG-oL86F",
	"Overlay": "_26ZDq_OpvqPo-0ENgjlJL2"
};
module.exports = exports;
