export function amountFormat(num: number, currency: string = null, len = 2) {
    if (!num && String(num) !== '0') return ''

    return (num).toLocaleString('en-US', {
        maximumFractionDigits: len,
        minimumFractionDigits: len,
        ...( currency && { style: 'currency', currency })
    })
}

export function getRoundedAmount(num: number) {
    return parseFloat(amountFormat(num))
}
