import React from 'react'
import { Provider } from 'mobx-react'
import { render } from 'react-dom'
import { App } from './PaymentApiApp'
import { PaymentApiAppStore } from './PaymentApiAppStore'
import { disableIosTextFieldZoom, docReady } from '~/code/services'
import ElementQueries from 'css-element-queries/src/ElementQueries'
import { addCardType } from '~/code/services'
import { ErrorBoundary } from 'react-error-boundary'
import { LogStore } from '~/code/config'
import { GLOBAL_ERROR_DEFAULT } from '~/code/config/LogStore'
import * as Sentry from '@sentry/react'
import { log } from '~/code/services/logger'
import { isRelevantError, SENTRY_TRANSACTION_ID } from '~/code/services/sentry-helper'

log('initializing sentry')

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
    ],
    beforeSend(event) {
        // Ensure the event originates from your library
        if (!isRelevantError(event)) {
            return null // Discard errors not related to your library
        }
        return event // Send only relevant errors
    },
    environment: 'payment-api',
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// Set the transaction ID in Sentry context
Sentry.setContext('transaction', { id: SENTRY_TRANSACTION_ID })

log('finished initializing sentry')

addCardType ()

const div = document.createElement('div')
render(
    <Provider>
        <ErrorBoundary
            fallbackRender={ () => null }
            onError={ (error) =>  {
                LogStore.error(GLOBAL_ERROR_DEFAULT, null, error, true)
            } }
            key={ 'payment-api' }
        >
            <App store={ PaymentApiAppStore } />
        </ErrorBoundary>
    </Provider>,
    div
)

docReady(() => {
    ElementQueries.listen()
    ElementQueries.init()
    disableIosTextFieldZoom()
    document.body.appendChild(div) // TODO: may be it will generate class
    window.onpageshow = (event) => {
        if (event.persisted) {
            window.location.reload()
        }
    }
})

