import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    moreDetails: string,
    pleaseEnterEmail: string,

    pleaseEnterValidEmail: string,
    emailMustNotBeGreaterThan256: string,
    pleaseEnterFirstName: string,
    firstNameMustNotBeGreaterThan32: string,
    pleaseEnterLastName: string,
    lastNameMustNotBeGreaterThan32: string,
    pleaseSelectCountry: string,
    countryMustNotBeGreaterThan2: string,
    pleaseEnterStreet: string,
    streetMustNotBeGreaterThan50: string,
    pleaseEnterCity: string,
    cityMustNotBeGreaterThan50: string,
    pleaseEnterPostalCode: string,
    postalCodeMustNotBeGreaterThan13: string,
    pleaseEnterMobilePhone: string,
    mobilePhoneMustNotBeGreaterThan16: string,
    mobilePhoneMustNotBeLessThan8: string,

    mustNotBeGreaterThan: string,
    mustNotBeLessThan: string,
    characters: string,
    billingAddress: string,
    deliveryAddress: string,
    country: string,
    city: string,
    street: string,
    email: string,
    firstName: string,
    lastName: string,
    postalCode: string,
    mobilePhone: string,
    mobilePhoneInInternationalFormat: string,
    pay: string
    submit: string

    pleaseEnterAllRequiredFields: string
}>()
