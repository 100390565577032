import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    back: string
    cancel: string
    continue: string
    infoText: string
    permissionText: string
    privacyPolicyText(termsUrl: string, privacyUrl: string): React.ReactNode
}>()
