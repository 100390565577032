import translations from './translations'

translations.add('pt', {
    back: 'voltar',
    label: 'Enviar o comprovativo de pagamento para',
    placeholder: 'Endereço de email',
    inputButton: 'Enviar',
    text: 'O comprovativo de pagamento será enviado para',
    link: 'Enviar para um endereço de e-mail diferente',
    button: 'Enviar comprovativo',
    invalidEmail: 'o e-mail é inválido',
    successText: 'O comprovativo de pagamento foi enviado com sucesso.',
    errorText: 'Falha ao enviar o comprovativo de pagamento, tente novamente mais tarde.'
})
