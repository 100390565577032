// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".nuf2z26Pf0jRsHlU4vyzr{font-family:\"Poppins\",sans-serif;color:#000;font-weight:200;display:flex;justify-content:center;align-items:center;flex-direction:column;padding:10px;box-sizing:border-box;text-align:center}.nuf2z26Pf0jRsHlU4vyzr ._1tVBwB4MSt6K4O7SsXJgWh{font-size:80px;margin-top:60px}.nuf2z26Pf0jRsHlU4vyzr .r-3-rm0aFR05JyuppvetA{padding-left:65px;padding-right:65px;margin-top:50px;margin-bottom:30px}.nuf2z26Pf0jRsHlU4vyzr ._1-MWIQ-QtrTz95sp0A1Ar{color:#9b9b9b;font-size:12px;font-family:\"Open Sans\",sans-serif;font-weight:400}", ""]);
// Exports
exports.locals = {
	"Forbidden": "nuf2z26Pf0jRsHlU4vyzr",
	"Header": "_1tVBwB4MSt6K4O7SsXJgWh",
	"ToBack": "r-3-rm0aFR05JyuppvetA",
	"ErrorCode": "_1-MWIQ-QtrTz95sp0A1Ar"
};
module.exports = exports;
