export enum TransactionNotifyStatus {
    ABANDONED = 'ABANDONED',
    CREATED = 'CREATED',
    CHARGE = 'CHARGE',
    AUTH = 'AUTH',
    REFUND = 'REFUND',
    FAILED = 'FAILED',
    REJECT = 'REJECT',
    CANCEL = 'CANCEL'
}
