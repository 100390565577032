// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3U5RpQBkPFdzZ1nQGjBhOY ._3PaWChC-qEUXVLr7ubXF80{box-shadow:inset 0px -1px 0px rgba(0,0,0,.12);padding:16px;box-sizing:border-box;text-align:center;font-family:\"Poppins\",sans-serif;font-style:normal;font-weight:normal;font-size:14px;line-height:21px;color:#9b9b9b;background-color:#fff}", ""]);
// Exports
exports.locals = {
	"IssueFeedback": "_3U5RpQBkPFdzZ1nQGjBhOY",
	"Message": "_3PaWChC-qEUXVLr7ubXF80"
};
module.exports = exports;
