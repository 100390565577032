import moment from 'moment'
import { ConfigStore } from '~/code/config'
import { CardItem, PageType } from '~/code/models'
import { warn } from '~/code/services/logger'

export enum Mode {
    production = 'production',
    test = 'test',
    developmentServer = 'development-server',
    developmentLocal = 'development-local'
}

export function isPayByLink(): boolean {
    return [PageType.PAYBYLINK, PageType.PAYBYLINK_VERIFICATION].includes(ConfigStore.pageType)
}

export function isEmbeddedWidget(): boolean {
    return ConfigStore.pageType === PageType.EMBEDDED_WIDGET
}

export function isProductionMode(): boolean {
    return ENVIRONMENT === Mode.production
}

export function isDevelopmentMode(): boolean {
    return isServerDevelopmentMode() || isLocalDevelopmentMode()
}

export function isServerDevelopmentMode(): boolean {
    return ENVIRONMENT === Mode.developmentServer
}

export function isLocalDevelopmentMode(): boolean {
    return ENVIRONMENT === Mode.developmentLocal
}

export function isTestMode(): boolean {
    return ENVIRONMENT === Mode.test
}

export function isFullUrl (str) {
    return str.startsWith('http')
}

export function isBase64ImageUr(str) {
    return str.startsWith('data:')
}

export function inIframe () {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}

export function isValidCards(cards: CardItem[]) {

    if (!cards || !Array.isArray(cards)) throw new Error('Invalid cards format')

    cards.forEach((el) => {
        if (!el.merchantTokenId) warn('Invalid format. Merchant token id is required')
        else if (el.merchantTokenId.toString().length > 250) warn('Invalid format. The length of merchant token id must be 250 characters or fewer')

        else if (el.cardSchemeId && el.cardSchemeId?.toString()?.length > Math.pow(2, 31) - 1 ) warn(`Invalid format. The length of card scheme id must be ${ Math.pow(2, 31) - 1 } characters or fewer`)

        if (el.expiryDate) {
            if (el.expiryDate.toString().length < 4 || !moment(el.expiryDate, 'MM/YY').isValid()) warn('Invalid format. Please enter the expiry date in the format "MM/YY" or "MMMYY"')
        }
    })

    return true
}

export function isNumber(num: any) {
    return num !== '' && num !== null && num !== undefined && !isNaN(num)
}

export function isFunction (v: any) {
    return typeof v === 'function'
}
