// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2HA7mxz2aJW-SYrJKub-Gm{display:flex;justify-content:center;align-items:center;flex-direction:column;box-sizing:border-box;height:100%;padding-top:0px}._2HA7mxz2aJW-SYrJKub-Gm ._2vAp7tRVRo84kAYEM1tDpY{width:60px;height:60px}._2HA7mxz2aJW-SYrJKub-Gm ._1r-88rq2gdf5GH26D8etE-{flex-grow:1;display:flex;flex-direction:column;align-items:center;justify-content:center;margin-top:30px;margin-bottom:30px}._2HA7mxz2aJW-SYrJKub-Gm .EQdCY7QRSD_dUP7V94ODL{color:#9b9b9b;font-size:12px;font-family:\"Open Sans\",sans-serif;font-weight:400}._2HA7mxz2aJW-SYrJKub-Gm ._1hyc6oQPus_RdjugOxk8cT{display:flex;justify-content:flex-end;margin-top:40px}@media(min-width: 768px){._2HA7mxz2aJW-SYrJKub-Gm ._1hyc6oQPus_RdjugOxk8cT{margin-top:20px}}._2HA7mxz2aJW-SYrJKub-Gm ._2GCui7R4VSjcnCyywGeVYa{font-family:\"Poppins\",\"Open Sans\",sans-serif;font-weight:200;font-size:24px;margin-top:20px;color:#dc0053;text-align:center}._2HA7mxz2aJW-SYrJKub-Gm ._28WWHoSiZIhBYIKmYh_O3B{margin-top:5px;font-size:14px;color:#9b9b9b;font-family:\"Open Sans\",sans-serif;font-weight:400;text-align:center;margin-top:15px}._2HA7mxz2aJW-SYrJKub-Gm .mDdhFG2zmHluxucTfBGHq{padding:10px 46px}", ""]);
// Exports
exports.locals = {
	"Failure": "_2HA7mxz2aJW-SYrJKub-Gm",
	"RejectIcon": "_2vAp7tRVRo84kAYEM1tDpY",
	"Content": "_1r-88rq2gdf5GH26D8etE-",
	"ErrorCode": "EQdCY7QRSD_dUP7V94ODL",
	"Action": "_1hyc6oQPus_RdjugOxk8cT",
	"Status": "_2GCui7R4VSjcnCyywGeVYa",
	"Description": "_28WWHoSiZIhBYIKmYh_O3B",
	"BackToPaymentForm": "mDdhFG2zmHluxucTfBGHq"
};
module.exports = exports;
