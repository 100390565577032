// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3xdR3dvUL7KQpwP5O3Oei{width:100px;text-align:center}._3xdR3dvUL7KQpwP5O3Oei div{width:14px;height:14px;margin:0 3px;border-radius:100%;display:inline-block;-webkit-animation:_3XPv0s30KE69j-2QfTOy9y 1.4s infinite ease-in-out both;animation:_3XPv0s30KE69j-2QfTOy9y 1.4s infinite ease-in-out both}._3xdR3dvUL7KQpwP5O3Oei ._1HkRigcZ0-XgufXoL9k615{-webkit-animation-delay:-0.32s;animation-delay:-0.32s}._3xdR3dvUL7KQpwP5O3Oei ._3bs85C9sm1SoFr_xIEhw7c{-webkit-animation-delay:-0.16s;animation-delay:-0.16s}@-webkit-keyframes _3XPv0s30KE69j-2QfTOy9y{0%,80%,100%{-webkit-transform:scale(0)}40%{-webkit-transform:scale(1)}}@keyframes _3XPv0s30KE69j-2QfTOy9y{0%,80%,100%{-webkit-transform:scale(0);transform:scale(0)}40%{-webkit-transform:scale(1);transform:scale(1)}}", ""]);
// Exports
exports.locals = {
	"ThreeDotsLoader": "_3xdR3dvUL7KQpwP5O3Oei",
	"sk-bouncedelay": "_3XPv0s30KE69j-2QfTOy9y",
	"Bounce1": "_1HkRigcZ0-XgufXoL9k615",
	"Bounce2": "_3bs85C9sm1SoFr_xIEhw7c"
};
module.exports = exports;
