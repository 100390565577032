// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1WyKT6mJjsmP2ngk4iQWz7{display:flex;width:100%}._1nNn5JJTg9FKUMlmDf2YAB{flex-grow:1}._1nNn5JJTg9FKUMlmDf2YAB:first-child{margin-right:12px}@media screen and (max-width: 767.9px){._1WyKT6mJjsmP2ngk4iQWz7{flex-wrap:wrap}._1nNn5JJTg9FKUMlmDf2YAB{width:100%}._1nNn5JJTg9FKUMlmDf2YAB:first-child{margin:0 0 12px}}", ""]);
// Exports
exports.locals = {
	"EcospendPayeeBlock": "_1WyKT6mJjsmP2ngk4iQWz7",
	"block": "_1nNn5JJTg9FKUMlmDf2YAB"
};
module.exports = exports;
