import { action, computed, observable } from 'mobx'
import { convertPaymentMethods, isLocalDevelopmentMode, isServerDevelopmentMode } from '~/code/services'
import testConf from './testConfig'
import prodConf from './prodConfig'
import localConf from './localConfig'
import { AuthConfig, AuthToken, CardItem, Config, DonationConfiguration, EmbeddedWidgetConfiguration, Merchant, PageType, PaymentDataV2, PaymentMethod, PaymentMethodItem, PaymentMethodsSettings, WidgetMode } from '~/code/models'
import constants from '~/code/config/constants'
import { PaymentMethodStatus } from '~/code/models/PaymentMethodStatus'
import { CardScheme } from '~/code/pages/CardData/models'
import { DEFAULT_LOCALE } from '~/code/components/Translation/constants/constants'
import { getLanguageFromLocale } from '~/code/components/Translation/services/utils'
import { LocaleConfiguration } from '~/code/models/LocaleConfiguration'
import { PaymentSettings } from '~/code/models/V2/PaymentSettings'
import { Locale } from '~/code/components/Translation/types/Locale'

class ConfigStore {
    @observable
    public isTestMode: boolean = false

    @observable
    public _version: number = null

    @observable
    public auth: AuthToken = null

    @observable
    public isRequirePersonalInfo: boolean = true

    @observable
    public isEnableDonation: boolean = false

    @observable
    public isActiveDonation: boolean = false

    @observable
    public allowSavingCards: boolean = false

    @observable
    public allowedAttempts: number = constants.defaultAllowedAttempts

    @observable
    public initialAllowedAttempts: number = constants.defaultAllowedAttempts

    @observable
    public widgetMode: WidgetMode = null

    @observable
    public merchantName: string = ''

    @observable
    public merchantId: string = ''

    @observable
    public merchant: Merchant = null

    @observable
    public merchantAuthorisedScopes: string = ''

    @observable
    public personalInfoFields: { [key: string]: boolean } = {}

    @observable
    public cryptoKey: string = ''

    @observable
    public isCSCRequiredForTokenPayments: boolean = true

    @observable
    public isCSCRequiredForNonTokenPayments: boolean = true

    @observable
    public termsAndConditionsLink: string = constants.defaultTermsAndConditionsLink

    @observable
    public autoRedirectDelayInMs: number = null

    @observable
    public embeddedWidget: EmbeddedWidgetConfiguration = constants.defaultEmbeddedWidget

    @observable
    public donation: DonationConfiguration = null

    @observable
    public fixedDonationAmount: number = null

    @computed
    public get version() {
        return this._version || constants.defaultVersion
    }

    @computed
    public get isDonationIncluded() {
        return Boolean(this.fixedDonationAmount || this.isActiveDonation)
    }

    @computed
    public get donationAmount() {
        return this.fixedDonationAmount || this.donation?.amount
    }

    @observable
    public paymentMethods: PaymentMethodItem[] = convertPaymentMethods(constants.defaultPaymentMethods)

    @observable
    public paymentMethod: PaymentMethod = null

    @observable
    public paymentDescription: string = null

    @observable
    public maxVisiblePaymentMethods: number = constants.defaultMaxVisiblePaymentMethods

    @observable
    public scopes: AuthConfig = {}

    @observable
    public pageType: PageType = PageType.STANDARD

    @observable
    public cards: CardItem[] = null

    @observable
    public paymentMethodsSettings: PaymentMethodsSettings = null

    @observable
    public disabledCardSchemes: CardScheme[] = null

    @observable
    public paymentTimeoutInSeconds: number = null

    // a field that is used to store the time left for the payment timeout
    // it gets updated every second by the useTimer hook
    // TODO move the logic of the useTimer hook somewhere globally
    @observable
    public leftTimeForPaymentTimeout: number = null

    @observable
    public isTdsFeedbackEnabled: boolean = false

    @observable
    public locale: Locale = null

    @observable
    public localeConfiguration: LocaleConfiguration = null

    @observable
    public paymentSettings: PaymentSettings = null

    @computed
    public get nonNullLocale() {
        return this.localeConfiguration?.targetLocale || this.locale || this.localeConfiguration?.defaultLocale || DEFAULT_LOCALE
    }

    @computed
    public get nonNullLanguage() {
        return getLanguageFromLocale(this.nonNullLocale)
    }

    hasReturnUrl() {
        return Boolean(this.paymentSettings?.returnUrl)
    }

    @action
    init() {
        this.auth = null
        this.merchantAuthorisedScopes = ''
        this.personalInfoFields = {}
        this.cryptoKey = ''
        this.isCSCRequiredForTokenPayments = true
        this.isCSCRequiredForNonTokenPayments = true
        this.widgetMode = null
        this.donation = null
        this.merchantName = ''
        this.merchant = null
        this.termsAndConditionsLink = constants.defaultTermsAndConditionsLink
        this.allowedAttempts = constants.defaultAllowedAttempts
        this.autoRedirectDelayInMs = null
        this.paymentMethod = null
        this.pageType = PageType.STANDARD
        this.isRequirePersonalInfo = true
        this.isActiveDonation = false
        this.paymentMethodsSettings = null
        this.locale = null
    }

    public activePaymentMethods() {
        const result = this.paymentMethods.filter((method: PaymentMethodItem) => {
            const paymentMethodItem = this.getPaymentMethodConfig(method.name)
            switch (method.name) {
                case PaymentMethod.BankCard:
                    return true
                case PaymentMethod.Alipay:
                case PaymentMethod.WeChatPay:
                case PaymentMethod.AlipayPlus:
                case PaymentMethod.ApplePay:
                case PaymentMethod.PayPal:
                case PaymentMethod.PayByBank:
                case PaymentMethod.GooglePay:
                case PaymentMethod.Ecospend:
                case PaymentMethod.ClickToPay:
                case PaymentMethod.AstroPay:
                case PaymentMethod.Klarna:
                    return paymentMethodItem?.status === PaymentMethodStatus.ACTIVE
                default:
                    return false
            }
        } )

        if (result.length === 0) {
            // check if there was click2pay in the list of payment methods, and it became failed, if so, we should show the bank card
            const clickToPayExists = this.paymentMethods.find((method: PaymentMethodItem) => method.name === PaymentMethod.ClickToPay)
            const clickToPay = this.getPaymentMethodConfig(PaymentMethod.ClickToPay)
            if (clickToPayExists && (clickToPay?.status === PaymentMethodStatus.FAILED || clickToPay?.status === PaymentMethodStatus.DISABLED)) {
                result.push({
                    name: PaymentMethod.BankCard
                })
            }
        }

        // make the list contain only unique payment methods
        const uniquePaymentMethods = result.filter((method, index, self) => self.findIndex((t) => t.name === method.name) === index)

        return uniquePaymentMethods
    }

    public getPaymentMethodConfig(methodName: PaymentMethod) {
        switch (methodName) {
            case PaymentMethod.BankCard:
                return this.paymentMethodsSettings?.bankCard
            case PaymentMethod.PayPal:
                return this.paymentMethodsSettings?.paypal
            case PaymentMethod.PayByBank:
                return this.paymentMethodsSettings?.payByBankApp
            case PaymentMethod.GooglePay:
                return this.paymentMethodsSettings?.googlepay
            case PaymentMethod.ApplePay:
                return this.paymentMethodsSettings?.applepay
            case PaymentMethod.Ecospend:
                return this.paymentMethodsSettings?.ecospend
            case PaymentMethod.Klarna:
                return this.paymentMethodsSettings?.klarna
            case PaymentMethod.ClickToPay:
                return this.paymentMethodsSettings?.clickToPay
            case PaymentMethod.AstroPay:
                return this.paymentMethodsSettings?.astropay
            case PaymentMethod.Alipay:
                return this.paymentMethodsSettings?.alipay
            case PaymentMethod.AlipayPlus:
                return this.paymentMethodsSettings?.alipayplus
            case PaymentMethod.WeChatPay:
                return this.paymentMethodsSettings?.wechatpay
            default:
                return null
        }
    }

    @action
    setPaymentStatus(methodName: PaymentMethod, status: PaymentMethodStatus) {
        const paymentMethodItem = this.getPaymentMethodConfig(methodName)
        if (paymentMethodItem) {
            paymentMethodItem.status = status
        }
    }

    @computed
    public get acceptedCardSchemes() {
        const acceptedCardSchemes = this.paymentMethodsSettings?.bankCard?.acceptedCardSchemes || []
        const disabledCardSchemes = this.disabledCardSchemes || []

        const cardSchemes = acceptedCardSchemes.filter((scheme) => !disabledCardSchemes.find((s) => s.cardSchemeId === scheme.cardSchemeId))
        return cardSchemes.length > 0 ? cardSchemes : null
    }

    @action
    getUrl(): Config {
        return {
            ...( this.isTestMode ? testConf : prodConf ),
            ...( isServerDevelopmentMode() ? { paymentPageUrl: constants.devPaymentPageUrl } : {}),
            ...( isLocalDevelopmentMode() ? localConf : {})
        }
    }

    @action
    public setField<K extends keyof this>(name: K, value: any) {
        this[name] = value
    }

    getAutoRedirectDelayInMs() {
        return this.autoRedirectDelayInMs || constants.defaultAutoCloseModalTime
    }

    @computed
    public get wasAttemptToPay(): boolean {
        return this.initialAllowedAttempts > this.allowedAttempts
    }
}

const ConfigStoreInstance = new ConfigStore()

export { ConfigStoreInstance as ConfigStore }
