import loadScript from 'load-script'
import { action, computed, observable, reaction, runInAction } from 'mobx'
import { IAlipayWeChatPayParentStore } from './models'
import { ConfigStore } from '~/code/config'
import { PaymentMethod } from '~/code/models'
import { error, log } from '~/code/services/logger'
import { PaymentMethodStatus } from '~/code/models/PaymentMethodStatus'
import { AlipayWeChatPayEvents } from './models/AlipayWeChatPayEvents'
import { getLanguageFromLocale } from '~/code/components/Translation/services/utils'
import { TranslationBuilder } from '~/code/components/Translation'

type ScriptLoadingState = 'idle' | 'loading' | 'loaded' | 'failed'

export class AlipayWeChatPayStore {

    public parentStore: IAlipayWeChatPayParentStore

    @observable
    public isLoading: boolean = false

    @observable
    public isProcessingPayment = false

    @observable
    public isPaymentSuccessful: boolean = false

    @observable
    public availabilityState: 'unknown' | 'available' | 'unavailable' = 'unknown'

    public currentPaymentState: 'beforeButtonLoad' | 'afterButtonLoad' | 'duringPayment'

    public paymentMethod: PaymentMethod.Alipay | PaymentMethod.WeChatPay | PaymentMethod.AlipayPlus

    private component: any

    constructor (parentStore: IAlipayWeChatPayParentStore, paymentMethod: PaymentMethod.Alipay | PaymentMethod.WeChatPay | PaymentMethod.AlipayPlus) {
        this.parentStore = parentStore
        this.currentPaymentState = 'beforeButtonLoad'
        this.paymentMethod = paymentMethod || PaymentMethod.Alipay
        this.startLoading()
    }

    @computed
    public get paymentData() {
        return this.parentStore.paymentData
    }

    @action
    public startLoading() {
        this.isLoading = true
    }

    @action
    public stopLoading() {
        this.isLoading = false
        log(`AlipayWeChatPayStore ${this.paymentMethod} stopLoading: `, this.isLoading)
    }

    public onClick = () => {
        this.currentPaymentState = 'duringPayment'
        this.startPaymentProcessing()
        return {
            paymentTimeoutInSeconds: ConfigStore.leftTimeForPaymentTimeout
        }
    }

    public onError = (err) => {
        log('Error has occurred', err)
        this.stopPaymentProcessing()
        if (this.currentPaymentState === 'beforeButtonLoad' || this.currentPaymentState === 'afterButtonLoad') {
            ConfigStore.setPaymentStatus(this.paymentMethod, PaymentMethodStatus.FAILED)
        } else {
            ConfigStore.setField('paymentMethod', this.paymentMethod)
            this.parentStore.stopPaymentProcessing(false)
            this.parentStore.handleFailureResult(err)
        }
    }

    public onCancel = () => {
        log('The payment has been cancelled')
        this.stopPaymentProcessing()
        this.currentPaymentState = 'afterButtonLoad'
    }

    public onPaymentSuccess = (result) => {
        log('Payment has successfully been processed', result)
        this.stopPaymentProcessing()
        this.isPaymentSuccessful = true
        this.currentPaymentState = 'afterButtonLoad'
        ConfigStore.setField('paymentMethod', this.paymentMethod)
        this.parentStore.transactionId = result.id
        this.parentStore.handleSuccessResult()
    }

    private  events: AlipayWeChatPayEvents = {
        onClick: this.onClick,
        onError: this.onError,
        onPaymentSuccess: this.onPaymentSuccess,
        onCancel: this.onCancel
    }

    @action
    public loadLibrary = async (buttonContainer) => {
        loadScript(ALIPAY_WECHAT_PAY_COMPONENT_URL, {}, (err, result) => {
            if (err) {
                error('AlipayWeChatPayStore.loadLibrary error: ', err)
                ConfigStore.setPaymentStatus(this.paymentMethod, PaymentMethodStatus.FAILED)
                runInAction(() => {
                    this.stopLoading()
                })
            } else {
                this.component = null

                try {
                    this.component = window.DNAPayments?.AlipayWeChatPayComponent.create(
                        buttonContainer,
                        this.events,
                        {
                            paymentData: this.paymentData,
                            paymentMethod: this.paymentMethod,
                            token: this.paymentData.auth.access_token,
                            language: getLanguageFromLocale(TranslationBuilder.lang),
                            paymentTimeoutInSeconds: ConfigStore.paymentTimeoutInSeconds
                        })
                    this.stopLoading()
                    this.currentPaymentState = 'afterButtonLoad'
                } catch (error) {
                    error(`error in AlipayWeChatPayStore.loadScript ${this.paymentMethod}`, error)
                    ConfigStore.setPaymentStatus(this.paymentMethod, PaymentMethodStatus.FAILED)
                    runInAction(() => {
                        this.stopLoading()
                    })
                }

                return this.component
            }
        })
    }

    @action
    public startPaymentProcessing = () => {
        this.isProcessingPayment = true
        this.parentStore.startPaymentProcessing()
    }

    @action
    public stopPaymentProcessing = () => {
        this.isProcessingPayment = false
        this.parentStore.stopPaymentProcessing(this.isPaymentSuccessful, true)
    }
}
