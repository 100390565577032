import moment from 'moment'
import { getCreditCardTypeByScheme } from '~/code/pages/CardData/services/getters'
import { CardItem, PaymentDataConfig, PaymentDataV1, PaymentFormConfigure, PaymentLinkDataType, PaymentMethod, PaymentMethodItem, TransactionType } from '~/code/models'
import creditCardType, { getTypeInfo } from 'credit-card-type'
import { PaymentDataV2 } from '~/code/models/PaymentDataV2'
import { CardNetworks } from '~/code/pages/ApplePay/models'
import { CardScheme } from '~/code/pages/CardData/models'
import { AddressInfo } from '~/code/models/V2/AddressInfo'
import { PaymentAddressModel } from '~/code/models/PaymentAddressModel'
import { ConfigStore } from '~/code/config'
import { toJS } from 'mobx'
import { isNumber } from '~/code/services/checkers'
import { mergePaymentMethodsSettings } from '~/code/services/payment-methods-settings'
import { encodeToUrl } from '~/code/services/generators'

export function lighten(col, amt) {
    let usePound = false
    if (col[0] === '#') {
        col = col.slice(1)
        usePound = true
    }
    const num = parseInt(col, 16)
    // tslint:disable-next-line:no-bitwise
    let r = (num >> 16) + amt
    if (r > 255) {
        r = 255
    } else if (r < 0) {
        r = 0
    }
    // tslint:disable-next-line:no-bitwise
    let b = (( num >> 8) & 0x00FF) + amt
    if (b > 255) {
        b = 255
    } else if (b < 0) {
        b = 0
    }
    // tslint:disable-next-line:no-bitwise
    let g = (num & 0x0000FF) + amt
    if (g > 255) {
        g = 255
    } else if (g < 0) {
        g = 0
    }
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export function encodeHTML(s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/'/g, '&quot;')
}

export function convertPaymentMethods (arr: (PaymentMethod | { name: PaymentMethod, message?: string })[]): PaymentMethodItem[]  {
    if (!arr || !Array.isArray(arr)) throw new Error('Invalid payment methods format')
    return arr.map((el) => {
        return {
            message: null, // 'NO FEE'
            ...(typeof el !== 'object' ? { name: el } : el )
        }
    })
}

export function convertCards (cards: CardItem[]): CardItem[] {
    return cards?.filter((el) => !!el?.merchantTokenId)?.map((el) => {
        const creditCardTypeInfo = getCreditCardTypeByScheme(el?.cardSchemeId, el?.cardSchemeName)
        return {
            cardHolderName: '',
            expiryDate: '',
            ...el,
            ...(el.cardName ? { cardName: String(el.cardName).slice(0, 25) } : {}),
            ...(el.panStar ? { panStar: String(el.panStar) } : {}),
            ...(el.panStar ? { panStarPretty: convertCardNumber(el.panStar) } : {}),
            ...(el.cardSchemeId ? { cardSchemeId: String(el.cardSchemeId) } : {}),
            ...(el.expiryDate ? { expiryDate: moment(el.expiryDate, 'MM/YY').format('MM/YY') } : {}),
            creditCardType: creditCardTypeInfo
        }
    })
}

export function convertCardNumber (cardNumber: string) {
    return `${ repeat('\u2022', 4) } ${ cardNumber.replace(/\D+/g, '').slice(-4) }`
}

function convertNumberFieldPaymentData(fieldName, value): { [key: string]: number } {
    return value ? { [fieldName]: parseFloat(String(value)) } : {}
}

function convertStringFieldPaymentData(fieldName, value): { [key: string]: string } {
    return value ? { [fieldName]: String(value) } : {}
}

function convertFieldPaymentData(fieldName, value, type: string = 'string') {
    if (type === 'number') {
        return convertNumberFieldPaymentData(fieldName, value)
    } else if ( type === 'string') {
        return convertStringFieldPaymentData(fieldName, value)
    }

    return value ? { [fieldName]: value } : {}
}

export function convertPaymentLinkToPaymentData(paymentLink: PaymentLinkDataType): Partial<PaymentDataV2> {
    const { amount, currency, description, invoiceId, paymentSettings, periodic, terminalId, token, transactionType } = paymentLink
    return {
        amount,
        auth: {
            access_token: token
        },        
        currency,
        description,
        invoiceId,
        periodic,
        paymentSettings: {
            ...(paymentSettings || {}),
            terminalId
        },
        transactionType
    }
}

export function convertPaymentDataTypes(paymentData: PaymentDataV1 | PaymentDataV2): PaymentDataV2 {
    if ('paymentSettings' in paymentData && paymentData?.paymentSettings?.terminalId) {
        return paymentData as PaymentDataV2
    }
    return convertPaymentDataFromV1toV2(paymentData)
}

function convertShippingAddress (shippingAddress: PaymentAddressModel): AddressInfo {
    if (!shippingAddress) {
        return null
    }
    const { region, streetAddress1, streetAddress2, ...rest } = shippingAddress

    const result: AddressInfo = {
        addressLine1: streetAddress1,
        addressLine2: streetAddress2,
        ...rest
    }

    if (region && region?.length < 4) {
        result.region = region
    }

    return result
}

export function convertPaymentDataFromV1toV2(paymentData: PaymentDataV1): PaymentDataV2 {
    const {
        transactionType,
        currency,
        invoiceId,
        terminal,
        description,
        merchantCustomData,
        entryMode,
        avsHouseMatrix,
        avsPostCodeMatrix,
        PAMatrix,
        CSCMatrix,
        postLink,
        failurePostLink,
        backLink,
        failureBackLink,
        accountId,
        accountFirstName,
        accountLastName,
        accountStreet1,
        accountStreet2,
        accountPostalCode,
        accountCity,
        phone,
        accountCountry,
        shippingAddress,
        accountEmail,
        recurringFrequency,
        recurringExpirationDate,
        periodicType,
        numberOfInstallments,
        sequenceType,
        merchantName,
        merchantNumber,
        merchantURL,
        merchantDepartmentID,
        merchantStoreID,
        visaMID,
        masterCardMID,
        amexMID,
        CVC2RC,
        paymentTo,
        taxAmount,
        deliveryType,
        softDescriptor,
        ...fields
    } = paymentData

    return {
        ...fields,
        invoiceId: invoiceId ? String(invoiceId) : '',
        amount: parseFloat(String(paymentData.amount)),
        ...convertFieldPaymentData('currency', currency),
        ...(transactionType ? { transactionType: String(paymentData.transactionType).toUpperCase() as TransactionType } : {}),
        ...convertFieldPaymentData('description', description),
        ...convertFieldPaymentData('merchantCustomData', merchantCustomData),
        ...convertFieldPaymentData('entryMode', entryMode),
        paymentSettings: {
            terminalId: terminal,
            ...convertFieldPaymentData( 'avsHouseMatrix', avsHouseMatrix, 'number'),
            ...convertFieldPaymentData('avsPostCodeMatrix', avsPostCodeMatrix, 'number'),
            ...convertFieldPaymentData('cscMatrix', CSCMatrix, 'number'),
            ...convertFieldPaymentData('paMatrix', PAMatrix, 'number'),

            ...convertFieldPaymentData('returnUrl', backLink),
            ...convertFieldPaymentData('failureReturnUrl', failureBackLink),
            ...convertFieldPaymentData('callbackUrl', postLink),
            ...convertFieldPaymentData('failureCallbackUrl', failurePostLink)
        },
        customerDetails: {
            accountDetails: {
                ...convertFieldPaymentData('accountId', accountId)
            },
            billingAddress: {
                ...convertFieldPaymentData('firstName', accountFirstName),
                ...convertFieldPaymentData('lastName', accountLastName),
                ...convertFieldPaymentData('addressLine1', accountStreet1),
                ...convertFieldPaymentData('addressLine2', accountStreet2),
                ...convertFieldPaymentData('postalCode', accountPostalCode),
                ...convertFieldPaymentData('city', accountCity),
                ...convertFieldPaymentData('country', accountCountry)
            },
            deliveryDetails: {
                ...convertFieldPaymentData('deliveryAddress', convertShippingAddress(shippingAddress), 'object')
            },
            ...convertFieldPaymentData('email', accountEmail),
            ...convertFieldPaymentData('mobilePhone', phone)
        },
        periodic: {
            ...convertFieldPaymentData('recurringFrequency', recurringFrequency),
            ...convertFieldPaymentData('recurringExpirationDate', recurringExpirationDate),
            ...convertFieldPaymentData('periodicType', periodicType),
            ...convertFieldPaymentData('numberOfInstallments', numberOfInstallments),
            ...convertFieldPaymentData('sequenceType', sequenceType)
        },
        requestorDetails: {
            ...convertFieldPaymentData('merchantName', merchantName),
            ...convertFieldPaymentData('merchantNumber', merchantNumber),
            ...convertFieldPaymentData('merchantUrl', merchantURL),
            ...convertFieldPaymentData('merchantDepartmentId', merchantDepartmentID, 'number'),
            ...convertFieldPaymentData('merchantStoreId', merchantStoreID, 'number'),
            ...convertFieldPaymentData('visaMid', visaMID),
            ...convertFieldPaymentData('mastercardMid', masterCardMID),
            ...convertFieldPaymentData('amexMid', amexMID)
        },
        ...convertFieldPaymentData('CVC2RC', CVC2RC),
        ...convertFieldPaymentData('paymentTo', paymentTo),
        ...convertFieldPaymentData('taxAmount', taxAmount, 'number'),
        ...convertFieldPaymentData('deliveryType', deliveryType),
        ...convertFieldPaymentData('softDescriptor', softDescriptor)
    }
}

export function convertPaymentDataFromV2toV1(paymentData: PaymentDataV2): PaymentDataV1 {
    const {
        invoiceId,
        amount,
        currency,
        transactionType,
        description,
        merchantCustomData,
        entryMode,
        paymentSettings,
        language,
        customerDetails,
        periodic,
        CVC2RC,
        paymentTo,
        requestorDetails,
        taxAmount,
        deliveryType,
        amountBreakdown,
        orderLines,
        productType,
        softDescriptor,
        ...fields
    } = paymentData

    return {
        ...fields,
        invoiceId: invoiceId ? String(invoiceId) : '',
        amount: parseFloat(String(paymentData.amount)),
        ...convertFieldPaymentData('language', language),
        ...convertFieldPaymentData('description', description),
        ...convertFieldPaymentData('currency', currency),

        ...convertFieldPaymentData('terminal', paymentSettings?.terminalId),
        ...convertFieldPaymentData('postLink', paymentSettings?.callbackUrl),
        ...convertFieldPaymentData('failurePostLink', paymentSettings?.failureCallbackUrl),
        ...convertFieldPaymentData('backLink', paymentSettings?.returnUrl),
        ...convertFieldPaymentData('failureBackLink', paymentSettings?.failureReturnUrl),
        ...convertFieldPaymentData('accountId', customerDetails?.accountDetails?.accountId),
        ...convertFieldPaymentData('accountCountry', customerDetails?.billingAddress?.country),
        ...convertFieldPaymentData('accountCity', customerDetails?.billingAddress?.city),
        ...convertFieldPaymentData('accountStreet1', customerDetails?.billingAddress?.addressLine1),
        ...convertFieldPaymentData('accountStreet2', customerDetails?.billingAddress?.addressLine2),
        ...convertFieldPaymentData('accountStreet3', customerDetails?.billingAddress?.addressLine3),
        ...convertFieldPaymentData('accountEmail', customerDetails?.email),
        ...convertFieldPaymentData('accountFirstName', customerDetails?.billingAddress?.firstName),
        ...convertFieldPaymentData('accountLastName', customerDetails?.billingAddress?.lastName),
        ...convertFieldPaymentData('accountPostalCode', customerDetails?.billingAddress?.postalCode),
        ...convertFieldPaymentData('phone', customerDetails?.mobilePhone),
        ...convertFieldPaymentData('accountEmail', customerDetails?.email),

        ...convertFieldPaymentData('transactionType', transactionType),


        ...convertFieldPaymentData('recurringFrequency', periodic?.recurringFrequency),
        ...convertFieldPaymentData('recurringExpirationDate', periodic?.recurringExpirationDate),
        ...convertFieldPaymentData('periodicType', periodic?.periodicType),
        ...convertFieldPaymentData('sequenceType', periodic?.sequenceType),
        ...convertFieldPaymentData('numberOfInstallments', periodic?.numberOfInstallments),

        ...convertFieldPaymentData('entryMode', entryMode),
        ...convertFieldPaymentData('CVC2RC', CVC2RC),
        ...convertFieldPaymentData('paymentTo', paymentTo),

        ...convertFieldPaymentData('avsHouseMatrix', paymentSettings?.avsHouseMatrix, 'number'),
        ...convertFieldPaymentData('avsPostCodeMatrix', paymentSettings?.avsPostCodeMatrix, 'number'),
        ...convertFieldPaymentData('CSCMatrix', paymentSettings?.cscMatrix, 'number'),
        ...convertFieldPaymentData('PAMatrix', paymentSettings?.paMatrix, 'number'),

        ...convertFieldPaymentData('merchantName', requestorDetails?.merchantName),
        ...convertFieldPaymentData('merchantNumber', requestorDetails?.merchantNumber),
        ...convertFieldPaymentData('merchantURL', requestorDetails?.merchantUrl),


        ...convertFieldPaymentData('merchantDepartmentID', requestorDetails?.merchantDepartmentId, 'number'),
        ...convertFieldPaymentData('merchantStoreID', requestorDetails?.merchantStoreId, 'number'),

        ...convertFieldPaymentData('visaMID', requestorDetails?.visaMid),
        ...convertFieldPaymentData('masterCardMID', requestorDetails?.mastercardMid),
        ...convertFieldPaymentData('amexMID', requestorDetails?.amexMid),

        ...convertFieldPaymentData('merchantCustomData', merchantCustomData),
        ...convertFieldPaymentData('taxAmount', taxAmount, 'number'),
        ...convertFieldPaymentData('deliveryType', deliveryType),
        ...convertFieldPaymentData('amountBreakdown', amountBreakdown, 'object'),
        ...convertFieldPaymentData('orderLines', orderLines, 'object'),
        ...convertFieldPaymentData('shippingAddress', customerDetails?.deliveryDetails?.deliveryAddress, 'object'),
        ...convertFieldPaymentData('productType', productType),
        ...convertFieldPaymentData('softDescriptor', softDescriptor)
    }
}


export function addCardType () {
    const union = getTypeInfo(creditCardType.types.UNIONPAY)
    creditCardType.addCard({
        ...union,
        lengths: [
            13,
            ...union.lengths
        ]
    })
}

export function repeat(str: string, n: number){
    const numLength = n || 1
    return Array(numLength + 1).join(str)
}

/**
 * A converter function that converts a list of card scheme product names into a list of card network names
 * If the list is empty, null or undefined it returns an empty list
 * @acceptedCardSchemes - a list of card schemes
 */
export function extractAllowedCardNetworks (acceptedCardSchemes: CardScheme[]) {
    // we need to find out what card networks are allowed according to the accepted card scheme products that
    // we obtain form the backend in the configuration
    return acceptedCardSchemes?.map(item => item.cardSchemeName) // get the card scheme product names
        .map(schemeName => {
            for (const cardSchemesKey of Object.keys(CardNetworks)) {
                if (CardNetworks[cardSchemesKey].find(item => schemeName.toLowerCase().includes(item))) {
                    return cardSchemesKey
                }
            }
            return ''
        }) // convert card scheme product names into card networks (e.g. MasterCard DEBIT => MASTERCARD)
        .reduce((previousValue, currentValue) => {
            if (!currentValue || previousValue.includes(currentValue)) {
                return previousValue
            }
            return [...previousValue, currentValue]
        }, []) || [] // remove duplicates
}

export function convertPaymentDataToQueryString(paymentData = this.paymentData, config: PaymentFormConfigure = null): string {
    const paymentMethod = config?.paymentMethod || ConfigStore.paymentMethod
    const paymentMethods = config?.paymentMethods || ConfigStore.paymentMethods
    const paymentMethodsSettings = config?.paymentMethodsSettings

    const _config: Partial<PaymentFormConfigure> = {
        ...config,
        isTestMode: ConfigStore.isTestMode,
        isEnableDonation: ConfigStore.isEnableDonation,
        paymentMethods: toJS(paymentMethods)
    }

    if (isNumber(ConfigStore.autoRedirectDelayInMs)) {
        _config.autoRedirectDelayInMs = ConfigStore.autoRedirectDelayInMs
    }

    if (isNumber(ConfigStore.paymentTimeoutInSeconds)) {
        _config.paymentTimeoutInSeconds = ConfigStore.paymentTimeoutInSeconds
    }
    if (ConfigStore.allowSavingCards) {
        _config.allowSavingCards = ConfigStore.allowSavingCards
    }
    if (ConfigStore.cards) {
        _config.cards = ConfigStore.cards
    }
    if (ConfigStore.disabledCardSchemes) {
        _config.disabledCardSchemes = ConfigStore.disabledCardSchemes
    }
    if (ConfigStore.localeConfiguration) {
        _config.locale = {
            targetLocale: ConfigStore.localeConfiguration.targetLocale,
            defaultLocale: ConfigStore.localeConfiguration.defaultLocale,
            detectLocale: ConfigStore.localeConfiguration.detectLocale
        }
    }
    if (paymentMethodsSettings) {
        ConfigStore.paymentMethodsSettings = mergePaymentMethodsSettings(ConfigStore.paymentMethodsSettings, paymentMethodsSettings)
    }

    if (ConfigStore.paymentMethodsSettings) {
        _config.paymentMethodsSettings = ConfigStore.paymentMethodsSettings
    }

    if (ConfigStore._version) {
        _config.version = ConfigStore._version
    }

    return 'paymentData=' + encodeToUrl({
            ...paymentData
        }) +
        '&config=' + encodeToUrl({
            ..._config,
            ...(paymentMethod ? { paymentMethod } : {})
        })
}
