import React, { forwardRef, LegacyRef } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { StyleStore } from '~/code/config'
import { InputProps } from './props'
import styles from './Input.scss'


const useStyles = createUseStyles({
    Element: {
        '&:focus': {
            borderColor: () => StyleStore.style.primaryColor
        }
    }
})

export const Input = forwardRef((props: InputProps = {}, forwardedRef: LegacyRef<any>) => {
    const { label, iconClassName, renderIcon, errorText, warningText, notFloatingLabel, showPlaceholderWhenNotFocus, ...inputProps } = props
    const classes = useStyles()

    const getInputClassname = () => {
        if (errorText) {
            return styles.HasError
        } else if (warningText) {
            return styles.HasWarning
        }
        return ''
    }

    const renderMessage = () => {
        if (errorText) {
            return (
                <div className={ styles.Error }>
                    { errorText }
                </div>
            )
        } else if (warningText) {
            return ( <div className={ styles.Warning }>
                { warningText }
            </div> )
        }
        return null
    }

    return (
        <div
            className={ classNames(styles.Input, { [styles.HasValue]: notFloatingLabel || props.value }, getInputClassname()) }
        >
            <input
                ref={ forwardedRef }
                { ...inputProps }
                className={ classNames(styles.Element, classes.Element, props.className, {[styles.ShowPlaceholder]: showPlaceholderWhenNotFocus}) }
            />
            {
                label && (
                    <label htmlFor={ props.id } className={ styles.Label }> { label } </label>
                )
            }
            {
                renderMessage()
            }
            { renderIcon && (
                <span className={ classNames(styles.Icon, iconClassName) }>
                    { renderIcon() }
                </span>
            )}
        </div>
    )
})
