// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NF156kio_-2PGidLIHiWd{font-family:\"Poppins\",sans-serif;width:50%}.NF156kio_-2PGidLIHiWd ._158ysxWW_wxHsqNlAHwON-{display:flex;flex-direction:column}.NF156kio_-2PGidLIHiWd ._1vrVoXoyPtlFg2qqbTS8f7,.NF156kio_-2PGidLIHiWd ._2xEJ0uMUuDfHiLm6Z7ISfN{display:none}.NF156kio_-2PGidLIHiWd ._1PMOzp6pp74T_imsGQcz9Y{width:100%}.NF156kio_-2PGidLIHiWd ._2YsErLvKBq7wCq2ESqAxI1,.NF156kio_-2PGidLIHiWd ._2V4_y7OKtTnxCnMrlrwX5p{display:none}.NF156kio_-2PGidLIHiWd .jYApoJkxfQGUQxEh3-HNm ._2sVEfhGI5xYPpWFTSdmC-9{width:80%}.NF156kio_-2PGidLIHiWd:last-child:not(:nth-child(odd)){width:100%;padding-left:0;padding-right:0}.NF156kio_-2PGidLIHiWd:last-child:not(:nth-child(odd)) .jYApoJkxfQGUQxEh3-HNm ._2sVEfhGI5xYPpWFTSdmC-9{width:50%}.NF156kio_-2PGidLIHiWd .jYApoJkxfQGUQxEh3-HNm{display:flex;align-items:center;justify-content:center}.NF156kio_-2PGidLIHiWd .jYApoJkxfQGUQxEh3-HNm ._2sVEfhGI5xYPpWFTSdmC-9{width:70px;height:39px;background-size:contain}", ""]);
// Exports
exports.locals = {
	"SimplePaymentMethodItem": "NF156kio_-2PGidLIHiWd",
	"PaymentMethodItemBlock": "_158ysxWW_wxHsqNlAHwON-",
	"Message": "_1vrVoXoyPtlFg2qqbTS8f7",
	"Description": "_2xEJ0uMUuDfHiLm6Z7ISfN",
	"PaymentMethodItemBlockHeader": "_1PMOzp6pp74T_imsGQcz9Y",
	"PaymentMethodItemBlockContent": "_2YsErLvKBq7wCq2ESqAxI1",
	"PaymentMethodItemBlockMessage": "_2V4_y7OKtTnxCnMrlrwX5p",
	"Header": "jYApoJkxfQGUQxEh3-HNm",
	"HeaderLogo": "_2sVEfhGI5xYPpWFTSdmC-9"
};
module.exports = exports;
