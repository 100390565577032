import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import Image from '~/code/components/Image/Image'
import { KlarnaProps } from '~/code/pages/Klarna/porps'
import styles from './Klarna.scss'
import { KLARNA_PAY_BUTTON } from '~/code/constants/playwright-ids'

export const Klarna = observer(({ store, onClick: handleClick}: KlarnaProps) => {
    return (
        <div data-test-id={KLARNA_PAY_BUTTON} className={styles.Klarna} onClick={
            () => {
                store.onClick()
                handleClick()
            }
        }>
            <Image className={ styles.image }
                   src={'klarna_pink_logo.svg'}
            />
        </div>
    )
})
