// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2MrwlSBfb0k_oMQqytCL2K ._3-8VBTxC5VxFlTqD_RcU5n{box-sizing:border-box;padding:8px 0}._2MrwlSBfb0k_oMQqytCL2K ._226N39lu-UKVnDydU-FhDJ{display:flex;justify-content:center;align-items:center;margin-top:16px;margin-bottom:8px}._2MrwlSBfb0k_oMQqytCL2K ._1-KlG_KLzqN2znWup2W-CW{color:#9b9b9b;font-size:12px;font-family:\"Open Sans\",sans-serif;font-weight:400;margin-right:8px}._2MrwlSBfb0k_oMQqytCL2K ._2TjTgrhdEbY7nbFrqg3cqC{display:block;flex-grow:1;width:145px;height:30px;background:left no-repeat;background-size:contain;margin-left:auto;margin-right:auto}", ""]);
// Exports
exports.locals = {
	"EmbeddedWidget": "_2MrwlSBfb0k_oMQqytCL2K",
	"Content": "_3-8VBTxC5VxFlTqD_RcU5n",
	"PoweredByBlock": "_226N39lu-UKVnDydU-FhDJ",
	"PoweredBy": "_1-KlG_KLzqN2znWup2W-CW",
	"DnaLogo": "_2TjTgrhdEbY7nbFrqg3cqC"
};
module.exports = exports;
