import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    cardNumber: string,
    cardHolderName: string,
    expiryDate: string,
    mm: string,
    yy: string,
    notSupportScheme: string,
    invalidCardNumber: string,
    invalidCardHolderName: string,
    longCardHolderName: string,
    invalidExpiryDate: string,
    invalidCvv: string,
    backCardList: string
    yourClickToPayPaymentUnsuccessful: string
    loading: string
    visa: string
    mastercard: string
    amex: string
    discover: string
    dinersClub: string
    unionPay: string
    jcb: string
    saveCardDetails: string
}>()
