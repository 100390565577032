import { action, computed, observable } from 'mobx'
import { IApplePayParentStore } from './models'
import { ConfigStore, LogStore } from '~/code/config'
import { ApplePaySession, createApplePaySession, isApplePayAvailable, postMessageToParent, validateApplePaySession } from './services'
import { PaymentMethod } from '~/code/models'
import * as errorCodes from '~/code/config/LogStore'
import { inIframe, isEmbeddedWidget } from '~/code/services'
import { PaymentMethodStatus } from '~/code/models/PaymentMethodStatus'
import { PostMessageActions } from '~/code/pages/ApplePay/constants/post-message-actions'
import { ApplePayStatuses } from '~/code/pages/ApplePay/constants/apple-pay-statuses'
import { error, log } from '~/code/services/logger'

export class ApplePayStore {
    public parentStore: IApplePayParentStore

    @observable
    public isProcessingPayment: boolean = false

    @observable
    public isPaymentSuccessful: boolean = false

    @observable
    public isLoading: boolean = false

    @observable
    public availabilityState: 'unknown' | 'available' | 'unavailable' = 'unknown'

    public currentPaymentState: 'beforeButtonLoad' | 'afterButtonLoad' | 'duringPayment'

    private applePaySession

    constructor (parentStore: IApplePayParentStore) {
        this.parentStore = parentStore
        this.currentPaymentState = 'beforeButtonLoad'
        this.startLoading()
    }

    @action
    public startLoading() {
        this.isLoading = true
    }

    @action
    public stopLoading() {
        this.isLoading = false
    }

    @action
    public startPaymentProcessing = () => {
        this.isProcessingPayment = true
        this.parentStore.startPaymentProcessing()
    }

    @action
    public stopPaymentProcessing = () => {
        this.isProcessingPayment = false
        this.parentStore.stopPaymentProcessing(this.isPaymentSuccessful, true)
    }

    @action
    public setPaymentSuccessful = (isSuccessful) => {
        this.isPaymentSuccessful = isSuccessful
    }

    @action
    public onReadyToPayChange = (isReadyToPay) => {
        if (isReadyToPay) {
            this.currentPaymentState = 'afterButtonLoad'
        }
        const cardNetworks = this.allowedCardNetworks
        this.availabilityState = Boolean(isReadyToPay) ? 'available' : 'unavailable'
        if (!isReadyToPay || !cardNetworks || cardNetworks.length === 0) {
            ConfigStore.setPaymentStatus(PaymentMethod.ApplePay, PaymentMethodStatus.FAILED)
            this.availabilityState = 'unavailable'
        }
        this.stopLoading()
    }

    @computed
    public get paymentData() {
        return this.parentStore?.paymentData
    }

    public isApplePayAvailable () {
        if (inIframe()) {
            postMessageToParent({action: 'checkIfCanMakePayments'})
        } else {
            this.onReadyToPayChange(isApplePayAvailable())
        }
    }

    public get merchantName() {
        return this.paymentData?.requestorDetails?.merchantName || ConfigStore.merchantName || this.paymentData?.paymentSettings?.terminalId
    }

    public onCancel = (reason?) => {
        this.stopPaymentProcessing()
    }

    public onError = (e: Error) => {
        if (this.currentPaymentState === 'beforeButtonLoad') {
            ConfigStore.setPaymentStatus(PaymentMethod.ApplePay, PaymentMethodStatus.FAILED)
        } else if (this.currentPaymentState === 'duringPayment') {
            ConfigStore.setField('paymentMethod', PaymentMethod.ApplePay)
            this.parentStore.stopPaymentProcessing(false)
            this.parentStore.handleFailureResult(e)
        }
    }

    private validateApplePaySession = (validationURL, applePaySession?) => {
        let merchantId = ConfigStore.merchantId
        // let domainName = window.location.hostname
        // if (document.referrer) {
        //     domainName = new URL(document.referrer)?.hostname
        // }
        //
        // // tslint:disable-next-line:no-console
        // console.log('Domain name: ', domainName)

        let domainName: string | undefined
        try {
            // Attempt to get the topmost domain
            domainName = window.top.location.hostname
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.log('Cross-origin restriction or other error accessing top domain:', e)
            // Fallback to document.referrer
            if (document.referrer) {
                try {
                    domainName = new URL(document.referrer).hostname
                } catch (referrerError) {
                    // tslint:disable-next-line:no-console
                    console.log('Error parsing document.referrer:', referrerError)
                    domainName = undefined  // or handle the case where referrer is not available or invalid
                }
            } else {
                domainName = undefined  // or handle the case where there's no referrer
            }
        }

        // tslint:disable-next-line:no-console
        console.log('Domain name: ', domainName)

        if (['pay.dnapayments.com', 'test-pay.dnapayments.com', 'dev-pay.dnapayments.com'].includes(domainName?.toLowerCase())) {
            merchantId = undefined
            domainName = undefined
        }

        validateApplePaySession(validationURL, this.merchantName, merchantId, domainName)
            .then(result => {
                const decodedData = window.atob(result.result as string)
                if (applePaySession) {
                    applePaySession.completeMerchantValidation(JSON.parse(decodedData))
                } else {
                    postMessageToParent({ action: 'completeMerchantValidation', decodedData })
                }
            })
            .catch(e => {
                LogStore.error(errorCodes.APPLEPAY_COULD_NOT_VALIDATE, 'Could not validate apple pay session.', e, true)
                if (applePaySession) {
                    applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
                    this.onError(e)
                } else {
                    postMessageToParent({ action: 'completePayment', status: 'STATUS_FAILURE', error: e })
                }
            })
    }

    private authorizePayment (payment, applePaySession?) {
        ConfigStore.setField('paymentMethod', PaymentMethod.ApplePay)
        this.parentStore.onPay({customCrypto: payment, paymentMethod: PaymentMethod.ApplePay.toLowerCase(), invokedFrom: 'ApplePayStore.authorizePayment'}).
        then(result => {
            if (result) {
                this.setPaymentSuccessful(true)
                if (applePaySession) {
                    log('Apple Pay Store: Complete Payment SUCCESS')
                    this.applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS)

                    // This means we are in an Embedded Widget
                    // We should not stop processing for PayByLink
                    if (isEmbeddedWidget()) {
                        this.stopPaymentProcessing()
                    }
                } else {
                    log('Apple Pay Store: Payment completed successfully.')
                    postMessageToParent({ action: PostMessageActions.completePayment, status: ApplePayStatuses.STATUS_SUCCESS })
                }
            } else {
                this.setPaymentSuccessful(false)
                LogStore.error(errorCodes.APPLEPAY_COULD_NOT_AUTHORIZE, null, null, true)
                if (applePaySession) {
                    log('Apple Pay Store: Complete Payment FAILURE')
                    applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
                    this.onError(null)
                } else {
                    log('Apple Pay Store: Payment completed successfully.')
                    postMessageToParent({ action: PostMessageActions.completePayment, status: ApplePayStatuses.STATUS_FAILURE })
                }
            }
            // this.stopPaymentProcessing()
        })
        .catch(e => {
            error('Failed to process Apple Pay payment: ', e)
            LogStore.error(errorCodes.APPLEPAY_OCCURRED_WHILE_PROCESSING, 'Error occurred while processing apple pay payment.', e, true)
            if (applePaySession) {
                applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
                this.onError(e)
            } else {
                postMessageToParent({ action: PostMessageActions.completePayment, status: ApplePayStatuses.STATUS_FAILURE, error: e })
            }
        })
    }

    private handleApplePaySessionEvents = (applePaySession) => {
        applePaySession.onvalidatemerchant = (event) => {
            log('Apple Pay Store: Validate Merchant')
            this.validateApplePaySession(event.validationURL, applePaySession)
        }

        applePaySession.onpaymentauthorized = (event) => {
            log('Apple Pay Store: Payment Authorized')
            this.authorizePayment(event.payment, applePaySession)
        }

        applePaySession.oncancel = (args) => {
            log('Apple Pay Store: Payment Cancelled', args)
            this.onCancel()
        }
    }

    @action
    public startApplePaySession = () => {
        this.startPaymentProcessing()

        if (inIframe() ) {
            postMessageToParent({action: PostMessageActions.startApplePaySession, config: this.config})
        } else {
            this.applePaySession = createApplePaySession(this.config)
            this.handleApplePaySessionEvents(this.applePaySession)
            this.applePaySession.begin()
        }

        this.currentPaymentState = 'duringPayment'
    }


    public get config () {
        return {
            currencyCode: this.paymentData?.currency || 'GBP',
            countryCode: this.paymentData?.customerDetails?.billingAddress?.country || 'GB',
            merchantCapabilities: [
                'supports3DS'
            ],
            supportedNetworks: this.allowedCardNetworks,
            total: {
                label: this.merchantName,
                amount: this.paymentData.amount,
                type: 'final'
            }
        }
    }

    public get allowedCardNetworks () {
        // TODO: ONPAY-1023
        // return extractAllowedCardNetworks(ConfigStore?.acceptedCardSchemes)
       return ['MASTERCARD', 'VISA']
    }

    @action
    public handlePostMessage (message) {
        switch (message.action) {
            case PostMessageActions.checkIfCanMakePayments: {
                this.onReadyToPayChange(message.canMakePayments)
                break
            }
            case PostMessageActions.onValidateMerchant: {
                this.validateApplePaySession(message.validationURL)
                break
            }
            case PostMessageActions.onPaymentAuthorized: {
                this.authorizePayment(message.payment)
                break
            }
            case PostMessageActions.onCancel: {
                this.onCancel(message.message)
                break
            }
            case PostMessageActions.onError: {
                this.onError(message.error)
                break
            }
        }
    }
}
