import React from 'react'
import { observer } from 'mobx-react'
import { ThreeDotsLoader } from '~/code/components'
import { ConfigStore } from '~/code/config'
import translations from './translations'
import styles from './Processing.scss'
import { PageType } from '~/code/models'
import { ProcessingProps } from './props'

export default observer((props: ProcessingProps) => {
    const { description = '', secondaryDescription = '' } = props

    const renderDescription = () => {
        if (description) {
            return description
        }
        switch (ConfigStore.pageType) {
            case PageType.TOKENIZATION:
                return translations().tokenizationDescription
            case PageType.VERIFICATION:
            case PageType.PAYBYLINK_VERIFICATION:
                return translations().verificationDescription
            default:
                return translations().mainDescription
        }
    }

    const renderSecondaryDescription = () => {
        if (secondaryDescription) {
            return secondaryDescription
        }
        return translations().secondaryDescription
    }

    return (
        <div className={ styles.Processing }>
            <div className={ styles.Content }>
                <ThreeDotsLoader />
                <div className={ styles.Description }>
                    <div className={ styles.Status }>{ renderDescription() }</div>
                    <div className={ styles.SecondaryDescription }>
                        {  renderSecondaryDescription() }
                    </div>
                </div>
            </div>
        </div>
    )
})
