import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { ConfigStore, StyleStore } from '~/code/config'
import { PageType } from '~/code/models'
import { CheckIcon } from '~/assets/icons'
import { Image, ActionButton, CopyText } from '~/code/components'
import { SendReceipt } from '~/code/components/SendReceipt'
import { EcospendBankBlock, EcospendBlock, EcospendField, EcospendText, EcospendPayeeBlock } from '..'
import { isStatusOk } from '../../services'
import ecospendTranslations from '../../translations'
import translations from './translations'
import { EcospendResultPageProps } from './props'
import styles from './EcospendResultPage.scss'

export const EcospendResultPage = observer((props: EcospendResultPageProps) => {
    const { store } = props
    const { status, bank_reference_id, message } = store.result
    const isOk = isStatusOk(status)

    const isSendReceiptVisible = ConfigStore.pageType === PageType.PAYBYLINK
    const isBackActionHidden = !store.paymentData?.paymentSettings?.returnUrl
    
    const renderMessage = () => {
        if (isOk) return translations().successMessage
        if (message) return message
        switch (status) {
            case 'Canceled': return translations().cancelMessage
            case 'Failed': return translations().failMessage
            case 'Rejected': return translations().rejectMessage
        }
        return ''
    }

    const renderAction = () => {
        if (isOk && isSendReceiptVisible) {
            return (
                <SendReceipt
                    store={store.parentStore.sendReceiptStore}
                    email={store.paymentData?.customerDetails?.email}
                    paymentId={store.createOrderResponse?.id}
                />
            )
        }

        if (!isBackActionHidden) {
            return (
                <ActionButton
                    onClick={() => store.returnToMerchantSite()}
                    className={styles.button}
                    colorStyle={ StyleStore.style }
                >
                    {translations().returnToBack}
                </ActionButton>
            )
        }

        return null
    }

    return <div className={styles.EcospendResultPage}>
        { isOk ?
            <div className={styles.checkIcon}><CheckIcon color={ StyleStore.style.primaryColor } /></div> :
            <Image className={styles.rejectIcon} src={ 'reject.svg' } /> }

        <h3 className={classNames(styles.title, {[styles.errorTitle]: !isOk})}>{isOk ? translations().successTitle : status}</h3>

        <EcospendText className={styles.subtitle}>{renderMessage()}</EcospendText>

        { bank_reference_id ? <EcospendBlock className={styles.largeBlock}>
            <EcospendField label={ translations().transactionId } content={
                <div className={styles.transactionContent}>{bank_reference_id} <CopyText className={styles.copyText} copyText={bank_reference_id} />
            </div>} />
        </EcospendBlock> : null }

        <EcospendBankBlock store={store} className={styles.block} />

        <EcospendPayeeBlock store={store} className={styles.payeeBlock} />

        { isOk && store.hasMerchantLink && <>
            <p className={styles.description}>
                { translations().autoRedirectAfter }&nbsp;
                <span className={styles.nowrap}>{ Math.floor(ConfigStore.getAutoRedirectDelayInMs() / 1000 ) } { translations().seconds }</span>
            </p>
        </> }

        <div className={styles.buttons}>
            { isOk || ConfigStore.allowedAttempts === 0  ?
                renderAction()
                : <ActionButton onClick={() => store.tryAgain()} className={styles.button}>{translations().tryAgain}</ActionButton>
            }

        </div>

        <EcospendText className={styles.footertext}>{ecospendTranslations().footerText('https://register.fca.org.uk/s/firm?id=0010X00004KSo9HQAT')}</EcospendText>
    </div>
})
