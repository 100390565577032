import React from 'react'
import  ReactModal from 'react-responsive-modal'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { ModalProps } from './props'
import styles from './Modal.scss'

export default observer((props: ModalProps) => {
    return (
        <div>
            <ReactModal
                center={ true }
                open={ props.isOpen }
                onClose={ props.onClose }
                closeOnOverlayClick={ false }
                onExited={ props.onExited }
                classNames={{
                    overlay: classNames(styles.Overlay, props.classNames?.overlay),
                    closeIcon: classNames(props.classNames?.closeIcon),
                    modal: classNames(styles.Content, props.classNames?.content)
                }}
                styles={{
                    closeIcon: {
                        display: props.showCloseIcon ? 'block' : 'none'
                    }
                }}
            >
                { props.children }
            </ReactModal>
        </div>
    )
})
