// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2vCntjKZX7jXjKmZKdGEQK{font-family:\"Open Sans\",sans-serif;font-weight:bold;font-size:16px;line-height:22px;text-align:center;padding:12px 30px;border:none;transition:.25s background,.25s color ease,1s width ease;outline:none;cursor:pointer}._2vCntjKZX7jXjKmZKdGEQK ._3jY-dAsOCpS8-yVlZ1LE9l{width:100%}._2vCntjKZX7jXjKmZKdGEQK ._3jY-dAsOCpS8-yVlZ1LE9l>div{width:10px;height:10px}._2vCntjKZX7jXjKmZKdGEQK._3VKcfSCKqkH_vDYXW2LjLf{background:#f5f5f5 !important;border-color:#d9d9d9 !important;box-shadow:none;color:rgba(0,0,0,.25) !important;text-shadow:none;cursor:not-allowed}", ""]);
// Exports
exports.locals = {
	"Button": "_2vCntjKZX7jXjKmZKdGEQK",
	"Loader": "_3jY-dAsOCpS8-yVlZ1LE9l",
	"Disabled": "_3VKcfSCKqkH_vDYXW2LjLf"
};
module.exports = exports;
