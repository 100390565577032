// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2NjpnQn87_W6TxJn2OR1Jp{overflow:hidden auto;visibility:visible;border:0px none rgba(0,0,0,0);display:block;margin:0px;padding:0px;position:fixed;left:0px;top:0px;width:100%;height:100%;z-index:2147000000}._2NjpnQn87_W6TxJn2OR1Jp ._1wJ-AqXspvvjBcVlArVWCA{position:fixed;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,.7)}", ""]);
// Exports
exports.locals = {
	"IframeWrapper": "_2NjpnQn87_W6TxJn2OR1Jp",
	"Overlay": "_1wJ-AqXspvvjBcVlArVWCA"
};
module.exports = exports;
