import React from 'react'
import { observer } from 'mobx-react'
import { ActionButton } from '~/code/components'
import { SendReceipt } from '~/code/components/SendReceipt'
import { ConfigStore, StyleStore } from '~/code/config'
import translations from './translations'
import { CheckIcon } from '~/assets/icons'
import { PageType } from '~/code/models'
import { SuccessProps } from './props'
import styles from './Success.scss'

export default observer((props: SuccessProps) => {
    const renderHeader = () => {
        switch (ConfigStore.pageType) {
            case PageType.TOKENIZATION:
                return translations().tokenizationSuccessful
            case PageType.VERIFICATION:
            case PageType.PAYBYLINK_VERIFICATION:
                return translations().verificationSuccessful
            default:
                return translations().paymentSuccessful
        }
    }

    const isSendReceiptVisible = ConfigStore.pageType === PageType.PAYBYLINK

    const isBackActionHidden = () => {
        return !props.paymentData?.paymentSettings?.returnUrl
    }

    const renderDescription = () => {
        if (isBackActionHidden()) {
            return null
        }

        return (
            <>
                <div className={ styles.description }>
                    { `${ translations().autoRedirectAfter } ${ Math.floor(ConfigStore.getAutoRedirectDelayInMs() / 1000 ) } ${ translations().seconds }` }
                </div>
                {
                    ConfigStore.paymentMethod && ConfigStore.paymentDescription && (
                        <div className={ styles.description }>
                            { translations().yourPaymentWasSuccessfulWith(ConfigStore.paymentMethod, ConfigStore.paymentDescription) }
                        </div>
                    )
                }
            </>
        )
    }

    const renderAction = () => {
        if (isBackActionHidden()) {
            return null
        }

        return (
            <div className={ styles.Action } data-id={'go-back-to-site-button'}>
                <ActionButton
                    colorStyle={ StyleStore.style }
                    onClick={ () => props.onBackToSite() }>
                    { translations().backToStore }
                </ActionButton>
            </div>
        )
    }

    return (
        <div className={ styles.Success } data-id={'success'}>
            <div className={ styles.Content }>
                <div>
                    <CheckIcon color={ StyleStore.style.primaryColor } />
                </div>
                <div className={ styles.Status }> { renderHeader() }</div>
                { renderDescription() }
                { renderAction() }
                { isSendReceiptVisible && (
                    <SendReceipt
                        email={props.email}
                        paymentId={props.transactionId}
                        isOnlyInputVisible={true}
                        store={props.store}
                    />
                ) }
            </div>
        </div>
    )
})
