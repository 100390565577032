import React from 'react'
import { observer } from 'mobx-react'
import { OrderInfo } from '~/code/components'
import { ConfigStore } from '~/code/config'
import { OrderInfoBlock } from '../../components'
import { PageType } from '~/code/models'
import translations from '../../translations'
import { PaymentFormSideProps } from './props'
import styles from './styles.scss'

export const PaymentFormSide = observer((props: PaymentFormSideProps) => {
    const renderOrderInfo = () => {
        switch (ConfigStore.pageType) {
            case PageType.TOKENIZATION:
                return <OrderInfoBlock firstDescription={ translations().firstTokenizationDescription } secondaryDescription={ translations().secondaryOrderInfoDescription } />
            case PageType.VERIFICATION:
                return <OrderInfoBlock firstDescription={ translations().firstVerificationDescription } secondaryDescription={ translations().secondaryOrderInfoDescription } />
            case PageType.PAYBYLINK_VERIFICATION:
                return <>
                    <OrderInfoBlock
                        className={styles.info}
                        firstDescription={ translations().firstVerificationDescription }
                        secondaryDescription={ translations().secondaryOrderInfoDescription }
                    />
                    <OrderInfo
                        isHiddenTotal={true}
                        orderNumber={ props.orderNumber }
                        total={ props.orderTotal }
                        description={ props.orderDescription }
                        currency={ props.orderCurrency }
                        isVisibleDonation={ props.isVisibleDonation }
                    />
                </>
            default:
                return (
                    <OrderInfo
                        orderNumber={ props.orderNumber }
                        total={ props.orderTotal }
                        description={ props.orderDescription }
                        currency={ props.orderCurrency }
                        isVisibleDonation={ props.isVisibleDonation }
                    />
                )
        }
    }

    return <div className={ styles.side + (props.className ? ` ${props.className}` : '') }>
      { renderOrderInfo() }
  </div>
})
