import React from 'react'
import { observer } from 'mobx-react'
import { ConfigStore, StyleStore } from '~/code/config'
import { Decimal } from 'decimal.js'
import { amountFormat } from './services'
import translations from './translations'
import { getSymbolFromCurrency, isPayByLink } from '~/code/services'
import { OrderInfoProps } from './props'
import styles from './OrderInfo.scss'
import { ORDER_AMOUNT } from '~/code/constants/playwright-ids'

export default observer((props: OrderInfoProps) => {

    const renderAmountWithDonation = () => {
        return (
            <span data-id={'amount-with-donation'}>
                { amountFormat(new Decimal(props.total).plus(ConfigStore.donationAmount).toNumber()) + ' ' }
                <div className={ styles.IncludeDonationText }>
                    { `(${ translations().includeDonation } ${ amountFormat(ConfigStore.donationAmount, props.currency) })`}
                </div>
            </span>
        )
    }

    const renderField = (label, value, dataId?) => {
        return (
            <div className={ styles.Field } data-id={dataId}>
                <div className={ styles.FieldTitle }> { label } </div>
                <div className={ styles.FieldContent }> { value }</div>
            </div>
        )
    }

    return (
        <div className={ `${ styles.OrderInfo } ${ props.isVisibleDonation ? styles.HasDonation : '' }` }>
            <div className={ styles.Content }>
                {!props.isHiddenTotal && (
                    <div className={ styles.Field } data-id={'total'}>
                        <div className={ styles.FieldTitle }> { `${ translations().total }: ` } </div>
                        <div
                            className={ `${ styles.AmountValue } ${ styles.FieldContent }` }
                            style={{
                                color: StyleStore.style.primaryColor
                            }}
                        >
                            <span> { getSymbolFromCurrency(props.currency) } </span>
                            <span className={ styles.AmountTotal } data-test-id={ORDER_AMOUNT}>
                                { ConfigStore.isDonationIncluded ? renderAmountWithDonation() : amountFormat(props.total) }
                            </span>
                        </div>
                    </div>
                )}
                {
                    isPayByLink() && ConfigStore.merchant?.descriptor && renderField(`${ translations().descriptor }: `, ConfigStore.merchant?.descriptor, 'merchant-descriptor')
                }
                {
                    isPayByLink() && ConfigStore.merchant?.fullName && renderField(`${ translations().companyName }: `, ConfigStore.merchant?.fullName, 'merchant-full-name')
                }
                {
                    isPayByLink() && ConfigStore.merchant?.address && renderField(`${ translations().companyAddress }: `, ConfigStore.merchant?.address, 'merchant-address')
                }
                {
                    StyleStore.style?.showOrderNumberBlock && props.orderNumber && renderField(`${ translations().orderNumber }: `, props.orderNumber, 'order-number')
                }
                {
                    StyleStore.style?.showOrderDescriptionBlock && props.description && renderField(`${ translations().orderDescription }: `, props.description, 'order-description')
                }
            </div>
        </div>

    )
})
