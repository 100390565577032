// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1skjCBzJXRyxZYQ-HW1i2v{display:flex;justify-content:center;align-items:center;flex-direction:column;box-sizing:border-box;width:100%;height:100%;padding-top:40px}@media(min-width: 768px){._1skjCBzJXRyxZYQ-HW1i2v{padding-top:0}}._1skjCBzJXRyxZYQ-HW1i2v .oxM9-8kjWjQ9UCiKD1_g6{width:100%;flex-grow:1;display:flex;flex-direction:column;align-items:center;justify-content:center;margin-top:30px}._1skjCBzJXRyxZYQ-HW1i2v ._1JxoLkstmFiTiNOPnuXALU{display:flex;justify-content:flex-end;margin-top:40px}@media(min-width: 768px){._1skjCBzJXRyxZYQ-HW1i2v ._1JxoLkstmFiTiNOPnuXALU{margin-top:30px}}._1skjCBzJXRyxZYQ-HW1i2v ._3dspH1w3pgDvTJoLnrf0Lq{font-family:\"Poppins\",\"Open Sans\",sans-serif;font-weight:200;font-size:20px;color:#000;margin-top:20px;color:#141e2e}._1jS0zHt9REMh8KILfHO9ux{margin-top:5px;font-size:14px;color:#9b9b9b;font-family:\"Open Sans\",sans-serif;font-weight:400;text-align:center}", ""]);
// Exports
exports.locals = {
	"Success": "_1skjCBzJXRyxZYQ-HW1i2v",
	"Content": "oxM9-8kjWjQ9UCiKD1_g6",
	"Action": "_1JxoLkstmFiTiNOPnuXALU",
	"Status": "_3dspH1w3pgDvTJoLnrf0Lq",
	"description": "_1jS0zHt9REMh8KILfHO9ux"
};
module.exports = exports;
