import { Locale, localeArr } from '~/code/components/Translation/types/Locale'

export const getLanguageFromLocale = (locale: string): string => {
    const [language] = locale.split('_')
    return language
}

export const convertToStandardLocale = (locale: string): Locale => {
    if (Boolean(locale)) {
        const [language, country] = locale.split('-')
        const result = `${language}_${country?.toUpperCase()}` as Locale
        if (localeArr.includes(result)) {
            return result
        }
    }
    return null
}
