// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3KoMddtIE-NOawTuan8uIO src-otp-input{min-width:auto !important}", ""]);
// Exports
exports.locals = {
	"root": "_3KoMddtIE-NOawTuan8uIO"
};
module.exports = exports;
