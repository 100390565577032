import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { PaymentMethodItemWrapper } from '~/code/components'
import translations from './translations'
import { EcospendButtonProps } from './props'
import styles from './EcospendButton.scss'
import { OPEN_BANKING_BUTTON } from '~/code/constants/playwright-ids'

export const EcospendButton = observer((props: EcospendButtonProps) => {
    const { className, store, dataId, ...rest } = props

    useEffect(() => {
        if (!store.isBanksLoaded) {
            store.fetchBanks()
        }
    }, [])

    return <PaymentMethodItemWrapper
        {...rest}
        isLoading={store.isLoading}
        className={classNames(styles.EcospendButton, className)}
        dataId={'ecospend-button'}
        data-test-id={OPEN_BANKING_BUTTON}
    >
        {translations().text}
    </PaymentMethodItemWrapper>
})
