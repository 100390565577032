import translations from './translations'

translations.add('is', {
    orderNumber: 'Pöntunarnúmer',
    orderDescription: 'Lýsing á pöntun',
    total: 'Samtals',
    includeDonation: 'þ.m.t. framlag af',
    descriptor: 'Kortið þitt verður gjaldfært af',
    companyName: 'Nafn fyrirtækis',
    companyAddress: 'Skráð skrifstofa'
})
