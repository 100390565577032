import { action, computed, observable } from 'mobx'
import urlParser from 'url-parse'
import { IframeWrapperParentStore } from './models'
import { ConfigStore } from '~/code/config'
import { addListener, removeListener } from '~/code/services'
import { isDevelopmentMode } from '~/code/services'
import { IframeWrapperAction, PaymentDataConfig } from '~/code/models'

export class IframeWrapperStore {
    private parentStore: IframeWrapperParentStore

    @observable
    public isVisible: boolean = false

    public config: PaymentDataConfig = null

    constructor (parentStore: IframeWrapperParentStore, config?: PaymentDataConfig) {
        this.parentStore = parentStore
        this.config = config
    }

    @computed
    public get link() {
        const link = ConfigStore.getUrl().paymentPageUrl + '/?' + this.parentStore.getParamsQueryString(
            this.parentStore.paymentData,
            this.config
        )
        return link
    }

    @computed
    public get isOpen() {
        return this.parentStore.isOpenPaymentWidget
    }

    @action
    onSuccess() {
        this.parentStore.handleSuccessResult()
    }

    @action
    onDecline() {
        // TODO: discuss with the merchant for what purposes these events are used
        if (this.parentStore?.events?.declined) {
            this.parentStore?.events?.declined()
            this.parentStore?.isOpenPaymentWidget && this.parentStore.redirectToFailureReturnUrl()
        } else {
            this.parentStore?.isOpenPaymentWidget && this.parentStore.redirectToFailureReturnUrl()
        }
    }


    @action
    onCancelled() {
        this.parentStore._closePaymentWidget(true)
    }

    @action
    onOpened() {
        this.isVisible = true
        this.parentStore?.events?.opened && this.parentStore?.events?.opened()
    }

    @action
    handleMessage = (event) => {
        if (urlParser(event.origin, true).origin.endsWith('dnapayments.com') || isDevelopmentMode()) {
            switch (event?.data?.name) {
                case IframeWrapperAction.DECLINED:
                    return this.onDecline()
                case IframeWrapperAction.SUCCESS:
                    return this.onSuccess()
                case IframeWrapperAction.OPENED:
                    return this.onOpened()
                case IframeWrapperAction.CANCELLED:
                    return this.onCancelled()
                case IframeWrapperAction.CLOSE_WIDGET:
                    this.parentStore.closePaymentWidget()
            }
        }

    }

    public addListener() {
        addListener('message', this.handleMessage, window)
    }

    public removeListener() {
        removeListener('message', this.handleMessage, window)
    }
}
