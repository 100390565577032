import React from 'react'
import { Button, Modal } from '~/code/components'
import translations from './translations'
import styles from './TimedOutMessageModal.scss'
import { StyleStore } from '~/code/config'

export interface TimedOutMessageModalProps {
    close: () => void
    isOpen: boolean
    closeButtonText?: string
}

export const TimedOutMessageModal = (props: TimedOutMessageModalProps) => {
    return <div>
        <Modal
            onClose={ props.close }
            isOpen={ props.isOpen }
            showCloseIcon={ false }
            classNames={{
                content: styles.modalContent,
                overlay: styles.overlay
            }}
        >
            <div className={ styles.TimedOutMessageModal }>
                <div className={ styles.message  }>
                    <div>
                        { translations().paymentTimedOut }
                    </div>
                    <div className={ styles.actionButtonContainer }>
                        <Button
                            className={ styles.actionButton }
                            colorStyle={ StyleStore.style }
                            onClick={ props.close }>
                            { props.closeButtonText || translations().close }
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
}
