import React from 'react'
import { observer } from 'mobx-react'
import { Button, Modal, TextArea } from '~/code/components'
import { CloseIcon, ErrorFeedbackBlock } from './components'
import translations from './translations'
import { IssueFeedbackModalProps } from './props'
import styles from './IssueFeedbackModal.scss'
import RadioList from '~/code/components/RadioList/RadioList'
import { getReasonList } from '~/code/pages/ThreeDSecure/components/IssueFeedback/components/IssueFeedbackModal/services'
import { StyleStore } from '~/code/config'

export default observer((props: IssueFeedbackModalProps) => {
    const { store } = props
    const renderCloseIcon = () => {
        return (
            <span className={ styles.Close } onClick={ () => store.close() }>
                <CloseIcon />
            </span>
        )
    }

    const renderHeader = () => {
        return (
            <div className={ styles.Header }>
                <div className={ styles.HeaderLabel }>
                    { translations().headerMessage }
                </div>
            </div>
        )
    }

    const renderDescription = () => {
        const items = getReasonList()
        return (
            <div className={ styles.Description }>
                <div>
                    { translations().sorryToSee }
                </div>
                <div>
                    { translations().selectReason }
                </div>
                <div className={ styles.SelectReasonBlock }>
                    <div className={ styles.SelectReasonBlockContent }>
                        <div className={ styles.SelectReasonRadioContainer }>
                            <RadioList onChange={ (v) => store.onChangeReason(v)} items={ items } value={ store.reason }/>
                        </div>

                        <div>
                            <TextArea
                                className={ styles.Comment }
                                value={ store.comment }
                                onChange={ (e) => store.onChangeComment(e.target.value) }
                                placeholder={ translations().yourVariantOrComment }
                                rows={ 4 }
                                maxLength={ 1000 }
                            />
                        </div>

                        {store.showError && (
                            <div className={styles.Error}>{translations().pleaseSelectReasonOrComment}</div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const renderSubmitButton = () => {
        return (
            <div className={ styles.ActionButtonContainer }>
                <Button
                    isLoading={ store.isLoading }
                    className={ styles.ActionButton }
                    colorStyle={ StyleStore.style }
                    onClick={ () => store.submitFeedback() }>
                    { translations().submit }
                </Button>
            </div>
        )
    }

    const renderAdditionalInfo = () => {
        return (
            <div className={ styles.AdditionalInfo }>
                <div>{ translations().additionalInfo.line1 }</div>
                <div>{ translations().additionalInfo.line2 }</div>
                <div>{ translations().additionalInfo.line3 }</div>
                <div>{ translations().additionalInfo.line4 }</div>
            </div>
        )
    }


    const renderFeedbackFormBlock = () => {
        return <div>
            { renderDescription() }
            { renderSubmitButton() }
            { renderAdditionalInfo() }
        </div>
    }

    const renderFeedbackSubmissionSuccessBlock = () => {
        return (
            <div className={ styles.SuccessFeedbackBlock  }>
                <div>
                    { translations().thankYou }
                </div>
                <div>
                    { translations().yourFeedbackExtremelyValuable }
                </div>
                <div className={ styles.ContactYourCardProvider }>
                    { translations().contactYourCardProvider }
                </div>
                <div className={ styles.ActionButtonContainer }>
                    <Button
                        className={ styles.ActionButton }
                        colorStyle={ StyleStore.style }
                        onClick={ () => store.reSubmitPayment() }>
                        { translations().reSubmitPayment }
                    </Button>
                </div>
            </div>
        )
    }

    const renderFeedbackSubmissionErrorBlock = () => (
        <ErrorFeedbackBlock
            tryAgain={store.closeFeedbackSubmissionErrorBlock}
            message={store.feedbackSubmissionError}
        />
    )

    return (
        <div>
            <Modal
                onClose={ () => store.close() }
                isOpen={ store.isOpen }
                showCloseIcon={ false }
                classNames={{
                    content: styles.ModalContent,
                    overlay: styles.Overlay
                }}
            >
                <div className={ styles.IssueFeedbackModal }>
                    { renderCloseIcon() }
                    { renderHeader() }
                    { store.feedbackSubmissionError ? renderFeedbackSubmissionErrorBlock() : (store.isSubmitted ? renderFeedbackSubmissionSuccessBlock() : renderFeedbackFormBlock()) }
                </div>
            </Modal>
        </div>
    )
})
