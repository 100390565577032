// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1xeY5_TuXaOniNYzX3k_iS .pVJaP-DMyt6DD7nMchrVS{width:17%}._1xeY5_TuXaOniNYzX3k_iS ._1FXToNXZxAl-hBte9UJ4an{display:flex;align-items:center;justify-content:space-between;width:83%;padding-left:15px;box-sizing:border-box}._1xeY5_TuXaOniNYzX3k_iS ._1FXToNXZxAl-hBte9UJ4an ._1_nOFBdF_OCUeFOGixEAQ2{color:#9b9b9b;font-weight:400;font-size:14px}._1xeY5_TuXaOniNYzX3k_iS ._1FXToNXZxAl-hBte9UJ4an .w5M6eCF3Ky2qBxkEcj7J7{color:#9b9b9b;font-weight:700;font-size:12px}", ""]);
// Exports
exports.locals = {
	"PaymentMethodItem": "_1xeY5_TuXaOniNYzX3k_iS",
	"Header": "pVJaP-DMyt6DD7nMchrVS",
	"ContentWrapper": "_1FXToNXZxAl-hBte9UJ4an",
	"Content": "_1_nOFBdF_OCUeFOGixEAQ2",
	"Message": "w5M6eCF3Ky2qBxkEcj7J7"
};
module.exports = exports;
