export const ApplePaySession = (window as any).ApplePaySession // it is declared as a class

export  const isApplePayAvailable = () => {
    try {
        return ApplePaySession && ApplePaySession.canMakePayments()
    } catch (e) {
        return false
    }
}

export const createApplePaySession = (config) => {
    let version = 1
    while (ApplePaySession.supportsVersion(version) && version < 11) {
        version++
    }
    return new ApplePaySession(--version, config)
}

export const generateApplePayPostMessage = (payload) => {
    return {
        paymentMethod: 'applepay',
        payload
    }
}

export const postMessageToParent = (payload) => {
    window.parent.postMessage(generateApplePayPostMessage(payload), '*')
}
