import translations from './translations'

translations.add('en', {
    lookupCards: 'Lookup your cards',
    pleaseEnterEmailOrPhone: 'Please enter your email you registered at Click To Pay',
    pleaseEnterEmail: 'Please enter your email',
    pleaseEnterValidEmail: 'Please enter a valid email',
    emailMustNotBeGreaterThan256: 'Email must not be greater than 256 characters',
    pleaseEnterPhone: 'Please enter a mobile phone number',
    mobilePhoneMustNotBeGreaterThan16: 'Mobile phone number must not be greater than 16 characters',
    mobilePhoneMustNotBeLessThan8: 'Mobile phone number must not be less than 8 characters',
    email: 'Email',
    phone: 'Mobile phone number',
    back: 'BACK',
    signOut: 'SIGN OUT',
    lookup: 'LOOKUP'
})
