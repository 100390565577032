// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1bCEJgcK5pbyKwb8re6EtP{position:relative}._1uJ4rTowwwN2QHlzoQf3tz{position:absolute;top:0;left:0;opacity:0;z-index:-900;width:1px}._1MVonKHZ6lIFoe_kZGDX-J{font-size:16px;cursor:pointer;color:#8d99a7}._1MVonKHZ6lIFoe_kZGDX-J:hover svg path{fill:#020101 !important}._16hJROZxPOjtH-yF2bKg9l{background:none !important}._16hJROZxPOjtH-yF2bKg9l .rc-tooltip-inner{min-height:auto !important;background-color:#555;border:none;color:#fff;text-align:center;border-radius:6px;padding:5px 10px}._16hJROZxPOjtH-yF2bKg9l .rc-tooltip-arrow{display:block !important;border-top-color:#555}", ""]);
// Exports
exports.locals = {
	"copyText": "_1bCEJgcK5pbyKwb8re6EtP",
	"copyTextInput": "_1uJ4rTowwwN2QHlzoQf3tz",
	"copyTextIcon": "_1MVonKHZ6lIFoe_kZGDX-J",
	"tooltip": "_16hJROZxPOjtH-yF2bKg9l"
};
module.exports = exports;
