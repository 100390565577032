export function getOnlyNumber (str: string) {
    return str.replace(/[^0-9\\.]+/g, '')
}

export function getFilteredExpiryDate (value) {
    const [ month, year ] = value.split('/')
    if (!year || year.length === 2) return value

    if (year.length === 3) return `${ month }/${ year.slice(0, 2) }`
    return `${ month }/${ year.slice(-2) }`
}
