import React, { useState } from 'react'
import classNames from 'classnames'

import { ConfigStore, StyleStore } from '~/code/config'
import { Button, Checkbox } from '~/code/components'
import { CardPaymentButtonProps } from './props'
import translations from './translations'
import styles from './styles.scss'
import { PAY_BUTTON } from '~/code/constants/playwright-ids'

export const CardPaymentButton: React.FC<CardPaymentButtonProps> = (props) => {
    const { showAgreeCheckbox, buttonText, disabled, onPay, hideAgreeLabel } = props
    const [isConfirmState, setConfirmState] = useState<boolean>(false)

    return (
        <div
            data-id={'terms-and-conditions'}
            className={ classNames(styles.root, { [styles.hasCheckbox]: showAgreeCheckbox }) }
        >
            <div className={ styles.block }>
                {
                    showAgreeCheckbox && (
                        <Checkbox
                            checked={ isConfirmState }
                            onClick={ (val) => {
                                setConfirmState(val)
                            }}
                        />
                    )
                }
                {
                    !hideAgreeLabel &&
                    <div className={ styles.text }>
                        { translations().iAgreeWith }
                        <br/>
                        <a target='_blanks' href={ ConfigStore.termsAndConditionsLink }> { translations().termsAndConditions } </a>
                    </div>
                }
            </div>
            <Button
                colorStyle={ StyleStore.style }
                data-id={'pay-button'}
                data-test-id={PAY_BUTTON}
                onClick={onPay}
                disabled={ disabled || (showAgreeCheckbox && !isConfirmState) }
            >
                { buttonText || translations().pay }
            </Button>
        </div>
    )
}
