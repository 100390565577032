import translations from './translations'

translations.add('pt', {
    orderNumber: 'Número do pedido',
    orderDescription: 'Descrição do pedido',
    total: 'Total',
    includeDonation: 'incl. doação de',
    descriptor: 'O seu cartão será cobrado por',
    companyName: 'Nome da empresa',
    companyAddress: 'Sede social'
})
