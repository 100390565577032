import React from 'react'

const CheckIcon = (props : { color: string } = { color: '#4BB9AA' }) => (
    <svg width='43px' height='31px' viewBox='0 0 43 31'>
        <title>check</title>
        <desc>Created with Sketch.</desc>
        <g id='widgets' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
            <g id='1.2-optomany---popup-in-store---step-2' transform='translate(-563.000000, -418.000000)' stroke={ props.color } strokeWidth='4'>
                <g id='popup' transform='translate(385.000000, 192.000000)'>
                    <g id='left' transform='translate(53.500000, 228.000000)'>
                        <g id='check' transform='translate(126.500000, 0.000000)'>
                            <polyline id='Shape' points='39 1.87350135e-16 12 27 0 15'></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default CheckIcon

