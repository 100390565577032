import translations from './translations'

translations.add('pt', {
    cardNumber: 'Número do cartão',
    cardHolderName: 'Nome do titular do cartão',
    expiryDate: 'Data de vencimento',
    mm: 'MM',
    yy: 'AA',
    invalidCardNumber: 'Por favor, insira um número de cartão válido',
    notSupportScheme: 'Sistema de cartão não suportado',
    invalidCardHolderName: 'Insira um nome de titular de cartão válido',
    longCardHolderName: 'O máximo de 45 caracteres foi excedido',
    invalidExpiryDate: 'Insira uma data de expiração válida',
    invalidCvv: 'Insira um código de segurança válido',
    backCardList: 'Voltar à lista de cartões',
    yourClickToPayPaymentUnsuccessful: 'O seu pagamento com Click To Pay não foi bem-sucedido. Tente novamente mais tarde',
    loading: 'A carregar...',
    visa: 'Visa',
    mastercard: 'Mastercard',
    amex: 'Amex',
    discover: 'Discover',
    dinersClub: 'Descobrir',
    unionPay: 'UnionPay',
    jcb: 'JCB',
    saveCardDetails: 'Salve os detalhes do meu cartão com o comerciante para compras futuras mais rápidas'
})
