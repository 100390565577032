import React, { forwardRef, LegacyRef, Ref } from 'react'
import { observer } from 'mobx-react'
import MaskedInput from 'react-text-mask'
import styles from './CvvInputMaskInput.scss'
import { Image, Input, Tooltip } from '~/code/components'
import translations from './translations'
import classNames from 'classnames'
import { CvvInputMaskInputProps } from './props'
import { CARD_CVC_FOUR_MASK, CARD_CVC_THREE_MASK } from '../../models/constants'

export default observer(forwardRef((props: CvvInputMaskInputProps, forwardedRef: LegacyRef<any>) => {
    const { maskSize } = props

    const renderPromptContent = () => {
        return (
            <div className={ styles.SecureCodeTooltipContent }>
                <div className={ styles.SecureCodeTooltipDescription }>
                    <div className={ styles.SecureCodeTooltipDescriptionMessage }>
                        <div><b>MasterCard</b>/<b>Visa</b>/<b>Discover</b>/<b>Diners Club</b>/<b>JCB</b></div>
                        <div> { translations().threeDigitsDescription }</div>
                    </div>
                    <Image className={ styles.SecureCodeTooltipDescriptionImage } src={ 'cvc_other.svg' } />
                </div>
                <hr/>
                <div className={ styles.SecureCodeTooltipDescription }>
                    <div className={ styles.SecureCodeTooltipDescriptionMessage }>
                        <div><b>American Express</b></div>
                        <div>{ translations().fourDigitsDescription }</div>
                    </div>
                    <Image className={ styles.SecureCodeTooltipDescriptionImage } src={ 'cvc_american_express.svg' } />
                </div>
            </div>
        )
    }

    const renderSecureCodeTooltip = () => {
        return (
            <>
                <Tooltip
                    placement={ 'top' }
                    trigger={ [ 'hover' ] }
                    tooltip={ renderPromptContent() }
                >
                    <Image className={ styles.SecureCodeTooltipIcon } src={ 'question.svg' } />
                </Tooltip>
            </>
        )
    }

    return (
        <div className={ classNames(styles.CvvInputMaskInput, props.className) }>
            <MaskedInput
                mask={ maskSize === 3 ? CARD_CVC_THREE_MASK : CARD_CVC_FOUR_MASK }
                onChange={ (e) => props.onChange(e) }
                onBlur={ (e) => props.onBlur(e) }
                ref={ forwardedRef as Ref<any> }
                guide={ false }
                render={ (ref, inputProps) => {
                    return (
                        <Input
                            ref={ (node) => {
                                props.setRef(node)
                                ref(node)
                            } }
                            { ...inputProps }
                            autoComplete='off'
                            type={ 'text' }
                            pattern={ '[0-9]*' }
                            inputMode={ 'numeric' }
                            label={ translations().cvcCode }
                            renderIcon={ () => renderSecureCodeTooltip() }
                            errorText={ props.errorText }
                            value={ props.value }
                        />
                    )
                }}
            />
        </div>
    )
}))
