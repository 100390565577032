import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    yourClickToPayPaymentUnsuccessful: string
    makeSureEmailIsCorrect: string
    unknownError: string
    errors: {
        signOut: string
    }
    messages: {
        signOut: string
    }
}>()
