import React from 'react'
import { observer } from 'mobx-react'
import { StyleStore } from '~/code/config'

export default observer( ()  => {
    return (
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g opacity={ StyleStore.style.closeButtonOpacity }>
                <path fillRule='evenodd' clipRule='evenodd' d='M16 2.66667C8.62663 2.66667 2.66663 8.62667 2.66663 16C2.66663 23.3733 8.62663 29.3333 16 29.3333C23.3733 29.3333 29.3333 23.3733 29.3333 16C29.3333 8.62667 23.3733 2.66667 16 2.66667ZM22.6666 20.7867L20.7866 22.6667L16 17.88L11.2133 22.6667L9.33329 20.7867L14.12 16L9.33329 11.2133L11.2133 9.33334L16 14.12L20.7866 9.33334L22.6666 11.2133L17.88 16L22.6666 20.7867Z' fill={ StyleStore.style.closeButtonColor }/>
            </g>
        </svg>
    )
})
