import translations from './translations'

translations.add('is', {
    successTitle: 'Til hamingju',
    successMessage: 'Greiðslan þín hefur verið send inn.',
    cancelMessage: 'Hætt hefur verið við greiðsluna þína.',
    failMessage: 'Greiðslan þín mistókst.',
    rejectMessage: 'Greiðslu þinni hefur verið hafnað.',
    transactionId: 'Færsluauðkenni',
    autoRedirectAfter: 'Þér verður sjálfkrafa vísað á síðuna eftir',
    seconds: 'sek',
    tryAgain: 'Reyndu aftur',
    returnToBack: 'Fara aftur á síðuna'
})
