import { PostMessageActions } from './constants/post-message-actions'
import { ApplePayStatuses } from './constants/apple-pay-statuses'
import { ApplePaySession, generateApplePayPostMessage } from './services'

/**
 * This is a store that needs to be initialized outside an iFrame in the root window in order to handle post messages
 * from the internal AppStore which is initialized in the iFrame
 */
export class ApplePayExternalStore {
    private applePaySession
    public applePayIFrame

    public createApplePaySession = (config) => {
        let version = 1
        while (ApplePaySession.supportsVersion(version) && version < 11) {
            version++
        }
        return new ApplePaySession(--version, config)
    }

    public handleApplePaySessionEvents = (applePaySession) => {
        applePaySession.onvalidatemerchant = (event) => {
            this.applePayIFrame.postMessage(generateApplePayPostMessage({action: PostMessageActions.onValidateMerchant, validationURL: event.validationURL}), '*')
        }

        applePaySession.onpaymentauthorized = (event) => {
            this.applePayIFrame.postMessage(generateApplePayPostMessage({action: PostMessageActions.onPaymentAuthorized, payment: event.payment}), '*')
        }

        applePaySession.oncancel = (event) => {
            this.applePayIFrame.postMessage(generateApplePayPostMessage({action: PostMessageActions.onCancel, message: event.sessionError}), '*')
        }
    }

    public handlePostMessage = (event) => {
        const action = event.data.payload.action
        switch (action) {
            case PostMessageActions.checkIfCanMakePayments: {
                try {
                    const canMakePayments = ApplePaySession && ApplePaySession.canMakePayments()
                    event.source.postMessage(generateApplePayPostMessage({ action, canMakePayments }), '*')
                } catch (e) {
                    event.source.postMessage(generateApplePayPostMessage({ action, canMakePayments: false }), '*')
                }
                break
            }
            case PostMessageActions.startApplePaySession: {
                this.applePayIFrame = event.source
                this.applePaySession = this.createApplePaySession(event.data.payload.config)
                this.handleApplePaySessionEvents(this.applePaySession)
                this.applePaySession.begin()
                event.source.postMessage(generateApplePayPostMessage({ action}), '*')
                break
            }
            case PostMessageActions.completeMerchantValidation: {
                this.applePaySession.completeMerchantValidation(JSON.parse(event.data.payload.decodedData), '*')
                break
            }
            case PostMessageActions.completePayment: {
                const status = event.data.payload.status
                this.applePaySession.completePayment(ApplePaySession[status])
                if (status === ApplePayStatuses.STATUS_FAILURE) {
                    event.source.postMessage(generateApplePayPostMessage({ action, error: event.data.payload.error }), '*')
                }
            }
        }
    }
}
