import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    lookupCards: string
    pleaseEnterEmailOrPhone: string,
    pleaseEnterEmail: string,
    pleaseEnterValidEmail: string,
    emailMustNotBeGreaterThan256: string,
    pleaseEnterPhone: string,
    mobilePhoneMustNotBeGreaterThan16: string,
    mobilePhoneMustNotBeLessThan8: string,
    email: string,
    phone: string,
    back: string
    signOut: string
    lookup: string
}>()
