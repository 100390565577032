// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UBqA9tICHvexVXoQMEaJz{margin-bottom:10px}.xCcUlhUPEHjMBS-SaEnhs{font-family:\"Poppins\",sans-serif}.xCcUlhUPEHjMBS-SaEnhs ._3veO-vIKcmy28Kw7nqWL1M{display:flex;justify-content:space-between;align-items:flex-end;flex-direction:column}@media(min-width: 768px){.xCcUlhUPEHjMBS-SaEnhs ._3veO-vIKcmy28Kw7nqWL1M{flex-direction:row}}.xCcUlhUPEHjMBS-SaEnhs ._3YL6qG5vf68S-Ss2Vg8hAC{min-height:130px;padding:12px;border:1px solid rgba(52,71,84,.4);box-sizing:border-box;border-radius:8px;display:flex;flex-direction:column;width:100%;word-break:break-word}@media(min-width: 768px){.xCcUlhUPEHjMBS-SaEnhs ._3YL6qG5vf68S-Ss2Vg8hAC:not(._3FsFaPCrn5Mzw3QoEb43hU){width:60%}}.xCcUlhUPEHjMBS-SaEnhs ._3YL6qG5vf68S-Ss2Vg8hAC ._7siB1kizy59sH24WyQKSY{font-weight:bold;font-size:16px;line-height:30px;color:#344754}.xCcUlhUPEHjMBS-SaEnhs ._3YL6qG5vf68S-Ss2Vg8hAC .GJ_C2Gi2VEQSXfUSU1-tX,.xCcUlhUPEHjMBS-SaEnhs ._3YL6qG5vf68S-Ss2Vg8hAC ._3ehw1rEjgKYGWzTH1IemHd{font-size:14px;line-height:24px;color:rgba(52,71,84,.4)}.xCcUlhUPEHjMBS-SaEnhs ._3YL6qG5vf68S-Ss2Vg8hAC ._1bhyfOgN0qks-8yU6mJodL>span{width:35px;background-size:cover}.xCcUlhUPEHjMBS-SaEnhs ._3YL6qG5vf68S-Ss2Vg8hAC .s9coV7ePw2P7KkFONvUX6{margin-top:auto;width:100%;align-self:flex-end;display:flex;justify-content:space-between;align-items:center}.xCcUlhUPEHjMBS-SaEnhs ._2kMYZFtCMuG_ro7oavehKW{width:100%;display:flex;flex-direction:column;box-sizing:border-box;margin-top:10px}@media(min-width: 768px){.xCcUlhUPEHjMBS-SaEnhs ._2kMYZFtCMuG_ro7oavehKW{width:40%;margin-top:0px;padding-left:10px}}.xCcUlhUPEHjMBS-SaEnhs ._2kMYZFtCMuG_ro7oavehKW ._2x29HNHiHcU7hnLbtOv-sD{width:100%}.xCcUlhUPEHjMBS-SaEnhs ._2kMYZFtCMuG_ro7oavehKW ._2logcQJr8mnm11-tmPoNhH{font-size:12px;line-height:18px;color:#9b9b9b}", ""]);
// Exports
exports.locals = {
	"BackCardListLink": "UBqA9tICHvexVXoQMEaJz",
	"CardItemPage": "xCcUlhUPEHjMBS-SaEnhs",
	"CardItemPageContent": "_3veO-vIKcmy28Kw7nqWL1M",
	"CardItem": "_3YL6qG5vf68S-Ss2Vg8hAC",
	"noCVV": "_3FsFaPCrn5Mzw3QoEb43hU",
	"CardName": "_7siB1kizy59sH24WyQKSY",
	"CardNumber": "GJ_C2Gi2VEQSXfUSU1-tX",
	"CardExpiryDate": "_3ehw1rEjgKYGWzTH1IemHd",
	"CreditCardIcon": "_1bhyfOgN0qks-8yU6mJodL",
	"CardItemFooter": "s9coV7ePw2P7KkFONvUX6",
	"CvvWrapper": "_2kMYZFtCMuG_ro7oavehKW",
	"CvvWrapperInput": "_2x29HNHiHcU7hnLbtOv-sD",
	"CvvLabel": "_2logcQJr8mnm11-tmPoNhH"
};
module.exports = exports;
