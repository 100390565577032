export const PostMessageActions = {
    checkIfCanMakePayments: 'checkIfCanMakePayments',
    startApplePaySession: 'startApplePaySession',
    onValidateMerchant: 'onValidateMerchant',
    completeMerchantValidation: 'completeMerchantValidation',
    onPaymentAuthorized: 'onPaymentAuthorized',
    onCancel: 'onCancel',
    onError: 'onError',
    completePayment: 'completePayment'
}
