import { OrderPersonalInfoStore } from '../OrderPersonalInfo'
import { InputField, InputFieldName, OrderPersonalInfoParentStore } from '~/code/pages/OrderPersonalInfo/models'
import { getAllInputFields, getVisibleField } from '~/code/pages/OrderPersonalInfo/services'
import { isEmpty } from 'lodash'
import { computed, reaction, toJS } from 'mobx'

export class KlarnaOrderPersonalInfoStore extends OrderPersonalInfoStore {

    constructor (parentStore: OrderPersonalInfoParentStore) {
        super(parentStore)
    }

    @computed
    public get isValidForm() {
        if (isEmpty(this.fields)) return true

        const fields = Object.entries(this.fields).filter(([key, value]) => {
            const error = value.validate ? value.validate(value.value) : undefined
            if (error) {
                return true
            }
            return false
        })
        return fields.length === 0
    }

    generateReactionOnPersonalInfoFields () {
        let paymentDataFields = this.getPaymentDataFields()
        this.fields = this.addBillingAddressAndDeliveryAddressLabels2(getVisibleField(this.getRequiredFields(), paymentDataFields))
        reaction(() => {
            return {
                customerDetails: toJS(this.parentStore.customerDetails)
            }
        }, (value) => {
            paymentDataFields = this.getPaymentDataFields()
            this.fields = this.addBillingAddressAndDeliveryAddressLabels2(getVisibleField(this.getRequiredFields(), paymentDataFields))
        })
    }

    addBillingAddressAndDeliveryAddressLabels2 (_fields: { [p: string]: InputField }) {
        const newAllFields = {}
        const billingAddressFields = [
            InputFieldName.BillingAddressCountry,
            InputFieldName.BillingAddressCity,
            InputFieldName.BillingAddressAddressLine1,
            InputFieldName.BillingAddressPostalCode
        ]

        const deliveryAddressFields = [
            InputFieldName.DeliveryAddressCountry,
            InputFieldName.DeliveryAddressCity,
            InputFieldName.DeliveryAddressAddressLine1,
            InputFieldName.DeliveryAddressPostalCode
        ]

        let billingAddressLabelPresent = false
        let deliveryAddressLabelPresent = false

        Object.entries(_fields).forEach(([fieldName, field]) => {
            if (!billingAddressLabelPresent && billingAddressFields.includes(fieldName as InputFieldName)) {
                newAllFields[InputFieldName.BillingAddress] = getAllInputFields()[InputFieldName.BillingAddress]
                newAllFields[InputFieldName.BillingAddress].isVisible = true
                billingAddressLabelPresent = true
            }

            if (!deliveryAddressLabelPresent && deliveryAddressFields.includes(fieldName as InputFieldName)) {
                newAllFields[InputFieldName.DeliveryAddress] = getAllInputFields()[InputFieldName.DeliveryAddress]
                newAllFields[InputFieldName.DeliveryAddress].isVisible = true
                deliveryAddressLabelPresent = true
            }

            newAllFields[fieldName] = field
        })

        return newAllFields
    }

    getRequiredFields (): { [p: string]: boolean } {
        return {
            [InputFieldName.AccountEmail]: true,

            [InputFieldName.AccountFirstName]: true,
            [InputFieldName.AccountLastName]: true,

            [InputFieldName.BillingAddressCountry]: true,
            [InputFieldName.BillingAddressCity]: true,
            [InputFieldName.BillingAddressAddressLine1]: true,
            [InputFieldName.BillingAddressPostalCode]: true,

            [InputFieldName.DeliveryAddressCountry]: true,
            [InputFieldName.DeliveryAddressCity]: true,
            [InputFieldName.DeliveryAddressAddressLine1]: true,
            [InputFieldName.DeliveryAddressPostalCode]: true
        }
    }

    public getPaymentDataFields(): { [p: string]: string } {
        const customerDetails = this.parentStore.customerDetails

        const res = {
            [InputFieldName.AccountEmail]: customerDetails?.email,

            [InputFieldName.AccountFirstName]: customerDetails?.billingAddress?.firstName,
            [InputFieldName.AccountLastName]: customerDetails?.billingAddress?.lastName,

            [InputFieldName.BillingAddressCountry]: customerDetails?.billingAddress?.country,
            [InputFieldName.BillingAddressCity]: customerDetails?.billingAddress?.city,
            [InputFieldName.BillingAddressAddressLine1]: customerDetails?.billingAddress?.addressLine1,
            [InputFieldName.BillingAddressPostalCode]: customerDetails?.billingAddress?.postalCode,

            [InputFieldName.DeliveryAddressCountry]: customerDetails?.deliveryDetails?.deliveryAddress?.country,
            [InputFieldName.DeliveryAddressCity]: customerDetails?.deliveryDetails?.deliveryAddress?.city,
            [InputFieldName.DeliveryAddressAddressLine1]: customerDetails?.deliveryDetails?.deliveryAddress?.addressLine1,
            [InputFieldName.DeliveryAddressPostalCode]: customerDetails?.deliveryDetails?.deliveryAddress?.postalCode
        }
        return res
    }
}
