import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    headerMessage: string
    sorryToSee: string
    selectReason: string,
    reason: {
        pageDidNotLoad: string
        didNotReceiveConfirmationCode: string
    },
    submit: string
    thankYou: string
    yourFeedbackExtremelyValuable: string
    contactYourCardProvider: string
    reSubmitPayment: string
    additionalInfo: {
        line1: string
        line2: string
        line3: string
        line4: string
    },
    yourVariantOrComment: string
    pleaseSelectReasonOrComment: string
    errorReportIssue: string
}>()
