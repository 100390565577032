// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1sBk0XFJ2ekH9kZ-6-l5-r{width:100%;box-sizing:border-box;display:flex;flex-direction:column;justify-content:space-between;order:-1;border-left:none}@media(min-width: 768px){._1sBk0XFJ2ekH9kZ-6-l5-r{width:40%;order:1;border-left:1px solid #ccc}}._10-wYcTNh9pA_7pSdxYy0D{margin:0 0 16px 16px}", ""]);
// Exports
exports.locals = {
	"side": "_1sBk0XFJ2ekH9kZ-6-l5-r",
	"info": "_10-wYcTNh9pA_7pSdxYy0D"
};
module.exports = exports;
