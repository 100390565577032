// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2LOwt_IFAvrSb6PgOEEupb{font-size:16px;color:#000;background:none;width:100%;outline:none;transition:border-color .5s;font-family:\"Open Sans\",Verdana,sans-serif;-webkit-tap-highlight-color:rgba(0,0,0,0);margin-top:12px;margin-bottom:17px;position:relative}._2LOwt_IFAvrSb6PgOEEupb .b0Bpuz664uIGRr5SHBMo7{position:absolute;word-break:break-word;font-family:\"Open Sans\",sans-serif;color:#dc3545;font-size:12px;text-transform:initial}", ""]);
// Exports
exports.locals = {
	"error-color": "#dc3545",
	"Select": "_2LOwt_IFAvrSb6PgOEEupb",
	"Error": "b0Bpuz664uIGRr5SHBMo7"
};
module.exports = exports;
