// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2q51JDq27XJjIlUtZwfkf2{display:flex;justify-content:center;align-items:center;flex-direction:column;box-sizing:border-box;height:100%;padding-top:0px}._2q51JDq27XJjIlUtZwfkf2 ._3UVoWrGVYZL-z-JwtH0aIr{flex-grow:1;display:flex;flex-direction:column;align-items:center;justify-content:center;margin-top:30px;margin-bottom:30px}._2q51JDq27XJjIlUtZwfkf2 ._1Y-l83YtaxQXOLzl5Ol9gE{font-family:\"Poppins\",\"Open Sans\",sans-serif;font-weight:200;font-size:24px;margin-top:20px;color:#000;text-align:center}._2q51JDq27XJjIlUtZwfkf2 ._2cqbQHUVnVnrQV7nEBRuYR{margin-top:5px;font-size:14px;color:#9b9b9b;font-family:\"Open Sans\",sans-serif;font-weight:400;text-align:center}._2q51JDq27XJjIlUtZwfkf2 .vF4nbl0jPXxECdrlSLDS_{margin-top:10px;font-size:14px;font-family:\"Open Sans\",sans-serif;font-weight:400;color:#9b9b9b}._2q51JDq27XJjIlUtZwfkf2 ._2X3_27F8mCHubKWJrR-h07{width:100px;height:90px;background:center no-repeat;background-size:contain}", ""]);
// Exports
exports.locals = {
	"Processing": "_2q51JDq27XJjIlUtZwfkf2",
	"Content": "_3UVoWrGVYZL-z-JwtH0aIr",
	"Status": "_1Y-l83YtaxQXOLzl5Ol9gE",
	"Description": "_2cqbQHUVnVnrQV7nEBRuYR",
	"SecondaryDescription": "vF4nbl0jPXxECdrlSLDS_",
	"ProcessingIcon": "_2X3_27F8mCHubKWJrR-h07"
};
module.exports = exports;
