import React from 'react'
import { observer } from 'mobx-react'
import styles from './Image.scss'
import { getImageUrl } from '~/code/services'

export default observer((props) => {
    const { src, width, height, ...imageProps } = props
    return (
        <span
            { ...imageProps }
            style={{
                backgroundImage: `url(${ getImageUrl(src) })`,
                ...(width ? { width } : {}),
                ...(height ? { height } : {})
            }}
            className={
                `${ styles.Image }
                 ${ props.className ? props.className : '' }`
            }
        />
    )
})
