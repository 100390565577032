import { ConfigStore, LogStore } from '~/code/config'
import { PaymentMethodStatus } from '~/code/models/PaymentMethodStatus'
import { error, log } from '~/code/services/logger'
import { ClickToPayParentStore } from './models/ClickToPayParentStore'
import { action, observable, runInAction } from 'mobx'
import { ClickToPayValidationChannelIdType, InitiateValidationResponseType, RecognizedCardType, RecognizedType } from './models'
import { ClickToPayCheckoutResult } from '~/code/pages/CardData/components/ClickToPay/models/ClickToPayCheckoutResult'
import { ClickToPayCheckoutResultEvent } from '~/code/pages/CardData/components/ClickToPay/models/ClickToPayCheckoutResultEvent'
import * as errorCodes from '~/code/config/LogStore'
import { CLICK_TO_PAY_CHECKOUT_FAILED, CLICK_TO_PAY_CHECKOUT_FAILED_DUE_TO_UNEXPECTED_ERROR } from '~/code/config/LogStore'
import translations from './translations'
import { ClickToPayCheckoutResponseActionCode } from '~/code/pages/CardData/components/ClickToPay/models/ClickToPayCheckoutResponseActionCode'
import { getClickToPayAddress, getDpaTransactionOptions } from './services'
import { loadScriptPromise } from '~/code/services'
import { getCardSchemeList } from '~/code/services/cards'
import { ClickToPayErrorType } from '~/code/pages/CardData/components/ClickToPay/models/ClickToPayErrorType'
import { parsePhoneNumber } from 'libphonenumber-js'
import { InputFieldName } from '~/code/pages/OrderPersonalInfo/models'
import { CreditCardType, InputField } from '~/code/pages/CardData/models'
import { validateEmailInput, validatePhoneInput } from '~/code/pages/CardDataPage/services'
import { PaymentMethod } from '~/code/models'

export class ClickToPayStore {
    click2payInstance = null

    @observable
    recognizedType: RecognizedType = null

    @observable
    cards: RecognizedCardType[] = []

    @observable
    validationChannelId: ClickToPayValidationChannelIdType = 'DEFAULT'

    @observable
    initiateValidationResponse: InitiateValidationResponseType = null

    @observable
    isInitiating: boolean = false

    @observable
    isGettingNewEmailOrPhoneFromUser: boolean = false

    @observable
    isPaymentProcessing: boolean = false

    @observable
    isValidatingOTP: boolean = false

    @observable
    isLookingUpCards: boolean = false

    @observable
    isLookingUpId: boolean = false

    @observable
    idLookupErrorMessage: string = null

    @observable
    initiateValidationErrorMessage = null

    @observable
    checkRecognizedCardsErrorMessage = null

    @observable
    otpValidationErrorMessage = null

    @observable
    validateErrorReason = null

    @observable
    hasAgreedToShareWithClickToPay = false

    @observable
    currentCardBrand: CreditCardType = null

    @observable
    isSigningOut: boolean = false

    constructor (private parentStore: ClickToPayParentStore) {}

    @observable
    currentScreenName: 'cardData' | 'cardsList' | 'emailOrPhone' | 'otp' = 'cardData'

    @action
    setCurrentScreenName = (screenName: 'cardData' | 'cardsList' | 'emailOrPhone' | 'otp') => {
        this.currentScreenName = screenName
    }

    @observable
    // @ts-ignore
    fields: { [key in InputFieldName]: InputField<string> } = {
        [InputFieldName.AccountEmail]: {
            name: InputFieldName.AccountEmail,
            value: '',
            onChange: (value: string) => {
                const field = this.fields[InputFieldName.AccountEmail]
                field.value = value
                field.errorCode = this.fields[InputFieldName.AccountEmail].validate(value)
                this.clearErrorMessages()
            },
            validate: (value: string = this.fields[InputFieldName.AccountEmail].value) => {
                const field = this.fields[InputFieldName.AccountEmail]
                field.errorCode = validateEmailInput(value)
                return field.errorCode
            },
            errorCode: ''
        },
        [InputFieldName.AccountPhone]: {
            name: InputFieldName.AccountPhone,
            value: '',
            onChange: (value: string) => {
                const field = this.fields[InputFieldName.AccountPhone]
                field.errorCode = field.validate(value)
                field.value = value && value.toString().toUpperCase()
                this.clearErrorMessages()
            },
            validate: (value: string = this.fields[InputFieldName.AccountPhone].value) => {
                const field = this.fields[InputFieldName.AccountPhone]
                field.errorCode = validatePhoneInput(value)
                return field.errorCode
            },
            errorCode: ''
        }
    }

    makeClickToPayUnavailable = () => {
        runInAction(() => {
            ConfigStore.setField('paymentMethod', null)
            ConfigStore.setField('paymentMethodsSettings', {
                ...ConfigStore.paymentMethodsSettings,
                clickToPay: {
                    status: PaymentMethodStatus.FAILED
                }
            })
            this.clear(true)
            this.parentStore.renderPaymentMethodsListPage()
        })
    }

    @action.bound
    async loadClickToPayScript() {
        const srcDpaId = ConfigStore?.paymentMethodsSettings?.clickToPay?.srcDpaId

        if (ConfigStore?.paymentMethodsSettings?.clickToPay?.status === PaymentMethodStatus.ACTIVE && srcDpaId) {
            this.isInitiating = true

            try {
                await Promise.all([
                    loadScriptPromise(`${CLICK_TO_PAY_LIB_URL}?srcDpaId=${srcDpaId}&locale=${ConfigStore.nonNullLocale}`),
                    loadScriptPromise(`${CLICK_TO_PAY_SDK_URL}`)
                ])

                this.click2payInstance = new window.Click2Pay()
                await this.click2payInstance.init({
                    srcDpaId,
                    dpaTransactionOptions: getDpaTransactionOptions(this.paymentData, ConfigStore.nonNullLocale),
                    dpaData: {
                        dpaPresentationName: ConfigStore?.merchant?.descriptor,
                        dpaName: ConfigStore?.merchant?.fullName
                    },
                    cardBrands: getCardSchemeList()
                })
                await this.checkRecognizedCards(this.paymentData?.customerDetails?.email)

            } catch (err) {
                log('FAILED TO LOAD C2P', err)
                this.makeClickToPayUnavailable()
            } finally {
                runInAction(() => {
                    this.isInitiating = false
                })
            }
        }
    }

    @action
    setGettingNewEmailOrPhoneFromUser = (value: boolean) => {
        this.isGettingNewEmailOrPhoneFromUser = value
    }

    @action
    agreeDisagreeToShareWithClickToPay = () => {
        this.hasAgreedToShareWithClickToPay = !this.hasAgreedToShareWithClickToPay
        if (this.hasAgreedToShareWithClickToPay) {
            ConfigStore.paymentMethod = PaymentMethod.ClickToPay
        } else {
            ConfigStore.paymentMethod = PaymentMethod.BankCard
        }
    }

    @action
    setInitiateValidationResponse = (response) => {
        this.initiateValidationResponse = response
        if (Boolean(response)) {
            this.setCurrentScreenName('otp')
        }
    }

    @action
    setOtpValidationErrorMessage = (errorMessage) => {
        this.otpValidationErrorMessage = errorMessage
    }

    @action
    startPaymentProcessing = () => {
        this.isPaymentProcessing = true
        this.parentStore.startPaymentProcessing('hide')
    }

    @action
    stopPaymentProcessing = (isPaymentSuccessful?) => {
        this.isPaymentProcessing = false
        this.parentStore.stopPaymentProcessing(isPaymentSuccessful)
    }

    get paymentData() {
        return this.parentStore.paymentData
    }

    get billingAddress() {
        const personalInfoFields = this.parentStore.orderPersonalInfoStore.getFieldValues()
        let address = this.paymentData?.customerDetails?.billingAddress || {}
        address = {
            ...address,
            firstName: address.firstName || personalInfoFields[InputFieldName.AccountFirstName],
            lastName: address.lastName || personalInfoFields[InputFieldName.AccountLastName],
            addressLine1: address.addressLine1 || personalInfoFields[InputFieldName.AccountStreet1],
            postalCode: address.postalCode || personalInfoFields[InputFieldName.AccountPostalCode],
            city: address.city || personalInfoFields[InputFieldName.AccountCity],
            country: address.country || personalInfoFields[InputFieldName.AccountCountry]
        }
        return getClickToPayAddress(address)
    }

    checkoutWithNewCard = async (): Promise<ClickToPayCheckoutResult> => {
        const dataToEncrypt = {
            primaryAccountNumber: this.parentStore?.cardNumber,
            panExpirationMonth: this.parentStore?.expiryDate?.month,
            panExpirationYear: this.parentStore?.expiryDate?.year,
            cardSecurityCode: this.parentStore?.cvv,
            cardholderFirstName: this.parentStore?.cardHolderName || this.parentStore?.userFullName,
            cardholderLastName: '',
            billingAddress: this.billingAddress
        }

        return this.checkout(async (openedWindow) => {
            log('dataToEncrypt', dataToEncrypt)
            const encryptedCardData = await this.click2payInstance.encryptCard(dataToEncrypt)
            const personalInfoFields = this.parentStore.orderPersonalInfoStore.getFieldValues()

            runInAction(() => {
                this.currentCardBrand = encryptedCardData.cardBrand
            })

            let phoneNumber
            try {
                phoneNumber = parsePhoneNumber(this.paymentData.customerDetails.mobilePhone)
            } catch (err) {
               error('Failed to parse phone number from customer details')
            }

            if (!phoneNumber) {
                try {
                    phoneNumber = parsePhoneNumber(personalInfoFields[InputFieldName.AccountPhone])
                } catch (err) {
                    error('Failed to parse phone number from personal info fields')
                }
            }

            if (phoneNumber) {
                phoneNumber = {
                    countryCode: phoneNumber.countryCallingCode,
                    phoneNumber: phoneNumber.nationalNumber
                }

                log('PHONE: ', JSON.stringify(phoneNumber, null, 2))
            }
            const params = {
                windowRef: openedWindow,
                encryptedCard: encryptedCardData.encryptedCard,
                cardBrand: encryptedCardData.cardBrand,
                consumer: encryptedCardData.cardBrand?.toLowerCase() === 'visa' ? undefined : {
                    emailAddress: this.paymentData?.customerDetails?.email || personalInfoFields[InputFieldName.AccountEmail],
                    firstName: this.paymentData?.customerDetails?.firstName || personalInfoFields[InputFieldName.AccountFirstName],
                    lastName: this.paymentData?.customerDetails?.lastName || personalInfoFields[InputFieldName.AccountLastName],
                    mobileNumber: phoneNumber
                },
                dpaTransactionOptions: getDpaTransactionOptions(this.paymentData, ConfigStore.nonNullLocale)
            }

            log('Params:', params)

            return await this.click2payInstance.checkoutWithNewCard(params)
        })
    }

    checkoutWithExistingCard = async (cardId: string): Promise<ClickToPayCheckoutResult> => {
        runInAction(() => {
            this.hasAgreedToShareWithClickToPay = false
        })
        return this.checkout(async (openedWindow) => {
            return await this.click2payInstance.checkoutWithCard(
                {
                    windowRef: openedWindow,
                    srcDigitalCardId: cardId,
                    dpaTransactionOptions: getDpaTransactionOptions(this.paymentData, ConfigStore.nonNullLocale)
                }
            )
        })
    }

    setWindowRef = (win: Window) => {
        //
    }

    private checkout = async (fnCheckout: (window: Window) => Promise<any>): Promise<ClickToPayCheckoutResult> => {
        runInAction(() => {
            this.startPaymentProcessing()
        })

        const windowRef = await new Promise<Window>((resolve) => {
            this.setWindowRef = resolve
        })

        try {
            const result = await fnCheckout(windowRef)

            runInAction(() => {
                this.isPaymentProcessing = false
            })

            if (result?.checkoutActionCode === ClickToPayCheckoutResponseActionCode.cancel) {
                return {
                    event: ClickToPayCheckoutResultEvent.cancel
                }
            } else if (result?.checkoutActionCode === ClickToPayCheckoutResponseActionCode.error) {
                error('C2P err: ', result)
                this.makeClickToPayUnavailable()
                LogStore.error(errorCodes.CLICK_TO_PAY_CHECKOUT_FAILED)

                return {
                    event: ClickToPayCheckoutResultEvent.failure,
                    payload: {
                        code: CLICK_TO_PAY_CHECKOUT_FAILED,
                        message: translations().yourClickToPayPaymentUnsuccessful
                    }
                }
            } else if (result?.checkoutActionCode === ClickToPayCheckoutResponseActionCode.changeCard) {
                runInAction(() => {
                    this.stopPaymentProcessing()
                })
                return {
                    event: ClickToPayCheckoutResultEvent.success,
                    payload: result
                }
            }

            this.setCurrentScreenName('cardData')
            return {
                event: ClickToPayCheckoutResultEvent.success,
                payload: result
            }
        } catch (err) {
            error('C2P unexpected err: ', err)
            this.makeClickToPayUnavailable()
            LogStore.error(errorCodes.CLICK_TO_PAY_CHECKOUT_FAILED_DUE_TO_UNEXPECTED_ERROR)
            runInAction(() => {
                this.isPaymentProcessing = false
            })

            return {
                event: ClickToPayCheckoutResultEvent.failure,
                payload: {
                    code: CLICK_TO_PAY_CHECKOUT_FAILED_DUE_TO_UNEXPECTED_ERROR,
                    message: translations().yourClickToPayPaymentUnsuccessful
                }
            }
        }
    }

    @action
    validateAndSubmitLookupForm = async () => {
        runInAction(() => {
            this.fields[InputFieldName.AccountEmail].validate()
        })
        if ((this.fields[InputFieldName.AccountEmail].errorCode || !this.fields[InputFieldName.AccountEmail].value) ){
            // this.fields[InputFieldName.AccountPhone].errorText || !this.fields[InputFieldName.AccountPhone].value) {
            return
        }

        await this._idLookup(this.fields[InputFieldName.AccountEmail].value, this.fields[InputFieldName.AccountPhone].value)
        await this.initiateValidation()
    }

    _idLookup = async (email?: string, phoneNumber?: string) => {
        this.isLookingUpId = true
        this.clearErrorMessages()
        try {
            // const mobilePhone = this._parsePhoneNumber(phoneNumber)
            const response = await this.click2payInstance.idLookup(
                {
                    email
                    // ,phone: mobilePhone
                }
            )
            if (response?.consumerPresent) {
                runInAction(() => {
                    this.validationChannelId = 'EMAIL'
                    this.recognizedType = 'byEmailOrPhone'
                })
            }
        } catch (err) {
            this.handleIdLookupError(err)
        } finally {
            this.isLookingUpId = false
        }
    }

    private _parsePhoneNumber = (rawNumber: string) => {
        let phoneNumber
        try {
            phoneNumber = parsePhoneNumber(rawNumber)
        } catch (err) {
            error('Failed to parse phone number')
        }

        if (phoneNumber) {
            phoneNumber = {
                countryCode: phoneNumber.countryCallingCode,
                phoneNumber: phoneNumber.nationalNumber
            }
        }

        return phoneNumber
    }

    async checkRecognizedCards(email?: string, phoneNumber?: string) {
        try {
            const cards = await this.click2payInstance.getCards()
            if (cards?.length) {
                runInAction(() => {
                    this.recognizedType = 'byCookie'
                    this.cards = cards
                    this.setCurrentScreenName('cardsList')
                })
            } else {
                await this._idLookup(email)
            }
        } catch (err) {
            this.handleCheckRecognizedCardsError(err)
        }
    }

    @action
    clearValidationChannelId() {
        this.validationChannelId = null
    }

    @action
    clear(cardAlso = false) {
        this.validationChannelId = 'DEFAULT'
        this.initiateValidationResponse = null
        this.isPaymentProcessing = false
        this.validateErrorReason = null
        this.isLookingUpCards = false
        this.validateErrorReason = []
        this.isGettingNewEmailOrPhoneFromUser = false
        this.currentScreenName = 'cardData'

        if (cardAlso) {
            this.cards = []
        }
    }

    @action
    signOut = async () => {
        log('Signing OUT')
        runInAction(() => {
            this.isSigningOut = true
            this.clearErrorMessages()
            this.recognizedType = null
            this.cards = []
            this.setCurrentScreenName('cardData')
        })
        try {
            const response = await this.click2payInstance.signOut()
            if (response.recognized) {
                throw new Error(translations().errors.signOut)
            }
            this.clear(true)
            runInAction(() => {
                this.recognizedType = null
            })
        } catch (err) {
            this.handleSignOutError(err)
        } finally {
            runInAction(() => {
                this.isSigningOut = false
            })
        }
    }

    @action.bound
    async initiateValidation(channelId: ClickToPayValidationChannelIdType = this.validationChannelId) {
        this.clearErrorMessages()
        this.isLookingUpCards = true
        try {
            const response = channelId !== 'DEFAULT'
                ? await this.click2payInstance.initiateValidation({ requestedValidationChannelId: channelId })
                : await this.click2payInstance.initiateValidation()

            runInAction(() => {
                this.setInitiateValidationResponse(response)
                this.validationChannelId = channelId
                this.isGettingNewEmailOrPhoneFromUser = false
            })
        } catch (err) {
            this.handleInitiateValidationError(err)
        } finally {
            this.isLookingUpCards = false
        }
    }

    async validate(otp: string) {
        runInAction(() => {
            this.isValidatingOTP = true
        })
        try {
            const cards = await this.click2payInstance.validate({ value: otp })
            runInAction(() => {
                this.cards = cards || []
                this.validateErrorReason = null
                this.setCurrentScreenName('cardsList')
            })
        } catch (err) {
            this.handleValidateError(err)
        } finally {
            runInAction(() => {
                this.isValidatingOTP = false
            })
        }
    }

    @action
    handleSignOutError = (err: ClickToPayErrorType) => {
        log('sign out err', JSON.stringify(err))
    }

    @action
    handleIdLookupError = (err: ClickToPayErrorType) => {
        log('id lookup err', JSON.stringify(err))
        this.idLookupErrorMessage = this._extractMessageFromC2PError(err)
    }

    @action
    handleInitiateValidationError = (err: ClickToPayErrorType) => {
        log('lookup err', JSON.stringify(err))
        if (err.reason === 'UNKNOWN_ERROR') {
            this.makeClickToPayUnavailable()
            return
        }
        this.idLookupErrorMessage = this._extractMessageFromC2PError(err)
        this.setCurrentScreenName('emailOrPhone')
    }

    @action
    onBackFromInitiateValidation = () => {
        this.fields[InputFieldName.AccountEmail].value = ''
        this.initiateValidationErrorMessage = ''
        this.setCurrentScreenName('cardData')
    }

    @action
    handleCheckRecognizedCardsError = (err: ClickToPayErrorType) => {
        log('check recognized cards err', JSON.stringify(err))
        this.checkRecognizedCardsErrorMessage = this._extractMessageFromC2PError(err)
    }

    @action
    handleValidateError = (err: ClickToPayErrorType) => {
        log('validate err', JSON.stringify(err))
        this.otpValidationErrorMessage = err.reason
        if (err.reason === 'VALDATA_MISSING' || err.reason === 'RETRIES_EXCEEDED' || err.reason === 'ACCT_INACCESSIBLE') {
            this.clear()
            ConfigStore.setField('paymentMethod', null)
            ConfigStore.setField('paymentMethodsSettings', {
                ...ConfigStore.paymentMethodsSettings,
                clickToPay: {
                    status: PaymentMethodStatus.FAILED
                }
            })
            this.clear(true)
            this.parentStore.renderPaymentMethodsListPage()
        }
    }

    _extractMessageFromC2PError = (err: ClickToPayErrorType) => {
        return err.reason === 'UNKNOWN_ERROR' ? translations().unknownError : err?.details?.message
    }

    @action
    clearErrorMessages = () => {
        this.idLookupErrorMessage = ''
        this.initiateValidationErrorMessage = ''
        this.checkRecognizedCardsErrorMessage = ''
        this.otpValidationErrorMessage = ''
    }
}
