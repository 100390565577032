import React from 'react'
import { observer } from 'mobx-react'
import { getSymbolFromCurrency } from '~/code/services'
import { amountFormat } from '~/code/components/OrderInfo/services'
import { ActionButton, Button, SpinThreeDotsLoader } from '~/code/components'
import { EcospendBackLink, EcospendBankBlock, EcospendBlock, EcospendText } from '..'
import ecospendTranslations from '../../translations'
import translations from './translations'
import { EcospendConsentPageProps } from './props'
import styles from './EcospendConsentPage.scss'
import { EcospendPayeeBlock } from '../EcospendPayeeBlock'
import { StyleStore } from '~/code/config'

export const EcospendConsentPage = observer((props: EcospendConsentPageProps) => {
    const { store } = props

    return <SpinThreeDotsLoader isLoading={store.isProcessingPayment}>
    <>
        <EcospendBackLink onClick={() => store.back()} text={ translations().back } />

        <div className={styles.amountWrapper}>
            <span className={styles.currency}>{getSymbolFromCurrency(store.paymentData?.currency)}</span>
            <span className={styles.amount}>{amountFormat(store.paymentData?.amount)}</span>
        </div>

        <EcospendBankBlock store={store} className={styles.block} />

        <EcospendPayeeBlock store={store} className={styles.block} />

        <EcospendBlock className={styles.largeBlock}>
            <EcospendText className={styles.infoText}>{translations().infoText}</EcospendText>

            <EcospendText className={styles.continueText}>{translations().privacyPolicyText('https://www.dnapayments.com/page/terms-use', 'https://www.dnapayments.com/page/privacy-policy')}</EcospendText>

            <div className={styles.buttons}>
                <ActionButton
                    onClick={ () => store.cancel()}
                    className={styles.cancel}
                    colorStyle={ StyleStore.style }
                    >
                    {translations().cancel}
                </ActionButton>
                <Button colorStyle={ StyleStore.style } className={styles.continue} onClick={() => { store.openPopupWindow(); props.onContinueClick() }}>{translations().continue}</Button>
            </div>

            <EcospendText className={styles.smalltext}>{translations().permissionText}</EcospendText>
        </EcospendBlock>

        <EcospendText className={styles.footertext}>{ecospendTranslations().footerText('https://register.fca.org.uk/s/firm?id=0010X00004KSo9HQAT')}</EcospendText>
    </>
    </SpinThreeDotsLoader>
})
