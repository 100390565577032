import React from 'react'
import { render } from 'react-dom'
import { action, observable } from 'mobx'
import { apiCheck, FlowResult, form, noThrow, post } from 'back-connector'
import * as errorCodes from '~/code/config/LogStore'
import { AppStore } from '~/code/models/AppStore'
import { Modal } from '~/code/components'
import { IframeWrapper, IframeWrapperParentStore, IframeWrapperStore } from '~/code/pages/IframeWrapper'
import constants from '~/code/config/constants'
import { OrderPersonalInfoStore } from '~/code/pages/OrderPersonalInfo'
import { ConfigStore, LogStore } from '~/code/config'
import { AuthData, AuthError, AuthToken, IntegrationScope, Page, PageType, PaymentDataConfig, PaymentDataV1, PaymentFormConfigure, PaymentMethod, WidgetMode } from '~/code/models'
import { closeTransaction, convertCards, convertPaymentDataTypes, convertPaymentMethods, encodeHTML, getScopes, isFunction, isNumber, isValidCards } from '~/code/services'
import { EmbeddedWidget } from '~/code/pages'
import { EmbeddedWidgetParentStore, EmbeddedWidgetStore } from '~/code/pages/EmbeddedWidget'
import { PayPalStore } from '~/code/pages/Paypal'
import { PayByBankStore } from '~/code/pages/PayByBank'
import { GooglePayStore } from '~/code/pages/GooglePay'
import { ApplePayStore } from '~/code/pages/ApplePay'
import { EcospendStore } from '~/code/pages/Ecospend'
import { PaymentDataV2 } from '~/code/models/PaymentDataV2'
import { error, log } from '~/code/services/logger'
import { KlarnaStore } from '~/code/pages/Klarna/KlarnaStore'
import { ApplePayExternalStore } from '~/code/pages/ApplePay/ApplePayExternalStore'
import { CardDataStore } from '~/code/pages/CardData'
import { KlarnaOrderPersonalInfoStore } from '~/code/pages/Klarna/KlarnaOrderPersonalInfoStore'
import { GooglePayComponent } from '~/code/pages/GooglePay/models/GooglePayComponent'
import { PayByBankComponent } from '~/code/pages/PayByBank/models/PayByBankComponent'
import { AlipayWeChatPayComponent } from '~/code/pages/AlipayWeChatPay/models/AlipayWeChatPayComponent'
import { AlipayWeChatPayStore } from '~/code/pages/AlipayWeChatPay'
import { AstroPayStore } from '~/code/pages/AstroPay'
import { OpenEmbeddedWidgetConfig } from '~/code/models/OpenEmbeddedWidgetConfig'
import { ClickToPayComponentStore } from '~/code/pages/ClickToPayComponent'

declare global {
    interface Window {
        DNAPayments: {
            openPaymentWidget: (options: PaymentDataV1 | PaymentDataV2, configs?: any) => void,
            openPaymentIframeWidget: (options: PaymentDataV1 | PaymentDataV2, configs?: any) => void,
            openPaymentPage: (options: PaymentDataV1 | PaymentDataV2, configs?: any) => void,
            openEmbeddedWidget: (options: PaymentDataV1 | PaymentDataV2, configs?: any) => void,
            closePaymentWidget: () => void,
            changeEmbeddedWidgetPaymentData: (paymentData: PaymentDataV1 | PaymentDataV2) => void,
            auth: (options: AuthData) => Promise<FlowResult<AuthToken>>,
            configure: (options: PaymentFormConfigure) => void,
            paymentMethods: typeof PaymentMethod,
            widgetMode: typeof WidgetMode,
            configuration?: PaymentFormConfigure
            openPaymentPageArgs?: {paymentData, configs}
            openPaymentIframeWidgetArgs?: {paymentData, configs}

            // alternative payment methods
            GooglePayComponent?: GooglePayComponent
            PayByBankComponent?: PayByBankComponent
            AlipayWeChatPayComponent?: AlipayWeChatPayComponent
            ClickToPayComponent?: any
        }
    }
}

class PaymentApiAppStore extends AppStore implements EmbeddedWidgetParentStore, IframeWrapperParentStore {

    constructor () {
        super()
        // init the state
        this.initialState()

        // we need to load the configuration from the window object that is set by the payment-api.js
        window.DNAPayments?.configuration && this.configure(window.DNAPayments.configuration)

        const openPaymentPageArgs  = window.DNAPayments?.openPaymentPageArgs
        const openPaymentIframeWidgetArgs  = window.DNAPayments?.openPaymentIframeWidgetArgs

        // after loading the configuration we can override the properties of the window object
        window.DNAPayments = {
            ...(window.DNAPayments || {}),
            openPaymentPage: this.openPaymentPage.bind(this),
            openPaymentIframeWidget: this.openPaymentIframeWidget.bind(this),
            openPaymentWidget: this.openPaymentWidget.bind(this),
            openEmbeddedWidget: this.openEmbeddedWidget.bind(this),
            closePaymentWidget: this.closePaymentWidget.bind(this),
            changeEmbeddedWidgetPaymentData: this.initEmbeddedWidgetPaymentData.bind(this),
            auth: this.auth.bind(this),
            configure: this.configure.bind(this),
            paymentMethods: PaymentMethod,
            widgetMode: WidgetMode
        }

        // check if any of the methods above are triggered before the main bundle is loaded, if yes, then trigger them
        if (openPaymentPageArgs) {
            this.openPaymentPage(openPaymentPageArgs.paymentData, openPaymentPageArgs.configs)
        } else if (openPaymentIframeWidgetArgs) {
            this.openPaymentIframeWidget(openPaymentIframeWidgetArgs.paymentData, openPaymentIframeWidgetArgs.configs)
        }
    }

    public iframeWrapperStore: IframeWrapperStore
    public embeddedWidgetStore: EmbeddedWidgetStore

    @observable
    currentPage = () => null

    @observable
    public isOpenPaymentWidget: boolean = false

    private applePayExternalStore: ApplePayExternalStore

    @action
    initialState() {
        this.paymentData = {} as PaymentDataV2
        this.initialized = false
        this.orderPersonalInfoStore = new OrderPersonalInfoStore(this)
        this.klarnaOrderPersonalInfoStore = new KlarnaOrderPersonalInfoStore(this)
        this.threeDSecureStore = undefined
        this.upiThreeDSecureStore = undefined
        this.embeddedWidgetStore = undefined
        this.iframeWrapperStore = null
        this.isCloseConfirmationModalOpen = false
        this.currentPageName = null
        this.paymentResult = null
        this.transactionId = null
        this.isSelectedCardMode = false
        this.selectedCard = null
        this.isPaymentInProgress = false
        this.isPaymentSuccessful = false
        this.currentPage = () => null
        this.applePayExternalStore = new ApplePayExternalStore()
        ConfigStore.init()
    }

    @action
    public configure(options: PaymentFormConfigure) {
        if (options.hasOwnProperty('isTestMode')) {
            ConfigStore.setField('isTestMode', options.isTestMode)
        }

        if (isNumber(options.autoRedirectDelayInMs)) {
            ConfigStore.setField('autoRedirectDelayInMs', Number(options.autoRedirectDelayInMs))
        }

        if (isNumber(options.paymentTimeoutInSeconds)) {
            ConfigStore.setField('paymentTimeoutInSeconds', Number(options.paymentTimeoutInSeconds))
        }

        if (options.hasOwnProperty('scopes')) {
            ConfigStore.setField('scopes', options.scopes)
        }

        if (options.hasOwnProperty('embeddedWidget')) {
            ConfigStore.setField('embeddedWidget', {
                ...constants.defaultEmbeddedWidget,
                ...options.embeddedWidget
            })
        }

        if (options.hasOwnProperty('paymentMethods')) {
            const paymentMethods = convertPaymentMethods(options.paymentMethods)
            ConfigStore.setField('paymentMethods', paymentMethods)
            if ( (paymentMethods.length === 2
                    && (paymentMethods.some(item => item.name === PaymentMethod.BankCard)
                        && paymentMethods.some(item => item.name === PaymentMethod.ClickToPay))) ||
                (paymentMethods.length === 1 && paymentMethods[0].name === PaymentMethod.BankCard)
            ) {
                ConfigStore.setField('paymentMethod', PaymentMethod.BankCard)
            }
        }

        if (options.hasOwnProperty('maxVisiblePaymentMethods')) {
            ConfigStore.setField('maxVisiblePaymentMethods', options.maxVisiblePaymentMethods)
        }

        if (options.hasOwnProperty('isEnableDonation')) {
            ConfigStore.setField('isEnableDonation', options.isEnableDonation)
        }

        if (options.hasOwnProperty('allowSavingCards')) {
            ConfigStore.setField('allowSavingCards', options.allowSavingCards)
        }

        if (options.hasOwnProperty('cards') && isValidCards(options.cards)) {
            ConfigStore.setField('cards', convertCards(options.cards))
        }

        if (options.events?.opened && isFunction(options.events?.opened)) {
            this.events.opened = options.events.opened.bind({})
        }

        if (options.events?.cancelled && isFunction(options.events?.cancelled)) {
            this.events.cancelled = options.events.cancelled.bind({})
        }

        if (options.events?.paid && isFunction(options.events?.paid)) {
            this.events.paid = options.events.paid.bind({})
        }

        if (options.events?.declined && isFunction(options.events?.declined)) {
            this.events.declined = options.events.declined.bind({})
        }

        if (options.hasOwnProperty('disabledCardSchemes')) {
            ConfigStore.setField('disabledCardSchemes', options.disabledCardSchemes)
        }

        if (options.hasOwnProperty('locale')) {
            ConfigStore.setField('localeConfiguration', options.locale)
        }

        if (options.hasOwnProperty('version')) {
            ConfigStore.setField('_version', options.version)
        }
    }

    @action
    public auth(options: AuthData = {} as AuthData): Promise<FlowResult<AuthToken>> {
        const { client_id, client_secret, invoiceId, amount, currency, terminal, postLink, failurePostLink, callbackUrl, failureCallbackUrl } = options
        const authData = {
            grant_type: constants.grant_type,
            scope: getScopes(),
            client_id: String(client_id),
            client_secret: String(client_secret),
            invoiceId: String(invoiceId),
            terminal: String(terminal),
            amount: parseFloat(String(amount)),
            currency: String(currency),
            paymentFormURL: PAYMENT_FORM_URL,
            ...(callbackUrl ? { callbackUrl: String(callbackUrl) } : {}),
            ...(failureCallbackUrl ? { failureCallbackUrl: String(failureCallbackUrl) } : {}),
            ...(postLink ? { postLink: String(postLink) } : {}),
            ...(failurePostLink ? { failurePostLink: String(failurePostLink) } : {})
        }
        return noThrow<AuthToken>(apiCheck(post(`${ConfigStore.getUrl().authUrl}/oauth2/token`, form(authData as any)))) // TODO ${ConfigStore.getUrl().authUrl}/oauth2/token
    }

    @action
    public async openEmbeddedWidget(paymentData: PaymentDataV1 | PaymentDataV2, config: OpenEmbeddedWidgetConfig) {
        try {
            ConfigStore.setField('pageType', PageType.EMBEDDED_WIDGET)
            await this.initEmbeddedWidgetPaymentData(paymentData, config)
            this.renderEmbeddedWidget(config)
        } catch (e) {
            error('Failed to open EW', e)
            return this.handleFailureResult(e)
        }
    }

    @action
    closePaymentWidget() {
        this.isOpenPaymentWidget = false
        this.removeGlobalListener()
    }

    @action
    public async initEmbeddedWidgetPaymentData(paymentData: PaymentDataV1 | PaymentDataV2, configs?: OpenEmbeddedWidgetConfig) {
        const embeddedWidgetStore = this.embeddedWidgetStore || (this.embeddedWidgetStore = new EmbeddedWidgetStore(this))
        this.initPaymentData(paymentData)
        await this.loadConfiguration()
        embeddedWidgetStore.setPaymentData(this.paymentData, configs)
    }

    @action
    public async openPaymentWidget(paymentData: PaymentDataV1 | PaymentDataV2, configs: PaymentDataConfig = {} as PaymentDataConfig) {
        try {
            this.initPaymentData(paymentData)
            if (configs) {
                if (configs.paymentMethod && Object.values(PaymentMethod).includes(configs.paymentMethod)) {
                    ConfigStore.setField('paymentMethod', configs.paymentMethod)
                }
            }

            await this.loadConfiguration()

            if (!this.hasPrivilege(IntegrationScope.INTEGRATION_SEAMLESS)) {
                LogStore.error(errorCodes.API_APPSTORE_HAS_NOT_PRIVILEGE_SEAMLESS)
                this.isOpenPaymentWidget = true
                return this.renderForbiddenPage()
            }
            this.isOpenPaymentWidget = true
            ConfigStore.setField('widgetMode', WidgetMode.SEAMLESS)
            this.addGlobalListener()
            this.setInitialPaymentMethod()
            this.showPaymentMethodsPage()
            this.events?.opened && this.events?.opened()
        } catch (e) {
            if (e instanceof Error) {
                LogStore.error(errorCodes.GLOBAL_ERROR_DEFAULT, null, e)
            } else {
                error(e) // TODO: add sent to server
            }

            this.isOpenPaymentWidget = true
            if (e instanceof AuthError) {
                return this.renderForbiddenPage()
            }
            this.renderErrorPage()
        }
    }

    @action
    public async openPaymentIframeWidget(paymentData: PaymentDataV1 | PaymentDataV2, config: PaymentDataConfig = {} as PaymentDataConfig) {
        this.initPaymentData(paymentData)
        this.isOpenPaymentWidget = true
        ConfigStore.setField('widgetMode', WidgetMode.EMBEDDED)
        this.setCurrentPage(this.renderIframeWrapper(config))
    }

    @action
    public async openPaymentPage(paymentData: PaymentDataV1 | PaymentDataV2, config: PaymentDataConfig = {} as PaymentDataConfig) {
        if (!paymentData || !paymentData.auth) {
            LogStore.error(errorCodes.API_APPSTORE_HAS_NOT_AUTH_TOKEN)
            throw new AuthError('No auth service')
        }
        location.href = ConfigStore.getUrl().paymentPageUrl + '/?' + this.getParamsQueryString(convertPaymentDataTypes(paymentData), config)
    }

    @action
    public renderEmbeddedWidget(config?: OpenEmbeddedWidgetConfig) {
        const container = config?.container || document.querySelector(encodeHTML(ConfigStore.embeddedWidget.container))
        const cardDataStore = this.cardDataStore || (this.cardDataStore = new CardDataStore(this, this.clickToPayStore))
        const paypalStore = this.embeddedWidgetPaypalStore || (this.embeddedWidgetPaypalStore = new PayPalStore(this))
        const payByBankStore = this.embeddedWidgetPayByBankStore || (this.embeddedWidgetPayByBankStore = new PayByBankStore(this))
        const googlePayStore = this.embeddedWidgetGooglePayStore || (this.embeddedWidgetGooglePayStore = new GooglePayStore(this))
        const applePayStore = this.embeddedWidgetApplePayStore || (this.embeddedWidgetApplePayStore = new ApplePayStore(this))
        const ecospendStore = this.embeddedWidgetEcospendStore || (this.embeddedWidgetEcospendStore = new EcospendStore(this))
        const klarnaStore = this.embeddedWidgetKlarnaStore || (this.embeddedWidgetKlarnaStore = new KlarnaStore(this))
        const astroPayStore = this.embeddedWidgetAstroPayStore || (this.embeddedWidgetAstroPayStore = new AstroPayStore(this))
        const alipayStore = this.embeddedWidgetAlipayStore || (this.embeddedWidgetAlipayStore = new AlipayWeChatPayStore(this, PaymentMethod.Alipay))
        const alipayPlusStore = this.embeddedWidgetAlipayPlusStoreStore || (this.embeddedWidgetAlipayPlusStoreStore = new AlipayWeChatPayStore(this, PaymentMethod.AlipayPlus))
        const weChatPayStore = this.embeddedWidgetWeChatPayStore || (this.embeddedWidgetWeChatPayStore = new AlipayWeChatPayStore(this, PaymentMethod.WeChatPay))
        const clickToPayComponentStore = this.embeddedWidgetClickToPayStore || (this.embeddedWidgetClickToPayStore = new ClickToPayComponentStore(this))

        if (!container) throw new Error(`Error: Container not found neither in the config nor by selector ${ ConfigStore.embeddedWidget.container }`)
        render(
            <div>
                <EmbeddedWidget
                    cardDataStore={cardDataStore}
                    store={ this.embeddedWidgetStore }
                    ecospendStore={ ecospendStore }
                    paypalStore={ paypalStore }
                    payByBankStore={ payByBankStore }
                    googlePayStore={ googlePayStore }
                    applePayStore={ applePayStore }
                    klarnaStore={ klarnaStore }
                    astroPayStore={ astroPayStore }
                    alipayStore={ alipayStore }
                    alipayPlusStore={ alipayPlusStore }
                    weChatPayStore={ weChatPayStore }
                    clickToPayComponentStore={ clickToPayComponentStore }
                    config={ config }
                />
            </div>,
            container
        )
    }

    @action
    public wrapWidgetWrapper (component: JSX.Element): () => JSX.Element {
        if (ConfigStore.widgetMode === WidgetMode.EMBEDDED) {
            return () => component
        }
        return this.wrapModalWidgetWrapper(component)
    }

    @action
    public renderIframeWrapper(config: PaymentDataConfig): JSX.Element {
        const iframeWrapperStore = this.iframeWrapperStore || (this.iframeWrapperStore = new IframeWrapperStore(this, config))
        iframeWrapperStore.config = config
        return (
            <IframeWrapper
                store={ iframeWrapperStore }
            />
        )
    }

    @action
    public wrapModalWidgetWrapper(component: JSX.Element): () => JSX.Element {
        return () => (
            <Modal
                isOpen={ this.isOpenPaymentWidget }
                onExited={ () => this.afterClosePaymentWidget() }
                onClose={ () => null }
                showCloseIcon={ false }
            >
                { component }
            </Modal>
        )
    }

    @action
    handleSuccessResult() {
        this.events?.paid && this.events?.paid()
        if (this.isOpenPaymentWidget && ConfigStore.widgetMode === WidgetMode.SEAMLESS) {
            this.redirectToSuccessPage()
        }
    }

    @action
    public async _closePaymentWidget(isWithoutCloseConfirm: boolean = this.isWithoutCloseConfirm) {

        if ([Page.SUCCESS, Page.ECOSPEND_SUCCESS].includes(this.currentPageName)) {
            this.redirectToReturnUrl()
            return
        }

        if (isWithoutCloseConfirm || ConfigStore.allowedAttempts === 0) {
            this.removeGlobalListener()
            this.timer && clearTimeout(this.timer)

            if (this.currentPageName === Page.FORBIDDEN && !this.paymentData?.paymentSettings?.failureReturnUrl) {
                this.isOpenPaymentWidget = false
                return
            }
            if (![Page.SUCCESS, Page.ECOSPEND_SUCCESS].includes(this.currentPageName)) {
                if (this.transactionId) {
                    await closeTransaction(this.transactionId)
                }
                if (!ConfigStore.wasAttemptToPay) {
                    this.events?.cancelled && this.events?.cancelled()
                    this.closePaymentWidget()
                } else {
                    this.events?.declined && this.events?.declined()
                    this.isOpenPaymentWidget && this.redirectToFailureReturnUrl()
                }

                return
            }
        }

        // Open Confirm Modal
        this.isCloseConfirmationModalOpen = true
    }

    @action
    public afterClosePaymentWidget() {
        log('afterClosePaymentWidget')
    }

    postMessageListener = (event) => {
        if (event.data?.paymentMethod === 'applepay') {
            this.applePayExternalStore.handlePostMessage(event)
        }
    }

    addListeners = () => {
        window.addEventListener('message', this.postMessageListener, false)
    }

    removeListeners = () => {
        window.removeEventListener('message', this.postMessageListener, false)
    }
}

const PaymentApiAppStoreInstance = new PaymentApiAppStore()

export { PaymentApiAppStoreInstance as PaymentApiAppStore }
