import React from 'react'
import classNames from 'classnames'
import { Image } from '~/code/components'
import { CreditCardType } from '~/code/pages/CardData/models'
import { CardSchemeImagesProps } from './props'
import styles from './styles.scss'

export const CardSchemeImages: React.FC<CardSchemeImagesProps> = (props) => {
    const { className, hasClickToPay, cardSchemes, size = 'medium' } = props
    const cardSchemeImageMap = {
        [CreditCardType.VISA]: 'visa-white-logo.svg',
        [CreditCardType.MASTERCARD]: 'mastercard-white-logo.svg',
        [CreditCardType.AMERICAN_EXPRESS]: 'amex.svg',
        [CreditCardType.AMEX]: 'amex.svg',
        [CreditCardType.DISCOVER]: 'discover.svg'
    }

    return <div className={classNames(styles.root, className, size)}>
        {
            hasClickToPay && (
                <>
                    <Image className={ styles.image } src={ 'click2pay.png' }/>
                    <div className={styles.verticalLine}/>
                </>
            )                
        }
        {
            Object.entries(cardSchemeImageMap)
                .filter(([scheme]) => cardSchemes.find((s) => s === scheme))
                .map(([scheme, image]) => (
                    <Image key={scheme} className={styles.image} src={image}/>
                ))
        }
    </div>
}
