import React from 'react'
import classNames from 'classnames'

import { CheckboxProps } from './props'
import styles from './Checkbox.scss'

export default (props: CheckboxProps) => {
    const { name, label = '', className = '', checked = false, onClick, disabled } = props

    const onClickWrapper = (event) => {
        if (disabled){
            event.preventDefault()
        } else {
            onClick(!checked)
        }
    }

    return (
        <div className={ classNames(styles.Checkbox, className) }>
            <input
                name={ name }
                type='checkbox'
                className={ classNames(styles.Input) }
                checked={ checked }
                onClick={ (event) => onClickWrapper(event) }
                onChange={ (e) => null }
                disabled={ disabled }
            />
            <label
                htmlFor={ name }
                className={ classNames(styles.Label, { [styles.HasLabel]: !!label, [styles.disabled]: disabled }) }
                onClick={ (event) => onClickWrapper(event)}
            >{ label }</label>
        </div>
    )
}
