// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1DTP2sE8EJDoJmEVjPXBvc{position:relative;padding:16px;font-family:\"Poppins\",sans-serif}._1DTP2sE8EJDoJmEVjPXBvc ._1tK-y9BAvfpx50XRNi4B9M{text-align:center;margin-top:16px;margin-bottom:16px}._1DTP2sE8EJDoJmEVjPXBvc ._1tK-y9BAvfpx50XRNi4B9M ._1zHh7k0KO45vfmmLn1o1fv{padding:12px 32px}._1DTP2sE8EJDoJmEVjPXBvc ._25UKDoI_M8c55LKuP_W65i{margin-top:16px;text-align:center;font-size:14px;line-height:21px;letter-spacing:.388889px}.WthfvVS8tSCDBeCRru76f{width:100%;max-width:480px;min-height:147px;box-sizing:border-box;border-radius:16px}@media(min-width: 768px){.WthfvVS8tSCDBeCRru76f{width:480px}}.WthfvVS8tSCDBeCRru76f,.WthfvVS8tSCDBeCRru76f>div,._3k2oQUhwzbV_TUi6LLAvdS{display:flex;flex-direction:column;justify-content:space-between;flex-grow:1}._2RbXNzqUKRR541LW-fLDhM{z-index:2147000090}", ""]);
// Exports
exports.locals = {
	"TimedOutMessageModal": "_1DTP2sE8EJDoJmEVjPXBvc",
	"actionButtonContainer": "_1tK-y9BAvfpx50XRNi4B9M",
	"actionButton": "_1zHh7k0KO45vfmmLn1o1fv",
	"message": "_25UKDoI_M8c55LKuP_W65i",
	"modalContent": "WthfvVS8tSCDBeCRru76f",
	"content": "_3k2oQUhwzbV_TUi6LLAvdS",
	"overlay": "_2RbXNzqUKRR541LW-fLDhM"
};
module.exports = exports;
