import translations from './translations'

translations.add('es', {
    back: 'volver',
    label: 'Enviar el comprobante de pago a',
    placeholder: 'Dirección de correo electrónico',
    inputButton: 'Enviar',
    text: 'El comprobante de pago se enviará a',
    link: 'Enviar a una dirección de correo electrónico diferente',
    button: 'Enviar recibo',
    invalidEmail: 'el correo electrónico no es válido',
    successText: 'El comprobante de pago se ha enviado correctamente.',
    errorText: 'No se ha podido enviar el comprobante de pago, inténtelo de nuevo más tarde.'
})
