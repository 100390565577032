// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3yFsfV1vy-oymHVTB80ZTr{width:100%}", ""]);
// Exports
exports.locals = {
	"AlipayWeChatPayButton": "_3yFsfV1vy-oymHVTB80ZTr"
};
module.exports = exports;
