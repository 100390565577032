import translations from './translations'

translations.add('en', {
    cardNumber: 'Card number',
    cardHolderName: 'Cardholder name',
    expiryDate: 'Expiry date',
    mm: 'MM',
    yy: 'YY',
    invalidCardNumber: 'Please enter a valid card number',
    notSupportScheme: 'Card scheme not supported',
    invalidCardHolderName: 'Please enter a valid cardholder name',
    longCardHolderName: 'Maximum length of 45 characters is exceeded',
    invalidExpiryDate: 'Please enter a valid expiry date',
    invalidCvv: 'Please enter a valid security code',
    backCardList: 'Back to card list',
    yourClickToPayPaymentUnsuccessful: 'Your Click To Pay payment was unsuccessful. Please try again later',
    loading: 'Loading...',
    visa: 'Visa',
    mastercard: 'Mastercard',
    amex: 'Amex',
    discover: 'Discover',
    dinersClub: 'Diners Club',
    unionPay: 'UnionPay',
    jcb: 'JCB',
    saveCardDetails: 'Save my card details with the merchant for faster future checkouts'
})
