export const COUNTRIES = [
    {code: 'AF', name: 'افغانستان'},
    {code: 'AL', name: 'Shqipëria'},
    {code: 'DZ', name: 'الجزائر'},
    {code: 'AD', name: 'Andorra'},
    {code: 'AO', name: 'Angola'},
    {code: 'AI', name: 'Anguilla'},
    {code: 'AG', name: 'Antigua and Barbuda'},
    {code: 'AR', name: 'Argentina'},
    {code: 'AM', name: 'Հայաստան'},
    {code: 'AW', name: 'Aruba'},
    {code: 'AU', name: 'Australia'},
    {code: 'AT', name: 'Österreich'},
    {code: 'AZ', name: 'Azərbaycan'},
    {code: 'BS', name: 'Bahamas'},
    {code: 'BH', name: 'البحرين'},
    {code: 'BD', name: 'Bangladesh'},
    {code: 'BB', name: 'Barbados'},
    {code: 'BY', name: 'Беларусь'},
    {code: 'BE', name: 'België'},
    {code: 'BZ', name: 'Belize'},
    {code: 'BJ', name: 'Bénin'},
    {code: 'BM', name: 'Bermuda'},
    {code: 'BT', name: 'འབྲུག་ཡུལ'},
    {code: 'BO', name: 'Bolivia, Plurinational State of'},
    {code: 'BA', name: 'Bosna i Hercegovina'},
    {code: 'BW', name: 'Botswana'},
    {code: 'BR', name: 'Brasil'},
    {code: 'BN', name: 'Negara Brunei Darussalam'},
    {code: 'BG', name: 'България'},
    {code: 'BF', name: 'Burkina Faso'},
    {code: 'BI', name: 'Burundi'},
    {code: 'KH', name: 'កម្ពុជា'},
    {code: 'CM', name: 'Cameroun'},
    {code: 'CA', name: 'Canada'},
    {code: 'CV', name: 'Cabo Verde'},
    {code: 'KY', name: 'Cayman Islands'},
    {code: 'CF', name: 'République centrafricaine'},
    {code: 'TD', name: 'Tchad'},
    {code: 'CL', name: 'Chile'},
    {code: 'CN', name: '中国'},
    {code: 'CO', name: 'Colombia'},
    {code: 'KM', name: 'Comores'},
    {code: 'CG', name: 'Congo, Republic of the'},
    {code: 'CD', name: 'Congo, Democratic Republic of the'},
    {code: 'CR', name: 'Costa Rica'},
    {code: 'CI', name: `Côte d'Ivoire`},
    {code: 'HR', name: 'Hrvatska'},
    {code: 'CU', name: 'Cuba'},
    {code: 'CY', name: 'Κύπρος'},
    {code: 'CZ', name: 'Česká republika'},
    {code: 'DK', name: 'Danmark'},
    {code: 'DJ', name: 'Djibouti'},
    {code: 'DM', name: 'Dominica'},
    {code: 'DO', name: 'República Dominicana'},
    {code: 'EC', name: 'Ecuador'},
    {code: 'EG', name: 'مصر'},
    {code: 'SV', name: 'El Salvador'},
    {code: 'GQ', name: 'Guinea Ecuatorial'},
    {code: 'ER', name: 'Eritrea'},
    {code: 'EE', name: 'Eesti'},
    {code: 'ET', name: 'ኢትዮጵያ'},
    {code: 'FK', name: 'Falkland Islands (Malvinas)'},
    {code: 'FO', name: 'Føroyar'},
    {code: 'FJ', name: 'Fiji'},
    {code: 'FI', name: 'Suomi'},
    {code: 'FR', name: 'France'},
    {code: 'GF', name: 'Guyane française'},
    {code: 'PF', name: 'Polynésie française'},
    {code: 'GA', name: 'Gabon'},
    {code: 'GM', name: 'Gambia'},
    {code: 'GE', name: 'საქართველო'},
    {code: 'DE', name: 'Deutschland'},
    {code: 'GH', name: 'Ghana'},
    {code: 'GI', name: 'Gibraltar'},
    {code: 'GR', name: 'Ελλάδα'},
    {code: 'GL', name: 'Kalaallit Nunaat'},
    {code: 'GD', name: 'Grenada'},
    {code: 'GP', name: 'Guadeloupe'},
    {code: 'GT', name: 'Guatemala'},
    {code: 'GG', name: 'Guernsey'},
    {code: 'GN', name: 'Guinée'},
    {code: 'GW', name: 'Guiné-Bissau'},
    {code: 'GY', name: 'Guyana'},
    {code: 'HT', name: 'Haïti'},
    {code: 'HN', name: 'Honduras'},
    {code: 'HK', name: 'Hong Kong'},
    {code: 'HU', name: 'Magyarország'},
    {code: 'IS', name: 'Ísland'},
    {code: 'IN', name: 'भारत'},
    {code: 'ID', name: 'Indonesia'},
    {code: 'IR', name: 'ایران'},
    {code: 'IQ', name: 'العراق'},
    {code: 'IE', name: 'Éire'},
    {code: 'IM', name: 'Isle of Man'},
    {code: 'IL', name: 'ישראל'},
    {code: 'IT', name: 'Italia'},
    {code: 'JM', name: 'Jamaica'},
    {code: 'JP', name: '日本'},
    {code: 'JE', name: 'Jersey'},
    {code: 'JO', name: 'الأردن'},
    {code: 'KZ', name: 'Қазақстан'},
    {code: 'KE', name: 'Kenya'},
    {code: 'KI', name: 'Kiribati'},
    {code: 'KP', name: '북한'},
    {code: 'KR', name: '대한민국'},
    {code: 'KW', name: 'الكويت'},
    {code: 'KG', name: 'Кыргызстан'},
    {code: 'LA', name: 'ລາວ'},
    {code: 'LV', name: 'Latvija'},
    {code: 'LB', name: 'لبنان'},
    {code: 'LS', name: 'Lesotho'},
    {code: 'LR', name: 'Liberia'},
    {code: 'LY', name: 'ليبيا'},
    {code: 'LI', name: 'Liechtenstein'},
    {code: 'LT', name: 'Lietuva'},
    {code: 'LU', name: 'Lëtzebuerg'},
    {code: 'MO', name: 'Macao'},
    {code: 'MK', name: 'Северна Македонија'},
    {code: 'MG', name: 'Madagasikara'},
    {code: 'MW', name: 'Malawi'},
    {code: 'MY', name: 'Malaysia'},
    {code: 'MV', name: 'ދިވެހިރާއްޖެ'},
    {code: 'ML', name: 'Mali'},
    {code: 'MT', name: 'Malta'},
    {code: 'MH', name: 'M̧ajeļ'},
    {code: 'MQ', name: 'Martinique'},
    {code: 'MR', name: 'موريتانيا'},
    {code: 'MU', name: 'Maurice'},
    {code: 'YT', name: 'Mayotte'},
    {code: 'MX', name: 'México'},
    {code: 'FM', name: 'Micronesia, Federated States of'},
    {code: 'MD', name: 'Republica Moldova'},
    {code: 'MC', name: 'Monaco'},
    {code: 'MN', name: 'Монгол улс'},
    {code: 'ME', name: 'Црна Гора'},
    {code: 'MS', name: 'Montserrat'},
    {code: 'MA', name: 'المغرب'},
    {code: 'MZ', name: 'Moçambique'},
    {code: 'MM', name: 'Myanmar'},
    {code: 'NA', name: 'Namibia'},
    {code: 'NR', name: 'Nauru'},
    {code: 'NP', name: 'नेपाल'},
    {code: 'NL', name: 'Nederland'},
    {code: 'NC', name: 'Nouvelle-Calédonie'},
    {code: 'NZ', name: 'New Zealand'},
    {code: 'NI', name: 'Nicaragua'},
    {code: 'NE', name: 'Niger'},
    {code: 'NG', name: 'Nigeria'},
    {code: 'NU', name: 'Niue'},
    {code: 'NF', name: 'Norfolk Island'},
    {code: 'MP', name: 'Northern Mariana Islands'},
    {code: 'NO', name: 'Norge'},
    {code: 'OM', name: 'عُمان'},
    {code: 'PK', name: 'پاکستان'},
    {code: 'PW', name: 'Palau'},
    {code: 'PS', name: 'فلسطين'},
    {code: 'PA', name: 'Panamá'},
    {code: 'PG', name: 'Papua New Guinea'},
    {code: 'PY', name: 'Paraguay'},
    {code: 'PE', name: 'Perú'},
    {code: 'PH', name: 'Pilipinas'},
    {code: 'PN', name: 'Pitcairn Islands'},
    {code: 'PL', name: 'Polska'},
    {code: 'PT', name: 'Portugal'},
    {code: 'PR', name: 'Puerto Rico'},
    {code: 'QA', name: 'قطر'},
    {code: 'RE', name: 'La Réunion'},
    {code: 'RO', name: 'România'},
    {code: 'RU', name: 'Россия'},
    {code: 'RW', name: 'Rwanda'},
    {code: 'BL', name: 'Saint-Barthélemy'},
    {code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha'},
    {code: 'KN', name: 'Saint Kitts and Nevis'},
    {code: 'LC', name: 'Saint Lucia'},
    {code: 'MF', name: 'Saint Martin (French part)'},
    {code: 'PM', name: 'Saint Pierre and Miquelon'},
    {code: 'VC', name: 'Saint Vincent and the Grenadines'},
    {code: 'WS', name: 'Samoa'},
    {code: 'SM', name: 'San Marino'},
    {code: 'ST', name: 'São Tomé e Príncipe'},
    {code: 'SA', name: 'المملكة العربية السعودية'},
    {code: 'SN', name: 'Sénégal'},
    {code: 'RS', name: 'Србија'},
    {code: 'SC', name: 'Seychelles'},
    {code: 'SL', name: 'Sierra Leone'},
    {code: 'SG', name: 'Singapore'},
    {code: 'SX', name: 'Sint Maarten (Dutch part)'},
    {code: 'SK', name: 'Slovensko'},
    {code: 'SI', name: 'Slovenija'},
    {code: 'SB', name: 'Solomon Islands'},
    {code: 'SO', name: 'Soomaaliya'},
    {code: 'ZA', name: 'South Africa'},
    {code: 'GS', name: 'South Georgia and the South Sandwich Islands'},
    {code: 'SS', name: 'South Sudan'},
    {code: 'ES', name: 'España'},
    {code: 'LK', name: 'ශ්‍රී ලංකාව'},
    {code: 'SD', name: 'السودان'},
    {code: 'SR', name: 'Suriname'},
    {code: 'SJ', name: 'Svalbard and Jan Mayen'},
    {code: 'SZ', name: 'Eswatini'},
    {code: 'SE', name: 'Sverige'},
    {code: 'CH', name: 'Schweiz'},
    {code: 'SY', name: 'سوريا'},
    {code: 'TW', name: '臺灣'},
    {code: 'TJ', name: 'Тоҷикистон'},
    {code: 'TZ', name: 'Tanzania'},
    {code: 'TH', name: 'ราชอาณาจักรไทย'},
    {code: 'TL', name: 'Timor-Leste'},
    {code: 'TG', name: 'Togo'},
    {code: 'TK', name: 'Tokelau'},
    {code: 'TO', name: 'Tonga'},
    {code: 'TT', name: 'Trinidad and Tobago'},
    {code: 'TN', name: 'تونس'},
    {code: 'TR', name: 'Türkiye'},
    {code: 'TM', name: 'Түркменистан'},
    {code: 'TC', name: 'Turks and Caicos Islands'},
    {code: 'TV', name: 'Tuvalu'},
    {code: 'UG', name: 'Uganda'},
    {code: 'UA', name: 'Україна'},
    {code: 'AE', name: 'دولة الإمارات العربية المتحدة'},
    {code: 'GB', name: 'United Kingdom'},
    {code: 'US', name: 'United States of America'},
    {code: 'UM', name: 'United States Minor Outlying Islands'},
    {code: 'UY', name: 'Uruguay'},
    {code: 'UZ', name: 'Oʻzbekiston'},
    {code: 'VU', name: 'Vanuatu'},
    {code: 'VE', name: 'Venezuela, Bolivarian Republic of'},
    {code: 'VN', name: 'Việt Nam'},
    {code: 'VG', name: 'Virgin Islands, British'},
    {code: 'VI', name: 'Virgin Islands, U.S.'},
    {code: 'WF', name: 'Wallis and Futuna'},
    {code: 'EH', name: 'الصحراء الغربية'},
    {code: 'YE', name: 'اليمن'},
    {code: 'ZM', name: 'Zambia'},
    {code: 'ZW', name: 'Zimbabwe'}
]
