// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2kaOcs6U1N29prlKhthgnu ._3pZiff4wkFJxRpOpgQHhWI{display:block;width:30px;height:30px;background-size:contain;background-repeat:no-repeat;background-position:center}", ""]);
// Exports
exports.locals = {
	"CreditCardIcon": "_2kaOcs6U1N29prlKhthgnu",
	"Icon": "_3pZiff4wkFJxRpOpgQHhWI"
};
module.exports = exports;
