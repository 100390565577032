import translations from './translations'

translations.add('is', {
    cardNumber: 'Kortanúmer',
    cardHolderName: 'Nafn korthafa',
    expiryDate: 'Gildir til',
    mm: 'MM',
    yy: 'ÁÁ',
    invalidCardNumber: 'Vinsamlegast sláðu inn gilt kortanúmer',
    notSupportScheme: 'Kortakerfi ekki stutt',
    invalidCardHolderName: 'Vinsamlegast sláðu inn gilt nafn korthafa',
    longCardHolderName: '45 stafa hámarkslengd náð',
    invalidExpiryDate: 'Vinsamlega sláðu inn gilda dagsetningu',
    invalidCvv: 'Vinsamlegast sláðu inn gildan öryggiskóða',
    backCardList: 'Aftur í kortalistann',
    yourClickToPayPaymentUnsuccessful: 'Click To Pay greiðslan þín tókst ekki. Vinsamlegast reyndu aftur síðar',
    loading: 'Hleð...',
    visa: 'Visa',
    mastercard: 'Mastercard',
    amex: 'Amex',
    discover: 'Discover',
    dinersClub: 'Diners Club',
    unionPay: 'UnionPay',
    jcb: 'JCB',
    saveCardDetails: 'Vistaðu kortaupplýsingarnar mínar hjá söluaðilanum fyrir hraðari útgreiðslur í framtíðinni'
})
