import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { ApplePayProps } from './props'
import styles from './ApplePay.scss'

export const ApplePay = observer((props: ApplePayProps) => {
    const { store, onClick: handleClick } = props

    useEffect(() => {
        store.isApplePayAvailable()
    }, [])

    const onClick = () => {
        store.startApplePaySession()
        handleClick()
    }

    return (
        <div className={styles.ApplePay} lang={'en'} onClick={onClick}></div>
    )
})
