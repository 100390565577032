export const DEFAULT_IFRAME = 'defaultIframe'
export const CUSTOM_IFRAME = 'customIframe'
export const REDIRECT_PAYMENT = 'redirectPaymentPage'
export const REDIRECT_CUSTOM_PAYMENT = 'redirectCustomPayment'
export const APPLE_PAY_COMPONENT = 'applePayComponent'
export const APPLE_PAY_EXPRESS_COMPONENT = 'applePayExpressComponent'
export const GOOGLE_PAY_COMPONENT = 'googlePayComponent'
export const PAY_BY_BANK_COMPONENT = 'payByBankComponent'
export const CLICK_TO_PAY_COMPONENT = 'clickToPayComponent'
export const ALIPAY_COMPONENT = 'alipayComponent'
export const WECHAT_PAY_COMPONENT = 'wechatPayComponent'
export const ALIPAY_PLUS_COMPONENT = 'alipayPlusComponent'
export const EMBEDDED_WIDGET_COMPONENT = 'embeddedWidgetComponent'
export const PAYMENT_FORM = 'paymentForm'
export const PAY_BY_CARD = 'payByCard'
export const CHANGE_PAYMENT_METHOD = 'goBackToChangePaymentMethod'
export const CARD_NUMBER_INPUT = 'cardNumber'
export const CARD_HOLDER_INPUT = 'cardHolder'
export const CARD_EXPIRE_DATE = 'cardExpireDate'
export const CVV_CODE = 'cvvCode'
export const CLOSE_BUTTON = 'closeButton'
export const ASTRO_PAY_BUTTON = 'astroPayButton'
export const OPEN_BANKING_BUTTON = 'openBankingButton'
export const ORDER_AMOUNT = 'totalOrderAmount'
export const CONFIRM_CLOSE_FORM = 'confirmCloseForm'
export const PAY_BUTTON = 'payButton'
export const KLARNA_PAY_BUTTON = 'klarnaPay'
export const OTHER_PAYMENT_METHODS = 'seeOrCloseOtherMethods'
export const CLICK_TO_PAY_LOOKUP_MY_CARDS_LINK = 'lookupMyCardsLink'
