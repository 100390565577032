import translations from './translations'

translations.add('en', {
    moreDetails: 'Just a Few More Details',
    pleaseEnterEmail: 'Please enter your email',

    pleaseEnterValidEmail: 'Please enter a valid email',
    emailMustNotBeGreaterThan256: 'Email must not be greater than 256 characters',
    pleaseEnterFirstName: 'Please enter your first name',
    firstNameMustNotBeGreaterThan32: 'First name must not be greater than 32 characters',
    pleaseEnterLastName: 'Please enter your last name',
    lastNameMustNotBeGreaterThan32: 'Last name must not be greater than 32 characters',
    pleaseSelectCountry: 'Please select a country',
    countryMustNotBeGreaterThan2: 'Country must not be greater than 2 characters',
    pleaseEnterStreet: 'Please enter a street',
    streetMustNotBeGreaterThan50: 'Street must not be greater than 50 characters',
    pleaseEnterCity: 'Please enter a city',
    cityMustNotBeGreaterThan50: 'City must not be greater than 50 characters',
    pleaseEnterPostalCode: 'Please enter a postal code',
    postalCodeMustNotBeGreaterThan13: 'Postal code must not be greater than 13 characters',
    pleaseEnterMobilePhone: 'Please enter a mobile phone',
    mobilePhoneMustNotBeGreaterThan16: 'Mobile phone must not be greater than 16 characters',
    mobilePhoneMustNotBeLessThan8: 'Mobile phone must not be less than 8 characters',

    mustNotBeGreaterThan: 'must not be greater than',
    mustNotBeLessThan: 'must not be less than',
    characters: 'characters',
    billingAddress: 'Billing address',
    deliveryAddress: 'Delivery address',
    country: 'Country',
    city: 'City',
    street: 'Street address',
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    postalCode: 'Postal code',
    mobilePhone: 'Mobile phone',
    mobilePhoneInInternationalFormat: 'International format',
    pay: 'PAY',
    submit: 'SUBMIT',

    pleaseEnterAllRequiredFields: 'Please enter all required fields'
})
