import React from 'react'
import classNames from 'classnames'
import { amountFormat } from '~/code/components/OrderInfo/services'
import { EcospendBlock } from '../EcospendBlock'
import { EcospendField } from '../EcospendField'
import translations from './translations'
import { EcospendBankBlockProps } from './props'
import styles from './EcospendBankBlock.scss'

export const EcospendBankBlock: React.FC<EcospendBankBlockProps> = (props) => {
    const { store, className } = props

    return <EcospendBlock className={classNames(styles.EcospendBankBlock, className)}>
        <EcospendField className={styles.bankField} label={translations().bank} content={store.selectedBank.friendly_name} />
        <EcospendField className={styles.bankField} label={translations().amount} content={amountFormat(store.paymentData?.amount)} />
        <EcospendField className={styles.bankField} label={translations().reference} content={store.paymentData?.invoiceId} />
    </EcospendBlock>
}
