// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2LyxgLHLvuc-pSgRhvE0nl{border-radius:4px;padding:10px 15px;background-color:rgba(245,245,245,.5)}", ""]);
// Exports
exports.locals = {
	"EcospendBlock": "_2LyxgLHLvuc-pSgRhvE0nl"
};
module.exports = exports;
