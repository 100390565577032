import { action, observable } from 'mobx'
import { isProductionMode } from '~/code/services'
import translations from './translations'
import { error } from '~/code/services/logger'

class LogStore {
    public errorCode: string = null

    public clearErrorCode() {
        this.errorCode = null
    }

    public error(code: string, message?: string, err?: Error | any, isLogServer: boolean = false) {
        const errorCodeMessage = message || translations().errors[code]
        this.errorCode = code
        error(code, errorCodeMessage, err)

        if (isLogServer) {
            this.sendMessage(code, errorCodeMessage, err)
        }
    }

    public sendMessage(code: string, message?: string, err?: Error | any) {
        // TODO: add, when will add a new endpoint
    }
}

const LogStoreInstance = new LogStore()

export { LogStoreInstance as LogStore }
