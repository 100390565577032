import React from 'react'
import { observer } from 'mobx-react'
import { EcospendBankList, EcospendConsentPage, EcospendResultPage, EcospendPaymentFormWrapper, EcospendCheckModal } from './components'
import { EcospendProps } from './props'

export const Ecospend = observer((props: EcospendProps) => {
    const { store } = props

    if (store.result) {
        return <EcospendResultPage store={store} />
    }

    if (store.createOrderResponse) {
        return <>
            <EcospendConsentPage store={store} onContinueClick={() => {props.onContinueClick()}}/>
            <EcospendCheckModal
                isOpen={store.isCheckModalOpen}
                isLoading={store.isCheckModalLoading}
                onClose={() => store.setCheckModalOpen(false)}
                onOk={() => store.checkTransactionStatus()}
            />
        </>
    }

    return <EcospendPaymentFormWrapper store={store}>
        <EcospendBankList store={store}/>
    </EcospendPaymentFormWrapper>
})

