import _ from 'lodash'
import { apiCheck, noThrow } from 'back-connector'
import { action, computed, observable, runInAction } from 'mobx'
import { ConfigStore, LogStore } from '~/code/config'
import { TransactionType } from '~/code/models'
import { getWithToken, isDevelopmentMode, isTestMode, postWithToken } from '~/code/services'
import * as errorCodes from '~/code/config/LogStore'
import { AstroPayParentStore, AstroPayCreateOrderResponseModel } from './models'
import AstroPaySDK, { ClientConfiguration, ConsoleLogger, DepositStatus } from  '@astropay/astropay-sdk'
import { isSuccessTransaction, isFailureTransactionExcludeCancel } from './services'

const APP_ID = ''

type PaymentState = 'successful' | 'failed' | 'cancelled'

export class AstroPayStore {
    popupWindow: Window = null
    createOrderResponse: AstroPayCreateOrderResponseModel = null

    @observable
    isProcessingPayment: boolean = false

    constructor(public parentStore: AstroPayParentStore) {

        // SDK Config
        const config: ClientConfiguration = {
            environment: (isDevelopmentMode() || isTestMode()) ? 'sandbox' : 'production',
            logger: new ConsoleLogger()
        }

        // SDK Init
        AstroPaySDK.init(APP_ID, config)

        // SDK Event Listeners
        const eventListening = AstroPaySDK.getEventListener('deposit')

        // @ts-ignore
        eventListening.on('close', async (data) => {
            const result: PaymentState = await this.checkStatus()
            if (result === 'successful') {
                this.handleSuccess(data)
            } else if (result === 'failed') {
                this.handleStatusChange({
                    status: DepositStatus.UNDEFINED
                })
            } else {
                this.handleStatusChange({
                    status: DepositStatus.CANCELLED
                })
            }

        })

        // @ts-ignore
        eventListening.on('change-status', (data) => {
            this.handleStatusChange(data)
        })

        // @ts-ignore
        eventListening.on('complete', (data) => {
            this.handleSuccess(data)
        })

    }

    @action
    stopPaymentProcessing = () => {
        runInAction(() => this.isProcessingPayment = false)
    }

    @action
    handleStatusChange = (data) => {
        if (data.status === DepositStatus.UNDEFINED) {
            this.stopPaymentProcessing()
            this.parentStore.handleFailureResult({ code: 0 })
        } else if (data.status === DepositStatus.CANCELLED) {
            this.stopPaymentProcessing()
        }
    }

    @action
    handleSuccess = async (data) => {
        this.stopPaymentProcessing()
        this.parentStore.handleSuccessResult()
    }

    checkStatus = async (): Promise<PaymentState> => {
        if (!Boolean(this.createOrderResponse?.id)) {
            return 'cancelled'
        }

        const id = this.createOrderResponse.id
        let attemptCount = 0

        return new Promise<'successful' | 'failed' | 'cancelled'>((resolve, reject) => {
            const check = async () => {
                attemptCount++
                const result = await noThrow(apiCheck(getWithToken<any>(`${ ConfigStore.getUrl().apiUrl }/payments/alternative/search/${id}/status`, { source: 'checkout' })))

                if (!result.error && result.value?.transactionState) {
                    if (isSuccessTransaction(result.value.transactionState)) {
                        resolve('successful')
                        return
                    } else if (isFailureTransactionExcludeCancel(result.value.transactionState)) {
                        resolve('failed')
                        return
                    } else {
                        resolve ('cancelled')
                    }
                }

                if (attemptCount > 4) {
                    resolve('failed')
                } else {
                    setTimeout(check, 1000)
                }
            }

            check()
        })
    }

    async createOrder() {
        runInAction(() => this.isProcessingPayment = true)

        // this.openPopupWindow()
        const result = await noThrow(apiCheck(postWithToken<AstroPayCreateOrderResponseModel>(`${ ConfigStore.getUrl().apiUrl }/payments/alternative/createOrder`, this.order)))

        if (result.error || !result?.value?.success) {
            LogStore.error(errorCodes.ASTROPAY_CAN_NOT_CREATE_ORDER)
            this.parentStore.handleFailureResult({
                // @ts-ignore
                code: result.error ? result.error.errorCode : result.value?.errorCode
            })
            return
        }

        // this.popupWindow.location.href = result.value.deepLinkUrl
        this.createOrderResponse = result.value

        try {
            AstroPaySDK.deposit(this.createOrderResponse.externalResponse)
        } catch (e) {
            this.handleStatusChange({ status: DepositStatus.UNDEFINED })
        }

    }

    @computed
    get paymentData() {
        return this.parentStore?.paymentData
    }

    get returnUrl() {
        return `${ConfigStore.getUrl().paymentPageUrl}/astropay-return.html`
    }

    @computed
    get order() {
        const paymentData = this.paymentData
        const customerDetails = paymentData?.customerDetails
        return {
            amount: paymentData.amount,
            currency: paymentData.currency,
            invoiceId: paymentData.invoiceId,
            description: paymentData.description,
            taxAmount: paymentData.taxAmount,
            accountId: customerDetails?.accountDetails?.accountId,
            email: customerDetails?.email,
            locale: paymentData.language,
            terminalId: paymentData?.paymentSettings?.terminalId,
            paymentMethod: 'astropay',
            transactionType: paymentData.transactionType ||  TransactionType.SALE,
            returnUrl: this.returnUrl,
            backLink: this.paymentData?.paymentSettings?.returnUrl,
            failureBackLink: this.paymentData?.paymentSettings?.failureReturnUrl,
            callbackUrl: this.paymentData?.paymentSettings?.callbackUrl,
            failureCallbackUrl: this.paymentData?.paymentSettings?.failureCallbackUrl,
            billingAddress: this.billingAddress
        }
    }

    @computed
    public get billingAddress() {
        const customerDetails = this.paymentData?.customerDetails
        const obj = {
            firstName: customerDetails?.billingAddress?.firstName,
            lastName: customerDetails?.billingAddress?.lastName,
            streetAddress1: customerDetails?.billingAddress?.addressLine1,
            streetAddress2: customerDetails?.billingAddress?.addressLine2,
            postalCode: customerDetails?.billingAddress?.postalCode,
            city: customerDetails?.billingAddress?.city,
            phone: customerDetails?.billingAddress?.phone,
            country: customerDetails?.billingAddress?.country
        }
        const isEmpty = !_.some(obj)
        return isEmpty ? null : obj
    }
}
