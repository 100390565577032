import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Image } from '~/code/components'
import { ConfigStore, StyleStore } from '~/code/config'
import styles from './SupportCompany.scss'
import translations from './translations'
import { Language } from '~/code/components/Translation/types'
import { TranslationBuilder } from '~/code/components/Translation'

export default observer( () => {

    const [selectedLanguage, setSelectedLanguage] = React.useState<Language>('en')

    useEffect(() => {
        const language = TranslationBuilder.lang
        setSelectedLanguage(language)
    })

    const onLanguageSelect = (event) => {
        const language = event.target.value as Language
        setSelectedLanguage(language)
        TranslationBuilder.setLang(language)
    }
    return (
        <div className={ styles.SupportCompany }>
            {
                StyleStore.style.showPoweredByBlock && (
                    <div className={ styles.PoweredByBlock }>
                        <div className={ styles.PoweredBy }>
                            { `${ translations().poweredBy }: ` }
                        </div>
                        <Image className={ styles.DnaLogo } src={ 'logo-gray.svg' }/>
                    </div>
                )
            }
            {
                StyleStore.style.showPaymentSecuredByBlock && (
                    <div className={ styles.PaymentSecuredBlock }>
                        <div className={ styles.Header }>
                            { `${ translations().allPaymentsSecure }: ` }
                        </div>
                        <div className={ styles.Logos }>
                            <Image className={ `${ styles.LogoItem } ${ styles.NccGroup }` } src={ 'ncc.svg' } />
                            {/*<Image className={ `${ styles.LogoItem } ${ styles.MasterCard }` } src={ 'mc_sc.svg' } />*/}
                            <Image className={ `${ styles.LogoItem } ${ styles.Visa }` } src={ 'visa-check.svg' } />
                            <Image className={ `${ styles.LogoItem } ${ styles.Pci }` } src={ 'pci.svg' } />
                        </div>
                    </div>
                )
            }
            {
                <div className={styles.languageSelectContainer}>
                    <select className={styles.languageSelect} onChange={onLanguageSelect} value={selectedLanguage}>
                        <option value='en'>English</option>
                        <option value='es'>Español</option>
                        <option value='pt'>Português</option>
                        <option value='is'>Íslenska</option>
                    </select>
                </div>
            }
        </div>
    )
})
