import React, { useCallback, useEffect, useRef } from 'react'
import { ClickToPayOtpInputProps } from './props'
import styles from './styles.scss'

export const ClickToPayOtpInput: React.FC<ClickToPayOtpInputProps> = (props) => {
    const { locale, cardBrands, initiateValidationResponse: res, onContinue, errorMessage, isLoading } = props

    const refOtpInput = useRef<any>()
    const refOtpText = useRef<string>(null)

    const handleEvent = useCallback((event) => {
        switch (event.type) {
            case 'otpChanged':
                refOtpText.current = event.detail
                break
            case 'alternateRequested':
                onContinue(null)
                break
            case 'continue':
                onContinue(refOtpText.current)
                break
        }
    }, [])

    useEffect(() => {
        const instance = refOtpInput.current
        if (instance) {
            instance.addEventListener('otpChanged', handleEvent)
            instance.addEventListener('alternateRequested', handleEvent)
            instance.addEventListener('continue', handleEvent)
        }

        return () => {
            if (instance) {
                instance.removeEventListener('otpChanged', handleEvent)
                instance.removeEventListener('alternateRequested', handleEvent)
                instance.removeEventListener('continue', handleEvent)
            }
        }
    }, [])

    return (
        <div className={styles.root}>
            <src-otp-input
                ref={refOtpInput}
                locale={locale}
                card-brands={cardBrands}
                network-id={res?.network}
                masked-identity-value={res?.maskedValidationChannel}
                error-reason={errorMessage}
                disable-elements={isLoading}
            >
            </src-otp-input>
        </div>
    )
}
