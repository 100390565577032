import { action, observable } from 'mobx'
import { SendReceiptStatus } from '~/code/models'

export class SendReceiptStore {

    constructor(private parentStore: { timer: any }) {}

    @observable
    public status: SendReceiptStatus = null

    @action
    public setStatus = (status: SendReceiptStatus) => {
        this.status = status
    }

    public onFocus = () => {
        if (this.parentStore.timer) {
            clearTimeout(this.parentStore.timer)
        }
    }
}

