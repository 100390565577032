import { isProductionMode } from '~/code/services/index'
import { get, post } from 'back-connector'

export function log (...args) {
    if (isProductionMode()) return
    // tslint:disable-next-line:no-console
    console.log(...args)
}

export function error (...args) {
    if (isProductionMode()) return
    // tslint:disable-next-line:no-console
    console.error(...args)
}

export function warn (...args) {
    if (isProductionMode()) return
    // tslint:disable-next-line:no-console
    console.warn(...args)
}

export async function serverLog(serverAddress, ...args) {
    const url = `${serverAddress}/logs?args=${JSON.stringify(args)}`
    get(url, args)
}
