import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    paymentReject: string,
    tokenizationReject: string,
    verificationReject: string,
    hasNotAuthorisedPleaseCheck: string,
    hasNotAuthorisedPleaseRetry: string,
    hasBeenTechnicalError: string,
    payPalWasNotCompleted: string,
    payByBankWasNotCompleted: string,
    googlePayWasNotCompleted: string,
    applePayWasNotCompleted: string,
    ecospendWasNotCompleted: string,
    klarnaWasNotCompleted: string,
    clickToPayWasNotCompleted: string,
    invalidUnsupportedPaymentMethod: string,
    tryAgain: string,
    returnToBack: string
}>()
