import React from 'react'
import classNames from 'classnames'
import { ThreeDotsLoader } from '../ThreeDotsLoader'
import { PaymentMethodItemWrapperProps } from './props'
import styles from './PaymentMethodItemWrapper.scss'
import { ConfigStore } from '~/code/config'

export const PaymentMethodItemWrapper: React.FC<PaymentMethodItemWrapperProps> = (props) => {
    const { name, isLoading, className, children, dataId, ...rest } = props
    return <div className={classNames(styles.PaymentMethodItemWrapper, className, { [styles.Checked]: name === ConfigStore.paymentMethod })} data-id={dataId} {...rest}>
        { isLoading ? <ThreeDotsLoader className={ styles.Loader }/> : children}
    </div>
}
