// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1WPhyML3Z2KQGygq8k64_B{z-index:2147000101 !important;opacity:1 !important}._1WPhyML3Z2KQGygq8k64_B .rc-tooltip-arrow{display:none}", ""]);
// Exports
exports.locals = {
	"Tooltip": "_1WPhyML3Z2KQGygq8k64_B"
};
module.exports = exports;
