import { apiCheck, ApiResponse, ContentType, FlowResult, get, noThrow, post } from 'back-connector'
import { ConfigStore } from '~/code/config/ConfigStore'
import { MerchantConfig, PaymentLinkDataType } from '~/code/models'
import { Base64 } from 'js-base64'
import { log } from '~/code/services/logger'

export async function fetchConfiguration(): Promise<FlowResult<MerchantConfig>> {
    return await noThrow(apiCheck(getWithToken(`${ ConfigStore.getUrl().apiUrl }/payments/form/configuration`, null)))
}

export async function closeTransaction(transactionId: string): Promise<FlowResult<any>> {
    const res =  await noThrow(apiCheck(postWithToken(`${ ConfigStore.getUrl().apiUrl }/transaction/events/${ transactionId }`, {
        'eventName': 'EXIT'
    })))
    return res
}

export async function fetchPaymentDataByPaymentLink(token: [string, string?], linkId: string): Promise<FlowResult<PaymentLinkDataType>> {
    return await noThrow(apiCheck(get(`${ ConfigStore.getUrl().apiUrl }/v1/checkout/payment-links/${ linkId }`, {}, [
        ['Authorization', 'Basic ' + Base64.encode(token.join(':')) ]
    ])))
}

export function getWithToken<T>(url: string, data?: ContentType, headers?: string[][]): Promise<ApiResponse<T>> {
    return get(url, data, [
        ['Authorization', 'Bearer ' + ConfigStore.auth?.access_token],
        ...(headers ? headers : [])
    ])
}

export function postWithToken<T>(url: string, data?: ContentType, headers?: string[][]): Promise<ApiResponse<T>> {
    return post(url, data, [
        ['Authorization', 'Bearer ' + ConfigStore.auth?.access_token],
        ...(headers ? headers : [])
    ])
}
