import { createRef } from 'react'
import { action, computed, observable, reaction } from 'mobx'
import { map as mapObject } from 'lodash'
import creditCardType from 'credit-card-type'
import CardValidator from 'card-validator'
import { apiCheck, noThrow } from 'back-connector'
import { ConfigStore } from '~/code/config/ConfigStore'
import { fetchCardScheme, getFilteredExpiryDate, isCompleteExpDate, isFullCardNumber } from './services'
import translations from './translations'
import { cryptoCardDataJSEncrypt } from '~/code/services'
import { CardDataParentStore, CardInfo, CreditCardType, InputField, InputFieldName } from './models'
import { CARD_EXPIRY_DATE_MAX_ELAPSED_YEAR } from './models/constants'
import { ClickToPayStore } from '~/code/pages/CardData/components/ClickToPay/ClickToPayStore'
import { PaymentMethod } from '~/code/models'
import { ClickToPayCheckoutResult } from '~/code/pages/CardData/components/ClickToPay/models/ClickToPayCheckoutResult'
import { ClickToPayCheckoutResultEvent } from '~/code/pages/CardData/components/ClickToPay/models/ClickToPayCheckoutResultEvent'
import { INVALID_CARD_HOLDER_NAME, INVALID_CARD_NUMBER, INVALID_CVV, INVALID_EXPIRY_DATE, LONG_CARD_HOLDER_NAME, NOT_SUPPORT_SCHEME } from '~/code/pages/CardData/constants/error-codes'

class CardDataStore {
    private parentStore: CardDataParentStore
    private clickToPayStore: ClickToPayStore
    constructor (parentStore: CardDataParentStore, clickToPayStore: ClickToPayStore) {
        this.parentStore = parentStore
        this.clickToPayStore = clickToPayStore
        reaction(() => {
            return this.cardInfo && this.cardInfo.code && this.cardInfo.code.size
        }, (size) => {
            const inputValue = this.fields[InputFieldName.Cvv].value || ''
            const valueSize = size || 4
            if (!inputValue) return
            this.fields[InputFieldName.Cvv].onChange(inputValue.substring(0, valueSize))
        })
    }

    @observable
    public container = createRef<HTMLFormElement>()

    @observable
    public cardHolderInput = createRef<HTMLInputElement>()

    @observable
    public cvcInput: HTMLInputElement = null

    @observable
    public loadCardSchema: number = undefined

    @observable
    public isCardSchemaLoading: boolean = false

    @observable
    public shouldStoreCardOnFile: boolean = false

    @computed
    public get hasNextPage() {
        return !this.parentStore.isOrderInfoValid
    }

    @observable
    public fields: { [key in InputFieldName]: InputField<string> } = {
        [InputFieldName.CardNumber]: {
            name: InputFieldName.CardNumber,
            value: '',
            onChange: async (value: string) => {
                const field = this.fields[InputFieldName.CardNumber]
                this.loadCardSchema = undefined
                field.value = value
                field.errorCode = await field.validate(value)

                if ( isFullCardNumber(value) ) {
                    setTimeout(() => this.cardHolderInput.current.focus(), 50)
                }
            },
            validate: async (value: string = this.fields[InputFieldName.CardNumber].value): Promise<string> => {
                if (this.cardInfo?.type !== CreditCardType.UNIONPAY) {
                    const numberValidation = CardValidator.number(value, { luhnValidateUnionPay: true })
                    if (!numberValidation.isValid) {
                        return INVALID_CARD_NUMBER
                    }
                }

                if (this.loadCardSchema) {
                    return this.fields[InputFieldName.CardNumber].errorCode
                } else if (ConfigStore.acceptedCardSchemes && this.loadCardSchema === undefined) {
                    const res = await this.fetchCardScheme()
                    return res
                }

                return ''
            },
            errorCode: ''
        },
        [InputFieldName.CardHolderName]: {
            name: InputFieldName.CardHolderName,
            value: '',
            onChange: (value: string) => {
                const field = this.fields[InputFieldName.CardHolderName]
                field.errorCode = field.validate(value)
                field.value = value && value.toString().toUpperCase()
            },
            validate: (value: string = this.fields[InputFieldName.CardHolderName].value) => {
                if (value && value.length > 45) {
                    return LONG_CARD_HOLDER_NAME
                }
                const validate = /^[a-zA-Z\s.\-']+$/
                return !validate.test(value) ? INVALID_CARD_HOLDER_NAME : ''
            },
            errorCode: ''
        },
        [InputFieldName.ExpiryDate]: {
            name: InputFieldName.ExpiryDate,
            value: '',
            onChange: (value: string) => {
                const field = this.fields[InputFieldName.ExpiryDate]
                const changedValue = getFilteredExpiryDate(value)
                field.errorCode = field.validate(changedValue)
                field.value = changedValue

                if ( isCompleteExpDate(changedValue) ){
                    setTimeout(() => this.cvcInput.focus(), 50)
                }
            },
            validate: (value: string = this.fields[InputFieldName.ExpiryDate].value): string => {
                const expireDate = CardValidator.expirationDate(value, CARD_EXPIRY_DATE_MAX_ELAPSED_YEAR)
                if (!expireDate.isValid) {
                    return INVALID_EXPIRY_DATE
                }
                return ''
            },
            errorCode: ''
        },
        [InputFieldName.Cvv]: {
            name: InputFieldName.Cvv,
            value: '',
            onChange: (value: string) => {
                const field = this.fields[InputFieldName.Cvv]
                this.fields[InputFieldName.Cvv].errorCode =  field.validate(value)
                field.value = value
            },
            validate: (value: string = this.fields[InputFieldName.Cvv].value): string => {
                if (value) {
                    return value.length < 3 || (this.cardInfo && this.cardInfo.code && value.length !== this.cardInfo.code.size) ? INVALID_CVV : ''
                } else if (!ConfigStore.isCSCRequiredForNonTokenPayments) {
                    return ''
                }
                return INVALID_CVV
            },
            errorCode: ''
        }
    }

    @computed
    public get cardInfo(): CardInfo {
        const cards = creditCardType(this.fields[InputFieldName.CardNumber].value.replace(/[^0-9\\.]+/g, ''))
        const cardInfo = cards.length === 1 ? cards[0] : null
        return cardInfo
    }

    @computed
    public get isLoading(): boolean {
        return this.clickToPayStore.isInitiating
    }

    public isCardSchemeIdIncluded(cardSchemeId) {
        return ConfigStore.acceptedCardSchemes?.map((i) => i.cardSchemeId).includes(cardSchemeId)
    }

    @action
    async fetchCardScheme() {
        this.startCardSchemaLoading()
        const { value } = await noThrow(apiCheck(fetchCardScheme(await cryptoCardDataJSEncrypt({pan: this.parentStore.cardNumber}))))
        this.stopCardSchemaLoading()
        this.loadCardSchema = value?.cardSchemeId || null
        if (value && !this.isCardSchemeIdIncluded(value?.cardSchemeId)) {
            return NOT_SUPPORT_SCHEME
        }

        return ''
    }

    @action
    public showInitCardListPage() {
        this.parentStore.showInitCardListPage()
    }

    @action
    public startCardSchemaLoading() {
        this.isCardSchemaLoading = true
    }

    @action
    public stopCardSchemaLoading() {
        this.isCardSchemaLoading = false
    }

    @action
    public setShouldStoreCardOnFile(isChecked: boolean) {
        this.shouldStoreCardOnFile = isChecked
    }

    handleClickToPay = async (resultPromise: Promise<ClickToPayCheckoutResult>, _invokedFrom?: string) => {
        ConfigStore.setField('paymentMethod', PaymentMethod.ClickToPay)

        const result = await resultPromise

        switch (result.event) {
            case ClickToPayCheckoutResultEvent.cancel:
                this.parentStore.tryAgain()
                return false
            case ClickToPayCheckoutResultEvent.failure:
                this.parentStore.handleFailureResult({
                    code: result?.payload?.code,
                    message: result?.payload?.message
                })
                return false
            default:
                switch (result.payload?.checkoutActionCode) {
                    case 'SWITCH_CONSUMER': {
                        await this.clickToPayStore.signOut()
                        this.clickToPayStore.setCurrentScreenName('emailOrPhone')
                        return false
                    }
                    case 'CHANGE_CARD': {
                        return false
                    }
                    default: {
                        this.parentStore.onPay({
                            customCrypto: {
                                ...(result.payload || {}),
                                srcDpaId: ConfigStore?.paymentMethodsSettings?.clickToPay?.srcDpaId
                            },
                            paymentMethod: PaymentMethod.ClickToPay.toLowerCase(),
                            invokedFrom: (_invokedFrom || '') + '->CardDataStore.confirmData.clickToPay'
                        })
                        return true
                    }
                }
        }
        
    }

    @action
    async confirmCardData(_invokedFrom: string) {
        const fields = await Promise.all(mapObject(this.fields || {}, async (value) => {
            const error = await value.validate()
            if (error) {
                value.errorText = error
                return true
            }
            return false
        }))
        if (fields.every(i => !i)) {
            this.parentStore.onCardDataConfirm(_invokedFrom + '->CardDataStore.confirmData')
        }
    }

    @action
    public clearFields = () => {
        mapObject(this.fields, (value) => {
            value.value = ''
        })
    }
}

export { CardDataStore }
