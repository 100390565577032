import React from 'react'
import { observer } from 'mobx-react'
import { SimplePaymentMethodItemProps } from './props'
import styles from './SimplePaymentMethodItem.scss'
import { PaymentMethodItem, Image } from '~/code/components'

export default observer((props: SimplePaymentMethodItemProps) => {
    const renderHeader = () => {
        return (
            <div className={ styles.Header }>
                <Image
                    className={ styles.HeaderLogo }
                    src={ props?.image }
                />
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div className={ styles.Description }>
               { props.description }
            </div>
        )
    }

    const renderMessage = () => {
        return <span className={ styles.Message }> { props.message }</span>
    }
    return (
        <div
            className={`
                    ${ styles.SimplePaymentMethodItem }
                    ${ props.className ? props.className : ''}
                    `
            }
            data-id={props.dataId}
        >
            <PaymentMethodItem
                name={ props.name }
                className={ styles.PaymentMethodItemBlock }
                headerContainerClassName={ styles.PaymentMethodItemBlockHeader }
                contentContainerClassName={ styles.PaymentMethodItemBlockContent }
                messageContainerClassName={ styles.PaymentMethodItemBlockMessage }
                onClick={ props.onClick }
                renderItemHeader={ () => renderHeader() }
                renderItemContent={ () => renderContent() }
                renderItemMessage={ () => renderMessage() }
                isLoading={ props.isLoading }
            />
        </div>
    )
})
