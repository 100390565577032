import React from 'react'
import MaskedInput from 'react-text-mask'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { ConfigStore, StyleStore } from '~/code/config'
import { CheckboxBlock, Input, Link } from '~/code/components'
import { isPayByLink } from '~/code/services'
import { InputFieldName } from '~/code/pages/CardData/models'
import translations from './translations'
import { CreditCardIcon } from '~/code/components'
import { CardPaymentWrapper } from '~/code/pages/CardData/components'
import { CvvInputMaskInput } from '~/code/pages/CardData/components/CvvInputMaskInput'
import { LeftArrow } from '~/assets/icons'
import { CARD_EXPIRY_DATE_MASK, CARD_NUMBER_MASK } from './models/constants'
import { ClickToPayAgreeCheckbox } from './components/ClickToPay/components'
import { CardDataProps } from './props'
import styles from './CardData.scss'
import Processing from '~/code/pages/Processing/Processing'
import { CARD_EXPIRE_DATE, CARD_HOLDER_INPUT, CARD_NUMBER_INPUT, CVV_CODE } from '~/code/constants/playwright-ids'

@observer
export default class extends React.Component<CardDataProps> {

    renderCardNumberIcon() {
        const { store } = this.props

        if (store.isCardSchemaLoading) {
            return <div className={ styles.Loader } style={{ borderBottomColor: StyleStore.style.primaryColor }}></div>
        } else if (store.cardInfo && store.cardInfo.type) {
            return <CreditCardIcon type={  store.cardInfo.type } />
        }
        return null
    }

    renderCardNumber() {
        const { store } = this.props
        const cardNumberField = store.fields[InputFieldName.CardNumber]
        const { errorCode, value } = cardNumberField
        return (
            <div>
                <MaskedInput
                    mask={ CARD_NUMBER_MASK }
                    guide={ false }
                    onChange={ (event) => cardNumberField.onChange(event.target.value) }
                    onBlur={ async () => cardNumberField.errorCode = await cardNumberField.validate() }
                    render={ (ref, props) => {
                        return (
                            <Input
                                ref={ ref }
                                { ...props }
                                id='CreditCardNumber'
                                name='CreditCardNumber'
                                data-test-id={CARD_NUMBER_INPUT}
                                autoComplete='cc-number'
                                type='tel'
                                label={ translations().cardNumber }
                                errorText={ translations()[errorCode] }
                                renderIcon={ () => this.renderCardNumberIcon() }
                                autoFocus={ true }
                                value={ value }
                            />
                        )
                    }}
                />
            </div>
        )
    }

    renderCardHolderName() {
        const { store } = this.props
        const cardHolderNameField = store.fields[InputFieldName.CardHolderName]
        const { errorCode, value } = cardHolderNameField

        return (
            <div data-id={'card-holder-name'}>
                <Input
                    name={ 'ccname' }
                    id={'frmNameCC'}
                    autoComplete={'cc-name'}
                    ref={ store.cardHolderInput }
                    label={ translations().cardHolderName }
                    onChange={ (event) => cardHolderNameField.onChange(event.target.value) }
                    onBlur={ () => cardHolderNameField.errorCode = cardHolderNameField.validate() }
                    value={ value }
                    errorText={ translations()[errorCode] }
                    data-test-id={CARD_HOLDER_INPUT}
                />
            </div>
        )
    }

    renderExpireDate() {
        const { store } = this.props
        const expireDateField = store.fields[InputFieldName.ExpiryDate]
        const { errorCode, warningText, value } = expireDateField
        const [ month, year ] = value.split('/')

        return (
            <div className={ styles.ExpireDateWrapper } data-id={'expire-date'}>
                <div className={ styles.ExpireDate }>
                    <MaskedInput
                        mask={ CARD_EXPIRY_DATE_MASK }
                        guide={ false }
                        onChange={ (event) => {
                            expireDateField.onChange(event.target.value)
                        } }
                        onBlur={ () => expireDateField.errorCode = expireDateField.validate() }
                        render={ (ref, props) => {
                            return (
                                <div className={ styles.ExpireDateItem }>
                                    <Input
                                        ref={ ref }
                                        { ...props }
                                        id='ExpirationDate'
                                        name='ExpirationDate'
                                        autoComplete={ 'cc-exp' }
                                        type='text'
                                        label={ translations().expiryDate }
                                        placeholder='MM/YY'
                                        className={ classNames(styles.ExpirationDateInput) }
                                        errorText={ translations()[errorCode] }
                                        warningText={ warningText }
                                        value={ value }
                                        data-test-id={CARD_EXPIRE_DATE}
                                    />
                                    <div className={ styles.ExpireDateHiddenFields }> { /* For save card expiry date on Safari */ }
                                        <input type='number' id='cc-exp-month' name='cc-exp-month' autoComplete='cc-exp-month'  value={ month } onChange={ () => null } />
                                        <input type='number' id='cc-exp-year' name='cc-exp-year' autoComplete='cc-exp-year' value={ year ? `20${year}` : '' } onChange={ () => null } />
                                    </div>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
        )
    }

    renderCvc() {
        const { store } = this.props
        const cvvField = store.fields[InputFieldName.Cvv]
        const { errorCode, value } = cvvField
        return (
            <div className={ styles.CvvWrapper } data-id={'cvc'} data-test-id={CVV_CODE}>
                <CvvInputMaskInput
                    maskSize={ store.cardInfo && store.cardInfo.code && store.cardInfo.code.size }
                    onChange={ (event) => cvvField.onChange(event.target.value) }
                    onBlur={ () => cvvField.errorCode = cvvField.validate() }
                    setRef={ (node) => {
                        store.cvcInput = node
                    } }
                    errorText={ translations()[errorCode] }
                    value={ value }
                />
            </div>
        )
    }

    render () {
        const { store, clickToPayStore } = this.props

        return (
            <>
                {
                    store.isLoading &&
                    <Processing description={translations().loading}/>
                }
                {
                    !store.isLoading &&
                    <div
                        className={ styles.CardData }
                    >
                        <CardPaymentWrapper
                            hasNextPage={ store.hasNextPage }
                            onConfirm={ (invokedFrom) => store.confirmCardData(invokedFrom + '->CardData.CardPaymentWrapper.onConfirm') }
                        >
                            { ConfigStore.activePaymentMethods().length === 1 && ConfigStore.cards && Array.isArray(ConfigStore.cards) && ConfigStore.cards.length > 0 && (
                                <Link
                                    onClick={ () => store.showInitCardListPage() }
                                >
                                    <LeftArrow color={ StyleStore.style.primaryColor } /> { translations().backCardList }
                                </Link>
                            ) }

                            {
                                this.renderCardNumber()
                            }
                            {
                                this.renderCardHolderName()
                            }
                            <div className={ `${ styles.ExpireDateAndCvvRow }`}>
                                { this.renderExpireDate() }
                                { this.renderCvc() }
                            </div>

                            <ClickToPayAgreeCheckbox
                                selectedCardType={ store.cardInfo && store.cardInfo.type }
                                hasAgreedToShareWithClickToPay={ clickToPayStore.hasAgreedToShareWithClickToPay }
                                onAgreeToShareWithClickToPay={ clickToPayStore.agreeDisagreeToShareWithClickToPay }
                                cannotChangeAgreement={ConfigStore.paymentMethodsSettings?.clickToPay?.cannotChangeAgreement}
                            />

                            {ConfigStore.allowSavingCards && !isPayByLink() && (
                                <CheckboxBlock
                                    className={ styles.saveCardBlock }
                                    checked={ store.shouldStoreCardOnFile }
                                    onChange={ (isChecked) => store.setShouldStoreCardOnFile(isChecked) }
                                >
                                    {translations().saveCardDetails}
                                </CheckboxBlock>
                            )}
                        </CardPaymentWrapper>
                    </div>
                }
            </>
        )
    }
}

