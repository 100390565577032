// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".J2aTRrFvoA7wZwteecQgz{width:100%;min-height:46px;cursor:pointer;-webkit-appearance:-apple-pay-button}", ""]);
// Exports
exports.locals = {
	"ApplePay": "J2aTRrFvoA7wZwteecQgz"
};
module.exports = exports;
