import { validateEmailAndItsDomain } from '~/code/pages/OrderPersonalInfo/services'
import { EMAIL_MUST_NOT_BE_GREATER_THAN_256, MOBILE_PHONE_MUST_NOT_BE_GREATER_THAN_16, MOBILE_PHONE_MUST_NOT_BE_LESS_THAN_8, PLEASE_ENTER_EMAIL, PLEASE_ENTER_PHONE, PLEASE_ENTER_VALID_EMAIL } from '~/code/pages/CardDataPage/constants'

export const validateEmailInput = (value: string): string => {
    if (!value) {
        return PLEASE_ENTER_EMAIL
    } else if (!validateEmailAndItsDomain(value)) {
        return PLEASE_ENTER_VALID_EMAIL
    } else if (value.length > 256) {
        return EMAIL_MUST_NOT_BE_GREATER_THAN_256
    }
    return ''
}

export function validatePhoneInput (value: string) {
    if (!value) {
        return PLEASE_ENTER_PHONE
    } else if (value.length > 16) {
        return MOBILE_PHONE_MUST_NOT_BE_GREATER_THAN_16
    } else if (value.length < 8) {
        return MOBILE_PHONE_MUST_NOT_BE_LESS_THAN_8
    }
    return ''
}
