// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2rlZ_QtUEZDEfSUqg9Vjiq{width:100%;height:100%}._2aS_kPSz5UlOSTbEAooSX1{height:100%}._2aS_kPSz5UlOSTbEAooSX1>:first-child{margin:0px !important;width:100% !important}", ""]);
// Exports
exports.locals = {
	"PayByBank": "_2rlZ_QtUEZDEfSUqg9Vjiq",
	"invisibleButton": "_2aS_kPSz5UlOSTbEAooSX1"
};
module.exports = exports;
