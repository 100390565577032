import React from 'react'
import Select from 'react-select'
import { observer } from 'mobx-react'
import { StyleStore } from '~/code/config'
import { lighten } from '~/code/services'
import styles from './Select.scss'

export default observer((props) => {
    const { errorText, ...selectProps } = props
    return (
        <div className={`
            ${ styles.Select }
        `}>
            <Select
                { ...selectProps }
                styles={ {
                    control: (provided) => ({
                        ...provided,
                        boxShadow: 'none',
                        minHeight: '49px'
                    }),
                    dropdownIndicator: (base, state) => ({
                        ...base,
                        color: '#000000'
                    }),
                    menu: (base) => ({
                        ...base,
                        height: '200px'
                    }),
                    menuList: (base) => ({
                        ...base,
                        height: '200px'
                    }),
                    ...( props.styles ? props.styles : {} )
                } }
                theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: lighten(StyleStore.style.primaryColor, 10),
                            primary25: lighten(StyleStore.style.primaryColor, 20),
                            primary50: lighten(StyleStore.style.primaryColor, 20),
                            borderRadius: 1,
                            ...(errorText ? {
                                primary: styles['error-color'],
                                neutral20 : styles['error-color'],
                                neutral30 : styles['error-color']
                            } : {})
                        }
                    })
                }
            />
            <span className={ styles.Error }>
                { errorText }
            </span>
        </div>

    )
})
