import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { PaymentMethodItemWrapper, Image } from '~/code/components'
import { AstroPayButtonProps } from './props'
import styles from './styles.scss'
import { ASTRO_PAY_BUTTON } from '~/code/constants/playwright-ids'

export const AstroPayButton = observer((props: AstroPayButtonProps) => {
    const { className, name, dataId, ...rest } = props

    return <PaymentMethodItemWrapper
        name={ name || 'astropay' }
        data-test-id={ASTRO_PAY_BUTTON}
        className={classNames(styles.root, styles.astroPay, className)}
        dataId={dataId || 'astropay-button'}
        {...rest}
    >
        <Image src='astropay.svg' className={styles.image} />
    </PaymentMethodItemWrapper>
})
