// Add IE-specific interfaces to Window
declare global {
    interface Window {
        attachEvent (event: string, listener: EventListener): boolean
        detachEvent (event: string, listener: EventListener): void
    }
}

export function docReady(fn) {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        setTimeout(fn, 1)
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', fn)
    } else {
        window.attachEvent('onload', fn)
    }
}

export function addListener(event: string, callback: EventListener, elem: any) {
    if (elem.addEventListener) {
        elem.addEventListener(event, callback)
    } else {
        elem.attachEvent('on' + event, callback)
    }
}

export function removeListener(event: string, callback: EventListener, elem: any) {
    if (elem.removeEventListener) {
        elem.removeEventListener(event, callback)
    } else {
        elem.detachEvent('on' + event, callback)
    }
}

type AttemptsOptionType = { maxAttemptCount?: number, attemptDelayInMs?: number }
type ResponseType<T> = { isOk: boolean, data?: T }

export async function listenAttempts<T = any>(
    makeAttempt: () => Promise<ResponseType<T>>,
    options?: AttemptsOptionType
): Promise<ResponseType<T>> {

    const { maxAttemptCount, attemptDelayInMs = 1000 } = options || {}
    let attemptCount = 0

    return new Promise<ResponseType<T>>((resolve, reject) => {
        const attempt = async () => {
            attemptCount++
            const { isOk, data } = await makeAttempt()

            if (isOk) {
                resolve({ isOk, data })
                return
            }

            if (maxAttemptCount && attemptCount > maxAttemptCount) {
                resolve({ isOk: false, data })
            } else {
                setTimeout(attempt, attemptDelayInMs)
            }
        }

        attempt()
    })
}