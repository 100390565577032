import React from 'react'
import translations from './translations'

translations.add('es', {
    back: 'cambiar de banco',
    cancel: 'Cancelar',
    continue: 'Continuar',
    infoText: `Pediremos a su banco que comparta su nombre, código de clasificación y número de cuenta. 
        Solo utilizaremos estos datos si solicita un reembolso por esta transacción.`,
    permissionText: 'Ecospend Technologies Limited necesita su permiso para iniciar de forma segura una orden de pago de su banco.',
    privacyPolicyText: (termsUrl: string, privacyUrl: string) => <>
        Al hacer clic en continuar, acepta nuestros <a href={termsUrl} target='_blank'>términos y condiciones</a> y <a href={privacyUrl} target='_blank'>la política de privacidad</a>
    </>
})
