import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { postWithToken } from '~/code/services'
import { ConfigStore, StyleStore } from '~/code/config'
import { validateEmailAndItsDomain } from '~/code/pages/OrderPersonalInfo/services'
import { LeftArrow } from '~/assets/icons'
import { ThreeDotsLoader } from '~/code/components'
import { SendReceiptStore } from '~/code/components/SendReceipt'
import { ActionButton } from '../ActionButton'
import { Input } from '../Input'
import { Link } from '../Link'
import translations from './translations'
import styles from './styles.scss'

type Props = {
    email: string
    paymentId: string
    isOnlyInputVisible?: boolean
    store: SendReceiptStore
}

export const SendReceipt: React.FC<Props> = observer((props) => {
    const { email: oldEmail, paymentId, isOnlyInputVisible, store } = props
    const {status, setStatus, onFocus} = store
    const [isInputVisible, setIsInputVisible] = useState(isOnlyInputVisible || !oldEmail)
    const [newEmail, setNewEmail] = useState(isOnlyInputVisible ? oldEmail : '')

    const send = async (email: string) => {
        if (!email || !validateEmailAndItsDomain(email) || status === 'loading') return

        setStatus('loading')
        const url = `${ConfigStore.getUrl().apiUrl}/v1/payments/${paymentId}/receipts`
        try {
            const { error } = await postWithToken<any>(url, { email })
            if (error) throw new Error(error?.message)
            setStatus('success')
        } catch {
            setStatus('error')
        }
    }

    return (
        <div className={styles.root}>
            {
                status === 'success' ? (
                    <p className={styles.text}>{translations().successText}</p>
                ) : (
                    isInputVisible ? (
                        <>
                            {!isOnlyInputVisible && oldEmail && (
                                <Link className={styles.back} onClick={() => setIsInputVisible(false)}>
                                    <LeftArrow color={StyleStore.style.primaryColor} /> {translations().back}
                                </Link>
                            )}
                            <Input
                                type='email'
                                className={styles.input}
                                label={translations().label}
                                placeholder={translations().placeholder}
                                notFloatingLabel={true}
                                showPlaceholderWhenNotFocus={true}
                                onChange={ (e) => setNewEmail(e.target.value) }
                                onFocus={onFocus}
                                value={newEmail}
                                renderIcon={() => (
                                    status === 'loading' ?
                                        <ThreeDotsLoader className={styles.loader} backgroundColor={StyleStore.style?.primaryColor || StyleStore.getDefaultStyle()?.primaryColor}/> :
                                        <span
                                            className={styles.button}
                                            onClick={() => {
                                                send(newEmail)
                                                onFocus()
                                            }}
                                        >
                                            {translations().inputButton}
                                        </span>
                                )}
                                errorText={newEmail && !validateEmailAndItsDomain(newEmail) ? translations().invalidEmail : ''}
                            />
                        </>
                    ) : (
                        <>
                            <p className={styles.text}>{translations().text} {oldEmail}</p>
                            { status === 'loading' ? <ThreeDotsLoader backgroundColor={ StyleStore.style?.primaryColor || StyleStore.getDefaultStyle()?.primaryColor }/>
                                : <ActionButton
                                    colorStyle={ StyleStore.style }
                                    onClick={() => send(oldEmail)}
                                >
                                    {translations().button}
                                </ActionButton>
                            }
                            <a
                                href='#'
                                onClick={(e) => {
                                    e.preventDefault()
                                    setIsInputVisible(true)
                                    setStatus(null)
                                }}
                                className={styles.link}
                            >
                                {translations().link}
                            </a>          
                        </>
                    )
                )
            }
            { status === 'error' && <p className={styles.error}>{translations().errorText}</p> }
        </div>
    )
})
