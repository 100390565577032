import translations from './translations'

translations.add('is', {
    back: 'til baka',
    label: 'Senda greiðslukvittun til',
    placeholder: 'Netfang',
    inputButton: 'Senda',
    text: 'Greiðslukvittun verður send til',
    link: 'Senda á annað netfang',
    button: 'Senda kvittun',
    invalidEmail: 'netfang er ógilt',
    successText: 'Tókst að senda greiðslukvittun.',
    errorText: 'Ekki tókst að senda greiðslukvittunina, vinsamlegast reyndu aftur síðar.'
})
