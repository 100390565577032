// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Su2zMxhTEY4XernXpuSqJ{width:100%;height:46px}", ""]);
// Exports
exports.locals = {
	"GooglePay": "_3Su2zMxhTEY4XernXpuSqJ"
};
module.exports = exports;
