import translations from './translations'

translations.add('en', {
    yourClickToPayPaymentUnsuccessful: 'Your Click To Pay payment was unsuccessful. Please try again later',
    makeSureEmailIsCorrect: 'Please make sure your email is correct and try again',
    unknownError: 'Unknown error, please contact Mastercard',
    errors: {
        signOut: 'Unbind was unsuccessful!'
    },
    messages: {
        signOut: 'Successfully unbinded!'
    }
})
