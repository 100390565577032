import _ from 'lodash'
import { apiCheck, noThrow } from 'back-connector'
import { action, computed, observable, runInAction } from 'mobx'
import { ConfigStore, LogStore } from '~/code/config'
import { Page, TransactionType } from '~/code/models'
import { addListener, getWithToken, postWithToken } from '~/code/services'
import * as errorCodes from '~/code/config/LogStore'
import { listenPopupReturn, openPopupWindow } from '~/code/services/window-utils'
import { fetchTransactionStatus } from '~/code/services/transaction'
import { EcospendBankModel, EcospendParentStore, EcospendBankResponseModel, EcospendReturnPageQueryModel, EcospendCreateOrderResponseModel } from './models'
import { convertTransactionToEcospendStatus, isStatusOk } from './services'

export class EcospendStore {

    constructor(public parentStore: EcospendParentStore) {
        addListener('storage', this.listener, window)
    }

    popupWindow: Window = null
    isResultSet = false

    @observable
    isCheckModalOpen = false

    @observable
    isCheckModalLoading = false

    @observable
    isBanksLoaded: boolean = false

    @observable
    isLoading: boolean = false

    @observable
    isCreatingOrder: boolean = false

    @observable
    createOrderResponse: EcospendCreateOrderResponseModel = null

    @observable
    selectedBank: EcospendBankModel = null

    @observable
    banks: EcospendBankModel[] = []

    @observable
    searchText: string = ''

    @observable
    errorText: string = ''

    @observable
    isPaymentSuccessful: boolean = null

    @observable
    isProcessingPayment: boolean = false

    @observable
    result: EcospendReturnPageQueryModel = null

    tryAgain = () => {
        this.back()
        this.parentStore.tryAgain()
    }

    @action
    setSearchText(text: string) {
        this.searchText = text
    }

    @action
    setSelectedBank(bank?: EcospendBankModel) {
        this.selectedBank = bank
    }

    @action
    back() {
        this.selectedBank = null
        this.createOrderResponse = null
        this.result = null
        this.searchText = ''
        this.isResultSet = false
        this.isCheckModalOpen = false
    }

    @action
    startPaymentProcessing() {
        this.isProcessingPayment = true
        this.parentStore.startPaymentProcessing()
    }

    @action
    stopPaymentProcessing() {
        this.isProcessingPayment = false
        this.parentStore.stopPaymentProcessing(this.isPaymentSuccessful)
    }

    cancel() {
        this.back()
    }

    @action
    setResult(result: EcospendReturnPageQueryModel) {
        if (this.isResultSet) {
            return
        }
        this.isResultSet = true

        let { message } = result
        if (message) {
            message = message.replace(/\+/g, ' ')
        }
        this.result = { ...result, message }
        if (isStatusOk(result.status)) {
            this.parentStore.currentPageName = Page.ECOSPEND_SUCCESS
            this.parentStore.redirectToReturnUrl(true)
            this.isPaymentSuccessful = true
        } else {
            this.parentStore.currentPageName = Page.ECOSPEND_FAILURE
            this.parentStore.decreaseAllowedAttempts()
            this.isPaymentSuccessful = false
        }

        if (this.popupWindow) {
            this.popupWindow.close()
        }
    }

    @action
    async createOrder() {
        const result = await noThrow(apiCheck(postWithToken<EcospendCreateOrderResponseModel>(`${ ConfigStore.getUrl().apiUrl }/payments/alternative/createOrder`, this.order)))
        this.isCreatingOrder = false
        if (result.error || !result?.value?.success) {
            LogStore.error(errorCodes.ECOSPEND_CAN_NOT_CREATE_ORDER)
            this.parentStore.handleFailureResult({
                // @ts-ignore
                code: result.error ? result.error.errorCode : result.value?.errorCode
            })
            return
        }
        this.createOrderResponse = result.value
    }

    @action
    async fetchBanks() {
        this.isLoading = true
        const result = await noThrow(apiCheck(getWithToken<EcospendBankResponseModel>(`${ConfigStore.getUrl().apiUrl}/v1/ecospend/banks`)))
        this.isLoading = false

        if (result.error || !result.value) {
            this.errorText = 'Could not fetch bank list'
        }

        this.banks = result.value.data
        this.isBanksLoaded = true
    }

    async openPopupWindow() {
        if (!this.createOrderResponse) return
        const { bankUrl } = this.createOrderResponse

        this.startPaymentProcessing()
        this.setCheckModalOpen(true)
        this.isResultSet = false
        this.popupWindow = openPopupWindow(bankUrl, 'ecospend-popup-window', this.onPopupWindowClose)

        const { data } = await listenPopupReturn<EcospendReturnPageQueryModel>(this.popupWindow, this.returnUrl)
        this.setResult(data)
    }

    @action
    onPopupWindowClose = () => {
        this.stopPaymentProcessing()
        this.popupWindow = null
    }

    listener = (event: StorageEvent) => {
        let data: EcospendReturnPageQueryModel
        try {
            data = JSON.parse(event.newValue)
        } catch (exception) {
            return
        }

        if (event.key !== 'ecospend-return-data' || !data || data.payment_id !== this.createOrderResponse?.rrn) return

        this.setResult(data)
        localStorage.removeItem('ecospend-return-data')
    }

    returnToMerchantSite = () => {
        if (!this.hasMerchantLink) return
        if (isStatusOk(this.result.status)) {
            this.parentStore.redirectToReturnUrl(false)
        } else {
            this.parentStore.redirectToFailureReturnUrl()
        }
    }

    checkTransactionStatus = async () => {
        runInAction(() => this.isCheckModalLoading = true)
        const { id, invoiceId } = this.createOrderResponse

        const state = await fetchTransactionStatus(id)
        if (state) {
            this.setResult({
                key: 'ecospend-return-data',
                bank_reference_id: null,
                payment_id: invoiceId,
                status: convertTransactionToEcospendStatus(state)
            })
        }
        this.setCheckModalOpen(false)
        runInAction(() => this.isCheckModalLoading = false)
    }

    @action
    setCheckModalOpen(isOpen: boolean) {
        this.isCheckModalOpen = isOpen
    }

    @computed
    get hasMerchantLink() {
        return Boolean(this.paymentData?.paymentSettings?.returnUrl)
    }

    @computed
    get filteredBanks() {
        return this.banks.filter((bank) => bank.name.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) >= 0)
    }

    @computed
    get paymentData() {
        return this.parentStore?.paymentData
    }

    @computed
    get payee() {
        return ConfigStore.merchantName
    }

    get returnUrl() {
        return `${ConfigStore.getUrl().paymentPageUrl}/ecospend-return.html`
    }

    @computed
    get order() {
        const paymentData = this.paymentData
        const customerDetails = paymentData?.customerDetails
        return {
            bankId: this.selectedBank?.bank_id,
            amount: paymentData.amount,
            currency: paymentData.currency,
            invoiceId: paymentData.invoiceId,
            description: paymentData.description,
            taxAmount: paymentData.taxAmount,
            accountId: customerDetails?.accountDetails?.accountId,
            email: customerDetails?.email,
            locale: paymentData.language,
            terminalId: paymentData?.paymentSettings?.terminalId,
            paymentMethod: 'ecospend',
            transactionType: paymentData.transactionType ||  TransactionType.SALE,
            returnUrl: this.returnUrl,
            backLink: this.paymentData?.paymentSettings?.returnUrl,
            failureBackLink: this.paymentData?.paymentSettings?.failureReturnUrl,
            callbackUrl: this.paymentData?.paymentSettings?.callbackUrl,
            failureCallbackUrl: this.paymentData?.paymentSettings?.failureCallbackUrl,
            billingAddress: this.billingAddress
        }
    }

    @computed
    public get billingAddress() {
        const customerDetails = this.paymentData?.customerDetails
        const obj = {
            firstName: customerDetails?.billingAddress?.firstName,
            lastName: customerDetails?.billingAddress?.lastName,
            streetAddress1: customerDetails?.billingAddress?.addressLine1,
            streetAddress2: customerDetails?.billingAddress?.addressLine2,
            postalCode: customerDetails?.billingAddress?.postalCode,
            city: customerDetails?.billingAddress?.city,
            phone: customerDetails?.billingAddress?.phone,
            country: customerDetails?.billingAddress?.country
        }
        const isEmpty = !_.some(obj)
        return isEmpty ? null : obj
    }
}
