export enum TransactionType {
    AUTH = 'AUTH',
    RETAIL = 'RETAIL',
    VERIFICATION = 'VERIFICATION',
    RECURRING = 'RECURRING',
    P2P = 'P2P',
    TOKENIZATION = 'TOKENIZATION',
    PAYBYLINK = 'PAYBYLINK',
    SALE = 'SALE',
    BILLPT = 'BILLPT',
    PAYMT = 'PAYMT',
    SMB = 'SMB'
}
