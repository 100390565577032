import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { PaypalProps } from './props'
import styles from './Paypal.scss'
import { PAYPAL_BUTTON_CONTAINER_ID } from '~/code/pages/Paypal/constants/constants'

export const PayPal = observer( (props: PaypalProps) => {

    const buttonContainer = useRef(null)
    const { store } = props

    useEffect(() => {
        store.loadPayPalScript(buttonContainer.current)
    }, [])

    return (
        <div className={ styles.Paypal }>
            <div id={ PAYPAL_BUTTON_CONTAINER_ID } className={ styles.PaypalButton }  ref={buttonContainer}/>
        </div>
    )
})
