import { action, computed, IComputedValue, observable } from 'mobx'
import { Language } from '~/code/components/Translation/types'
import { Translation } from '~/code/components/Translation/types/Translation'
import { DEFAULT_LANGUAGE } from './constants'


// import storage from '~/services/storage' // TODO connect with storage

class TranslationStore {

    constructor() {
        this._lang = DEFAULT_LANGUAGE /*storage.get('lang') ||*/
    }

    @observable
    private _lang: Language

    @computed
    public get lang() {
        return this._lang
    }

    @action
    public setLang(value) {
        // storage.set('lang', value) TODO connect with storage
        this._lang = value || DEFAULT_LANGUAGE
    }

    public create<T>(): Translation<T> {

        const map = new Map<string, T>()

        const current: IComputedValue<T> = computed(() => map.get(this._lang) || map.get('en'))

        const translation: Translation<T> = () => current.get()

        translation.add = (lang, value) => {
            map.set(lang, value)
            return translation
        }

        return translation
    }
}

const translationBuilder = new TranslationStore()

export { translationBuilder as TranslationBuilder }
