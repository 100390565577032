import { PaymentMethod, WidgetMode } from '~/code/models'

export default {
    // AUTH URL
    authUrl: 'https://pay.dnapayments.com/checkout/oauth',
    testAuthUrl: 'https://test-pay.dnapayments.com/checkout/oauth',
    localAuthUrl: '/oauth',

    // PAYMENT PAGE URL
    devPaymentPageUrl : 'https://dev-pay.dnapayments.com/checkout',
    testPaymentPageUrl : 'https://test-pay.dnapayments.com/checkout',
    paymentPageUrl : 'https://pay.dnapayments.com/checkout',
    localPaymentPageUrl : (IS_HTTPS_LOCALLY === true ? 'https' : 'http') + '://localhost:' + LOCAL_SERVER_PORT,

    // API URL
    apiUrl: 'https://pay.dnapayments.com/checkout/api',
    testApiUrl: 'https://test-pay.dnapayments.com/checkout/api',
    localApiUrl: '/api',

    defaultTermsAndConditionsLink: 'https://dnapayments.com/page/terms-use',
    grant_type: 'client_credentials',
    basicAuthKey: 'ZxkprRr9zXBtUcgW4zvKUt4pkA3bVhAd3TbU2Rtq',
    scope: 'payment',
    testUserInfo: {
        client_id: 'Test Merchant',
        client_secret: 'PoF84JqIG8Smv5VpES9bcU31kmfSqLk8Jdo7',
        terminal: '8911a14f-61a3-4449-a1c1-7a314ee5774c',
        currency: 'GBP'
    },
    defaultAutoCloseModalTime: 30000,
    defaultStyle: {
        primaryColor: '#6c609e',
        headerGradientLinearDegree: '169deg',
        headerStartColor: '#1d1737',
        headerFinishColor: '#1d1737',
        headerLogoUrl: 'logo.svg',
        headerLogoMobileWidth: 188,
        headerLogoMobileHeight: 35,
        headerLogoDesktopWidth: 188,
        headerLogoDesktopHeight: 60,

        headerSecondaryLogoMobileWidth: 90,
        headerSecondaryLogoMobileHeight: 37,
        headerSecondaryLogoDesktopWidth: 90,
        headerSecondaryLogoDesktopHeight: 37,
        headerSecondaryLogoUrl: '',
        headerSecondaryLogoPosition: '',
        headerLogoTextColor: '#FFFFFF',
        headerLogoTextFontSize: 20,
        headerBottomLineHeight: 6,
        headerBottomLineBackgroundColor: '#000000',
        paymentPageBackgroundColor: '#ffffff',
        paymentPageBackgroundImageUrl: null,
        actionButtonBackground: '#FACF1A',
        actionButtonColor: '#000000',
        actionButtonBorderRadius: 24,
        closeButtonColor: '#FFFFFF',
        closeButtonOpacity: 0.3,
        showHeaderBottomLine: false,
        showOrderNumberBlock: true,
        showOrderDescriptionBlock: true,
        showPoweredByBlock: true,
        showPaymentSecuredByBlock: true,
        showIssueFeedbackBlock: true, // TODO: change to false
        showTestLabel: true,
        embeddedWidget: {
            primaryColor: '#4BB9AA',
            actionButtonBackground: '#FACF1A',
            actionButtonColor: '#000000',
            actionButtonBorderRadius: 0,
            showPoweredByBlock: true
        }
    },
    defaultVersion: 1,
    defaultAllowedAttempts: 3,
    defaultEmbeddedWidget: {
        container: '#dna-embedded-widget-container',
        orderButtonText: 'Order and Pay',
        widgetType: WidgetMode.EMBEDDED
    },
    defaultPaymentMethods: [],
    defaultMaxVisiblePaymentMethods: 5,
    defaultDefaultMerchantPollInterval: 5000
}
