import React from 'react'
import { observer } from 'mobx-react'
import { Button, Input, Select } from '~/code/components'
import { InputField, InputFieldType } from './models'
import { OrderPersonalInfoProps } from './props'
import translations from './translations'
import styles from './OrderPersonalInfo.scss'
import { StyleStore } from '~/code/config'
import { TransactionType } from '~/code/models'
import MaskedInput from 'react-text-mask'
import _ from 'lodash'

@observer
export class OrderPersonalInfo extends React.Component<OrderPersonalInfoProps> {
    private debouncedOnPay: any

    constructor (props) {
        super(props)
        this.debouncedOnPay = _.debounce(props.store.onPay.bind(props.store), 1000, { leading: true, trailing: false })
    }

    renderSelect(name, inputObject: InputField) {
        return (
            <Select
                placeholder={ translations()[inputObject.labelCode] }
                isSearchable={ true }
                options={ inputObject.options }
                value={ inputObject.value }
                onChange={ (selectedValue) => {
                    inputObject.errorCode = inputObject.validate(selectedValue)
                    inputObject.value = selectedValue
                } }
                onMenuClose={ (event) => {
                    inputObject.errorCode = inputObject.validate(inputObject.value)
                } }
                errorText={ translations()[inputObject.errorCode] }
            />
        )
    }

    renderTextInput(name, inputObject: InputField) {
        return (
            <div> {
                <Input
                    label={ translations()[inputObject.labelCode] }
                    onChange={ (event) => {
                        inputObject.errorCode = inputObject.validate(event.target.value)
                        inputObject.value = event.target.value
                    } }
                    onBlur={ () => {
                        inputObject.errorCode = inputObject.validate(inputObject.value)
                    } }
                    value={ inputObject.value }
                    errorText={ translations()[inputObject.errorCode] }
                    placeholder={ translations()[inputObject.placeholderCode] }
                />
            } </div>
        )
    }

    renderMaskedInput(name, inputObject: InputField) {
        const { value } = inputObject
        return (
            <div>
                <MaskedInput
                    mask={ inputObject.mask }
                    guide={ false }
                    onChange={ (event) => {
                        inputObject.errorCode = inputObject.validate(event.target.value)
                        inputObject.value = event.target.value
                    } }
                    onBlur={ () => inputObject.errorCode = inputObject.validate(value.value) }
                    render={ (ref, props) => {
                        return (
                            <Input
                                ref={ ref }
                                { ...props }
                                label={ translations().mobilePhone }
                                errorText={ translations()[inputObject.errorCode] }
                                renderIcon={ () => <></> }
                                value={ inputObject.value }
                            />
                        )
                    }}
                />
            </div>
        )
    }

    renderFields (name, inputObject: InputField) {
        switch (inputObject.type) {
            case InputFieldType.Select:
                return this.renderSelect(name, inputObject)
            case InputFieldType.Masked:
                return this.renderMaskedInput(name, inputObject)
            case InputFieldType.Label:
                return <div className={ styles.label }>{ translations()[inputObject.labelCode] }</div>
            default:
                return this.renderTextInput(name, inputObject)
        }
    }

    renderButtonTitle = () => {
        const { store } = this.props
        if (store.transactionType === TransactionType.TOKENIZATION ||
                store.transactionType === TransactionType.VERIFICATION) {
            return translations().submit
        }
        return translations().pay
    }

    render() {
        const { store } = this.props
        return (
            <div className={ styles.OrderPersonalInfo } ref={ store.container }>
                <div className={ styles.MainTitle }>
                    {
                        translations().moreDetails
                    }
                </div>
                <div className={styles.fields}>
                    { Object.entries(store.fields).map(([key, value]) => {
                        return (
                            <div key={ key }>
                                { value.isVisible && this.renderFields(key, value) }
                            </div>
                        )
                    })}
                </div>
                <div className={ styles.Action }>
                    <div>
                        <div className={styles.buttonContainer}>
                            <Button colorStyle={ StyleStore.style } onClick={ () => this.debouncedOnPay() }>
                                { this.renderButtonTitle() }
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount (): void {
        const { store } = this.props
        store.focusOnFirstInput()
    }
}
