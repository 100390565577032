import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { ErrorBoundary } from 'react-error-boundary'
import classNames from 'classnames'
import * as errorCodes from '~/code/config/LogStore'
import { ConfigStore, LogStore, StyleStore } from '~/code/config'
import { PayPal } from '~/code/pages/Paypal'
import { BankCardPaymentMethodItem, SimplePaymentMethodItem } from '~/code/components'
import { PaymentMethod } from '~/code/models'
import { RightArrow } from '~/assets/icons'
import { Processing } from '~/code/pages'
import { PayByBank } from '~/code/pages/PayByBank'
import { GooglePay } from '~/code/pages/GooglePay/GooglePay'
import { ApplePay } from '~/code/pages/ApplePay'
import { EcospendButton } from '~/code/pages/Ecospend'
import { Klarna } from '~/code/pages/Klarna'
import translations from './translations'
import { PaymentMethodListProps } from './props'
import styles from './PaymentMethodList.scss'
import { AstroPayButton } from '~/code/pages/AstroPay/components'
import { AlipayWeChatPay } from '~/code/pages/AlipayWeChatPay'
import { ClickToPayComponent } from '~/code/pages/ClickToPayComponent'
import { OTHER_PAYMENT_METHODS } from '~/code/constants/playwright-ids'
import { error } from '~/code/services/logger'

export const PaymentMethodList = observer((props: PaymentMethodListProps) => {
    const activePaymentMethods = ConfigStore.activePaymentMethods()
    const { maxItems = activePaymentMethods.length } = props

    // if we have both BankCard and ClickToPay, we should show only BankCard
    // check if activePaymentMethods contains BankCard
    if (activePaymentMethods.some((el) => el.name === PaymentMethod.BankCard)){
        // remove click to pay from activePaymentMethods
        const clickToPayIndex = activePaymentMethods.findIndex((el) => el.name === PaymentMethod.ClickToPay)
        if (clickToPayIndex !== -1) {
            activePaymentMethods.splice(clickToPayIndex, 1)
        }
    }

    const hiddenItems = activePaymentMethods.slice(maxItems)
    const showItems = activePaymentMethods.slice(0, maxItems)
    const [ isOpenHiddenItems, setOpenHiddenItems ] = useState(false)

    const renderBankCardItem = (item, isVisible) => {
        return (
            <BankCardPaymentMethodItem
                className={ classNames(
                    styles.PaymentMethodItem,
                    {
                        [styles.Visible]: isVisible,
                        [styles.Hidden]: !isVisible
                    })
                }
                onClick={ () => props.onSelectItem(PaymentMethod.BankCard) }
                message={ item.message }
                key={ item.name }
                store={props.cardDataStore}
            />
        )
    }

    const renderEcospendItem = (item, isVisible) => {
        if (!isVisible) return null

        return (
            <ErrorBoundary
                fallbackRender={ () => null }
                onError={ (err) =>  {
                    LogStore.error(errorCodes.METHOD_LIST_ECOSPEND, null, err, true)
                } }
                key={ item.name }
            >
                <EcospendButton
                    name={item.name}
                    store={props.ecospendStore}
                    onClick={() => props.onSelectItem(PaymentMethod.Ecospend)}
                />
            </ErrorBoundary>
        )
    }

    const renderAstroPayPage = (item, isVisible) => {
        if (!isVisible) return null

        const onClick = async () => {
            await props.astroPayStore?.createOrder()
            props.onSelectItem(PaymentMethod.AstroPay)
        }
        
        return (
            <ErrorBoundary
                fallbackRender={ () => null }
                onError={ (err) =>  {
                    LogStore.error(errorCodes.METHOD_LIST_ASTROPAY, null, err, true)
                } }
                key={ item.name }
            >
                <AstroPayButton
                    name={item.name}
                    onClick={onClick}
                />
            </ErrorBoundary>
        )
    }

    const renderKlarnaButton = (item, isVisible) => {
        if (!isVisible) return null

        return (
            <ErrorBoundary
                fallbackRender={ () => null }
                onError={ (err) =>  {
                    LogStore.error(errorCodes.METHOD_LIST_KLARNA, null, err, true)
                } }
                key={ item.name }
            >
                <Klarna store={props.klarnaStore} onClick={() => { props.onSelectItem(PaymentMethod.Klarna) }}/>
            </ErrorBoundary>
        )
    }

    const renderPaypalPage = (item, isVisible) => {
        const isLoading = props.paypalStore?.isLoading
        return (
            <ErrorBoundary
                fallbackRender={ () => null }
                onError={ (err) =>  {
                    LogStore.error(errorCodes.METHOD_LIST_PAYPAL, null, err, true)
                } }
                key={ item.name }
            >
                <div data-id={'paypal-button'}
                    className={ classNames(
                        styles.PaymentMethodItem,
                        {
                            [styles.Visible]: isVisible,
                            [styles.Hidden]: !isVisible
                        } ) }>
                    {
                        isLoading && (
                            <div>
                                <SimplePaymentMethodItem
                                    className={ classNames(
                                        styles.PaymentMethodItem,
                                        {
                                            [styles.Visible]: isVisible,
                                            [styles.Hidden]: !isVisible
                                        })
                                    }
                                    name={ PaymentMethod.PayPal }
                                    onClick={ () => null }
                                    image={ 'paypal.png' }
                                    isLoading={ true }
                                />
                            </div>
                        )
                    }
                    <div className={ classNames(
                        styles.PaymentMethodButton,
                        {
                            [styles.Hidden]: isLoading,
                            [styles.Visible]: !isLoading
                        })
                    }>
                        <PayPal store={ props.paypalStore } />
                    </div>
                </div>
            </ErrorBoundary>
        )
    }

    const renderPayByBankPage = (item, isVisible) => {
        const isLoading = props.payByBankStore?.isLoading
        return (
            <ErrorBoundary
                fallbackRender={ () => null }
                onError={ (err) =>  {
                    LogStore.error(errorCodes.METHOD_LIST_PBBA, null, err, true)
                } }
                key={ item.name }
            >
                <div data-id={'pay-by-bank-button'}
                     className={ classNames(
                    styles.PaymentMethodItem,
                    styles.PayByBankPaymentMethodItem,
                    styles.PayByBankFullPaymentMethodItem,
                    {
                        [styles.Visible]: isVisible,
                        [styles.Hidden]: !isVisible
                    }) }>
                    {
                        isLoading && (
                            <div>
                                <SimplePaymentMethodItem
                                    className={ classNames(
                                        styles.PaymentMethodItem,
                                        {
                                            [styles.Visible]: isVisible,
                                            [styles.Hidden]: !isVisible
                                        })
                                    }
                                    name={ PaymentMethod.PayByBank }
                                    onClick={ () => null }
                                    image={ 'pbba.png' }
                                    isLoading={ true }
                                />
                            </div>
                        )
                    }
                    <div className={ classNames(
                        styles.PaymentMethodButton,
                        {
                            [styles.Hidden]: isLoading,
                            [styles.Visible]: !isLoading
                        })
                    }>
                        <PayByBank
                            store={ props.payByBankStore }
                            isFullMode={ false }
                            onClick={() => { props.onSelectItem(PaymentMethod.PayByBank) }}
                            onSuccess={ () => {
                                props?.payByBankStore?.parentStore?.handleSuccessResult()
                            } }
                            onFailure={ (e) => {
                                props?.payByBankStore?.parentStore?.handleFailureResult(e)
                            } }
                        />
                    </div>
                </div>
            </ErrorBoundary>
        )
    }

    const renderGooglePayPage = (item, isVisible) => {
        const isLoading = props.googlePayStore?.isLoading
        return  (
            <ErrorBoundary
                fallbackRender={ () => null }
                onError={ (err) =>  {
                    LogStore.error(errorCodes.METHOD_LIST_GOOGLEPAY, null, err, true)
                } }
                key={ item.name }
            >
                <div data-id={'google-pay-button'}
                    className={ classNames(styles.PaymentMethodItem, {
                            [styles.Visible]: isVisible,
                            [styles.Hidden]: !isVisible
                        }
                    )
                }>
                    {
                        isLoading && (
                            <div>
                                <SimplePaymentMethodItem
                                    className={ classNames(styles.PaymentMethodItem) }
                                    name={ PaymentMethod.GooglePay }
                                    onClick={ () => null }
                                    isLoading={ true }
                                />
                            </div>
                        )
                    }
                    <div className={ classNames(styles.PaymentMethodButton,
                        {
                            [styles.Visible]: !isLoading,
                            [styles.Hidden]: isLoading
                        }
                    )}>
                        <GooglePay
                            store={props.googlePayStore}
                            isFullMode={false}
                            onSuccess={null}
                            onFailure={null}
                            isVisible={!isLoading}
                            onClick={() => { props.onSelectItem(PaymentMethod.GooglePay) }}
                        />
                    </div>
                </div>
            </ErrorBoundary>
        )
    }

    const renderApplePayPage = (item, isVisible) => {
        const isLoading = props.applePayStore?.isLoading
        return (
            <ErrorBoundary
                fallbackRender={ () => null }
                onError={ (err) =>  {
                    LogStore.error(errorCodes.METHOD_LIST_APPLEPAY, null, err, true)
                } }
                key={ item.name }
            >
                <div data-id={'apple-pay-button'}
                     className={ classNames(styles.PaymentMethodItem,
                    {
                        [styles.Visible]: isVisible,
                        [styles.Hidden]: !isVisible
                    })}>
                    {
                        isLoading && (
                            <div>
                                <SimplePaymentMethodItem
                                    className={ classNames(styles.PaymentMethodItem) }
                                    name={ PaymentMethod.ApplePay }
                                    onClick={ () => null }
                                    isLoading={ true }
                                />
                            </div>
                        )
                    }
                    <div className={ classNames(styles.PaymentMethodButton,
                        {
                            [styles.Visible]: !isLoading,
                            [styles.Hidden]: isLoading
                        }
                    )}>
                        <ApplePay
                            store={props.applePayStore}
                            onSuccess={null}
                            onFailure={null}
                            onClick={() => {props.onSelectItem(PaymentMethod.ApplePay)}}
                            />
                    </div>
                </div>
            </ErrorBoundary>
        )
    }

    const renderAlipayWeChatPayPage = (item, isVisible, paymentMethod: PaymentMethod) => {
        let paymentMethodStore = null
        switch (paymentMethod) {
            case PaymentMethod.Alipay:
                paymentMethodStore = props.alipayStore
                break
            case PaymentMethod.AlipayPlus:
                paymentMethodStore = props.alipayPlusStore
                break
            case PaymentMethod.WeChatPay:
                paymentMethodStore = props.weChatPayStore
                break
        }
        const isLoading = paymentMethodStore?.isLoading
        return (
            <ErrorBoundary
                fallbackRender={ () => null }
                onError={ (err) =>  {
                    LogStore.error(errorCodes.METHOD_LIST_PBBA, null, err, true)
                } }
                key={ item.name }
            >
                <div data-id={paymentMethod + '-button'}
                     className={ classNames(
                         styles.PaymentMethodItem,
                         {
                             [styles.Visible]: isVisible,
                             [styles.Hidden]: !isVisible
                         }) }>
                    {
                        isLoading && (
                            <div>
                                <SimplePaymentMethodItem
                                    className={ classNames(
                                        styles.PaymentMethodItem,
                                        {
                                            [styles.Visible]: isVisible,
                                            [styles.Hidden]: !isVisible
                                        })
                                    }
                                    name={ paymentMethod }
                                    onClick={ () => null }
                                    isLoading={ true }
                                />
                            </div>
                        )
                    }
                    <div className={ classNames(
                        styles.PaymentMethodButton,
                        {
                            [styles.Hidden]: isLoading,
                            [styles.Visible]: !isLoading
                        })
                    }>
                        <AlipayWeChatPay
                            store={ paymentMethodStore }
                            isFullMode={ false }
                            onClick={() => { props.onSelectItem(paymentMethod) }}
                            onSuccess={ () => {
                                paymentMethodStore?.parentStore?.handleSuccessResult()
                            } }
                            onFailure={ (e) => {
                                paymentMethodStore?.parentStore?.handleFailureResult(e)
                            } }
                        />
                    </div>
                </div>
            </ErrorBoundary>
        )
    }

    const renderClickToPayPage = (item, isVisible) => {
        const isLoading = props.clickToPayComponentStore?.isLoading
        return (
            <ErrorBoundary
                fallbackRender={ () => null }
                onError={ (err) =>  {
                    LogStore.error(errorCodes.METHOD_LIST_PBBA, null, err, true)
                } }
                key={ item.name }
            >
                <div data-id={'click-to-pay-button'}
                     className={ classNames(
                         styles.PaymentMethodItem,
                         {
                             [styles.Visible]: isVisible,
                             [styles.Hidden]: !isVisible
                         }) }>
                    {
                        isLoading && (
                            <div>
                                <SimplePaymentMethodItem
                                    className={ classNames(
                                        styles.PaymentMethodItem,
                                        {
                                            [styles.Visible]: isVisible,
                                            [styles.Hidden]: !isVisible
                                        })
                                    }
                                    name={ PaymentMethod.ClickToPay }
                                    onClick={ () => null }
                                    isLoading={ true }
                                />
                            </div>
                        )
                    }
                    <div className={ classNames(
                        styles.PaymentMethodButton,
                        {
                            [styles.Hidden]: isLoading,
                            [styles.Visible]: !isLoading
                        })
                    }>
                        <ClickToPayComponent
                            store={ props.clickToPayComponentStore }
                            isFullMode={ false }
                            onClick={() => { props.onSelectItem(PaymentMethod.ClickToPay) }}
                            onSuccess={ () => {
                                props.clickToPayComponentStore?.parentStore?.handleSuccessResult()
                            } }
                            onFailure={ (e) => {
                                props.clickToPayComponentStore?.parentStore?.handleFailureResult(e)
                            } }
                        />
                    </div>
                </div>
            </ErrorBoundary>
        )
    }

    const isProcessingPayment = () => {
        return props.paypalStore?.isProcessingPayment || props.klarnaStore?.isProcessingPayment ||
            props.googlePayStore?.isProcessingPayment || props.applePayStore?.isProcessingPayment ||
            props.payByBankStore?.isProcessingPayment || props.ecospendStore?.isProcessingPayment ||
            props.astroPayStore?.isProcessingPayment || props.alipayStore?.isProcessingPayment ||
            props.weChatPayStore?.isProcessingPayment
    }

    const renderItem = (item, isVisible) => {
        switch (item.name) {
            case PaymentMethod.BankCard:
                return renderBankCardItem(item, isVisible)
            case PaymentMethod.Ecospend:
                return renderEcospendItem(item, isVisible)
            case PaymentMethod.PayPal:
                return renderPaypalPage(item, isVisible)
            case PaymentMethod.PayByBank:
                return renderPayByBankPage(item, isVisible)
            case PaymentMethod.ClickToPay:
                return renderClickToPayPage(item, isVisible)
            case PaymentMethod.GooglePay:
                return renderGooglePayPage(item, isVisible)
            case PaymentMethod.ApplePay:
                return renderApplePayPage(item, isVisible)
            case PaymentMethod.Klarna:
                return renderKlarnaButton(item, isVisible)
            case PaymentMethod.AstroPay:
                return renderAstroPayPage(item, isVisible)
            case PaymentMethod.Alipay:
                return renderAlipayWeChatPayPage(item, isVisible, PaymentMethod.Alipay)
            case PaymentMethod.AlipayPlus:
                return renderAlipayWeChatPayPage(item, isVisible, PaymentMethod.AlipayPlus)
            case PaymentMethod.WeChatPay:
                return renderAlipayWeChatPayPage(item, isVisible, PaymentMethod.WeChatPay)
            default:
                return null
        }
    }

    const renderItems = () => {
        if (props.paymentMethods && props.paymentMethods.length > 0) {
            return props.paymentMethods.map((el) => renderItem({
                name: el,
                message: null,
                isLoading: false
            }, true))
        } else {
            return <>
                {
                    showItems.map((el) => renderItem(el, true))
                }
                {
                    hiddenItems && hiddenItems.length > 0 && (
                        <>
                            {
                                hiddenItems.map((el) => renderItem(el, isOpenHiddenItems))
                            }
                            <div
                                className={ styles.ListVisibilityAction }
                                style={{
                                    color: StyleStore.style.primaryColor
                                }}
                            >
                                { isOpenHiddenItems ? (
                                    <div className={styles.closeOtherPaymentMethods} onClick={ () => setOpenHiddenItems(false) }> { translations().closePaymentMethods }</div>
                                ) : (
                                    <div data-test-id={OTHER_PAYMENT_METHODS} onClick={ () => setOpenHiddenItems(true) }>
                                        { translations().otherPaymentMethods } <RightArrow color={ StyleStore.style.primaryColor } />
                                    </div>
                                ) }
                            </div>
                        </>
                    )
                }
            </>
        }
    }

    return (
        <div className={ styles.PaymentMethodList }>
            <div className={ classNames(styles.PaymentMethodListContent, { [styles.Hidden]: isProcessingPayment() } ) }>
                { renderItems() }
            </div>
            <div className={ classNames(styles.Processing, { [styles.Visible]: isProcessingPayment() } )}>
                <Processing />
            </div>
        </div>
    )
})
