import { NotifyStatus } from '../models'

export function isFailureTransactionExcludeCancel (state) {
    return [NotifyStatus.FAILED, NotifyStatus.REJECT].includes(state)
}

export function isFailureTransaction (state) {
    return [NotifyStatus.FAILED, NotifyStatus.REJECT, NotifyStatus.CANCEL].includes(state)
}
export function isSuccessTransaction (state) {
    return [NotifyStatus.CHARGE, NotifyStatus.AUTH, NotifyStatus.REFUND].includes(state)
}
