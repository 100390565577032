import React from 'react'
import { observer } from 'mobx-react'
import styles from './IssueFeedback.scss'
import translations from './translations'

export const IssueFeedback = observer((props: { open: () => void, tryAgain: () => void }) => {
    return (
        <div className={ styles.IssueFeedback }>
            <div className={ styles.Message }>
                { translations().message(() => props.open(), () => props.tryAgain()) }
            </div>
        </div>
    )
})
