import React, { useRef, useState } from 'react'
import { Tooltip } from '~/code/components'

import { CopyIcon } from '~/assets/icons'

import translations from './translations'
import { CopyTextProps } from './props'
import styles from './CopyText.scss'

export const CopyText: React.FC<CopyTextProps> = (props) => {
    const { copyText, className = '', children, style = {} } = props
    const inputEl = useRef<HTMLInputElement>()
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const copy = () => {
        if (inputEl && inputEl.current) {
            inputEl.current.select()
            inputEl.current.setSelectionRange(0, 99999)
            document.execCommand('copy')
            setIsTooltipOpen(true)
            setTimeout(() => setIsTooltipOpen(false), 1200)
        }
    }

    return <span className={styles.copyText + ' ' + className} style={style}>
        <Tooltip placement={'top'} overlayClassName={styles.tooltip} tooltip={translations().copied} visible={isTooltipOpen}>
            <span onClick={copy}>
                { children || <i className={styles.copyTextIcon}><CopyIcon /></i> }
            </span>
        </Tooltip>
        <input className={styles.copyTextInput} value={ copyText } ref={inputEl} readOnly />
    </span>
}
