import { observer } from 'mobx-react-lite'
import React from 'react'

import CardData from '../CardData/CardData'
import { ClickToPayCardList, ClickToPayFrame, ClickToPayHeader, ClickToPayOtpChannel, ClickToPayOtpInput } from '../CardData/components/ClickToPay/components'
import { CardDataPageProps } from './props'
import styles from './styles.scss'
import { Input, Modal } from '~/code/components'
import { CardPaymentButton } from '~/code/pages/CardData/components/CardPaymentButton'
import translations from './translations'
import { InputFieldName } from '~/code/pages/OrderPersonalInfo/models'
import { CreditCardType } from '~/code/pages/CardData/models'
import classNames from 'classnames'
import { ConfigStore } from '~/code/config'
import { PaymentMethod } from '~/code/models'
import { getCardSchemeList } from '~/code/services/cards'

export const CardDataPage = observer((props: CardDataPageProps) => {
    const { clickToPayStore, cardDataStore, paymentData } = props
    const activePaymentMethods = ConfigStore.activePaymentMethods()

    const baseProps = {
        locale: ConfigStore.nonNullLocale,
        cardBrands: getCardSchemeList()
    }

    if (clickToPayStore.isPaymentProcessing) {
        return (
            <Modal
                classNames={{
                    content: classNames(
                        styles.checkoutModal,
                        {[styles.checkoutModalDiscover]: clickToPayStore.currentCardBrand === CreditCardType.DISCOVER}
                    )
                }}
                isOpen={clickToPayStore.isPaymentProcessing}
                onClose={() => {
                    clickToPayStore.stopPaymentProcessing()
                }}
            >
                <ClickToPayFrame
                    onSetWindow={(win) => clickToPayStore.setWindowRef(win) }
                    cardBrand={clickToPayStore.currentCardBrand}
                />
            </Modal>
        )
    }

    if (clickToPayStore.currentScreenName === 'cardsList') {
        return (
            <ClickToPayCardList
                cards={clickToPayStore.cards}
                cardBrands={getCardSchemeList()}
                onContinue={async (cardId) => {
                    if (cardId) {
                        cardDataStore.handleClickToPay(clickToPayStore.checkoutWithExistingCard(cardId), 'ClickToPayCardList')
                    } else {
                        clickToPayStore.setCurrentScreenName('cardData')
                    }
                }}
                onSignOut={() => {
                    clickToPayStore.signOut()
                }}
            />
        )
    }

    if (clickToPayStore.currentScreenName === 'emailOrPhone') {
        const emailField = clickToPayStore.fields[InputFieldName.AccountEmail]
        const { errorCode: emailErrorCode, value: emailValue } = emailField

        return <div>
            <div className={ styles.mainTitle }>
                {
                    translations().lookupCards
                }
            </div>
            <div className={ styles.secondaryTitle }>
                {
                    translations().pleaseEnterEmailOrPhone
                }
            </div>
            <Input
                label={ translations().email }
                onChange={(event) => {
                    emailField.onChange(event.target.value)
                } }
                onBlur={ () => {
                    emailField.validate()
                } }
                value={ emailValue }
                errorText={ translations()[emailErrorCode] }
                placeholder={ translations().email }
            />
            {
                clickToPayStore.idLookupErrorMessage &&
                <div className={ styles.error }>
                    { clickToPayStore.idLookupErrorMessage }
                </div>
            }
            {
                clickToPayStore.initiateValidationErrorMessage &&
                <div className={ styles.error }>
                    { clickToPayStore.initiateValidationErrorMessage }
                </div>
            }

            <div className={styles.buttonsContainer}>
                <CardPaymentButton
                    showAgreeCheckbox={ false }
                    hideAgreeLabel={ true }
                    onPay={ () => {
                        clickToPayStore.onBackFromInitiateValidation()
                    }}
                    disabled={clickToPayStore.isLookingUpId}
                    buttonText={translations().back}
                />
                <CardPaymentButton
                    buttonText={translations().lookup}
                    showAgreeCheckbox={ false }
                    hideAgreeLabel={ true }
                    onPay={() => clickToPayStore.validateAndSubmitLookupForm() }
                    disabled={clickToPayStore.isLookingUpId}
                />
            </div>
        </div>
    }

    if (clickToPayStore.currentScreenName === 'otp') {
        if (!clickToPayStore.validationChannelId) {
            return (
                <div>
                    <ClickToPayOtpChannel
                        {...baseProps}
                        channels={clickToPayStore.initiateValidationResponse?.supportedValidationChannels}
                        onContinue={(channelId) => {
                            if (channelId) {
                                clickToPayStore.initiateValidation(channelId)
                            } else {
                                clickToPayStore.clear()
                            }
                        }}
                    />
                </div>
            )
        }

        return (
            <div>
                <ClickToPayOtpInput
                    {...baseProps}
                    initiateValidationResponse={clickToPayStore.initiateValidationResponse}
                    errorReason={clickToPayStore.validateErrorReason}
                    onContinue={(otp) => {
                        clickToPayStore.setOtpValidationErrorMessage(null)
                        if (otp) {
                            clickToPayStore.validate(otp)
                        } else {
                            clickToPayStore.clearValidationChannelId()
                        }
                    }}
                    errorMessage={clickToPayStore.otpValidationErrorMessage}
                    isLoading={clickToPayStore.isSigningOut || clickToPayStore.isValidatingOTP}
                />
                <div className={styles.buttonsContainer}>
                    <CardPaymentButton
                        buttonText={translations().back}
                        showAgreeCheckbox={ false }
                        hideAgreeLabel={ true }
                        onPay={async () => {
                            clickToPayStore.setInitiateValidationResponse(null)
                            clickToPayStore.setCurrentScreenName('emailOrPhone')
                        }}
                        disabled={clickToPayStore.isSigningOut || clickToPayStore.isValidatingOTP}
                        />
                    <CardPaymentButton
                        buttonText={translations().signOut}
                        showAgreeCheckbox={ false }
                        hideAgreeLabel={ true }
                        onPay={async () => {
                            await clickToPayStore.signOut()
                            clickToPayStore.setInitiateValidationResponse(null)
                            clickToPayStore.setCurrentScreenName('emailOrPhone')
                        }}
                        disabled={clickToPayStore.isSigningOut || clickToPayStore.isValidatingOTP}
                    />
                </div>
            </div>
        )
    }

    return <>
        {
            activePaymentMethods?.some(item => item.name === PaymentMethod.ClickToPay) &&
            <ClickToPayHeader
                {...baseProps}
                className={styles.clickToPayHeader}
                onLookUp={() => {
                    if (clickToPayStore.cards && clickToPayStore.cards.length > 0) {
                        clickToPayStore.setCurrentScreenName('cardsList')
                        return
                    }
                    if (!clickToPayStore.recognizedType) {
                        clickToPayStore.setCurrentScreenName('emailOrPhone')
                        return
                    }
                    clickToPayStore.initiateValidation()
                }}
                isLoading={clickToPayStore.isLookingUpCards}
            />
        }
        <CardData
            paymentData={paymentData}
            store={ cardDataStore }
            clickToPayStore={ clickToPayStore }
        />
    </>
})
