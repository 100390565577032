import React from 'react'
import classNames from 'classnames'
import { ActionButton, Image } from '~/code/components'
import translations from './translations'
import { ConfigStore, LogStore, StyleStore } from '~/code/config'
import { PageType } from '~/code/models'
import { ErrorPageProps } from './props'
import styles from './ErrorPage.scss'

export default function (props: ErrorPageProps) {

    const hasActionButton = () => {
        return ConfigStore.hasReturnUrl()
    }

    const renderActionButton = () => {
        if (!hasActionButton()) {
            return null
        }
        return (
            <ActionButton
                className={ styles.ToBack }
                colorStyle={ StyleStore.style }
                onClick={ () => props.close() }>
                { translations().close }
            </ActionButton>
        )
    }

    return (
        <div className={ styles.ErrorPage }>
            <div className={ styles.Header }>
                <Image className={ styles.RejectIcon } src={ 'reject.svg' } />
            </div>
            <div className={ classNames(styles.Description, { [ styles.HasAction ]: hasActionButton() }) }>
                { props.children ? props.children : translations().description }
            </div>
            {
                LogStore.errorCode && (
                    <div className={ styles.ErrorCode }>
                        { `#${ LogStore.errorCode }`}
                    </div>
                )
            }
            { renderActionButton() }
        </div>
    )
}
