import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { PaymentMethodItemProps } from './props'
import styles from './PaymentMethodItem.scss'
import { PaymentMethodItemWrapper } from '~/code/components'

export const PaymentMethodItem =  observer((props: PaymentMethodItemProps) => {
    const renderContent = () => {
        return <>
            {
                props.renderItemHeader && (
                    <div className={ `${ styles.Header } ${ props.headerContainerClassName ? props.headerContainerClassName : '' }` }>
                        {  props.renderItemHeader() }
                    </div>
                )
            }
            <div className={ `${ styles.ContentWrapper } ${ props.contentContainerClassName ? props.contentContainerClassName : '' }`}>
                {
                    props.renderItemContent && (
                        <div className={ styles.Content }>
                            { props.renderItemContent() }
                        </div>
                    )
                }
                {
                    props.renderItemMessage && (
                        <div className={ `${ styles.Message } ${ props.messageContainerClassName ? props.messageContainerClassName : '' }` }>
                            { props.renderItemMessage() }
                        </div>
                    )
                }
            </div>
        </>
    }
    return (
        <PaymentMethodItemWrapper
            name={props.name}
            isLoading={props.isLoading}
            className={classNames(styles.PaymentMethodItem, props.className)}
            onClick={ props.onClick }
        >
            { renderContent() }
        </PaymentMethodItemWrapper>
    )
})
