import React from 'react'
import styles from './OrderInfoBlock.scss'

export default function (props: { firstDescription: string, secondaryDescription: string, className?: string }) {
    return (
        <div className={ styles.OrderInfoBlock + ' ' + (props.className || '') }>
            <div className={ styles.FirstDescription }>
                { props.firstDescription }
            </div>
            <div className={ styles.SecondaryDescription }>
                { props.secondaryDescription }
            </div>
        </div>
    )
}
