import { PaymentDataV2 } from '~/code/models'
import { AddressInfo } from '~/code/models/V2/AddressInfo'

export const getClickToPayAddress = (address: AddressInfo) => {
    return address ? {
        name: [address.firstName, address.lastName].filter((el) => el).join(' '),
        line1: address.addressLine1,
        line2: address.addressLine2 || '',
        line3: address.addressLine3 || '',
        city: address.city,
        state: address.region || '',
        countryCode: address.country,
        zip: address.postalCode
    } : undefined
}


export const getDpaTransactionOptions = (paymentData: PaymentDataV2, locale: string) => {
    return {
        transactionAmount: {
            transactionAmount: paymentData.amount,
            transactionCurrencyCode: paymentData.currency
        },
        consumerEmailAddressRequested: true,
        consumerPhoneNumberRequested: false,
        dpaAcceptedBillingCountries: [],
        dpaAcceptedShippingCountries: [],
        dpaBillingPreference: 'FULL',
        dpaLocale: locale,
        dpaShippingPreference: 'NONE',
        paymentOptions: [ { dynamicDataType: 'CARD_APPLICATION_CRYPTOGRAM_SHORT_FORM' } ],
        threeDsPreference: 'NONE'
    }
}
