// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".XXAihQHWgoQfbDH0p4v4_{overflow:auto;height:100%;width:100%}.XXAihQHWgoQfbDH0p4v4_ iframe{width:100%;height:calc(100vh - 40px);border:none;max-height:740px}.XXAihQHWgoQfbDH0p4v4_ iframe._2wMTq6uk8Tsvbo68lE4WGd{min-width:320px}.XXAihQHWgoQfbDH0p4v4_ iframe.Knpcrf-hoMaxtZDc0B-2x{min-width:320px}.XXAihQHWgoQfbDH0p4v4_ iframe.C8Fax2TaG3aKeEdOtt7SL{max-height:840px}", ""]);
// Exports
exports.locals = {
	"root": "XXAihQHWgoQfbDH0p4v4_",
	"amex": "_2wMTq6uk8Tsvbo68lE4WGd",
	"visa": "Knpcrf-hoMaxtZDc0B-2x",
	"discover": "C8Fax2TaG3aKeEdOtt7SL"
};
module.exports = exports;
