// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._21q48u8NpSYzwcjAIKuvdE{padding:16px 16px;display:flex;justify-content:flex-start;align-items:center;position:relative}@media(min-width: 768px){._21q48u8NpSYzwcjAIKuvdE{padding:16px 24px}}._21q48u8NpSYzwcjAIKuvdE .EmoRzAc-EAJXfHC_MgIvS{background:left no-repeat;background-size:contain}._21q48u8NpSYzwcjAIKuvdE .IRGE4uRVo7SGSuJ3DfnMk{display:none;font-family:\"Poppins\",sans-serif;font-style:normal;font-weight:bold;margin-left:16px}@media(min-width: 768px){._21q48u8NpSYzwcjAIKuvdE .IRGE4uRVo7SGSuJ3DfnMk{display:block}}._21q48u8NpSYzwcjAIKuvdE ._3_3YbVCDyCqkPG5dwfE6Yi{position:absolute;outline:none;cursor:pointer;border-radius:50%;width:24px;height:24px;background-position:center;background-repeat:no-repeat;background-size:contain;right:14px;top:14px}@media(min-width: 768px){._21q48u8NpSYzwcjAIKuvdE ._3_3YbVCDyCqkPG5dwfE6Yi{right:16px;top:16px}}._21q48u8NpSYzwcjAIKuvdE ._3_3YbVCDyCqkPG5dwfE6Yi svg{width:100%;height:100%}._21q48u8NpSYzwcjAIKuvdE ._1V-5Z41RRyGttyxqvSjcna{background:left no-repeat;background-size:contain}._21q48u8NpSYzwcjAIKuvdE ._1V-5Z41RRyGttyxqvSjcna._3ugGgDUeKTZEIdSjAT-q7t{margin:22px 0px 0px auto}@media(min-width: 768px){._21q48u8NpSYzwcjAIKuvdE ._1V-5Z41RRyGttyxqvSjcna._3ugGgDUeKTZEIdSjAT-q7t{margin-right:-6px}}._21q48u8NpSYzwcjAIKuvdE ._1V-5Z41RRyGttyxqvSjcna._2VXNy8HRYIQyEgPswZj2H{margin:0 22px 0px auto}@media(min-width: 768px){._21q48u8NpSYzwcjAIKuvdE ._1V-5Z41RRyGttyxqvSjcna._2VXNy8HRYIQyEgPswZj2H{margin:0 27px 0px auto}}._21q48u8NpSYzwcjAIKuvdE ._1hVf6uHXOgUx-fTpfwGxy{width:100%;position:absolute;bottom:0;left:0}", ""]);
// Exports
exports.locals = {
	"Header": "_21q48u8NpSYzwcjAIKuvdE",
	"Logo": "EmoRzAc-EAJXfHC_MgIvS",
	"LogoText": "IRGE4uRVo7SGSuJ3DfnMk",
	"Close": "_3_3YbVCDyCqkPG5dwfE6Yi",
	"SecondaryLogo": "_1V-5Z41RRyGttyxqvSjcna",
	"Right": "_3ugGgDUeKTZEIdSjAT-q7t",
	"MiddleRight": "_2VXNy8HRYIQyEgPswZj2H",
	"BottomLine": "_1hVf6uHXOgUx-fTpfwGxy"
};
module.exports = exports;
