import React from 'react'
import { action, computed, observable } from 'mobx'
import { Page, PaymentResultV2 } from '~/code/models'
import { log } from '~/code/services/logger'
import { tryJSON } from '~/code/services/generators'
import { getBrowserName, openPopupWindow } from '~/code/services/window-utils'
import { ConfigStore } from '~/code/config'
import { ThreeDSecureReturnData } from '../ThreeDSecure/models'
import { UPIThreeDSecureParentStore } from './models'

export class UPIThreeDSecureStore {

    popupWindow: Window = null
    iframe = React.createRef<HTMLIFrameElement>()

    @observable
    isLoading: boolean = true

    @observable
    iframeLoaded: boolean = false

    @observable
    errorMessage: string = ''

    constructor (private parentStore: UPIThreeDSecureParentStore) {
        this.parentStore = parentStore
    }

    @computed
    get paymentResult() {
        return (this.parentStore.paymentResult as PaymentResultV2)
    }

    @computed
    get srcDoc() {
        return this.paymentResult?.threeDS?.html
    }

    get isSafari() {
        return getBrowserName() === 'Safari'
    }

    postMessageListener = (ev: MessageEvent) => {
        if (ev.data) {
            log('RECEIVED POST MESSAGE: ', ev.data)
            this.onUPI3DSComplete(ev.data)
        }
    }

    storageListener = (event: StorageEvent) => {
        const data: ThreeDSecureReturnData = tryJSON(event.newValue)
        log('RECEIVED STORAGE MESSAGE: ', data)

        if (event.key !== 'upi-3ds-return-data' || !data || data.reference !== this.paymentResult?.rrn) {
            return
        }

        this.onUPI3DSComplete(data)
        localStorage.removeItem('upi-3ds-return-data')

        if (this.popupWindow) {
            this.popupWindow.close()
        }
    }

    @action.bound
    iFrameLoadEventListener() {
        this.isLoading = false
        if (!this.iframeLoaded) this.iframeLoaded = true
    }

    @action.bound
    setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage
    }

    addListeners = () => {
        if (!this.isSafari) {
            window.addEventListener('message', this.postMessageListener, false)
            this.iframe.current.addEventListener('load', this.iFrameLoadEventListener)
        } else {
            this.popupWindow?.document.write(this.srcDoc)
            window.addEventListener('storage', this.storageListener, false)
        }
    }

    removeListeners = () => {
        if (!this.isSafari) {
            window.removeEventListener('message', this.postMessageListener, false)
            this.iframe.current?.removeEventListener('load', this.iFrameLoadEventListener)    
        } else {
            window.removeEventListener('storage', this.storageListener, false)
        }
    }

    onUPI3DSComplete = (threeDSResult: ThreeDSecureReturnData) => {
        if (threeDSResult) {
            this.parentStore.handle3dSecureResult(threeDSResult)
        }
    }

    openPopupWindow() {
        const url = `${ConfigStore.getUrl().paymentPageUrl}/processing.html`
        this.popupWindow = openPopupWindow(url, 'upi-popup-window', this.onPopupWindowClose)
    }

    closePopupWindow() {
        this.popupWindow.close()
    }

    onPopupWindowClose = () => {
        this.popupWindow = null
        this.isLoading = false
        if (this.parentStore.currentPageName === Page.UPI_THREE_D_SECURE) {
            this.onUPI3DSComplete({ result: 'error' })
        }
    }
}
