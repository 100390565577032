// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".YcTlesAkmKsRO2-iXGbrj{margin-bottom:8px}", ""]);
// Exports
exports.locals = {
	"BackLink": "YcTlesAkmKsRO2-iXGbrj"
};
module.exports = exports;
