import React from 'react'
import { observer } from 'mobx-react'
import Switch from 'react-switch'
import { ConfigStore, StyleStore } from '~/code/config'
import { getBase64ImageUrl, getSymbolFromCurrency, lighten } from '~/code/services'
import translations from './translations'
import { Image } from '~/code/components'
import styles from './Donation.scss'
import { DonationProps } from './props'
import { amountFormat } from '~/code/components/OrderInfo/services'

export const Donation =  observer((props: DonationProps) => {

    const getActiveText = () => {
        return ConfigStore.donation.penniesDonationActiveText
    }

    return (
        <div data-id={'donation'} className={ styles.Donation }>
            <div className={ styles.Content }>
                <div className={ styles.ContentHeader }>
                    <div>{ translations().header }</div>
                    <label className={ styles.SwitchWrapper } htmlFor='material-switch'>
                        <span className={ styles.SwitchLabel }>
                            {  ConfigStore.isActiveDonation ? translations().thanks : translations().donate + ' ' + amountFormat(ConfigStore.donation.amount, props.currency)}
                        </span>
                        <Switch
                            checked={ ConfigStore.isActiveDonation }
                            onChange={ (checked) => {
                                ConfigStore.setField('isActiveDonation', checked)
                            } }
                            onColor={ lighten(StyleStore.style.primaryColor, 30) }
                            onHandleColor={ StyleStore.style.primaryColor }
                            handleDiameter={ 20 }
                            uncheckedIcon={ false }
                            checkedIcon={ false }
                            boxShadow='0px 0px 1px 1px rgba(0, 0, 0, 0.2)'
                            activeBoxShadow='0px 0px 1px 1px rgba(0, 0, 0, 0.2)'
                            height={ 14 }
                            width={ 35 }
                            className={`react-switch`}
                            id='material-switch'
                        />
                    </label>
                </div>
                <div className={ styles.MainContent }>
                    <div className={ styles.LogoWrapper }>
                        { ConfigStore.donation?.logo &&
                            <Image className={ styles.Logo } src={ getBase64ImageUrl(ConfigStore.donation?.logoImageMimeType, ConfigStore.donation?.logo) } />
                        }
                        <Image className={ styles.SecondaryLogo } src={ 'pennies_logo.png' }/>
                    </div>
                    <div className={ styles.MessageWrapper }>
                        <div>
                            {
                                getActiveText()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
