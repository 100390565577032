import { action, computed, observable } from 'mobx'
import { ConfigStore, LogStore } from '~/code/config'
import UrlParser from 'url-parse'
import { loadScript } from '@paypal/paypal-js'
import { apiCheck, noThrow } from 'back-connector'
import * as errorCodes from '~/code/config/LogStore'
import { PaymentDataV2, PaymentMethod, TransactionType, PaymentMethodStatus } from '~/code/models'
import { OnApproveResponse, PaypalOrderDetail, PaypalParentStore } from './models'
import { postWithToken } from '~/code/services'
import { isBillingAddressEmpty } from '~/code/pages/Paypal/services'
import { error, log } from '~/code/services/logger'
import { PAYPAL_BUTTON_CONTAINER_ID } from '~/code/pages/Paypal/constants/constants'

export class PayPalStore {

    public parentStore: PaypalParentStore

    public currentPaymentState: 'beforeButtonLoad' | 'afterButtonLoad' | 'duringPayment'

    @observable
    public scriptLoadingState: 'idle' | 'loading' | 'loaded' | 'failed' = 'idle'

    constructor (parentStore: PaypalParentStore) {
        this.parentStore = parentStore
        this.currentPaymentState = 'beforeButtonLoad'
    }

    @observable
    public orderDetail: PaypalOrderDetail = null

    @computed
    public get isLoading() {
        return this.scriptLoadingState === 'loading'
    }

    @observable
    public isProcessingPayment: boolean = false

    @observable
    public isPaymentSuccessful: boolean = false

    @action
    public startPaymentProcessing() {
        this.isProcessingPayment = true
        this.parentStore.startPaymentProcessing()
    }

    @action
    public stopPaymentProcessing() {
        this.isProcessingPayment = false
        this.parentStore.stopPaymentProcessing(this.isPaymentSuccessful)
    }

    @computed
    public get order() {
        const paymentData = this.paymentData
        return {
            amount: paymentData.amount,
            currency: paymentData.currency,
            invoiceId: paymentData.invoiceId,
            orderDetail: paymentData?.description,
            taxAmount: paymentData?.taxAmount,
            accountId: paymentData?.customerDetails?.accountDetails?.accountId,
            email: this.parentStore?.email,
            locale: paymentData?.language,
            terminalId: paymentData?.paymentSettings?.terminalId,
            paymentMethod: 'paypal',
            transactionType: this.getTransactionType(),
            deliveryType: paymentData?.deliveryType,
            returnUrl: paymentData?.paymentSettings?.returnUrl,
            failureReturnUrl: paymentData?.paymentSettings?.failureReturnUrl,
            callbackUrl: paymentData?.paymentSettings?.callbackUrl,
            failureCallbackUrl: paymentData?.paymentSettings?.failureCallbackUrl,
            amountBreakdown: paymentData?.amountBreakdown,
            orderLines: paymentData?.orderLines,
            softDescriptor: paymentData?.softDescriptor,
            billingAddress: isBillingAddressEmpty(this.parentStore?.billingAddress) ? this.parentStore?.billingAddress : null ,
            shippingAddress: paymentData?.customerDetails?.deliveryDetails?.deliveryAddress,
            shippingPreference: paymentData?.customerDetails?.deliveryDetails?.deliveryAddress ? '' : 'NO_SHIPPING'
        }
    }

    public getTransactionType() {
        if (!this.paymentData?.transactionType && ConfigStore?.paymentMethodsSettings?.paypal?.defaultTransactionType) {
            return String(ConfigStore?.paymentMethodsSettings?.paypal?.defaultTransactionType).toUpperCase()
        }
        return this.paymentData?.transactionType
    }

    @computed
    public get paymentData(): PaymentDataV2 {
        return this.parentStore?.paymentData
    }

    @action
    public startLoading() {
        this.scriptLoadingState = 'loading'
    }

    @action
    public stopLoading(state: 'loaded' | 'failed') {
        this.scriptLoadingState = state
    }

    @action
    public onClick = () => {
        this.currentPaymentState = 'duringPayment'
        this.startPaymentProcessing()
    }

    @action
    public async createOrder(): Promise<string> {
        const result = await noThrow(apiCheck(postWithToken<PaypalOrderDetail>(`${ ConfigStore.getUrl().apiUrl }/payments/alternative/createOrder`, this.order)))
        if (result.error || !result?.value?.success) {
            LogStore.error(errorCodes.PAYPAL_CAN_NOT_CREATE_ORDER)
            this.onError({
                // @ts-ignore
                code: result.error ? result.error.errorCode : result.value?.errorCode
            })
            return null
        }
        this.orderDetail = result.value
        return result.value.paymentOrderId
    }

    @action
    public async onApprove() {
        const result = await noThrow(apiCheck(postWithToken<OnApproveResponse>(`${ ConfigStore.getUrl().apiUrl }/payments/alternative/authorize/${ this.orderDetail?.paymentOrderId }`)))
        if (result?.error || !result?.value?.success) {
            this.isPaymentSuccessful = false
            LogStore.error(errorCodes.PAYPAL_CAN_NOT_AUTHORIZE)
            this.onError(result.error || {})
            return
        }
        this.stopPaymentProcessing()

        this.isPaymentSuccessful = true
        this.currentPaymentState = 'afterButtonLoad'
        ConfigStore.setField('paymentMethod', PaymentMethod.PayPal)
        this.parentStore.transactionId = result?.value?.id
        this.stopPaymentProcessing()
        this.parentStore.handleSuccessResult()
    }

    @action
    public onCancel = async () => {
        await noThrow(apiCheck(postWithToken(`${ ConfigStore.getUrl().apiUrl }/payments/alternative/cancel/${ this.orderDetail?.paymentOrderId }`)))
        this.stopPaymentProcessing()
        this.currentPaymentState = 'afterButtonLoad'
    }

    @action
    public async onError(err: {code?, message?}) {
        LogStore.error(errorCodes.PAYPAL_DEFAULT, err.message)

        if (this.currentPaymentState === 'beforeButtonLoad' || this.currentPaymentState === 'afterButtonLoad') { // this means the error occurred while rendering the button
            ConfigStore.setPaymentStatus(PaymentMethod.PayPal, PaymentMethodStatus.FAILED)
        } else {
            ConfigStore.setField('paymentMethod', PaymentMethod.PayPal)
            this.stopPaymentProcessing()
            this.isPaymentSuccessful = false
            this.parentStore.stopPaymentProcessing(false)
            this.parentStore.handleFailureResult(error)
        }
    }

    public getScriptUrl() {
        const url = new UrlParser('https://www.paypal.com/sdk/js')
        url.set('query', {
            'client-id': ConfigStore?.paymentMethodsSettings?.paypal?.clientId,
            'currency': this?.paymentData.currency,
            'debug': false, // ConfigStore.isTestMode
            'disable-funding': ConfigStore?.paymentMethodsSettings?.paypal?.disableFunding,
            'integration-date': '2020-06-24',
            'merchant-id': ConfigStore?.paymentMethodsSettings?.paypal?.paypalAccountMerchantId,
            'intent': this?.getIntent()
        })
        return url.toString()
    }

    public getIntent() {
        const transactionType = this.getTransactionType()
        if (transactionType === TransactionType.AUTH) {
            return 'authorize'
        } else if (transactionType === TransactionType.SALE) {
            return 'capture'
        }

        return ConfigStore?.paymentMethodsSettings?.paypal?.defaultTransactionType.toUpperCase() === TransactionType.AUTH ? 'authorize' : 'capture'
    }

    public loadPayPalScript = async (buttonContainer) => {

        const _initializePayPalComponent = (_paypal) => {
            const payPalComponent = _paypal.Buttons({
                onInit: (data, actions) => {
                    actions.enable()
                },
                createOrder: () => this.createOrder(),
                onApprove: () => this.onApprove(),
                onCancel: () => this.onCancel(),
                onError: (message) => this.onError({ message }),
                onClick: () => this.onClick(),
                style: {
                    color: 'white',
                    label: 'pay',
                    height: 46
                }
            })

            return payPalComponent
        }

        const _renderPayPalButton = (_paypal, _payPalComponent, trials = 0) => {
            _payPalComponent.render(buttonContainer)
                .then(() => {
                    log('PayPal button has been rendered')
                })
                .catch((err) => {
                    error(`Failed to render the PayPal Button: ${trials}`, err)
                    if (trials < 3) {
                        _loadScript()
                    }
                })
        }

        const _loadScript = () => {
            loadScript({
                    clientId: ConfigStore?.paymentMethodsSettings?.paypal?.clientId,
                    currency: this?.paymentData.currency,
                    debug: false, // ConfigStore.isTestMode
                    disableFunding: ConfigStore?.paymentMethodsSettings?.paypal?.disableFunding,
                    merchantId: ConfigStore?.paymentMethodsSettings?.paypal?.paypalAccountMerchantId,
                    intent: this?.getIntent()
                }
            ).then((paypal) => {
                this.scriptLoadingState = 'loaded'
                const payPalComponent = _initializePayPalComponent(paypal)
                _renderPayPalButton(paypal, payPalComponent)
            })
            .catch((err) => {
                this.scriptLoadingState = 'failed'
                error('Failed to load the PayPal JS SDK script', err)
            })
        }

        this.startLoading()

        _loadScript()
    }
}
