import React from 'react'
import { observer } from 'mobx-react'
import { BankCardPaymentMethodItemProps } from './props'
import styles from './BankCardPaymentMethodItem.scss'
import { PaymentMethodItem, Image, PaymentMethodItemWrapper } from '~/code/components'
import translations from './translations'
import { PaymentMethod } from '~/code/models'
import { ConfigStore } from '~/code/config'
import { PaymentMethodStatus } from '~/code/models/PaymentMethodStatus'
import { CardSchemeImages } from '../CardSchemeImages'
import { getCardSchemeList } from '~/code/services/cards'
import { PAY_BY_CARD } from '~/code/constants/playwright-ids'

export const BankCardPaymentMethodItem = observer((props: BankCardPaymentMethodItemProps) => {
    const { store } = props

    const renderHeader = () => {
        return (
            <div className={ styles.Header }>
                <Image
                    className={ styles.HeaderLogo }
                    src={ 'blue-credit-card.svg' }
                />
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div>
                <div className={ styles.HeaderMessage }>
                    { translations().headerMessage }
                </div>
                <CardSchemeImages
                    className={styles.SupportPaymentMethods}
                    hasClickToPay={ConfigStore.paymentMethodsSettings?.clickToPay?.status === PaymentMethodStatus.ACTIVE}
                    cardSchemes={getCardSchemeList()}
                />
            </div>
        )
    }

    const renderMessage = () => {
        return <span> { props.message }</span>
    }

    return (
        <div
            className={ `
                ${ styles.BankCardPaymentMethodItem }
                ${ props.className ? props.className : ''}`
            }
            data-id={'bank-card'}
            data-test-id={PAY_BY_CARD}
        >
            { store.isLoading ?
                <PaymentMethodItemWrapper
                    name={'bank-card-loading'}
                    isLoading={store.isLoading}
                    dataId={'bank-card-loading'}
                /> :
                <PaymentMethodItem
                    name={ PaymentMethod.BankCard }
                    className={ `${ styles.PaymentMethodItemBlock }` }
                    onClick={ props.onClick }
                    renderItemHeader={ () => renderHeader() }
                    renderItemContent={ () => renderContent() }
                    renderItemMessage={ props.message ?  () => renderMessage() : null }
                />
            }
        </div>
    )
})
