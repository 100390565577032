import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { StyleStore } from '~/code/config'
import { LeftArrow } from '~/assets/icons'
import { CreditCardIcon, Link } from '~/code/components'
import { CardPaymentWrapper } from '~/code/pages/CardData/components'
import { CvvInputMaskInput } from '~/code/pages/CardData/components/CvvInputMaskInput'
import { InputFieldName } from '~/code/pages/CardData/models'
import { isCSCHidden } from '~/code/services/cards'
import { CardItemPageProps } from './props'
import translations from './translations'
import styles from './CardItemPage.scss'


export default observer((props: CardItemPageProps) => {
    const { store } = props
    const { selectedCard } = store

    const renderCvv = () => {
        const cvvField = store.fields[InputFieldName.Cvv]
        const { value, errorCode } = cvvField
        return (
            <div className={ styles.CvvWrapper }>
                <div className={ styles.CvvLabel }> { translations().pleaseEnter } </div>
                <CvvInputMaskInput
                    className={ styles.CvvWrapperInput }
                    maskSize={ store.cardInfo && store.cardInfo.code && store.cardInfo.code.size }
                    onChange={ (event) => cvvField.onChange(event.target.value) }
                    onBlur={ () => cvvField.errorCode = cvvField.validate() }
                    value={ value }
                    setRef={ (node) => {
                        store.cvcInput = node
                    } }
                    errorText={ translations()[errorCode] }
                />
            </div>
        )
    }

    useEffect(() => {
        store.cvcInput && store.cvcInput.focus()        
        return () => {
            const cvvField = store.fields[InputFieldName.Cvv]
            if (cvvField) {
                cvvField.value = ''
                cvvField.errorCode = ''    
            }
        }
    }, [])

    return (
        <CardPaymentWrapper
            hasNextPage={ store.hasNextPage }
            onConfirm={ (invokedFrom) => store.confirmCardData(invokedFrom + '->CardItemPage.CardPaymentWrapper.onConfirm') }
        >
            <Link
                className={ styles.BackCardListLink }
                onClick={ () => store.showInitCardListPage() }
            >
                <LeftArrow color={ StyleStore.style.primaryColor } /> { translations().backCardList }
            </Link>
            <div className={ styles.CardItemPage }>
                <div className={ styles.CardItemPageContent }>
                    <div className={ classNames({ [styles.noCVV]: isCSCHidden(selectedCard) }, styles.CardItem) }>
                        <div className={ styles.CardName }>
                            { selectedCard.cardName }
                        </div>
                        <div className={ styles.CardNumber }>
                            { selectedCard.panStarPretty }
                        </div>
                        <div className={ styles.CardItemFooter }>
                            <div className={ styles.CardExpiryDate }>
                                { selectedCard.expiryDate }
                            </div>
                            <div>
                                <CreditCardIcon
                                    className={ styles.CreditCardIcon }
                                    type={ selectedCard?.creditCardType }
                                />
                            </div>
                        </div>
                    </div>

                    { !isCSCHidden(selectedCard) && renderCvv() }
                </div>
            </div>
        </CardPaymentWrapper>
    )
})
