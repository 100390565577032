import translations from './translations'

translations.add('es', {
    successTitle: 'Enhorabuena',
    successMessage: 'El pago se ha enviado correctamente.',
    cancelMessage: 'El pago se ha cancelado.',
    failMessage: 'El pago ha fallado.',
    rejectMessage: 'El pago se ha rechazado.',
    transactionId: 'ID de transacción',
    autoRedirectAfter: 'Será redirigido automáticamente al sitio en',
    seconds: 'segundo',
    tryAgain: 'Intentar otra vez',
    returnToBack: 'Volver al sitio'
})
