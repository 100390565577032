// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._26j0CbwokSsoSWN2Upbzqp{width:100%}._26j0CbwokSsoSWN2Upbzqp ._2UoR2cW-GHT4AuX8mw8inM{width:100%;height:46px}", ""]);
// Exports
exports.locals = {
	"Paypal": "_26j0CbwokSsoSWN2Upbzqp",
	"PaypalButton": "_2UoR2cW-GHT4AuX8mw8inM"
};
module.exports = exports;
