import { action, computed, observable } from 'mobx'
import { EventName, IssueFeedbackModalParentStore, Reason } from './models'
import { sendFeedback } from './services'
import translations from './translations'

export class IssueFeedbackModalStore {
    private parentStore: IssueFeedbackModalParentStore
    constructor (parentStore: IssueFeedbackModalParentStore) {
        this.parentStore = parentStore
    }

    @observable
    public isOpen: boolean = false

    @observable
    public isLoading: boolean = false

    @observable
    public isSubmitted: boolean = false

    @observable
    public reason: Reason = null

    @observable
    public comment: string = ''

    @observable
    public isSubmitClicked: boolean = false

    @observable
    public feedbackSubmissionError: string = ''

    @action
    public onChangeReason(v: Reason) {
        this.reason = v
    }

    @action
    public onChangeComment(v: string) {
        this.comment = v
    }

    @action
    public async submitFeedback() {
        this.isSubmitClicked = true
        if (!this.isFormValid) return

        try {
            this.startLoading()
            const { error, status } = await sendFeedback(this.parentStore.transactionId, {
                eventName: EventName.THREEDS,
                reason: this.reason ? this.reason : undefined,
                eventFeedback: this.comment ? this.comment : undefined,
                errorMessage: this.threeDSecureErrorMessage ? this.threeDSecureErrorMessage : undefined,
                paymentData: this.parentStore.paymentData
            })
            this.stopLoading()

            if (error || status !== 200) {
                this.feedbackSubmissionError = error.message || translations().errorReportIssue
                return
            }

            this.isSubmitted = true
            this.parentStore.clearThreeDSecureErrorMessage()
            this.reason = null
            this.comment = ''
        } catch (error) {
            this.feedbackSubmissionError = translations().errorReportIssue
            this.stopLoading()
        }
    }

    @action.bound
    public closeFeedbackSubmissionErrorBlock() {
        this.isSubmitted = false
        this.feedbackSubmissionError = ''
    }

    @action
    public startLoading() {
        this.isLoading = true
    }

    @action
    public stopLoading() {
        this.isLoading = false
    }

    @action
    public open() {
        this.isOpen = true
    }

    @action
    public close() {
        this.isOpen = false
        this.isSubmitted = false
        this.isSubmitClicked = false
    }

    @action
    reSubmitPayment() {
        this.close()
        this.parentStore.tryAgain()
    }

    @computed
    private get isFormValid(): boolean {
        if (!this.reason && !this.comment) return false
        return true
    }

    @computed
    public get showError() : boolean {
        if (this.isSubmitClicked && !this.isFormValid) return true
        return false
    }

    @computed
    private get threeDSecureErrorMessage(): string {
        return this.parentStore.threeDSecureErrorMessage
    }
}
