import React from 'react'
import translations from './translations'

translations.add('en', {
    back: 'change bank',
    cancel: 'Cancel',
    continue: 'Continue',
    infoText: `We will ask your bank to share your name, sort code and account number.
        We will only use these details if you ask for a refund for this transaction.`,
    permissionText: 'Ecospend Technologies Limited needs your permission to securely initiate a payment order from your bank.',
    privacyPolicyText: (termsUrl: string, privacyUrl: string) => <>
        By clicking continue you accept our <a href={termsUrl} target='_blank'>terms & conditions</a> and <a href={privacyUrl} target='_blank'>privacy policy</a>
    </>
})
