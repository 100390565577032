import translations from './translations'

translations.add('es', {
    yourClickToPayPaymentUnsuccessful: 'El pago Click To Pay no se ha realizado correctamente. Inténtelo de nuevo más tarde',
    makeSureEmailIsCorrect: 'Please make sure your email is correct and try again',
    unknownError: 'Unknown error, please contact Mastercard',
    errors: {
        signOut: '¡La desvinculación no se ha realizado correctamente!'
    },
    messages: {
        signOut: '¡La desvinculación se ha realizado correctamente!'
    }
})
