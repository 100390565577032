import React from 'react'
import { ErrorFeedbackBlockProps } from './props'
import styles from './ErrorFeedbackBlock.scss'
import translations from './translations'
import { FailureIcon } from '~/assets/icons'
import { Button } from '~/code/components'
import { StyleStore } from '~/code/config'

export const ErrorFeedbackBlock: React.FC<ErrorFeedbackBlockProps> = ({ tryAgain, message }) => {
    return (
        <div className={styles.Content}>
            <div className={styles.Header}>
                <FailureIcon />
                <span className={styles.StatusText}>{translations().failure}</span>
            </div>
            <div className={styles.Body}>
                {message}
            </div>
            <div className={styles.Action}>
                <Button
                    onClick={tryAgain}
                    colorStyle={ StyleStore.style }
                >
                    {translations().tryAgain}
                </Button>
            </div>
        </div>
    )
}
