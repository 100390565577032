import React from 'react'
import { observer } from 'mobx-react'
import { ActionButton, Button, Modal } from '~/code/components'
import translations from './translations'
import { CloseConfirmationModalProps } from './props'
import styles from './CloseConfirmationModal.scss'
import { StyleStore } from '~/code/config'
import { CONFIRM_CLOSE_FORM } from '~/code/constants/playwright-ids'

export default observer((props: CloseConfirmationModalProps) => {
    const { store } = props
    return (
        <div>
            <Modal
                onClose={ () => null }
                isOpen={ store.isOpen }
                classNames={{
                    content: styles.ModalContent,
                    overlay: styles.Overlay
                }}
                showCloseIcon={ false }
            >
                <div className={ styles.Content }>
                    <div className={ styles.Message }>
                        <div className={ styles.Header }>
                            { translations().headerMessage }
                        </div>
                    </div>
                    <div className={ styles.Actions }>
                        <div data-id={'close-button'}>
                            <ActionButton
                                className={ styles.CloseButton }
                                onClick={ () => store.close() }
                                colorStyle={ StyleStore.style }
                                data-test-id={CONFIRM_CLOSE_FORM}
                            >
                                { translations().yes }
                            </ActionButton>
                        </div>
                        <div data-id={'cancel-button'}>
                            <Button
                                className={ styles.CancelButton }
                                colorStyle={ StyleStore.style }
                                onClick={ () => store.cancel() }>
                                { translations().no }
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
})
