import { v4 as uuidv4 } from 'uuid'
import { log } from '~/code/services/logger'

function generateTransactionId() {
    return uuidv4()
}

export const SENTRY_TRANSACTION_ID = generateTransactionId()

/**
 * Function to check if the error is from your library
 */
export function isRelevantError(event) {
    if (!event.exception || !event.exception.values) {
        return false
    }

    return event.exception.values.some((exception) => {
        if (!exception.stacktrace || !exception.stacktrace.frames) {
            return false
        }

        return exception.stacktrace.frames.some((frame) =>
            frame.filename.includes('payment-api') || frame.module.includes('payment-api') ||
            frame.filename.includes('payment-page') || frame.module.includes('payment-page')
        )
    })
}
