import { useRef, useEffect, useState } from 'react'

/**
 * The function creates an interval with the given delay that triggers a callback which is provided as an argument. Also it allows to control the interval: start and stop.
 * When either the delay or the callback is changed, the interval gets recreated.
 * @param callback function called each delay time
 * @param delay delay time in milliseconds
 * @returns 
 */
function useInterval(callback: () => void, delay: number) {
    const intervalId = useRef<NodeJS.Timeout>()
    const savedCallback = useRef<() => void>()
    const [isRunning, setIsRunning] = useState(false)

    function tick() {
        savedCallback.current && savedCallback.current()
    }

    function start() {
        if (!intervalId.current) {
            intervalId.current = setInterval(tick, delay)
        }
        setIsRunning(true)
    }

    function stop() {
        if (intervalId.current) {
            clearInterval(intervalId.current)
        }
        intervalId.current = null
        setIsRunning(false)
    }

    // save latest callback
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // restart intervalId if delay changed
    useEffect(() => {
        if (intervalId.current) {
            stop()
            start()
        }
    }, [delay])

    const unmount = () => {
        stop()
    }

    // stop intervalId when unmounting
    useEffect(() => unmount, [])

    return { start, stop, isRunning }
}

export { useInterval }
