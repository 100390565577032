import React from 'react'
import translations from './translations'

translations.add('pt', {
    paymentSuccessful: 'Pagamento realizado com sucesso',
    tokenizationSuccessful: 'Registo do cartão realizado com sucesso',
    verificationSuccessful: 'Verificação do cartão realizada com sucesso',
    autoRedirectAfter: 'Será redirecionado automaticamente para o site após',
    seconds: 'segundo',
    backToStore: 'Voltar ao site',
    yourPaymentWasSuccessfulWith: (paymentMethod: string, paymentDescription?: string) => {
        return (<>O seu pagamento foi processado com sucesso ao usar <strong>{paymentMethod} {paymentDescription}</strong></>)
    }
})
