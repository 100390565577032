// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ZuoNPuw3t-MaWQ879_9ta{margin:5px 0}._2TLEoZBGYhPVjUxiV9EUwb{padding:9px 29px;height:48px;padding:13px 37px;margin-top:24px}._3IhPSdH7mFkZVRd828-WYR{display:flex;justify-content:space-between}.pZF52Xn4w6zSR89DY4M5b{font-family:\"Poppins\",sans-serif;font-size:24px;margin-bottom:12px;font-weight:200}._37SVsmFiVy0fjsMPQVtAqy{font-family:\"Poppins\",sans-serif;font-size:14px;margin-bottom:12px;font-weight:200}.OYxVsFnprFu-dub3qkEb7{word-break:break-word;font-family:\"Open Sans\",sans-serif;color:#dc3545;font-size:12px;position:relative;margin-top:-15px}._3mzcc7uGeoic84RlADKa-K{width:100%;height:calc(100vh - 40px);max-height:740px}._3aNAaV6C3IBfIUp4jU-7v0{max-height:840px}", ""]);
// Exports
exports.locals = {
	"clickToPayHeader": "_3ZuoNPuw3t-MaWQ879_9ta",
	"backButton": "_2TLEoZBGYhPVjUxiV9EUwb",
	"buttonsContainer": "_3IhPSdH7mFkZVRd828-WYR",
	"mainTitle": "pZF52Xn4w6zSR89DY4M5b",
	"secondaryTitle": "_37SVsmFiVy0fjsMPQVtAqy",
	"error": "OYxVsFnprFu-dub3qkEb7",
	"checkoutModal": "_3mzcc7uGeoic84RlADKa-K",
	"checkoutModalDiscover": "_3aNAaV6C3IBfIUp4jU-7v0"
};
module.exports = exports;
