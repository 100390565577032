import React from 'react'
import { observer } from 'mobx-react'
import { PaymentFormSide, PaymentMethodsLink } from '~/code/pages/PaymentForm/components'
import { EcospendPaymentFormWrapperProps } from './props'
import styles from './styles.scss'
import { ConfigStore } from '~/code/config'

export const EcospendPaymentFormWrapper = observer((props: EcospendPaymentFormWrapperProps) => {
  const { store, children } = props

  return <div className={styles.wrapper}>
    <div className={styles.content}>
      {
        ConfigStore.activePaymentMethods().length > 1 &&
        <PaymentMethodsLink onClick={() => store.parentStore.redirectInitialPaymentMethodsPage()} />
      }
      {children}
    </div>
    <PaymentFormSide
      className={styles.side}
      orderCurrency={store.paymentData?.currency}
      orderDescription={store.paymentData?.description}
      orderNumber={store.paymentData?.invoiceId}
      orderTotal={store.paymentData?.amount}
      isVisibleDonation={false}
    />
  </div>
})

