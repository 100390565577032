import React from 'react'
import translations from './translations'

translations.add('is', {
    paymentSuccessful: 'Greiðsla tókst',
    tokenizationSuccessful: 'Kortaskráning tókst',
    verificationSuccessful: 'Kortastaðfesting tókst',
    autoRedirectAfter: 'Þér verður sjálfkrafa vísað á síðuna eftir',
    seconds: 'sek',
    backToStore: 'Fara aftur á síðuna',
    yourPaymentWasSuccessfulWith: (paymentMethod: string, paymentDescription?: string) => {
        return (<>Greiðsla þín var afgreidd með <strong>{paymentMethod} {paymentDescription}</strong></>)
    }
})
