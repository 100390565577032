import React from 'react'
import { observer } from 'mobx-react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { StyleStore } from '~/code/config'
import { lighten } from '~/code/services'
import styles from './Button.scss'
import { ButtonProps } from '~/code/components/Button/props'
import { ThreeDotsLoader } from '~/code/components'

const useStyles = createUseStyles({
    Button: {
        background: (style) => style.actionButtonBackground,
        color: (style) => style.actionButtonColor,
        borderRadius: (style) => `${ style.actionButtonBorderRadius }px`,
        '&:hover': {
            background: (style) => lighten(style.actionButtonBackground, 5),
            color: (style) => lighten(style.actionButtonColor, 5)
        }
    }
})


export const Button = ((props: ButtonProps) => {
    const { colorStyle = StyleStore.getDefaultStyle(), isLoading, ...buttonProps } = props
    const classes = useStyles(colorStyle)

    return (
        <button
            { ...buttonProps }
            className={ classNames(styles.Button, classes.Button, { [styles.Disabled]: props.disabled }, props.className) }>
            { isLoading ? <ThreeDotsLoader className={ styles.Loader } backgroundColor={ colorStyle.primaryColor }/> : props.children }
        </button>
    )
})
