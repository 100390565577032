import React from 'react'
import { observer } from 'mobx-react'
import styles from './IframeWrapper.scss'
import { IframeWrapperProps } from './props'

@observer
export default class extends React.Component<IframeWrapperProps, any> {

    render() {
        const { store } = this.props
        return (
            <div>
                {
                    store.isOpen && (
                        <iframe
                            src={ store.link }
                            className={ styles.IframeWrapper }
                            style={{
                                opacity: store.isVisible ? 1 : 0
                            }}
                            allow={'payment'}
                            allowpaymentrequest={'true'}
                        />
                    )
                }
            </div>
        )
    }

    componentDidMount (): void {
        const { store } = this.props
        store.addListener()
    }

    componentWillUnmount () {
        const { store } = this.props
        store.removeListener()
    }
}
