export enum InputFieldName {
    AccountCountry = 'accountCountry',
    AccountCity = 'accountCity',
    AccountStreet1 = 'accountStreet1',
    AccountEmail = 'accountEmail',
    AccountFirstName = 'accountFirstName',
    AccountLastName = 'accountLastName',
    AccountPostalCode = 'accountPostalCode',
    AccountPhone = 'phone',

    BillingAddress = 'billingAddress',
    BillingAddressCountry = 'billingAddressCountry',
    BillingAddressCity = 'billingAddressCity',
    BillingAddressAddressLine1 = 'billingAddressAddressLine1',
    BillingAddressPostalCode = 'billingAddressPostalCode',

    DeliveryAddress = 'deliveryAddress',
    DeliveryAddressCountry = 'deliveryAddressCountry',
    DeliveryAddressCity = 'deliveryAddressCity',
    DeliveryAddressAddressLine1 = 'deliveryAddressAddressLine1',
    DeliveryAddressPostalCode = 'deliveryAddressPostalCode'

}
