import translations from './translations'

translations.add('is', {
    yourClickToPayPaymentUnsuccessful: 'Click To Pay greiðslan þín tókst ekki. Vinsamlegast reyndu aftur síðar',
    makeSureEmailIsCorrect: 'Please make sure your email is correct and try again',
    unknownError: 'Unknown error, please contact Mastercard',
    errors: {
        signOut: 'Tókst ekki að afbinda!'
    },
    messages: {
        signOut: 'Tókst að afbinda!'
    }
})
