// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3W_vr4JYyTAt4SMjDfs42b{font-family:\"Poppins\",sans-serif;width:100%}._3W_vr4JYyTAt4SMjDfs42b ._1U3IHNS86TsaPEiF_F4ewm{display:flex;align-items:center;justify-content:center}._3W_vr4JYyTAt4SMjDfs42b ._1U3IHNS86TsaPEiF_F4ewm ._4IfIcAoijLLLo0bKQb5mT{width:54px;height:39px}._3W_vr4JYyTAt4SMjDfs42b ._1i57tnG_8Mv7b6yZff-8RK{padding-top:15px;padding-bottom:15px;width:100%}._3W_vr4JYyTAt4SMjDfs42b .MxlBcdY6jjOzjcFkvX7zm{font-style:normal;font-weight:700;font-size:16px;line-height:24px;letter-spacing:.444444px;color:#000}._3W_vr4JYyTAt4SMjDfs42b .Fdbj2WfiOsQCOdh3FCMOo{margin-top:8px}._3W_vr4JYyTAt4SMjDfs42b ._24fNsvxPgF7Hg3mLC2Bcs-{width:36px;height:24px;margin-right:7px}._3W_vr4JYyTAt4SMjDfs42b ._59jwuZ7x64AvKJSKSH9zd{display:inline-block;border-left:2px solid #000;height:24px;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"BankCardPaymentMethodItem": "_3W_vr4JYyTAt4SMjDfs42b",
	"Header": "_1U3IHNS86TsaPEiF_F4ewm",
	"HeaderLogo": "_4IfIcAoijLLLo0bKQb5mT",
	"PaymentMethodItemBlock": "_1i57tnG_8Mv7b6yZff-8RK",
	"HeaderMessage": "MxlBcdY6jjOzjcFkvX7zm",
	"SupportPaymentMethods": "Fdbj2WfiOsQCOdh3FCMOo",
	"SupportPaymentMethodsLogo": "_24fNsvxPgF7Hg3mLC2Bcs-",
	"verticalLine": "_59jwuZ7x64AvKJSKSH9zd"
};
module.exports = exports;
