// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2PB0Knw6daWf9f64fzmhVK{display:flex;flex-direction:column}._2PB0Knw6daWf9f64fzmhVK ._1DbpTw0IB0cWGF9nKEksJy{font-family:\"Poppins\",sans-serif;font-size:24px;margin-bottom:12px;font-weight:200}._2PB0Knw6daWf9f64fzmhVK ._3TF3ajVMZZD5NWLxha8Vr{padding-right:20px;margin-right:-20px}._2PB0Knw6daWf9f64fzmhVK ._12n3U3B4quMQ03wEQYfYBR{display:flex;justify-content:flex-end;align-items:center;font-family:\"Open Sans\",sans-serif;margin-top:12px;box-sizing:border-box}._2PB0Knw6daWf9f64fzmhVK ._12n3U3B4quMQ03wEQYfYBR ._1meoyAR3FOOKQHpLuRx6SQ{display:flex;justify-content:flex-end}._2PB0Knw6daWf9f64fzmhVK ._12n3U3B4quMQ03wEQYfYBR ._1J_JmjV5R9DxXiE3b13UPE{word-break:break-word;font-family:\"Open Sans\",sans-serif;color:#dc3545;font-size:12px}._b8e_oBKjL6VaFfCfIydz{color:#9b9b9b;font-size:12px;font-family:\"Open Sans\",sans-serif;font-weight:400;margin:32px 0 16px}", ""]);
// Exports
exports.locals = {
	"OrderPersonalInfo": "_2PB0Knw6daWf9f64fzmhVK",
	"MainTitle": "_1DbpTw0IB0cWGF9nKEksJy",
	"fields": "_3TF3ajVMZZD5NWLxha8Vr",
	"Action": "_12n3U3B4quMQ03wEQYfYBR",
	"buttonContainer": "_1meoyAR3FOOKQHpLuRx6SQ",
	"error": "_1J_JmjV5R9DxXiE3b13UPE",
	"label": "_b8e_oBKjL6VaFfCfIydz"
};
module.exports = exports;
