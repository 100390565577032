import React from 'react'
import classnames from 'classnames'

import { Link, ThreeDotsLoader } from '~/code/components'
import { CardSchemeImages } from '~/code/components/CardSchemeImages'

import translations from './translations'
import { ClickToPayHeaderProps } from './props'
import styles from './styles.scss'
import { CLICK_TO_PAY_LOOKUP_MY_CARDS_LINK } from '~/code/constants/playwright-ids'

export const ClickToPayHeader: React.FC<ClickToPayHeaderProps> = (props) => {
    const { className, cardBrands, onLookUp, isLoading, errorMessage } = props

    return <div className={className}>
        <div className={classnames(styles.root)}>
            <CardSchemeImages
                hasClickToPay={true}
                cardSchemes={cardBrands}
                size='small'
                className={styles.cardSchemeImages}
            />
            {
                isLoading ? <ThreeDotsLoader/> :
                    <Link className={styles.lookupCardsLink} data-test-id={CLICK_TO_PAY_LOOKUP_MY_CARDS_LINK} onClick={onLookUp}>
                        {translations().lookUp}
                    </Link>
            }
        </div>
        {
            <div className={styles.error}>
                { errorMessage }
            </div>
        }
    </div>
}
