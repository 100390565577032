import translations from './translations'

translations.add('en', {
    paymentReject: 'Payment Unsuccessful',
    tokenizationReject: 'Tokenization Unsuccessful',
    verificationReject: 'Verification Unsuccessful',
    hasNotAuthorisedPleaseCheck: 'Your card has not been authorised, please check the details and retry or contact your bank.',
    hasNotAuthorisedPleaseRetry: 'Your card has not been authorised, please retry.',
    hasBeenTechnicalError: 'There has been a technical error and your card has not been authorised.',
    payPalWasNotCompleted: 'Your PayPal transaction was not completed, please retry or select another payment method.',
    payByBankWasNotCompleted: 'Your Pay by bank app transaction was not completed, please retry or select another payment method.',
    googlePayWasNotCompleted: 'Your Google Pay transaction was not completed, please retry or select another payment method.',
    applePayWasNotCompleted: 'Your Apple Pay transaction was not completed, please retry or select another payment method.',
    ecospendWasNotCompleted: 'Your Open Banking transaction was not completed, please retry or select another payment method.',
    klarnaWasNotCompleted: 'Your Klarna payment was unsuccessful, please retry or select another payment method.',
    clickToPayWasNotCompleted: 'Your Click To Pay payment was unsuccessful, please retry or select another payment method.',
    invalidUnsupportedPaymentMethod: 'Invalid/Unsupported Payment Method',
    tryAgain: 'Try again',
    returnToBack: 'Return to Site'
})
