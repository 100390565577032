// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".X7my8KBzvV7o_s0X7Un9o{width:100%;font-family:\"Poppins\",sans-serif;border:1px solid #ddd;border-radius:4px;padding:10px 15px;box-sizing:border-box;display:flex;align-items:center;justify-content:space-between;cursor:pointer;transition:box-shadow .2s}.X7my8KBzvV7o_s0X7Un9o ._3t9-CzEkXWcXCSMto8Un3z{width:100%}.X7my8KBzvV7o_s0X7Un9o:hover,.X7my8KBzvV7o_s0X7Un9o._2m5KpfQmiRHb-3cfZqRDRv{box-shadow:0px 3px 8px 0px rgba(0,0,0,.12)}", ""]);
// Exports
exports.locals = {
	"PaymentMethodItemWrapper": "X7my8KBzvV7o_s0X7Un9o",
	"Loader": "_3t9-CzEkXWcXCSMto8Un3z",
	"Checked": "_2m5KpfQmiRHb-3cfZqRDRv"
};
module.exports = exports;
