import React from 'react'
import { observer } from 'mobx-react'

import { StyleStore } from '~/code/config'
import { ActionButton, Button, Modal, SpinThreeDotsLoader } from '~/code/components'
import translations from './translations'
import { EcospendCheckModalProps } from './props'
import styles from './styles.scss'

export const EcospendCheckModal = observer((props: EcospendCheckModalProps) => {
    const { isOpen, isLoading, onClose, onOk } = props
    return (
        <div>
            <Modal
                onClose={ onClose }
                isOpen={ isOpen }
                classNames={{
                    content: styles.ModalContent,
                    overlay: styles.Overlay
                }}
            >
                <SpinThreeDotsLoader isLoading={isLoading}>
                    <div className={ styles.Content }>
                        <div className={ styles.Message }>
                            <div className={ styles.Header }>
                                { translations().headerMessage }
                            </div>
                        </div>
                        
                        <div className={ styles.Actions }>
                            <ActionButton
                                className={ styles.CancelButton }
                                colorStyle={ StyleStore.style }
                                onClick={ onClose}
                            >
                                { translations().no }
                            </ActionButton>
                            <Button
                                className={ styles.OkButton }
                                onClick={ onOk }
                                colorStyle={ StyleStore.style }
                            >
                                { translations().yes }
                            </Button>
                        </div>
                    </div>
                </SpinThreeDotsLoader>
            </Modal>
        </div>
    )
})
