// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1VnhFmFL4kNT--F0kD1FmC ._2-uW3XCdv4FvRwztpdDNlv{font-family:\"Open Sans\",sans-serif;font-size:16px;color:#000}._1VnhFmFL4kNT--F0kD1FmC ._2-uW3XCdv4FvRwztpdDNlv ._3HFY8KABTLfpGpuw70oENL{font-weight:700}._1VnhFmFL4kNT--F0kD1FmC ._2xQ2dPApopmW1O4bqzcy-1{flex-grow:1;display:flex;justify-content:space-between;align-items:flex-end}@media(min-width: 768px){._1VnhFmFL4kNT--F0kD1FmC ._2xQ2dPApopmW1O4bqzcy-1{justify-content:flex-end}}._1VnhFmFL4kNT--F0kD1FmC ._2xQ2dPApopmW1O4bqzcy-1 ._35FL7cE-3YgSz1RzL4URra{padding:10px 25px;margin-left:0px}@media(min-width: 768px){._1VnhFmFL4kNT--F0kD1FmC ._2xQ2dPApopmW1O4bqzcy-1 ._35FL7cE-3YgSz1RzL4URra{margin-left:30px;padding:12px 30px}}._1VnhFmFL4kNT--F0kD1FmC ._2xQ2dPApopmW1O4bqzcy-1 ._2LvBMv6sJ3YoIHGPzwTNi6{padding:9px 29px}@media(min-width: 768px){._1VnhFmFL4kNT--F0kD1FmC ._2xQ2dPApopmW1O4bqzcy-1 ._2LvBMv6sJ3YoIHGPzwTNi6{padding:9px 34px}}.hNd0gzKt-b2iIzIbHIYA9{width:100%;max-width:420px;min-height:150px;padding:20px;box-sizing:border-box}@media(min-width: 768px){.hNd0gzKt-b2iIzIbHIYA9{width:420px}}.hNd0gzKt-b2iIzIbHIYA9,.hNd0gzKt-b2iIzIbHIYA9>div,._1VnhFmFL4kNT--F0kD1FmC{display:flex;flex-direction:column;justify-content:space-between;flex-grow:1}._3d3W88ybipttfwUqNvnLIw{z-index:2147000100}", ""]);
// Exports
exports.locals = {
	"Content": "_1VnhFmFL4kNT--F0kD1FmC",
	"Message": "_2-uW3XCdv4FvRwztpdDNlv",
	"Header": "_3HFY8KABTLfpGpuw70oENL",
	"Actions": "_2xQ2dPApopmW1O4bqzcy-1",
	"CancelButton": "_35FL7cE-3YgSz1RzL4URra",
	"CloseButton": "_2LvBMv6sJ3YoIHGPzwTNi6",
	"ModalContent": "hNd0gzKt-b2iIzIbHIYA9",
	"Overlay": "_3d3W88ybipttfwUqNvnLIw"
};
module.exports = exports;
