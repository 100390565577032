// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3qTTdlDVfArNNcA3yy7ejo{display:flex;justify-content:space-between;align-items:stretch;flex-direction:column}@media(min-width: 768px){._3qTTdlDVfArNNcA3yy7ejo{flex-direction:row}}._2KrFGcqcMwzDQsNsqOACT7{width:100%;margin-right:0}@media(min-width: 768px){._2KrFGcqcMwzDQsNsqOACT7{width:65%;margin-right:24px}}._3p1H3aTGazakiXyq3VTd0U{padding:0;margin:8px 0 16px}@media(min-width: 768px){._3p1H3aTGazakiXyq3VTd0U{padding:0 24px;margin:0}}", ""]);
// Exports
exports.locals = {
	"wrapper": "_3qTTdlDVfArNNcA3yy7ejo",
	"content": "_2KrFGcqcMwzDQsNsqOACT7",
	"side": "_3p1H3aTGazakiXyq3VTd0U"
};
module.exports = exports;
