import loadScript from 'load-script'
import { action, computed, observable } from 'mobx'
import { IPayByBankParentStore } from './models'
import { ConfigStore } from '~/code/config'
import { PaymentMethod } from '~/code/models'
import { log } from '~/code/services/logger'
import { extractAllowedCardNetworks } from '~/code/services'
import { PaymentMethodStatus } from '~/code/models/PaymentMethodStatus'
import { PayByBankEvents } from '~/code/pages/PayByBank/models/PayByBankEvents'

type ScriptLoadingState = 'idle' | 'loading' | 'loaded' | 'failed'

export class PayByBankStore {

    public parentStore: IPayByBankParentStore

    @observable
    public isLoading: boolean = false

    @observable
    public isProcessingPayment = false

    @observable
    public isPaymentSuccessful: boolean = false

    @observable
    public availabilityState: 'unknown' | 'available' | 'unavailable' = 'unknown'

    public currentPaymentState: 'beforeButtonLoad' | 'afterButtonLoad' | 'duringPayment'

    constructor (parentStore: IPayByBankParentStore) {
        this.parentStore = parentStore
        this.currentPaymentState = 'beforeButtonLoad'
        this.startLoading()
    }

    @computed
    public get paymentData() {
        return this.parentStore.paymentData
    }

    @action
    public startLoading() {
        this.isLoading = true
    }

    @action
    public stopLoading() {
        this.isLoading = false
    }

    public onClick = () => {
        log('PayByBank button has been clicked')
        this.currentPaymentState = 'duringPayment'
        this.startPaymentProcessing()
    }

    public onError = (err) => {
        log('Error has occurred', err)
        if (this.currentPaymentState === 'beforeButtonLoad' || this.currentPaymentState === 'afterButtonLoad') {
            ConfigStore.setPaymentStatus(PaymentMethod.PayByBank, PaymentMethodStatus.FAILED)
        } else {
            ConfigStore.setField('paymentMethod', PaymentMethod.PayByBank)
            this.parentStore.stopPaymentProcessing(false)
            this.parentStore.handleFailureResult(err)
        }
    }

    public onCancel = () => {
        log('The payment has been cancelled')
        this.stopPaymentProcessing()
        this.currentPaymentState = 'afterButtonLoad'
    }

    public onPaymentSuccess = (result) => {
        log('Payment has successfully been processed', result)
        this.isPaymentSuccessful = true
        this.currentPaymentState = 'afterButtonLoad'
        ConfigStore.setField('paymentMethod', PaymentMethod.PayByBank)
        this.parentStore.transactionId = result.id
        this.parentStore.handleSuccessResult()
    }

    private  events: PayByBankEvents = {
        onClick: this.onClick,
        onError: this.onError,
        onPaymentSuccess: this.onPaymentSuccess,
        onCancel: this.onCancel
    }

    @action
    public loadPBBA = async (buttonContainer) => {
        loadScript(PAY_BY_BANK_LIB_URL, {}, (error, result) => {
            if (error) {
                log('error', 'PayByBankStore.loadPayByBank', error)
                ConfigStore.setPaymentStatus(PaymentMethod.PayByBank, PaymentMethodStatus.FAILED)
                this.stopLoading()
            } else {
                const allowedCardNetworks = extractAllowedCardNetworks(ConfigStore?.acceptedCardSchemes)
                let component = null

                try {
                    component = window.DNAPayments?.PayByBankComponent?.create(
                        buttonContainer,
                        this.events,
                        this.paymentData.auth.access_token,
                        this.paymentData,
                        {
                            hideMoreAbout: true,
                            width: '100%',
                            height: '46px'
                        })
                    this.stopLoading()
                    this.currentPaymentState = 'afterButtonLoad'
                } catch (error) {
                    log('error', 'PayByBankStore.loadPBBA', error)
                    ConfigStore.setPaymentStatus(PaymentMethod.PayByBank, PaymentMethodStatus.FAILED)
                    this.stopLoading()
                }

                return component
            }
        })
    }

    @action
    public startPaymentProcessing = () => {
        this.isProcessingPayment = true
        this.parentStore.startPaymentProcessing()
    }

    @action
    public stopPaymentProcessing = () => {
        this.isProcessingPayment = false
        this.parentStore.stopPaymentProcessing(this.isPaymentSuccessful, true)
    }
}
