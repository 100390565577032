import translations from './translations'

translations.add('es', {
    moreDetails: 'Solo algunos detalles más',
    pleaseEnterEmail: 'Introduzca un correo electrónico',

    pleaseEnterValidEmail: 'Por favor, introduzca un correo electrónico válido',
    emailMustNotBeGreaterThan256: 'El correo electrónico no debe ser superior a 256 caracteres',
    pleaseEnterFirstName: 'Por favor insira o primeiro nome',
    firstNameMustNotBeGreaterThan32: 'O primeiro nome não deve ser superior a 32 caracteres',
    pleaseEnterLastName: 'Por favor insira o apelido',
    lastNameMustNotBeGreaterThan32: 'O apelido não deve ser superior a 32 caracteres',
    pleaseSelectCountry: 'Por favor selecione o país',
    countryMustNotBeGreaterThan2: 'O país não deve ser superior a 2 caracteres',
    pleaseEnterStreet: 'Por favor insira a morada',
    streetMustNotBeGreaterThan50: 'A morada não deve ser superior a 50 caracteres',
    pleaseEnterCity: 'Por favor insira a cidade',
    cityMustNotBeGreaterThan50: 'A cidade não deve ser superior a 50 caracteres',
    pleaseEnterPostalCode: 'Por favor insira o código postal',
    postalCodeMustNotBeGreaterThan13: 'O código postal não deve ser superior a 13 caracteres',
    pleaseEnterMobilePhone: 'Por favor insira o telemóvel',
    mobilePhoneMustNotBeGreaterThan16: 'O telemóvel não deve ser superior a 16 caracteres',
    mobilePhoneMustNotBeLessThan8: 'O telemóvel não deve ser inferior a 8 caracteres',

    mustNotBeGreaterThan: 'no debe tener más de',
    mustNotBeLessThan: 'no debe tener menos de',
    characters: 'caracteres',
    billingAddress: 'Dirección de facturación',
    deliveryAddress: 'Dirección de entrega',
    country: 'País',
    city: 'Ciudad',
    street: 'Dirección',
    email: 'Correo electrónico',
    firstName: 'Nombre',
    lastName: 'Apellidos',
    postalCode: 'Código Postal',
    mobilePhone: 'Teléfono móvil',
    mobilePhoneInInternationalFormat: 'Formato internacional',
    pay: 'PAGAR',
    submit: 'ENTREGAR',

    pleaseEnterAllRequiredFields: 'Por favor, introduzca todos los campos requeridos'
})
