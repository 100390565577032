import lzString from 'lz-string'
import { Base64 } from 'js-base64'
import JSEncrypt from 'jsencrypt'
import { ConfigStore, LogStore } from '~/code/config'
import * as errorCodes from '~/code/config/LogStore'
import { arrayBufferToString, encryptDataWithPublicKey, stringToArrayBuffer } from '~/code/services/rsa'

export const tryJSON = (json: string) => {
    try {
        return JSON.parse(json)
    } catch {
        return null
    }
}

export function encodeToUrl(obj: object) {
    return Base64.encode(lzString.compressToEncodedURIComponent(JSON.stringify(obj)))
}

export function decodeFromUrl (str: string) {
    try {
        return JSON.parse(lzString.decompressFromEncodedURIComponent(Base64.decode(str)))
    } catch (e) {
        LogStore.error(errorCodes.GLOBAL_ERROR_CAN_NOT_PARSE_PAYMENT_DATA, null, e)
    }
}

export function cryptoCardDataJSEncrypt(dataToEncript: object) { // todo: add int
    const encrypter = new JSEncrypt()
    encrypter.setPublicKey(ConfigStore.cryptoKey)
    const stringForCryptogram = JSON.stringify(dataToEncript)
    return encrypter.encrypt(stringForCryptogram)
}

export async function cryptoCardDataV2(dataToEncript: object) { // todo: add int
    const pem = ConfigStore.cryptoKey

    // fetch the part of the PEM string between header and footer
    const pemHeader = '-----BEGIN PUBLIC KEY-----'
    const pemFooter = '-----END PUBLIC KEY-----'
    const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length)
    const binaryDerString = window.atob(pemContents)
    const binaryDer = stringToArrayBuffer(binaryDerString)

    const publicKey = await window.crypto.subtle.importKey(
        'spki',
        binaryDer,
        {
            name: 'RSA-OAEP',
            hash: { name: 'SHA-256' }
        },
        true,
        ['encrypt']
    )
    const enc = await encryptDataWithPublicKey(JSON.stringify(dataToEncript), publicKey, ConfigStore?.auth?.access_token)
    return window.btoa(arrayBufferToString(enc))
}

export function generateInvoiceId (): string {
    return new Date().getTime().toString()
}

export function generateAmount() {
    return randomIntFromInterval(1, 100, 2)
}

export function randomIntFromInterval(min: number, max: number, decimalPlaces: number) {
    const rand = Math.random() * (max - min) + min
    const power = Math.pow(10, decimalPlaces)
    return Math.floor(rand * power) / power
}
