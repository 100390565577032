import { TransactionNotifyStatus } from '~/code/models/TransactionNotifyStatus'
import { EcospendStatusType } from '../models'

export function isStatusOk(status: EcospendStatusType) {
    return status === 'Verified' || status === 'Completed'
}

export const convertTransactionToEcospendStatus = (status: TransactionNotifyStatus): EcospendStatusType => {
    switch (status) {
        case TransactionNotifyStatus.ABANDONED:
            return 'Abandoned'
        case TransactionNotifyStatus.AUTH:
            return 'Verified'
        case TransactionNotifyStatus.CANCEL:
            return 'Canceled'
        case TransactionNotifyStatus.REFUND:
        case TransactionNotifyStatus.CHARGE:
            return 'Completed'
        case TransactionNotifyStatus.FAILED:
            return 'Failed'
        case TransactionNotifyStatus.REJECT:
            return 'Rejected'
        case TransactionNotifyStatus.CREATED:
            return null
    }
}