import translations from './translations'

translations.add('en', {
    back: 'back',
    label: 'Send the payment receipt to',
    placeholder: 'Email address',
    inputButton: 'Send',
    text: 'The payment receipt will be sent to',
    link: 'Send to a different email address',
    button: 'Send receipt',
    invalidEmail: 'email is invalid',
    successText: 'The payment receipt has been sent successfully.',
    errorText: 'Failed to send the payment receipt, please try again later.'
})
