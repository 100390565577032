import translations from './translations'

translations.add('pt', {
    paymentReject: 'O pagamento falhou',
    tokenizationReject: 'A tokenização falhou',
    verificationReject: 'A verificação falhou',
    hasNotAuthorisedPleaseCheck: 'O seu cartão não foi autorizado, verifique os dados e tente novamente ou contacte o seu banco.',
    hasNotAuthorisedPleaseRetry: 'O seu cartão não foi autorizado, tente novamente.',
    hasBeenTechnicalError: 'Ocorreu um erro técnico e o seu cartão não foi autorizado.',
    payPalWasNotCompleted: 'A sua transação do PayPal não foi concluída, tente novamente ou selecione outro método de pagamento.',
    payByBankWasNotCompleted: 'A sua transação da aplicação Pay by bank não foi concluída. Tente novamente ou selecione outro método de pagamento.',
    googlePayWasNotCompleted: 'A sua transação do Google Pay não foi concluída. Tente novamente ou selecione outra forma de pagamento.',
    applePayWasNotCompleted: 'A sua transação do Apple Pay não foi concluída, tente novamente ou selecione outro método de pagamento.',
    ecospendWasNotCompleted: 'A sua transação no Open Banking não foi concluída, tente novamente ou selecione outro método de pagamento.',
    klarnaWasNotCompleted: 'O seu pagamento Klarna não foi bem-sucedido, tente novamente ou selecione outro método de pagamento.',
    clickToPayWasNotCompleted: 'O seu pagamento com Click To Pay não foi bem-sucedido, tente novamente ou selecione outro método de pagamento.',
    invalidUnsupportedPaymentMethod: 'Método de pagamento inválido/não suportado',
    tryAgain: 'Tentar novamente',
    returnToBack: 'Voltar ao site'
})
