import { action, computed, observable, runInAction } from 'mobx'
import { openPopupWindow } from '~/code/services/window-utils'
import { KlarnaCreateOrderResponseModel } from '~/code/pages/Klarna/models/KlarnaCreateOrderResponseModel'
import { apiCheck, noThrow } from 'back-connector'
import { addListener, postWithToken } from '~/code/services'
import { ConfigStore, LogStore } from '~/code/config'
import * as errorCodes from '~/code/config/LogStore'
import { KlarnaReturnPageQueryModel } from '~/code/pages/Klarna/models/KlarnaReturnPageQueryModel'
import { Page, PaymentMethod } from '~/code/models'
import { KlarnaParentStore } from '~/code/pages/Klarna/models/KlarnaParentStore'
import translations from './translations'
import _ from 'lodash'
import { InputFieldName } from '~/code/pages/OrderPersonalInfo/models'

export class KlarnaStore {

    constructor(public parentStore: KlarnaParentStore) {
        addListener('message', this.postMessageListener, window)
    }

    popupWindow: Window = null

    @observable
    isProcessingPayment: boolean = false

    @observable
    isCreatingOrder: boolean = false

    @observable
    createOrderResponse: KlarnaCreateOrderResponseModel = null

    @observable
    result: KlarnaReturnPageQueryModel = null

    @observable
    public isPaymentSuccessful: boolean = false

    @computed
    get paymentData() {
        return this.parentStore?.paymentData
    }

    @computed
    public get billingAddress() {
        const customerDetails = this.paymentData?.customerDetails
        const personalInfoFields = this.parentStore.klarnaOrderPersonalInfoStore.getFieldValues()
        const obj = {
            firstName: customerDetails?.billingAddress?.firstName || personalInfoFields[InputFieldName.AccountFirstName],
            lastName: customerDetails?.billingAddress?.lastName || personalInfoFields[InputFieldName.AccountLastName],
            streetAddress1: customerDetails?.billingAddress?.addressLine1 || personalInfoFields[InputFieldName.BillingAddressAddressLine1],
            streetAddress2: customerDetails?.billingAddress?.addressLine2,
            postalCode: customerDetails?.billingAddress?.postalCode || personalInfoFields[InputFieldName.BillingAddressPostalCode],
            city: customerDetails?.billingAddress?.city || personalInfoFields[InputFieldName.BillingAddressCity],
            phone: customerDetails?.billingAddress?.phone,
            country: customerDetails?.billingAddress?.country || personalInfoFields[InputFieldName.BillingAddressCountry]
        }
        const isEmpty = !_.some(obj)
        return isEmpty ? null : obj
    }

    @computed
    public get shippingAddress() {
        const deliveryAddress = this.paymentData?.customerDetails?.deliveryDetails?.deliveryAddress || {}
        const personalInfoFields = this.parentStore.klarnaOrderPersonalInfoStore.getFieldValues()
        return {
            ...deliveryAddress,
            firstName: deliveryAddress?.firstName || personalInfoFields[InputFieldName.AccountFirstName],
            lastName: deliveryAddress?.lastName || personalInfoFields[InputFieldName.AccountLastName],
            streetAddress1: deliveryAddress?.addressLine1 || personalInfoFields[InputFieldName.DeliveryAddressAddressLine1],
            postalCode: deliveryAddress?.postalCode || personalInfoFields[InputFieldName.DeliveryAddressPostalCode],
            city: deliveryAddress?.city || personalInfoFields[InputFieldName.DeliveryAddressCity],
            country: deliveryAddress?.country || personalInfoFields[InputFieldName.DeliveryAddressCountry]
        }
    }

    @computed
    get order() {
        const paymentData = this.paymentData
        const personalInfoFields = this.parentStore.klarnaOrderPersonalInfoStore.getFieldValues()
        const customerDetails = paymentData?.customerDetails
        const order =
        {
            ...paymentData,
            accountId: customerDetails?.accountDetails?.accountId,
            email: customerDetails?.email || personalInfoFields[InputFieldName.AccountEmail],
            locale: paymentData?.language,
            terminalId: paymentData?.paymentSettings?.terminalId,
            paymentMethod: 'klarna',
            returnUrl: this.returnUrl,
            billingAddress: this.billingAddress,
            shippingAddress: this.shippingAddress,
            callbackUrl: paymentData?.paymentSettings?.callbackUrl,
            failureCallbackUrl: paymentData?.paymentSettings?.failureCallbackUrl,
            browser: paymentData?.customerDetails?.browserDetails
        }
        return order
    }

    @computed
    get hasMerchantLink() {
        return Boolean(this.paymentData?.paymentSettings?.returnUrl)
    }

    get returnUrl() {
        return `${ConfigStore.getUrl().paymentPageUrl}/klarna-return-page.html`
    }

    public openKlarnaPopupWindow = () => {
        const url = `${ConfigStore.getUrl().paymentPageUrl}/loading.html`
        this.popupWindow = openPopupWindow(url, 'klarna-popup-window', this.onPopupWindowClose)
    }

    @action
    public setProcessingPayment = (isProcessingPayment: boolean) => {
        this.isProcessingPayment = isProcessingPayment
        if (isProcessingPayment) {
            this.parentStore.startPaymentProcessing()
        } else {
            this.parentStore.stopPaymentProcessing(this.isPaymentSuccessful)
        }
    }

    @action
    public onClick = async () => {
        this.parentStore.validateKlarnaRequiredFields()
    }

    @action
    public startPaymentProcessing = async () => {
        this.setProcessingPayment(true)
        this.openKlarnaPopupWindow()
        this.createOrderResponse = await this.createOrder()
        if (this.createOrderResponse) {
            this.popupWindow.location.href = this.createOrderResponse.redirectUrl
        }
    }

    @action
    onPopupWindowClose = () => {
        this.popupWindow = null
        this.setProcessingPayment(false)
        this.parentStore.stopPaymentProcessing(this.isPaymentSuccessful, !this.isPaymentSuccessful)
    }

    @action
    async createOrder() {
        this.isCreatingOrder = true
        const result = await noThrow(apiCheck(postWithToken<KlarnaCreateOrderResponseModel>(`${ ConfigStore.getUrl().apiUrl }/payments/alternative/createOrder`, this.order)))
        this.isCreatingOrder = false
        if (result.error || !result?.value?.success) {
            this.setProcessingPayment(false)
            this.popupWindow.close()
            LogStore.error(errorCodes.KLARNA_CANNOT_CREATE_ORDER)
            ConfigStore.setField('paymentMethod', PaymentMethod.Klarna)
            this.parentStore.handleFailureResult({
                // @ts-ignore
                code: result.error ? result.error.errorCode : result.value?.errorCode,
                message: translations().yourKlarnaPaymentUnsuccessful
            })
            return null
        }
        return result.value
    }

    @action
    setResult(result: KlarnaReturnPageQueryModel) {
        ConfigStore.setField('paymentMethod', PaymentMethod.Klarna)
        if (result.status?.toLowerCase() === 'success') {
            this.isPaymentSuccessful = true
            this.parentStore.currentPageName = Page.SUCCESS
            this.parentStore.transactionId = this.createOrderResponse?.id
            this.parentStore.handleSuccessResult()
        } else {
            this.isPaymentSuccessful = false
            this.parentStore.currentPageName = Page.FAILURE
            LogStore.error(errorCodes.KLARNA_PAYMENT_FAILED)
            this.parentStore.handleFailureResult({
                code: 0,
                message: translations().yourKlarnaPaymentUnsuccessful
            })
        }
    }

    @action
    back() {
        this.createOrderResponse = null
        this.result = null
    }

    postMessageListener = (event: MessageEvent<KlarnaReturnPageQueryModel>) => {
        if (event.data?.page !== 'klarna-return-page' || event.data?.id !== this.createOrderResponse?.id) return

        this.setResult(event.data)

        if (this.popupWindow) {
            this.popupWindow.close()
        }
    }
}
