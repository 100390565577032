import React from 'react'
import classNames from 'classnames'
import { StyleStore } from '~/code/config'
import { LeftArrow } from '~/assets/icons'
import { Link } from '../Link'
import { BackLinkProps } from './props'
import styles from './BackLink.scss'

export const BackLink: React.FC<BackLinkProps> = (props) => (
    <Link className={ classNames(styles.BackLink, props.className) } onClick={ props.onClick }>
        <LeftArrow color={ StyleStore.style.primaryColor } /> { props.text }
    </Link>
)
