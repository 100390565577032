// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media screen and (max-width: 767.9px){._3H3FCSNDBaQrXWStn8Mupi{margin-top:24px}}", ""]);
// Exports
exports.locals = {
	"EcospendBackLink": "_3H3FCSNDBaQrXWStn8Mupi"
};
module.exports = exports;
