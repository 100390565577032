import translations from './translations'

translations.add('pt', {
    yourClickToPayPaymentUnsuccessful: 'O seu pagamento com Click To Pay não foi bem-sucedido. Tente novamente mais tarde',
    makeSureEmailIsCorrect: 'Please make sure your email is correct and try again',
    unknownError: 'Unknown error, please contact Mastercard',
    errors: {
        signOut: 'A desassociação falhou.'
    },
    messages: {
        signOut: 'Desassociado com sucesso!'
    }
})
