// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".YwsFjTiPkpBbzAKBtyISa{background:#fff;border:1px solid #9b9b9b;border-radius:24px;font-size:16px;padding:10px 26px;text-transform:uppercase;color:#9b9b9b;font-family:\"Open Sans\",sans-serif;cursor:pointer;outline:none}.Yji966j4zbtRiMyQc0-7C{cursor:not-allowed}", ""]);
// Exports
exports.locals = {
	"ActionButton": "YwsFjTiPkpBbzAKBtyISa",
	"disabled": "Yji966j4zbtRiMyQc0-7C"
};
module.exports = exports;
