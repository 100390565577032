// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2uzpITAMv1CrSbOrVzMY8O{display:inline-flex}._2uzpITAMv1CrSbOrVzMY8O ._2e-4LuX3RE6DtE55ur-nrU{padding:0;height:initial;width:initial;margin-bottom:0;display:none;cursor:pointer}._2uzpITAMv1CrSbOrVzMY8O ._3zhztrRelnOCnvkAAQ5odc{position:relative;cursor:pointer}._2uzpITAMv1CrSbOrVzMY8O ._3zhztrRelnOCnvkAAQ5odc._3w9hRP5RrZYKZ-hItfWUBn:before{margin-right:5px}._2uzpITAMv1CrSbOrVzMY8O ._3zhztrRelnOCnvkAAQ5odc:before{content:\"\";-webkit-appearance:none;background-color:rgba(0,0,0,0);border:2px solid;border-radius:4px;padding:7px;display:inline-block;position:relative;vertical-align:middle;cursor:pointer;border-color:var(--primary-color)}._2uzpITAMv1CrSbOrVzMY8O ._2e-4LuX3RE6DtE55ur-nrU:checked+._3zhztrRelnOCnvkAAQ5odc:after{content:\"\";display:block;position:absolute;top:5.5px;left:6px;width:4px;height:10px;border:solid;border-width:0 2px 2px 0;transform:rotate(45deg);border-color:var(--primary-color)}._2uzpITAMv1CrSbOrVzMY8O ._3Hh4EZg76Daieb94Aj21yh{cursor:not-allowed !important;opacity:.5}._2uzpITAMv1CrSbOrVzMY8O ._3Hh4EZg76Daieb94Aj21yh+._3zhztrRelnOCnvkAAQ5odc:before{cursor:not-allowed !important}._2uzpITAMv1CrSbOrVzMY8O ._3Hh4EZg76Daieb94Aj21yh+._3zhztrRelnOCnvkAAQ5odc:after{cursor:not-allowed !important}", ""]);
// Exports
exports.locals = {
	"Checkbox": "_2uzpITAMv1CrSbOrVzMY8O",
	"Input": "_2e-4LuX3RE6DtE55ur-nrU",
	"Label": "_3zhztrRelnOCnvkAAQ5odc",
	"HasLabel": "_3w9hRP5RrZYKZ-hItfWUBn",
	"disabled": "_3Hh4EZg76Daieb94Aj21yh"
};
module.exports = exports;
