// BLOCK_SPECIFIC-MESSAGE

const GLOBAL_ERROR = 100000
export const GLOBAL_ERROR_DEFAULT = `${GLOBAL_ERROR}_1`
export const GLOBAL_ERROR_CAN_NOT_PARSE_PAYMENT_DATA = `${GLOBAL_ERROR}_2`
export const GLOBAL_ERROR_COULD_NOT_DO_POST_MESSAGE_IN_HANDLE_SUCCESS_RESULT = `${GLOBAL_ERROR}_3`


const APPSTORE = 100001
export const APPSTORE_DEFAULT = `${APPSTORE}_1`
export const APPSTORE_DO_NOT_CONFIGURATION = `${APPSTORE}_2`
export const APPSTORE_HAS_NOT_AUTH_TOKEN = `${APPSTORE}_3`
export const APPSTORE_HAS_NOT_REQUEST_RESULT_V1 = `${APPSTORE}_4`
export const APPSTORE_HAS_NOT_REQUEST_RESULT_V2 = `${APPSTORE}_5`
export const APPSTORE_PAY_REQUEST_ERROR_V1 = `${APPSTORE}_6`
export const APPSTORE_PAY_REQUEST_ERROR_V2 = `${APPSTORE}_7`

const API_APPSTORE = 100002
export const API_APPSTORE_DEFAULT = `${API_APPSTORE}_1`
export const API_APPSTORE_HAS_NOT_PRIVILEGE_SEAMLESS = `${API_APPSTORE}_2`
export const API_APPSTORE_HAS_NOT_AUTH_TOKEN = `${API_APPSTORE}_3`

export const PAGE_APPSTORE = 100003
export const PAGE_APPSTORE_DEFAULT = `${PAGE_APPSTORE}_1`
export const PAGE_APPSTORE_HAS_NOT_PRIVILEGE = `${PAGE_APPSTORE}_2`


const METHOD_LIST = 100004
export const METHOD_LIST_DEFAULT = `${METHOD_LIST}_1`
export const METHOD_LIST_ECOSPEND  = `${METHOD_LIST}_2`
export const METHOD_LIST_PAYPAL  = `${METHOD_LIST}_3`
export const METHOD_LIST_PBBA  = `${METHOD_LIST}_4`
export const METHOD_LIST_GOOGLEPAY  = `${METHOD_LIST}_5`
export const METHOD_LIST_APPLEPAY  = `${METHOD_LIST}_6`
export const METHOD_LIST_KLARNA  = `${METHOD_LIST}_7`
export const METHOD_LIST_ASTROPAY  = `${METHOD_LIST}_8`


const ECOSPEND  = 100005
export const ECOSPEND_DEFAULT  = `${ECOSPEND}_1`
export const ECOSPEND_CAN_NOT_CREATE_ORDER  = `${ECOSPEND}_2`

const PAYPAL  = 100006
export const PAYPAL_DEFAULT  = `${PAYPAL}_1`
export const PAYPAL_CAN_NOT_CREATE_ORDER  = `${PAYPAL}_2`
export const PAYPAL_CAN_NOT_AUTHORIZE  = `${PAYPAL}_3`
export const PAYPAL_CANCELLED  = `${PAYPAL}_4`

const PBBA  = 100007
export const PBBA_DEFAULT = `${PBBA}_1`
export const PBBA_NOT_RENDER_BUTTON = `${PBBA}_2`


const GOOGLEPAY  = 100008
export const GOOGLEPAY_DEFAULT = `${GOOGLEPAY}_1`

const APPLEPAY  = 100009
export const APPLEPAY_DEFAULT = `${APPLEPAY}_1`
export const APPLEPAY_COULD_NOT_VALIDATE = `${APPLEPAY}_2`
export const APPLEPAY_OCCURRED_WHILE_PROCESSING = `${APPLEPAY}_3`
export const APPLEPAY_COULD_NOT_AUTHORIZE = `${APPLEPAY}_4`

const KLARNA  = 100010
export const KLARNA_DEFAULT  = `${KLARNA}_1`
export const KLARNA_CANNOT_CREATE_ORDER  = `${KLARNA}_2`
export const KLARNA_PAYMENT_FAILED  = `${KLARNA}_3`


const CLICK_TO_PAY  = 100011
export const CLICK_TO_PAY_CHECKOUT_FAILED  = `${CLICK_TO_PAY}_1`
export const CLICK_TO_PAY_CHECKOUT_FAILED_DUE_TO_UNEXPECTED_ERROR  = `${CLICK_TO_PAY}_2`

const ASTROPAY  = 100012
export const ASTROPAY_CAN_NOT_CREATE_ORDER  = `${ASTROPAY}_1`

