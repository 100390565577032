import { action, computed, observable } from 'mobx'
import { getTypeInfo } from 'credit-card-type'
import { CardItemPageParentStore } from './models'
import { CardInfo, InputField, InputFieldName } from '~/code/pages/CardData/models'
import { isCSCRequired } from '~/code/services/cards'
import translations from './translations'
import { ClickToPayStore } from '~/code/pages/CardData/components/ClickToPay/ClickToPayStore'
import { INVALID_CVV } from '../CardData/constants/error-codes'

export class CardItemPageStore {
    private parentStore: CardItemPageParentStore
    private clickToPayStore: ClickToPayStore

    constructor (parentStore: CardItemPageParentStore, clickToPayStore: ClickToPayStore) {
        this.parentStore = parentStore
        this.clickToPayStore = clickToPayStore
    }

    @observable
    public cvcInput: HTMLInputElement = null

    @computed
    public get hasNextPage() {
        return !this.parentStore.isOrderInfoValid
    }

    @observable
    public fields: { [key in string]: InputField<string> } = {
        [InputFieldName.Cvv]: {
            name: InputFieldName.Cvv,
            value: '',
            onChange: (value: string) => {
                const field = this.fields[InputFieldName.Cvv]
                this.fields[InputFieldName.Cvv].errorCode = field.validate(value)
                field.value = value
            },
            validate: (value: string = this.fields[InputFieldName.Cvv].value): string => {
                if (value) {
                    return value.length < 3 || (this.cardInfo && this.cardInfo.code && value.length !== this.cardInfo.code.size) ? INVALID_CVV : ''
                } else if (!isCSCRequired(this.selectedCard)) {
                    return ''
                }
                return INVALID_CVV
            },
            errorCode: ''
        }
    }

    @action
    public showInitCardListPage() {
        this.parentStore.showInitCardListPage()
    }

    @computed
    public get cardInfo(): CardInfo {
        return getTypeInfo(this.selectedCard.creditCardType)
    }

    @computed
    public get selectedCard() {
        return this.parentStore.selectedCard
    }

    @action
    async confirmCardData (_invokedFrom: string) {
        const fields = Object.entries(this.fields || {}).filter(([key, value]) => {
            const error = value.validate()
            if (error) {
                value.errorCode = error
                return true
            }
            return false
        })
        if (fields.length === 0) {
            this.parentStore.onCardItemCardDataConfirm(_invokedFrom + '->CardItemPageStore.confirmCardData')
        }
    }
}
