import React from 'react'
import translations from './translations'

translations.add('es', {
    paymentSuccessful: 'Pago realizado correctamente',
    tokenizationSuccessful: 'Registro de tarjeta correcto',
    verificationSuccessful: 'Verificación de tarjeta realizada correctamente',
    autoRedirectAfter: 'Será redirigido automáticamente al sitio en',
    seconds: 'segundo',
    backToStore: 'Volver al sitio',
    yourPaymentWasSuccessfulWith: (paymentMethod: string, paymentDescription?: string) => {
        return (<>El pago se procesado correctamente con <strong>{paymentMethod} {paymentDescription}</strong></>)
    }
})
