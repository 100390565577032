import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import translations from './translations'
import { TimeoutWarningMessageProps } from './props'
import styles from './TimeoutWarningMessage.scss'

export const TimeoutWarningMessage = observer((props: TimeoutWarningMessageProps) => {
    const [countDown, setCountDown] = useState(props.countDownFrom)
    const lastTime = useRef<number>(Math.floor(Date.now() / 1000) + props.countDownFrom)
    const savedIntervalCallback = useRef(null)
    const intervalCallback = () => {
        if (countDown <= 1) {
            props.onTimeout()
        }
        const now = Math.floor(Date.now() / 1000)
        setCountDown(lastTime.current - now)
    }

    useEffect(() => {
        savedIntervalCallback.current = intervalCallback
    })

    useEffect(() => {
        const tick = () => {
            savedIntervalCallback.current()
        }

        const id = setInterval(tick, 1000)
        return () => clearInterval(id)
    }, [])

    return (
        <div className={ styles.TimeoutWarningMessage }>
            <div className={ styles.message }>
                { translations().timeLeftToFinishPayment(countDown) }
            </div>
        </div>
    )
})
