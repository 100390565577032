import React, { useEffect } from 'react'
import { PaymentApiAppStore } from './PaymentApiAppStore'
import { observer } from 'mobx-react'
import '~/assets/styles/main.scss'

const App = observer(({ store }: { store: typeof PaymentApiAppStore }) => {
    useEffect(() => {
        store.addListeners()
        return store.removeListeners
    })
    useEffect(() => {
        store.setLanguage()
    }, [])
    const Page = observer(() => store.currentPage())
    return (
        <Page />
    )
})

export { App }
