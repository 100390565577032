import { apiCheck, noThrow } from 'back-connector'
import { TransactionNotifyStatus } from '~/code/models/TransactionNotifyStatus'
import { ConfigStore } from '../config'
import { getWithToken } from './fetchers'

export const isFailureTransactionExcludeCancel = (state: any) => {
    return [TransactionNotifyStatus.FAILED, TransactionNotifyStatus.REJECT].includes(state)
}

export const isFailureTransaction = (state: any) => {
    return [TransactionNotifyStatus.FAILED, TransactionNotifyStatus.REJECT, TransactionNotifyStatus.CANCEL].includes(state)
}
export const isSuccessTransaction = (state: any) => {
    return [TransactionNotifyStatus.CHARGE, TransactionNotifyStatus.AUTH, TransactionNotifyStatus.REFUND].includes(state)
}

export const fetchTransactionStatus = (id: string) => {
    const maxAttemptCount = 4
    const attemptDelayInMs = 1000

    let attemptCount = 0

    return new Promise<TransactionNotifyStatus>((resolve, reject) => {
        const check = async () => {
            attemptCount++
            const result = await noThrow(apiCheck(getWithToken<any>(`${ ConfigStore.getUrl().apiUrl }/payments/alternative/search/${id}/status`, { source: 'checkout' })))
            const state = result.value?.transactionState as TransactionNotifyStatus

            if (state && ![TransactionNotifyStatus.CREATED].includes(state)) {
                resolve(state)
                return
            }

            if (attemptCount > maxAttemptCount) {
                resolve(null)
            } else {
                setTimeout(check, attemptDelayInMs)
            }
        }

        check()
    })
}
