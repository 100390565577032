// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3y8cSpc2xJHYHLP9Ll69b-{font-family:\"Poppins\",sans-serif}._3y8cSpc2xJHYHLP9Ll69b- .OoyQ1jexRZgZGaUOAmgwQ{font-size:16px;color:rgba(0,0,0,.85);font-weight:500}._3y8cSpc2xJHYHLP9Ll69b- ._3cudjE8jaISfU9JUDIUGcW{font-size:14px}", ""]);
// Exports
exports.locals = {
	"OrderInfoBlock": "_3y8cSpc2xJHYHLP9Ll69b-",
	"FirstDescription": "OoyQ1jexRZgZGaUOAmgwQ",
	"SecondaryDescription": "_3cudjE8jaISfU9JUDIUGcW"
};
module.exports = exports;
