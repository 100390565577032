// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3eAe6jRboxz1tioyEdKhsg{margin-top:24px;font-family:\"Poppins\",sans-serif;font-size:14px;background-color:#f7f7f7;padding:10px;border-radius:5px}._2DcrJDxksE_Rb7TGqNFF77{margin-left:10px;font-size:12px}", ""]);
// Exports
exports.locals = {
	"root": "_3eAe6jRboxz1tioyEdKhsg",
	"content": "_2DcrJDxksE_Rb7TGqNFF77"
};
module.exports = exports;
