import React from 'react'
import { action, computed, observable } from 'mobx'
import { ThreeDSecureParentStore } from './models'
import { ConfigStore } from '~/code/config'
import { PaymentResultV1, PaymentResultV2 } from '~/code/models'

export class ThreeDSecureStore {
    @observable
    public form: any = React.createRef<HTMLFormElement>()

    @observable
    public iframe: any = React.createRef<HTMLIFrameElement>()

    @observable
    public iframeLoaded: boolean = false

    @observable
    errorMessage: string = ''

    private parentStore: ThreeDSecureParentStore

    constructor (parentStore: ThreeDSecureParentStore) {
        this.parentStore = parentStore
    }

    @computed
    public get secure3Dfield() {

        if (ConfigStore.version === 1) {
            const dRequest = (this.parentStore.paymentResult as PaymentResultV1)?.secure3D
            return  {
                version: '1',
                md: dRequest.md,
                actionUrl: dRequest.action,
                paReq: dRequest.paReq,
                termUrl: `${ ConfigStore.getUrl().apiUrl }/payments/cards/confirmPayment?Access=${ this.parentStore.paymentData.auth.access_token }`
            }
        }

        const dRequestData = (this.parentStore.paymentResult as PaymentResultV2)?.threeDS
        return {
            md: dRequestData?.md,
            actionUrl: dRequestData?.acsUrl,
            paReq: dRequestData?.pareq,
            termUrl: dRequestData?.termUrl,
            // version 2
            version: dRequestData?.version,
            creq: dRequestData?.creq,
            threeDSSessionData: dRequestData?.threeDSSessionData
        }

    }

    postMessageListener = (ev) => {
        if (ev.data) {
            this.on3DSComplete(ev.data)
        }
    }

    iFrameLoadEventListener = () => {
        if (!this.iframeLoaded) this.iframeLoaded = true
    }

    @action.bound
    setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage
    }

    addListeners = () => {
        window.addEventListener('message', this.postMessageListener, false)
        this.iframe.addEventListener('load', this.iFrameLoadEventListener)
        setTimeout(() => this.form.submit())
    }

    removeListeners = () => {
        window.removeEventListener('message', this.postMessageListener, false)
        this.iframe.removeEventListener('load', this.iFrameLoadEventListener)
    }

    on3DSComplete = (threeDSResult) => {
        if (threeDSResult) {
            this.parentStore.handle3dSecureResult({ ...threeDSResult })
        }
    }
}
