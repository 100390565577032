import { InputField, InputFieldName, InputFieldType } from './../models'
import translations from './../translations'
import { validateEmailAndItsDomain } from './validators'
import { toJS } from 'mobx'
import { MOBILE_PHONE_INTERNATIONAL_MASK } from '~/code/pages/CardData/models/constants'
import { PLEASE_ENTER_EMAIL, PLEASE_ENTER_VALID_EMAIL,
    EMAIL_MUST_NOT_BE_GREATER_THAN_256, PLEASE_ENTER_FIRST_NAME,
    FIRST_NAME_MUST_NOT_BE_GREATER_THAN_32, PLEASE_ENTER_LAST_NAME,
    LAST_NAME_MUST_NOT_BE_GREATER_THAN_32, PLEASE_SELECT_COUNTRY,
    COUNTRY_MUST_NOT_BE_GREATER_THAN_2, PLEASE_ENTER_STREET,
    STREET_MUST_NOT_BE_GREATER_THAN_50, PLEASE_ENTER_CITY,
    CITY_MUST_NOT_BE_GREATER_THAN_50, PLEASE_ENTER_POSTAL_CODE,
    POSTAL_CODE_MUST_NOT_BE_GREATER_THAN_13, PLEASE_ENTER_MOBILE_PHONE,
    MOBILE_PHONE_MUST_NOT_BE_GREATER_THAN_16, MOBILE_PHONE_MUST_NOT_BE_LESS_THAN_8,
    CITY, COUNTRY, EMAIL, FIRST_NAME, LAST_NAME, MOBILE_PHONE, POSTAL_CODE, STREET
} from '~/code/pages/OrderPersonalInfo/constants'
import { COUNTRIES } from '~/code/pages/OrderPersonalInfo/constants/countries'

export function getAllInputFields(): { [key in InputFieldName]: InputField } {
    return {
        [InputFieldName.AccountEmail]: {
            name: InputFieldName.AccountEmail,
            type: InputFieldType.Text,
            labelCode: EMAIL,
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_EMAIL // `${ translations().pleaseEnterEmail }`
                } else if (!validateEmailAndItsDomain(value)) {
                    return PLEASE_ENTER_VALID_EMAIL // `${ translations().pleaseEnterValid } ${ translations().email.toLowerCase() }`
                } else if (value.length > 256) {
                    return EMAIL_MUST_NOT_BE_GREATER_THAN_256 // `${ translations().email } ${ translations().mustNotBeGreaterThan } ${ 256 } ${ translations().characters }`
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.AccountFirstName]: {
            name: InputFieldName.AccountFirstName,
            type: InputFieldType.Text,
            labelCode: FIRST_NAME,
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_FIRST_NAME
                } else if (value.length > 32) {
                    return FIRST_NAME_MUST_NOT_BE_GREATER_THAN_32
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.AccountLastName]: {
            name: InputFieldName.AccountLastName,
            type: InputFieldType.Text,
            labelCode: LAST_NAME,
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_LAST_NAME
                } else if (value.length > 32) {
                    return LAST_NAME_MUST_NOT_BE_GREATER_THAN_32
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.AccountCountry]: {
            name: InputFieldName.AccountCountry,
            type: InputFieldType.Select,
            labelCode: COUNTRY,
            value: '',
            options: getAllCountries(),
            defaultValue: getAllCountries().find((el) => el.value === 'GB'),
            validate: (value: { label: any, value: any }): string => {
                if (!value || !value.value) {
                    return PLEASE_SELECT_COUNTRY
                } else if (value.value.length > 2) {
                    return COUNTRY_MUST_NOT_BE_GREATER_THAN_2
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.AccountStreet1]: {
            name: InputFieldName.AccountStreet1,
            type: InputFieldType.Text,
            labelCode: STREET,
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_STREET
                } else if (value.length > 50) {
                    return STREET_MUST_NOT_BE_GREATER_THAN_50
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.AccountCity]: {
            name: InputFieldName.AccountCity,
            type: InputFieldType.Text,
            labelCode: CITY,
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_CITY
                } else if (value.length > 50) {
                    return CITY_MUST_NOT_BE_GREATER_THAN_50
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.AccountPostalCode]: {
            name: InputFieldName.AccountPostalCode,
            type: InputFieldType.Text,
            labelCode: POSTAL_CODE,
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_POSTAL_CODE
                } else if (value.length > 13) {
                    return POSTAL_CODE_MUST_NOT_BE_GREATER_THAN_13
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.AccountPhone]: {
            name: InputFieldName.AccountPhone,
            type: InputFieldType.Masked,
            labelCode: MOBILE_PHONE,
            value: '',
            placeholderCode: 'mobilePhoneInInternationalFormat',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_MOBILE_PHONE
                } else if (value.length > 16) {
                    return MOBILE_PHONE_MUST_NOT_BE_GREATER_THAN_16
                } else if (value.length < 8) {
                    return MOBILE_PHONE_MUST_NOT_BE_LESS_THAN_8
                }
                return ''
            },
            errorCode: '',
            isVisible: false,
            mask: MOBILE_PHONE_INTERNATIONAL_MASK
        },

        [InputFieldName.BillingAddress]: {
            name: InputFieldName.BillingAddress,
            type: InputFieldType.Label,
            labelCode: 'billingAddress',
            isVisible: false
        },
        [InputFieldName.BillingAddressCountry]: {
            name: InputFieldName.BillingAddressCountry,
            type: InputFieldType.Select,
            labelCode: 'country',
            value: '',
            options: getAllCountries(),
            defaultValue: getAllCountries().find((el) => el.value === 'GB'),
            validate: (value: { label: any, value: any }): string => {
                if (!value || !value.value) {
                    return PLEASE_SELECT_COUNTRY
                } else if (value.value.length > 2) {
                    return COUNTRY_MUST_NOT_BE_GREATER_THAN_2
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.BillingAddressAddressLine1]: {
            name: InputFieldName.BillingAddressAddressLine1,
            type: InputFieldType.Text,
            labelCode: 'street',
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_STREET
                } else if (value.length > 50) {
                    return STREET_MUST_NOT_BE_GREATER_THAN_50
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.BillingAddressCity]: {
            name: InputFieldName.BillingAddressCity,
            type: InputFieldType.Text,
            labelCode: 'city',
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_CITY
                } else if (value.length > 50) {
                    return CITY_MUST_NOT_BE_GREATER_THAN_50
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.BillingAddressPostalCode]: {
            name: InputFieldName.BillingAddressPostalCode,
            type: InputFieldType.Text,
            labelCode: 'postalCode',
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_POSTAL_CODE
                } else if (value.length > 13) {
                    return POSTAL_CODE_MUST_NOT_BE_GREATER_THAN_13
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },

        [InputFieldName.DeliveryAddress]: {
            name: InputFieldName.DeliveryAddress,
            type: InputFieldType.Label,
            labelCode: 'deliveryAddress',
            isVisible: false
        },
        [InputFieldName.DeliveryAddressCountry]: {
            name: InputFieldName.DeliveryAddressCountry,
            type: InputFieldType.Select,
            labelCode: 'country',
            value: '',
            options: getAllCountries(),
            defaultValue: getAllCountries().find((el) => el.value === 'GB'),
            validate: (value: { label: any, value: any }): string => {
                if (!value || !value.value) {
                    return PLEASE_SELECT_COUNTRY
                } else if (value.value.length > 2) {
                    return COUNTRY_MUST_NOT_BE_GREATER_THAN_2
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.DeliveryAddressAddressLine1]: {
            name: InputFieldName.DeliveryAddressAddressLine1,
            type: InputFieldType.Text,
            labelCode: 'street',
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_STREET
                } else if (value.length > 50) {
                    return STREET_MUST_NOT_BE_GREATER_THAN_50
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.DeliveryAddressCity]: {
            name: InputFieldName.DeliveryAddressCity,
            type: InputFieldType.Text,
            labelCode: 'city',
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_CITY
                } else if (value.length > 50) {
                    return CITY_MUST_NOT_BE_GREATER_THAN_50
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        },
        [InputFieldName.DeliveryAddressPostalCode]: {
            name: InputFieldName.DeliveryAddressPostalCode,
            type: InputFieldType.Text,
            labelCode: 'postalCode',
            value: '',
            validate: (value: string): string => {
                if (!value) {
                    return PLEASE_ENTER_POSTAL_CODE
                } else if (value.length > 13) {
                    return POSTAL_CODE_MUST_NOT_BE_GREATER_THAN_13
                }
                return ''
            },
            errorCode: '',
            isVisible: false
        }
    }
}

function getValue (field, fieldValue) {
    if (field.type === InputFieldType.Select) {
        return field.options.find((el) => el.value === fieldValue) || {}
    }
    return fieldValue
}

export function getVisibleField(personalInfoFields: { [key: string]: boolean }, paymentData: { [key: string]: any }) {
    const newAllFields = {}
    Object.entries(getAllInputFields()).forEach(([fieldName, field]) => {
        const fieldValue = toJS(paymentData[fieldName])
        field.value =  fieldValue ? getValue(field, fieldValue) : ''
        field.isVisible = !!toJS(personalInfoFields?.[fieldName])

        if (field.value || field.isVisible) {
            if (field.value) {
                const errorCode = field.validate(field.value)
                if (errorCode) {
                    field.errorCode = errorCode
                    field.isVisible = true
                } else {
                    field.isVisible = false
                }
            } else if (!field.value && field.defaultValue) {
                field.value = field.defaultValue
            }

            newAllFields[fieldName] = field
        }
    })

    return newAllFields
}

export const getAllCountries = () => {
    return COUNTRIES.map(({code, name}) => ({ label: name, value: code }))
}
