import translations from './translations'

translations.add('is', {
    moreDetails: 'Bara nokkrar frekari upplýsingar',
    pleaseEnterEmail: 'Vinsamlegast sláðu inn netfang',

    pleaseEnterValidEmail: 'Vinsamlegast sláðu inn gilt netfang',
    emailMustNotBeGreaterThan256: 'Netfang má ekki vera meira en 256 stafir',
    pleaseEnterFirstName: 'Vinsamlegast sláðu inn fornafn',
    firstNameMustNotBeGreaterThan32: 'Fornafn má ekki vera meira en 32 stafir',
    pleaseEnterLastName: 'Vinsamlegast sláðu inn eftirnafn',
    lastNameMustNotBeGreaterThan32: 'Eftirnafn má ekki vera meira en 32 stafir',
    pleaseSelectCountry: 'Vinsamlegast veldu land',
    countryMustNotBeGreaterThan2: 'Land má ekki vera meira en 2 stafir',
    pleaseEnterStreet: 'Vinsamlegast sláðu inn heimilisfang',
    streetMustNotBeGreaterThan50: 'Heimilisfang má ekki vera meira en 50 stafir',
    pleaseEnterCity: 'Vinsamlegast sláðu inn borg',
    cityMustNotBeGreaterThan50: 'Borg má ekki vera meira en 50 stafir',
    pleaseEnterPostalCode: 'Vinsamlegast sláðu inn póstnúmer',
    postalCodeMustNotBeGreaterThan13: 'Póstnúmer má ekki vera meira en 13 stafir',
    pleaseEnterMobilePhone: 'Vinsamlegast sláðu inn farsímanúmer',
    mobilePhoneMustNotBeGreaterThan16: 'Farsímanúmer má ekki vera meira en 16 stafir',
    mobilePhoneMustNotBeLessThan8: 'Farsímanúmer má ekki vera minna en 8 stafir',

    mustNotBeGreaterThan: 'má ekki vera meira en',
    mustNotBeLessThan: 'má ekki vera minna en',
    characters: 'stafir',
    billingAddress: 'Heimilisfang innheimtu',
    deliveryAddress: 'Heimilisfang afhendingar',
    country: 'Land',
    city: 'Borg',
    street: 'Heimilisfang',
    email: 'Netfang',
    firstName: 'Fornafn',
    lastName: 'Eftirnafn',
    postalCode: 'Póstnúmer',
    mobilePhone: 'Farsími',
    mobilePhoneInInternationalFormat: 'Alþjóðlegt snið',
    pay: 'BORGA',
    submit: 'SENDA',

    pleaseEnterAllRequiredFields: 'Vinsamlegast sláðu inn öll krafist reiti'
})
