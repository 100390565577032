import React from 'react'
import { action, computed, observable, reaction, runInAction, toJS } from 'mobx'
import isDeepEqual from 'deep-equal'
import _ from 'lodash'
import { check, noThrow } from 'back-connector'
import { ConfigStore, LogStore, StyleStore } from '~/code/config'
import { AppStoreInterface, AuthError, CardItem, FormEvents, IframeWrapperAction, IntegrationScope, Page, PageType, PaymentDataConfig, PaymentDataV1, PaymentDataV2, PaymentFormConfigure, PaymentFormWrapperOptions, PaymentMethod, PaymentResultV1, PaymentResultV2, TransactionType } from '~/code/models'
import { CardDataParentStore, CreditCardType, InputFieldName as CardInputFieldName } from '~/code/pages/CardData/models'
import { CardDataStore } from '~/code/pages/CardData'
import { ThreeDSecureParentStore, ThreeDSecureStore } from '~/code/pages/ThreeDSecure'
import { PaypalParentStore, PayPalStore } from '~/code/pages/Paypal'
import { CloseConfirmationModal, PaymentMethodList } from '~/code/components'
import { OrderPersonalInfo, OrderPersonalInfoStore } from '~/code/pages/OrderPersonalInfo'
import { CardItemPage, CardList, ErrorPage, Failure, Forbidden, PaymentForm, Processing, Success, ThreeDSecure } from '~/code/pages'
import { addHandleBeforePageClose, closeTransaction, convertCards, convertPaymentDataFromV2toV1, convertPaymentDataTypes, convertPaymentMethods, cryptoCardDataJSEncrypt, cryptoCardDataV2, decodeFromUrl, encodeToUrl, fetchConfiguration, getBrowserInfo, getChangedEmbeddedFields, getDonationAmountByDonationMode, inIframe, isEmbeddedWidget, isLocalDevelopmentMode, isNumber, isProductionMode, isValidCards, postWithToken, removeHandleBeforePageClose } from '~/code/services'
import { CloseConfirmationModalParentStore, CloseConfirmationModalStore } from '~/code/components/CloseConfirmationModal'
import constants from '~/code/config/constants'
import * as errorCodes from '~/code/config/LogStore'
import { CardItemPageStore } from '~/code/pages/CardItemPage'
import { CardItemPageParentStore } from '~/code/pages/CardItemPage/models'
import { PayByBankStore } from '~/code/pages/PayByBank'
import { CryptoRequestModel } from '~/code/models/V2/CryptoRequestModel'
import { GooglePayStore } from '~/code/pages/GooglePay'
import { IGooglePayParentStore } from '~/code/pages/GooglePay/models'
import { ApplePayStore } from '~/code/pages/ApplePay'
import { IApplePayParentStore } from '~/code/pages/ApplePay/models'
import { InputFieldName } from '~/code/pages/OrderPersonalInfo/models'
import { getRoundedAmount } from '~/code/components/OrderInfo/services'
import { AddressInfo } from '~/code/models/V2/AddressInfo'
import { Ecospend, EcospendStore } from '~/code/pages/Ecospend'
import { KlarnaStore } from '~/code/pages/Klarna/KlarnaStore'
import { IssueFeedbackModal, IssueFeedbackModalParentStore, IssueFeedbackModalStore } from '~/code/pages/ThreeDSecure/components/IssueFeedback/components/IssueFeedbackModal'
import { KlarnaParentStore } from '~/code/pages/Klarna/models/KlarnaParentStore'
import { UPIThreeDSecureParentStore, UPIThreeDSecureStore } from '~/code/pages/UPIThreeDSecure'
import { UPIThreeDSecure } from '~/code/pages/UPIThreeDSecure/UPIThreeDSecure'
import { log, serverLog } from '~/code/services/logger'
import { getBrowserName } from '../services/window-utils'
import { ClickToPayStore } from '~/code/pages/CardData/components/ClickToPay/ClickToPayStore'
import { CardDataPage } from '../pages/CardDataPage'
import { AstroPayStore } from '~/code/pages/AstroPay'
import { ClickToPayCheckoutResult } from '~/code/pages/CardData/components/ClickToPay/models/ClickToPayCheckoutResult'
import { ClickToPayCheckoutResultEvent } from '~/code/pages/CardData/components/ClickToPay/models/ClickToPayCheckoutResultEvent'
import { KlarnaOrderPersonalInfoStore } from '~/code/pages/Klarna/KlarnaOrderPersonalInfoStore'
import { TranslationBuilder } from '~/code/components/Translation'
import { convertToStandardLocale } from '~/code/components/Translation/services/utils'
import { AstroPayParentStore } from '~/code/pages/AstroPay/models'
import { SendReceiptStore } from '~/code/components/SendReceipt'
import { AlipayWeChatPayStore } from '~/code/pages/AlipayWeChatPay'
import { ClickToPayComponentStore } from '~/code/pages/ClickToPayComponent'
import { mergePaymentMethodsSettings } from '~/code/services/payment-methods-settings'
import { PaymentMethodStatus } from '~/code/models/PaymentMethodStatus'
import { SENTRY_TRANSACTION_ID } from '~/code/services/sentry-helper'

export abstract class AppStore implements AppStoreInterface, CardDataParentStore, ThreeDSecureParentStore, CloseConfirmationModalParentStore, IssueFeedbackModalParentStore, CardItemPageParentStore, PaypalParentStore, IGooglePayParentStore, IApplePayParentStore, KlarnaParentStore, UPIThreeDSecureParentStore, AstroPayParentStore {

    public cardDataStore: CardDataStore

    public cardItemPageStore: CardItemPageStore

    public orderPersonalInfoStore: OrderPersonalInfoStore

    public klarnaOrderPersonalInfoStore: KlarnaOrderPersonalInfoStore
    public sendReceiptStore: SendReceiptStore

    @observable
    public threeDSecureStore: ThreeDSecureStore
    @observable
    public closeConfirmationModalStore: CloseConfirmationModalStore
    @observable
    public issueFeedbackModalStore: IssueFeedbackModalStore

    @observable
    public upiThreeDSecureStore: UPIThreeDSecureStore
    public paypalStore: PayPalStore
    public payByBankStore: PayByBankStore
    public googlePayStore: GooglePayStore
    public applePayStore: ApplePayStore
    public klarnaStore: KlarnaStore
    public clickToPayStore: ClickToPayStore = new ClickToPayStore(this)
    public astroPayStore: AstroPayStore
    public clickToPayComponentStore: ClickToPayComponentStore
    public alipayStore: AlipayWeChatPayStore
    public alipayPlusStore: AlipayWeChatPayStore
    public weChatPayStore: AlipayWeChatPayStore

    @observable
    public embeddedWidgetPaypalStore: PayPalStore
    @observable
    public embeddedWidgetPayByBankStore: PayByBankStore
    public embeddedWidgetGooglePayStore: GooglePayStore
    public embeddedWidgetApplePayStore: ApplePayStore
    public embeddedWidgetKlarnaStore: KlarnaStore
    public embeddedWidgetAstroPayStore: AstroPayStore
    public embeddedWidgetAlipayStore: AlipayWeChatPayStore
    public embeddedWidgetAlipayPlusStoreStore: AlipayWeChatPayStore
    public embeddedWidgetWeChatPayStore: AlipayWeChatPayStore
    public embeddedWidgetClickToPayStore: ClickToPayComponentStore

    @observable
    public ecospendStore: EcospendStore
    @observable
    public embeddedWidgetEcospendStore: EcospendStore

    public abstract initialState(): void
    public abstract wrapWidgetWrapper(components: JSX.Element): () => JSX.Element
    public abstract _closePaymentWidget(isWithoutCloseConfirm?: boolean): void
    public abstract handleSuccessResult(): void

    public events: FormEvents = {
        opened: null,
        cancelled: null,
        paid: null,
        declined: null
    }

    constructor () {
        this.initPaymentMethodStores()

        this.onPayV2 = _.debounce(this.onPayV2.bind(this), 1000, { leading: true, trailing: false })

        reaction(
            () => this.threeDSecureStore?.iframeLoaded,
            (v) => {
                if (v) {
                    this.renderThreeDSecurePage() // TODO: force update, refactoring
                }
            }
        )

        reaction(
            () => this.upiThreeDSecureStore?.iframeLoaded,
            (v) => {
                if (v) {
                    this.renderUPIThreeDSecurePage() // TODO: force update, refactoring
                }
            }
        )

        reaction(() =>
            ({
                applePayState: this.applePayStore.availabilityState,
                googlePayState: this.googlePayStore.availabilityState
            }),
            () => {
                this.setInitialPaymentMethod()
                this.showPaymentMethodsPage()
            }
        )

        reaction(() => this.clickToPayStore.currentScreenName, () => {
            if ((ConfigStore.paymentMethod === PaymentMethod.BankCard || ConfigStore.paymentMethod === PaymentMethod.ClickToPay) &&
                Boolean(this.clickToPayStore.currentScreenName)) {
                this.renderCardDataPage()
            }
        })
    }

    initPaymentMethodStores() {
        this.sendReceiptStore = new SendReceiptStore(this)
        this.paypalStore = new PayPalStore(this)
        this.payByBankStore = new PayByBankStore(this)
        this.googlePayStore = new GooglePayStore(this)
        this.applePayStore = new ApplePayStore(this)
        this.ecospendStore = new EcospendStore(this)
        this.klarnaStore = new KlarnaStore(this)
        this.astroPayStore = new AstroPayStore(this)
        this.alipayStore = new AlipayWeChatPayStore(this, PaymentMethod.Alipay)
        this.alipayPlusStore = new AlipayWeChatPayStore(this, PaymentMethod.AlipayPlus)
        this.weChatPayStore = new AlipayWeChatPayStore(this, PaymentMethod.WeChatPay)
        this.clickToPayComponentStore = new ClickToPayComponentStore(this)
        this.clickToPayStore.clear()
    }

    @observable
    public currentPage: () => JSX.Element = () => null

    @observable
    public currentPageName: Page = null

    @observable
    public timer: any = null

    @observable
    public initialized: boolean = false

    @observable
    public isCloseConfirmationModalOpen: boolean = false

    @observable
    public paymentData: PaymentDataV2

    @observable
    public paymentResult: PaymentResultV1 | PaymentResultV2

    @observable
    public transactionId: string

    @observable
    public selectedCard: CardItem = null

    @observable
    public isSelectedCardMode: boolean = false

    @observable
    public isPaymentInProgress: boolean = false

    @observable
    public isPaymentSuccessful: boolean = false

    canCallOnPay: boolean = true
    lastPaymentData: any
    lastPaymentMethod: string
    lastOnPayInvokedFrom: string

    @computed
    public get cardNumber() {
        if (this.isSelectedCardMode) return this?.selectedCard?.panStar
        return this.cardDataStore.fields[CardInputFieldName.CardNumber].value.toString().replace(/[^0-9\\.]+/g, '')
    }

    @computed
    public get userFullName() {
        return `${this.billingAddress?.firstName || ''} ${ this.billingAddress?.lastName || ''}`.trim()
    }

    @computed
    public get cardHolderName() {
        return this.cardDataStore.fields[CardInputFieldName.CardHolderName].value.toString().trim()
    }

    @computed
    public get expiryDate() {
        const expiryDate = this.isSelectedCardMode ? this.selectedCard?.expiryDate : this.cardDataStore.fields[CardInputFieldName.ExpiryDate].value
        const [ month, year ] = expiryDate.split('/')
        return { month, year }
    }

    @computed
    public get cvv() {
        return this.isSelectedCardMode ? this.cardItemPageStore.fields[CardInputFieldName.Cvv].value : this.cardDataStore.fields[CardInputFieldName.Cvv].value
    }

    @computed
    public get customerDetails() {
        return {
            ...this.paymentData?.customerDetails,
            email: this.email,
            billingAddress: this.billingAddress,
            browserDetails: getBrowserInfo()
        }
    }

    @computed
    public get billingAddress(): AddressInfo {
        const address = this.orderPersonalInfoStore.getFieldValues()

        return {
            ...this.paymentData?.customerDetails?.billingAddress,
            firstName: address[InputFieldName.AccountFirstName],
            lastName: address[InputFieldName.AccountLastName],
            city: address[InputFieldName.AccountCity],
            country: address[InputFieldName.AccountCountry],
            postalCode: address[InputFieldName.AccountPostalCode],
            addressLine1: address[InputFieldName.AccountStreet1]
        }
    }

    @computed
    public get email(): string {
        const address = this.orderPersonalInfoStore.getFieldValues()
        return address[InputFieldName.AccountEmail]
    }

    @computed
    public get isOrderInfoValid(): boolean {
        if (!ConfigStore.isRequirePersonalInfo ||
            this.paymentData?.paymentSettings?.allowNonThreeDS ||
            (this.paymentData?.entryMode && ['mail-order', 'telephone-order'].includes(this.paymentData.entryMode))
        ) {
            return true
        }

        return this.orderPersonalInfoStore.isValidForm
    }

    @computed
    public get isWithoutCloseConfirm() {
        return [ Page.FORBIDDEN, Page.ERROR ].includes(this.currentPageName)
    }

    @computed
    public get isAvailableEntryMode() {
        return this.paymentData.entryMode && ['mail-order', 'telephone-order'].includes(this.paymentData.entryMode)
    }

    @computed
    public get isAvailableDonation() {
        return !this.isAvailableEntryMode && (!this.paymentData.transactionType || [ TransactionType.AUTH, TransactionType.SALE, TransactionType.RETAIL ].includes(this.paymentData.transactionType))
    }

    @action
    public cancelCloseConfirmationModal() {
        this.isCloseConfirmationModalOpen = false
    }

    @action
    public setCurrentPage(currentPage: JSX.Element | null) {
        this.currentPage = this.wrapWidgetWrapper(currentPage)
    }

    @action
    public setInitialPaymentMethod() {
        if (ConfigStore.activePaymentMethods().length === 1) {
            ConfigStore.setField('paymentMethod', ConfigStore.paymentMethods[0].name)
        }
    }

    @action
    public setPaymentInProgress = (progress: boolean, shouldShowPaymentsMethodsPage = false) => {
        this.isPaymentInProgress = progress
        if (!isEmbeddedWidget()) {
            if (shouldShowPaymentsMethodsPage) {
                this.showPaymentMethodsPage()
            }
            if (ConfigStore.paymentMethod === PaymentMethod.Ecospend) {
                this.renderEcospendPage()
            }
        }
    }

    @action
    public setPaymentSuccessful(isPaymentSuccessful: boolean) {
        this.isPaymentSuccessful = isPaymentSuccessful
    }

    @action
    async loadConfiguration(requiredFields?: any) {
        const result = await fetchConfiguration()
        if (result.error || !result.value) {
            LogStore.error(errorCodes.APPSTORE_DO_NOT_CONFIGURATION, null, new Error())
            throw new Error(result?.error?.message || 'Failed to load configuration')
        }

        const configuration = {
            ...result.value
        }

        ConfigStore.setField('personalInfoFields', { ...configuration.requiredFields, ...(requiredFields || {}) })
        ConfigStore.setField('merchantAuthorisedScopes', configuration.merchantAuthorisedScopes)
        ConfigStore.setField('cryptoKey', configuration.cert)
        ConfigStore.setField('isTdsFeedbackEnabled', configuration.isTdsFeedbackEnabled || false)

        if (configuration.hasOwnProperty('merchantName')) {
            ConfigStore.setField('merchantName', configuration.merchantName)
        }

        if (configuration.hasOwnProperty('merchantId')) {
            ConfigStore.setField('merchantId', configuration.merchantId)
        }

        if (configuration.hasOwnProperty('isCSCRequiredForTokenPayments')) {
            ConfigStore.setField('isCSCRequiredForTokenPayments', configuration.isCSCRequiredForTokenPayments)
        }
        if (configuration.hasOwnProperty('isCSCRequiredForNonTokenPayments')) {
            ConfigStore.setField('isCSCRequiredForNonTokenPayments', configuration.isCSCRequiredForNonTokenPayments)
        }
        if (configuration.hasOwnProperty('allowedAttempts')) {
            ConfigStore.setField('allowedAttempts', configuration.allowedAttempts)
            ConfigStore.setField('initialAllowedAttempts', configuration.allowedAttempts)
        }
        if (configuration.hasOwnProperty('allowSavingCards')) {
            ConfigStore.setField('allowSavingCards', Boolean(configuration.allowSavingCards))
        }
        if (configuration.termsAndConditionsLink) {
            ConfigStore.setField('termsAndConditionsLink', configuration.termsAndConditionsLink)
        }
        if (ConfigStore.autoRedirectDelayInMs === null && isNumber(configuration.autoRedirectDelayInMs)) {
            ConfigStore.setField('autoRedirectDelayInMs', Number(configuration.autoRedirectDelayInMs))
        }
        if (ConfigStore.paymentMethodsSettings === null && isNumber(configuration.paymentTimeoutInSeconds)) {
            ConfigStore.setField('paymentTimeoutInSeconds', Number(configuration.paymentTimeoutInSeconds))
        }
        if (configuration.maxVisiblePaymentMethods && ConfigStore.maxVisiblePaymentMethods === constants.defaultMaxVisiblePaymentMethods) {
            ConfigStore.setField('maxVisiblePaymentMethods', configuration.maxVisiblePaymentMethods)
        }
        if (configuration.embeddedWidget) {
            ConfigStore.setField('embeddedWidget', {
                ...constants.defaultEmbeddedWidget,
                ...configuration.embeddedWidget,
                ...(!isDeepEqual(ConfigStore.embeddedWidget, constants.defaultEmbeddedWidget) ? getChangedEmbeddedFields() : {}) // priority conf set fields by code
            })
        }

        if (!ConfigStore.paymentMethods || ConfigStore.paymentMethods.length === 0) {
            if (configuration.paymentMethods) {
                const paymentMethods = convertPaymentMethods(configuration.paymentMethods)
                ConfigStore.setField('paymentMethods', paymentMethods)
                if ( (paymentMethods.length === 2
                        && (paymentMethods.some(item => item.name === PaymentMethod.BankCard)
                            && paymentMethods.some(item => item.name === PaymentMethod.ClickToPay))) ||
                    (paymentMethods.length === 1 && paymentMethods[0].name === PaymentMethod.BankCard)
                ) {
                    ConfigStore.setField('paymentMethod', PaymentMethod.BankCard)
                }
            } else {
                ConfigStore.setField('paymentMethods', convertPaymentMethods([PaymentMethod.BankCard]))
            }
        }

        if (configuration.isEnableDonation && !ConfigStore.isEnableDonation) {
            ConfigStore.setField('isEnableDonation', configuration.isEnableDonation)
        }
        if (configuration.donation && this.isAvailableDonation) {
            ConfigStore.setField('donation', {
                ...configuration.donation,
                ...(configuration.donation.donationMode ? {
                    amount: getDonationAmountByDonationMode(configuration.donation, getRoundedAmount(this.paymentData.amount))
                } : {})
            })
        }
        if (configuration.paymentMethodsSettings) {
            // merge paymentMethodsSettings with default settings
            ConfigStore.setField('paymentMethodsSettings', mergePaymentMethodsSettings(configuration.paymentMethodsSettings, ConfigStore.paymentMethodsSettings))
        }

        if (configuration.version && !ConfigStore._version) {
            ConfigStore.setField('_version', configuration.version)
        }

        if (configuration.merchant) {
            ConfigStore.setField('merchant', configuration.merchant)
        }

        if (!Boolean(ConfigStore.localeConfiguration)) {
            if (configuration.locale) {
                ConfigStore.setField('localeConfiguration', configuration.locale)
                if (!Boolean(configuration.locale?.targetLocale) && configuration.locale?.detectLocale) {
                    this.detectLocale()
                }
                this.setLanguage()
            }
        }

        StyleStore.setStyles({
            ...configuration.customStyle
        })
    }

    @action
    public initConfigData(data) {
        const configData: PaymentFormConfigure = decodeFromUrl(data)

        if (configData) {
            // `isTest` is used in CMS plugins, so needed to support them
            if (configData.isTestMode || configData.isTest) {
                ConfigStore.setField('isTestMode', true)
            }
            if (isNumber(configData.autoRedirectDelayInMs)) {
                ConfigStore.setField('autoRedirectDelayInMs', Number(configData.autoRedirectDelayInMs))
            }
            if (isNumber(configData.paymentTimeoutInSeconds)) {
                ConfigStore.setField('paymentTimeoutInSeconds', Number(configData.paymentTimeoutInSeconds))
            }
            if (configData.paymentMethods) {
                ConfigStore.setField('paymentMethods', convertPaymentMethods(configData.paymentMethods))
            }
            if (configData.paymentMethodsSettings) {
                ConfigStore.setField('paymentMethodsSettings', configData.paymentMethodsSettings)
            }
            if (configData.paymentMethod) {
                ConfigStore.setField('paymentMethod', configData.paymentMethod)
            }
            if (configData.isEnableDonation) {
                ConfigStore.setField('isEnableDonation', configData.isEnableDonation)
            }
            if (configData.allowSavingCards) {
                ConfigStore.setField('allowSavingCards', configData.allowSavingCards)
            }
            if (configData.cards && isValidCards(configData.cards)) {
                ConfigStore.setField('cards', convertCards(configData.cards))
            }
            if (configData.disabledCardSchemes) {
                ConfigStore.setField('disabledCardSchemes', configData.disabledCardSchemes)
            }
            if (configData.locale) {
                ConfigStore.setField('localeConfiguration', configData.locale)
                this.setLanguage()
            }
            if (isNumber(configData.version)) {
                ConfigStore.setField('_version', Number(configData.version))
            }
        }
    }

    @action
    public initPaymentData(paymentData: PaymentDataV1 | PaymentDataV2) {
        if (!paymentData || !paymentData.auth) {
            LogStore.error(errorCodes.APPSTORE_HAS_NOT_AUTH_TOKEN, null, new Error())
            throw new AuthError('No auth service')
        }
        this.paymentData = convertPaymentDataTypes(paymentData)
        const transactionType = this.paymentData?.transactionType
        if (!isProductionMode()) {
            ConfigStore.setField('isTestMode', true)
        }
        if (transactionType === TransactionType.TOKENIZATION) {
            ConfigStore.setField('pageType', PageType.TOKENIZATION)
            ConfigStore.setField('isRequirePersonalInfo', false)
            ConfigStore.setField('paymentMethod', PaymentMethod.BankCard)
        } else if (transactionType === TransactionType.VERIFICATION) {
            ConfigStore.setField('pageType', PageType.VERIFICATION)
            ConfigStore.setField('paymentMethod', PaymentMethod.BankCard)
        }
        if (this.isAvailableEntryMode) {
            ConfigStore.setField('isCSCRequiredForTokenPayments', false)
            ConfigStore.setField('isCSCRequiredForNonTokenPayments', false)
        }
        ConfigStore.setField('fixedDonationAmount', this.paymentData?.donationAmount || null)
        ConfigStore.setField('paymentSettings', this.paymentData.paymentSettings || null)
        ConfigStore.setField('auth', this.paymentData.auth)
    }

    public hasPrivilege(scope: IntegrationScope): boolean {
        return true
        // return ConfigStore.merchantAuthorisedScopes.includes(scope)
    }

    @action
    public validateKlarnaRequiredFields = () => {
        if (this.klarnaOrderPersonalInfoStore.isValidForm) {
            this.klarnaStore.startPaymentProcessing()
            return
        }
        this.renderKlarnaOrderPersonalInfoPage()
    }

    @action
    public startPaymentProcessing = (shouldShowPaymentsMethodsPage: 'show' | 'hide' = 'show') => {
        this.setPaymentInProgress(true, shouldShowPaymentsMethodsPage === 'show')
    }

    @action
    public stopPaymentProcessing(isPaymentSuccessful?: boolean, shouldShowPaymentsMethodsList = false) {
        this.setPaymentInProgress(false, shouldShowPaymentsMethodsList)
        this.setPaymentSuccessful(isPaymentSuccessful)
    }

    @action
    public addGlobalListener() {
        if (isLocalDevelopmentMode()) return
        addHandleBeforePageClose()
    }

    @action
    public removeGlobalListener() {
        removeHandleBeforePageClose()
    }

    @action
    public onCardDataConfirm = async (_invokedFrom: string) => {
        if (this.isOrderInfoValid) {
            this.onPay({invokedFrom: _invokedFrom + '->AppStore.onCardDataConfirm'})
            return
        }
        this.renderOrderPersonalInfoPage()
    }

    @action
    public onCardItemCardDataConfirm = async (_invokedFrom: string) => {
        if (this.isOrderInfoValid) {
            this.onPay({invokedFrom: _invokedFrom + '->AppStore.onCardItemCardDataConfirm'})
            return
        }
        this.renderOrderPersonalInfoPage()
    }

    @action
    handleClickToPay = async (checkoutFunction: Promise<ClickToPayCheckoutResult>, _invokedFrom?: string) => {
        ConfigStore.setField('paymentMethod', PaymentMethod.ClickToPay)
        this.renderCardDataPage()

        const result = await checkoutFunction

        switch (result.event) {
            case ClickToPayCheckoutResultEvent.cancel:
                this.tryAgain()
                return false
            case ClickToPayCheckoutResultEvent.failure:
                this.handleFailureResult({
                    code: result?.payload?.code,
                    message: result?.payload?.message
                })
                return false
            default:
                switch (result.payload?.checkoutActionCode) {
                    case 'CHANGE_CARD': {
                        if (this.clickToPayStore?.cards?.length > 0) {
                            runInAction(() => {
                                this.cardDataStore.clearFields()
                                this.clickToPayStore.setCurrentScreenName('cardsList')
                            })
                        } else {
                            this.tryAgain()
                            this.renderCardDataPage()
                        }

                        return false
                    }
                    case 'SWITCH_CONSUMER': {
                        this.clickToPayStore.setCurrentScreenName('emailOrPhone')
                        return false
                    }
                    default: {
                        this.handleCommonOnPay({
                            customCrypto: result.payload,
                            paymentMethod: PaymentMethod.ClickToPay.toLowerCase(),
                            invokedFrom: (_invokedFrom || '') + '->CardDataStore.confirmData.clickToPay'
                        })
                        return true
                    }
                }
        }
    }

    public async getCardPaymentDataV2(cryptogram?: string, paymentMethod?: string): Promise<CryptoRequestModel> {
        const { donationAmount, ...rest } = this.paymentData
        const storeCardOnFile = this.cardDataStore.shouldStoreCardOnFile
        return {
            ...rest,
            paymentMethod,
            customerDetails: this.customerDetails,
            cardDetails: cryptogram ?
                {
                    cryptogram,
                    storeCardOnFile,
                    cardholderName: this.cardHolderName || this.userFullName,
                    encryptedData: paymentMethod === PaymentMethod.ClickToPay.toLowerCase() ? await this.getCryptoCardDataV2() : undefined
                } :
                {
                    storeCardOnFile,
                    encryptedData: await this.getCryptoCardDataV2()
                } ,
            ...(ConfigStore.isDonationIncluded ? { donationAmount: ConfigStore.donationAmount } : {}),
            languageUsed: TranslationBuilder.lang
        }
    }

    getCryptoCardData() {
        const { terminalId }  = this.paymentData?.paymentSettings
        const dataToEncript = {
            hpan: this.cardNumber,
            expDate: this.expiryDate.month + this.expiryDate.year,
            cvc: this.cvv,
            terminalId,
            ...( this.isSelectedCardMode ? { cardToken: this.selectedCard?.merchantTokenId } : {})
        }
        return cryptoCardDataJSEncrypt(dataToEncript)
    }


    @action
    public async onPay({customCrypto, paymentMethod, invokedFrom}: {customCrypto?: string, paymentMethod?: string, invokedFrom?: string}) {
        if (ConfigStore.paymentMethod === PaymentMethod.Klarna) {
            this.startPaymentProcessing()
            await this.klarnaStore.startPaymentProcessing()
            return null
        }
        if (ConfigStore.paymentMethodsSettings?.clickToPay?.status === PaymentMethodStatus.ACTIVE &&
            (this.clickToPayStore.hasAgreedToShareWithClickToPay || ConfigStore.paymentMethodsSettings?.clickToPay?.cannotChangeAgreement)) {
            if (customCrypto) {
                await this.handleCommonOnPay({customCrypto, paymentMethod, invokedFrom})
            } else {
                await this.handleClickToPay(this.clickToPayStore.checkoutWithNewCard())
            }

            return null
        }

        return this.handleCommonOnPay({customCrypto, paymentMethod, invokedFrom})
    }

    private handleCommonOnPay = ({customCrypto, paymentMethod, invokedFrom}: {customCrypto?: string, paymentMethod?: string, invokedFrom?: string}) => {
        this.lastPaymentData = {...this.paymentData}
        this.lastPaymentMethod = paymentMethod
        this.lastOnPayInvokedFrom = invokedFrom

        if (this.canCallOnPay){
            this.canCallOnPay = false
            setTimeout(() => { this.canCallOnPay = true }, 1000)
            return this._onPay(customCrypto, paymentMethod)
        } else {
            const obj = {
                lastPaymentData: {invoiceId: this.lastPaymentData.invoiceId, amount: this.paymentData.amount},
                lastPaymentMethod: this.lastPaymentMethod,
                lastOnPayInvokedFrom: this.lastOnPayInvokedFrom,
                currentPaymentData: {invoiceId: this.paymentData.invoiceId, amount: this.paymentData.amount},
                currentPaymentMethod: paymentMethod,
                onPayInvokedFrom: invokedFrom
            }
            serverLog(ConfigStore.getUrl().paymentPageUrl, obj)
        }

        return null
    }

    private _onPay = (customCrypto, paymentMethod) => {
        if (ConfigStore.version === 2) {
            return this.onPayV2(customCrypto, paymentMethod)
        }
        return this.onPayV1(customCrypto, paymentMethod)
    }

    @action
    public onPayV1 = async (customCrypto?: string, paymentMethod?: string) => {
        this.renderProcessingPage()
        const paymentData = convertPaymentDataFromV2toV1({
            ...this.paymentData,
            customerDetails: this.customerDetails
        })

        const data = {
            ...paymentData,
            name: this.cardHolderName || this.userFullName,
            paymentMethod,
            сryptogram: customCrypto || this.getCryptoCardData(),
            ...(ConfigStore.isDonationIncluded ? { donationAmount: ConfigStore.donationAmount } : {}),
            ...(this.isSelectedCardMode ? { cardType: this.selectedCard?.cardSchemeId } : {}),
            languageUsed: TranslationBuilder.lang
        }

        const cryptoUrl = `${ ConfigStore.getUrl().apiUrl }/payment/cryptopay?${ this.getParamsQueryString() }`

        const payment = check(await noThrow<any>(postWithToken(cryptoUrl, data)))
        if (payment.error) {
            LogStore.error(errorCodes.APPSTORE_PAY_REQUEST_ERROR_V1)
            this.transactionId = payment?.error?.id
            this.handleFailureResult(payment?.error)
            return false
        }

        if (!payment.result) {
            LogStore.error(errorCodes.APPSTORE_HAS_NOT_REQUEST_RESULT_V1)
            this.renderForbiddenPage()
            return false
        }

        this.paymentResult = payment.result as PaymentResultV1
        this.transactionId = payment?.result?.id

        if (this.paymentResult?.secure3D) {
            this.renderThreeDSecurePage()
        } else {
            this.handleSuccessResult()
        }

        return true
    }


    /**
     * A function that is called to process cryptogram payments
     * @param cryptogram - the encoded payload to be processed
     * @param paymentMethod - the name of the payment method (Google Pay, Apple Pay, etc.)
     */
    @action
    public async onPayV2(cryptogram?: string, paymentMethod?: string) {
        this.renderProcessingPage()

        if (getBrowserName() === 'Safari' && this.cardDataStore.cardInfo?.type === CreditCardType.UNIONPAY) {
            const upiThreeDSecureStore = this.upiThreeDSecureStore || (this.upiThreeDSecureStore = new UPIThreeDSecureStore(this))
            upiThreeDSecureStore.openPopupWindow()
        }

        const cardData = await this.getCardPaymentDataV2(cryptogram, paymentMethod)
        const cryptoUrl = `${ ConfigStore.getUrl().apiUrl }/v2/payments`

        const payment = check(await noThrow<any>(postWithToken(cryptoUrl, cardData)))
        if (payment.error || !payment?.result?.success) {
            LogStore.error(errorCodes.APPSTORE_PAY_REQUEST_ERROR_V2)
            this.transactionId = payment.result?.id || payment?.error?.id
            this.handleFailureResult(payment?.result || payment?.error)

            if (getBrowserName() === 'Safari' && this.cardDataStore.cardInfo?.type === CreditCardType.UNIONPAY) {
                this.upiThreeDSecureStore.closePopupWindow()
            }

            return false
        }

        if (!payment.result) {
            LogStore.error(errorCodes.APPSTORE_HAS_NOT_REQUEST_RESULT_V2)
            this.renderForbiddenPage()
            return false
        }

        this.paymentResult = payment?.result as PaymentResultV2
        this.transactionId = payment?.result?.id

        if (payment?.result?.status === 'requires_action') {
            if (payment?.result?.threeDS?.html) {
                this.renderUPIThreeDSecurePage()
            } else {
                this.renderThreeDSecurePage()
            }
        } else {
            this.handleSuccessResult()
        }

        return true
    }

    @action
    public onSelectPaymentMethod(value: PaymentMethod) {
        ConfigStore.setField('paymentMethod', value)
    }

    @action
    decreaseAllowedAttempts() {
        if (ConfigStore.allowedAttempts > 0) {
            ConfigStore.setField('allowedAttempts', ConfigStore.allowedAttempts - 1)
        }
    }

    @action
    handle3dSecureResult(query: { result: string }) {
        if (query && query.result) {
            if (query.result === 'ok') {
                this.handleSuccessResult()
            } else if (query.result === 'error') {
                this.handleFailureResult()
            }
        }
    }

    // TODO should be implemented in the PaymentPageAppStore
    redirectToBack() {
        this.removeGlobalListener()
        if (inIframe()) {
            window.parent.postMessage({ name: IframeWrapperAction.CLOSE_WIDGET }, '*')
            window.parent.location.href = this.paymentData?.paymentSettings?.returnUrl
            return
        }
        location.href = this.paymentData?.paymentSettings?.returnUrl
    }

    hasReturnUrl() {
        return Boolean(this.paymentData?.paymentSettings?.returnUrl)
    }

    @action
    redirectToSuccessPage() {
        if (!this.hasReturnUrl()) {
            this.renderSuccessPage()
        } else if (ConfigStore.autoRedirectDelayInMs === 0) {
            this.redirectToReturnUrl()
        } else {
            this.renderSuccessPage()
            this.redirectToReturnUrl(true)
        }
    }

    @action
    redirectToReturnUrl(isAfterTimer?: boolean) {
        if (!this.paymentData?.paymentSettings?.returnUrl) return
        this.removeGlobalListener()
        if (!isAfterTimer) {
            this.redirectToBack()
        } else {
            this.timer = setTimeout(() => {
                this.redirectToBack()
            }, ConfigStore.getAutoRedirectDelayInMs())    
        }
    }

    @action
    redirectToFailureReturnUrl() {
        if (!this.paymentData?.paymentSettings?.failureReturnUrl) return
        if (inIframe()) {
            this.removeGlobalListener()
            window.parent.location.href = this.paymentData?.paymentSettings?.failureReturnUrl
        }

        location.href = this.paymentData?.paymentSettings?.failureReturnUrl
    }

    public async getCryptoCardDataV2() {
        if (!this.cardNumber) {
            return undefined
        }
        const dataToEncript = {
            accountNumber: this.cardNumber,
            expirationMonth: this.expiryDate.month,
            expirationYear: this.expiryDate.year,
            csc: this.cvv,
            cardholderName: this.cardHolderName || this.userFullName,
            paymentTo: this.paymentData?.paymentTo,
            ...(this.isSelectedCardMode ? { cardType: this.selectedCard?.cardSchemeId } : {}),
            ...(this.isSelectedCardMode ? { cardScheme: this.selectedCard?.cardSchemeName } : {}),
            ...(this.isSelectedCardMode ? { cardTokenId: this.selectedCard?.merchantTokenId } : {})
        }
        const result = await cryptoCardDataV2(dataToEncript)
        return result
    }

    @action
    getParamsQueryString(paymentData = this.paymentData, config: PaymentDataConfig = null): string {
        const paymentMethod = config?.paymentMethod || ConfigStore.paymentMethod
        const paymentMethods = config?.paymentMethods || ConfigStore.paymentMethods
        const paymentMethodsSettings = config?.paymentMethodsSettings

        const data: Partial<PaymentFormConfigure> = {
            isTestMode: ConfigStore.isTestMode,
            isEnableDonation: ConfigStore.isEnableDonation,
            paymentMethods: toJS(paymentMethods)
        }

        if (isNumber(ConfigStore.autoRedirectDelayInMs)) {
            data.autoRedirectDelayInMs = ConfigStore.autoRedirectDelayInMs
        }

        if (isNumber(ConfigStore.paymentTimeoutInSeconds)) {
            data.paymentTimeoutInSeconds = ConfigStore.paymentTimeoutInSeconds
        }
        if (ConfigStore.allowSavingCards) {
            data.allowSavingCards = ConfigStore.allowSavingCards
        }
        if (ConfigStore.cards) {
            data.cards = ConfigStore.cards
        }
        if (ConfigStore.disabledCardSchemes) {
            data.disabledCardSchemes = ConfigStore.disabledCardSchemes
        }
        if (ConfigStore.localeConfiguration) {
            data.locale = {
                targetLocale: ConfigStore.localeConfiguration.targetLocale,
                defaultLocale: ConfigStore.localeConfiguration.defaultLocale,
                detectLocale: ConfigStore.localeConfiguration.detectLocale
            }
        }
        if (paymentMethodsSettings) {
            ConfigStore.paymentMethodsSettings = mergePaymentMethodsSettings(ConfigStore.paymentMethodsSettings, paymentMethodsSettings)
        }

        if (ConfigStore.paymentMethodsSettings) {
            data.paymentMethodsSettings = ConfigStore.paymentMethodsSettings
        }

        if (ConfigStore._version) {
            data.version = ConfigStore._version
        }

        return 'params=' + encodeToUrl({
                ...paymentData
            }) +
            '&data=' + encodeToUrl({
                ...data,
                ...(paymentMethod ? { paymentMethod } : {})
            }) +
            '&sntTrId=' +  SENTRY_TRANSACTION_ID
    }

    @action
    public redirectInitialPaymentMethodsPage() {
        ConfigStore.setField('paymentMethod', null)
        this.showPaymentMethodsPage()
    }

    @action
    public showInitCardListPage() {
        this.isSelectedCardMode = false
        this.selectedCard = null
        this.renderCardListPage()
    }

    @action
    public showPaymentMethodsPage() {
        // check if there is only one active payment method
        if (ConfigStore.activePaymentMethods().length === 1) {
            ConfigStore.setField('paymentMethod', ConfigStore.paymentMethods[0].name)
        } else if (ConfigStore.activePaymentMethods().length === 2 && ConfigStore.paymentMethods.some(item => item.name === PaymentMethod.BankCard) && ConfigStore.paymentMethods.some(item => item.name === PaymentMethod.ClickToPay)) {
            ConfigStore.setField('paymentMethod', PaymentMethod.BankCard)
        }

        switch (ConfigStore.paymentMethod) {
            case PaymentMethod.ClickToPay:
            case PaymentMethod.BankCard: {
                if (ConfigStore.cards && Array.isArray(ConfigStore.cards) && ConfigStore.cards.length > 0 && this.paymentData?.transactionType !== TransactionType.TOKENIZATION && this.paymentData?.transactionType !== TransactionType.VERIFICATION) {
                    return this.renderCardListPage()
                }
                return this.renderCardDataPage()
            }
            case PaymentMethod.Ecospend:
                return this.renderEcospendPage()
        }

        return this.renderPaymentMethodsListPage()
    }

    @action
    renderPaymentMethodsListPage() {
        log('RENDERING PAYMENT METHODS LIST PAGE')
        this.currentPageName = Page.PAYMENT_METHODS_LIST
        this.renderPaymentFormWrapper(
            <div>
                <PaymentMethodList
                    paymentData={ this.paymentData }
                    cardDataStore={this.cardDataStore}
                    paypalStore={ this.paypalStore }
                    payByBankStore={ this.payByBankStore }
                    clickToPayComponentStore={ this.clickToPayComponentStore }
                    googlePayStore={ this.googlePayStore }
                    applePayStore={ this.applePayStore }
                    ecospendStore={ this.ecospendStore }
                    klarnaStore={ this.klarnaStore }
                    astroPayStore={ this.astroPayStore }
                    alipayStore={ this.alipayStore }
                    alipayPlusStore={ this.alipayPlusStore }
                    weChatPayStore={ this.weChatPayStore }
                    onSelectItem={ (v) => {
                        this.onSelectPaymentMethod(v)
                        if (v !== PaymentMethod.Klarna) {
                            this.showPaymentMethodsPage()
                        }
                    } }
                    maxItems={ ConfigStore.maxVisiblePaymentMethods }
                />
            </div>
        , {
            isPaymentMethodsLinkHidden: true,
            isDonationHidden: true
        })
        this.initialized = true
    }

    @action
    onSelectCard(selectedCard) {
        this.isSelectedCardMode = true
        this.selectedCard = selectedCard
        this.renderCardItemPage()
    }

    @action
    renderCardListPage() {
        this.currentPageName = Page.CARD_LIST
        this.renderPaymentFormWrapper(
            <CardList
                onSelectItem={ (selectedCard) => this.onSelectCard(selectedCard) }
                onPayNewCard={ () => {
                    this.isSelectedCardMode = false
                    this.selectedCard = null
                    this.renderCardDataPage()
                }}
            />
        )
        this.initialized = true
    }

    @action
    renderCardItemPage() {
        this.currentPageName = Page.CARD_ITEM
        this.renderPaymentFormWrapper(
            <CardItemPage store={ this.cardItemPageStore } />,
            {
                isPaymentMethodsLinkHidden: true
            }
        )
    }

    @action
    renderCardDataPage() {
        this.currentPageName = Page.CARD_DATA

        this.renderPaymentFormWrapper(
            <CardDataPage
                cardDataStore={this.cardDataStore}
                clickToPayStore={this.clickToPayStore}
                paymentData={this.paymentData}
            />,
            {
                isPaymentMethodsLinkHidden: Boolean(
                    this.clickToPayStore.currentScreenName === 'emailOrPhone' ||
                    this.clickToPayStore.currentScreenName === 'otp'
                )
            }
        )
        this.initialized = true
    }

    @action
    renderEcospendPage() {
        this.currentPageName = Page.ECOSPEND
        this.renderPaymentFormWrapper(
            <Ecospend
                store={ this.ecospendStore }
                onContinueClick={() => this.renderEcospendPage()}
            />,
            {
                isPaymentMethodsLinkHidden: true,
                isOrderInformationBlockHidden: true
            }
        )
        this.initialized = true
    }

    @action
    renderOrderPersonalInfoPage() {
        this.currentPageName = Page.ORDER_PERSONAL_INFO
        this.renderPaymentFormWrapper(
            <OrderPersonalInfo store={ this.orderPersonalInfoStore } />
        )
    }

    @action
    renderKlarnaOrderPersonalInfoPage() {
        this.currentPageName = Page.KLARNA_ORDER_PERSONAL_INFO
        this.renderPaymentFormWrapper(
            <OrderPersonalInfo store={ this.klarnaOrderPersonalInfoStore } />
        )
    }

    @action
    renderSuccessPage() {
        this.removeGlobalListener()
        this.currentPageName = Page.SUCCESS
        this.renderPaymentFormWrapper(
            <Success
                transactionId={ this.transactionId}
                email={ this.email }
                store={ this.sendReceiptStore }
                paymentData={ this.paymentData }
                onBackToSite={ () => this.redirectToReturnUrl() }
            />,
            {
                isPaymentMethodsLinkHidden: true,
                isDonationHidden: true,
                isTimeoutCountdownHidden: true
            }
        )
        this.initialized = true
    }

    @action
    public clearStore() {
        this.threeDSecureStore = null
        this.upiThreeDSecureStore = null
        this.embeddedWidgetPaypalStore = null
        this.embeddedWidgetPayByBankStore = null
        this.closeConfirmationModalStore = null
        this.issueFeedbackModalStore = null
        this.initPaymentMethodStores()
    }

    @action
    public tryAgain = () => {
        if (ConfigStore.paymentMethod === PaymentMethod.PayByBank) {
            this.removeGlobalListener()
            return location.reload()
        }

        this.isPaymentInProgress = false
        this.isPaymentSuccessful = false

        if (ConfigStore.paymentMethod === PaymentMethod.ClickToPay) {
            this.clickToPayStore.clear(true)
            ConfigStore.setField('paymentMethod', null)
        }

        this.clearStore()
        this.cardDataStore.clearFields()
        this.showPaymentMethodsPage()
    }

    @action
    handleFailureResult = async (error = {code: 0, message: ''}) => {
        this.setPaymentInProgress(false)
        if (isEmbeddedWidget()) {
            this.events?.declined && this.events?.declined()
            return
        }

        this.decreaseAllowedAttempts()
        if (ConfigStore.allowedAttempts === 0) {
            if (ConfigStore.pageType === PageType.PAYBYLINK) {
                if (this.transactionId) {
                    await closeTransaction(this.transactionId)
                }
            }

            if (inIframe()) {
                // TODO: discuss with the merchant for what purposes these events are used
                if (!this.events?.declined) {
                    this.currentPageName = Page.FAILURE
                    this._closePaymentWidget(true)
                } else {
                    window.parent.postMessage({name: IframeWrapperAction.DECLINED}, '*')
                }
                return
            }
            this.events?.declined && this.events?.declined()
            this.removeGlobalListener()
        }

        if (ConfigStore.paymentMethod === PaymentMethod.ClickToPay) {
            this.clickToPayStore.makeClickToPayUnavailable()
        }

        this.renderFailurePage(error)
    }

    @action
    renderFailurePage(error = {code: 0, message: ''}) {
        this.currentPageName = Page.FAILURE
        this.renderPaymentFormWrapper(
            <Failure
                error={ error }
                paymentData={ this.paymentData }
                returnToBack={ () => {
                    this._closePaymentWidget(true)
                } }
                tryAgain={ this.tryAgain }
            />, {
                isPaymentMethodsLinkHidden: true,
                isDonationHidden: true
            }
        )
        this.initialized = true
    }

    @action
    renderProcessingPage() {
        this.currentPageName = Page.PROCESSING
        this.renderPaymentFormWrapper(
            <Processing />, {
                isPaymentMethodsLinkHidden: true,
                isDonationHidden: true
            }
        )
    }

    @action
    renderThreeDSecurePage() {
        this.currentPageName = Page.THREE_D_SECURE
        const threeDSecureStore = this.threeDSecureStore || (this.threeDSecureStore = new ThreeDSecureStore(this))
        this.renderPaymentFormWrapper(
            <ThreeDSecure
                store={ threeDSecureStore }
            />,
            {
                isDonationHidden: true,
                isPaymentMethodsLinkHidden: true,
                isOrderInformationBlockHidden: threeDSecureStore.iframeLoaded
            }
        )
    }

    @action
    renderUPIThreeDSecurePage = () => {
        this.currentPageName = Page.UPI_THREE_D_SECURE
        const upiThreeDSecureStore = this.upiThreeDSecureStore || (this.upiThreeDSecureStore = new UPIThreeDSecureStore(this))
        this.renderPaymentFormWrapper(
            <UPIThreeDSecure
                store={ upiThreeDSecureStore }
            />,
            {
                isDonationHidden: true,
                isPaymentMethodsLinkHidden: true,
                isOrderInformationBlockHidden: upiThreeDSecureStore.iframeLoaded
            }
        )
    }

    @action
    renderErrorPage(message?: JSX.Element | string): void {
        this.currentPageName = Page.ERROR
        this.renderPaymentFormWrapper(
            <ErrorPage close={ () => this._closePaymentWidget(true) }>{ message }</ErrorPage>,
            {
                isDonationHidden: true,
                isPaymentMethodsLinkHidden: true,
                isOrderInformationBlockHidden: true,
                isFooterHidden: true,
                isTimeoutCountdownHidden: true
            }
        )
        this.initialized = true
    }

    @action
    renderForbiddenPage(): void {
        this.currentPageName = Page.FORBIDDEN
        this.renderPaymentFormWrapper(
            <Forbidden close={ () => this._closePaymentWidget(true) }/>,
            {
                isDonationHidden: true,
                isPaymentMethodsLinkHidden: true,
                isOrderInformationBlockHidden: true,
                isFooterHidden: true
            }
        )
        this.initialized = true
    }

    @action
    renderPaymentFormWrapper(component: JSX.Element, options?: PaymentFormWrapperOptions) {
        const _options = {
            ...options,
            isClickToPayAvailable: true // TODO load from configs
        }
        this.setCurrentPage(this.withPaymentFormWrapper(component, _options))
    }

    @action
    openIssueFeedbackModal() {
        const issueFeedbackModalStore = this.issueFeedbackModalStore || (this.issueFeedbackModalStore = new IssueFeedbackModalStore(this))
        issueFeedbackModalStore.open()
    }

    @action
    closeIssueFeedbackModal() {
        const issueFeedbackModalStore = this.issueFeedbackModalStore || (this.issueFeedbackModalStore = new IssueFeedbackModalStore(this))
        issueFeedbackModalStore.close()
    }

    withPaymentFormWrapper(component: JSX.Element, options?: PaymentFormWrapperOptions) {
        return <>
            <PaymentForm
                currentPageName={ this.currentPageName }
                orderNumber={ this.paymentData.invoiceId }
                orderTotal={ this.paymentData.amount }
                orderCurrency={ this.paymentData.currency }
                orderDescription={ this.paymentData?.description }
                close={ (closeWithoutConfirmation) => this._closePaymentWidget(closeWithoutConfirmation) }
                redirectInitialPaymentMethodsPage={ () => this.redirectInitialPaymentMethodsPage() }
                tryAgain={ () => this.tryAgain() }
                openIssueFeedbackModal={ () => this.openIssueFeedbackModal() }
                closeIssueFeedbackModal={ () => this.closeIssueFeedbackModal() }
                renderIssueFeedBackModal={ () => this.renderIssueFeedBackModal() }
                isHeaderHidden={ Boolean(options?.isHeaderHidden) }
                isPaymentMethodsLinkHidden={ Boolean(options?.isPaymentMethodsLinkHidden) }
                isDonationHidden={ Boolean(options?.isDonationHidden) }
                isOrderInformationBlockHidden={ Boolean(options?.isOrderInformationBlockHidden) }
                isFooterHidden={ Boolean(options?.isFooterHidden) }
                paymentTimeoutInSeconds={ConfigStore.paymentTimeoutInSeconds}
                isPaymentInProgress={this.isPaymentInProgress}
                isPaymentSuccessful={this.isPaymentSuccessful}
                paymentData={this.paymentData}
                isTimeoutCountdownHidden={options?.isTimeoutCountdownHidden}
                isClickToPayAvailable={options?.isClickToPayAvailable}
                appStore={this}
            >
                { component }
            </PaymentForm>
            { this.renderCloseConfirmationModal() }
        </>
    }

    @action
    renderCloseConfirmationModal() {
        const closeConfirmationModalStore = this.closeConfirmationModalStore || (this.closeConfirmationModalStore = new CloseConfirmationModalStore(this))
        return (
            <CloseConfirmationModal
                store={ closeConfirmationModalStore }
            />
        )
    }

    @action
    renderIssueFeedBackModal() {
        const issueFeedbackModalStore = this.issueFeedbackModalStore || (this.issueFeedbackModalStore = new IssueFeedbackModalStore(this))
        return (
            <IssueFeedbackModal
                store={ issueFeedbackModalStore }
            />
        )
    }

    @computed
    get threeDSecureErrorMessage(): string {
        return this.threeDSecureStore.errorMessage
    }

    clearThreeDSecureErrorMessage() {
        this.threeDSecureStore.setErrorMessage('')
    }

    setLanguage() {
        TranslationBuilder.setLang(ConfigStore.nonNullLanguage)
    }

    @action
    detectLocale() {
        // check the locale of the browser and set it to the config store
        ConfigStore.setField('locale', convertToStandardLocale(navigator?.language) || ConfigStore.locale)
    }
}
