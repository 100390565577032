import { action, computed, observable } from 'mobx'
import { EmbeddedWidgetParentStore } from './models'
import { ConfigStore } from '~/code/config'
import { PaymentDataConfig, PaymentDataV1, PaymentDataV2, PaymentMethod } from '~/code/models'

export class EmbeddedWidgetStore {
    public parentStore: EmbeddedWidgetParentStore

    constructor (parentStore: EmbeddedWidgetParentStore) {
        this.parentStore = parentStore
    }

    @observable
    public paymentData: PaymentDataV1 | PaymentDataV2

    @observable
    public config: PaymentDataConfig

    @computed
    public get isLoading() {
        if (ConfigStore.paymentMethod === PaymentMethod.PayPal) {
            return this.parentStore?.embeddedWidgetPaypalStore?.isLoading || this.parentStore?.embeddedWidgetPaypalStore?.isProcessingPayment
        } else if (ConfigStore.paymentMethod === PaymentMethod.PayByBank) {
            return this.parentStore?.embeddedWidgetPayByBankStore?.isLoading || this.parentStore?.embeddedWidgetPayByBankStore?.isProcessingPayment
        }
        return false
    }

    @action
    public setPaymentData(paymentData: PaymentDataV1 | PaymentDataV2, configs?: object) {
        this.paymentData = paymentData
        if (configs) {
            this.config = configs
        }
    }

    @action
    onSuccess() {
        this.parentStore.redirectToReturnUrl()
    }

    @action
    onFailure(e?) {
        this.parentStore.redirectToFailureReturnUrl()
    }

    @action
    public onSelectPaymentMethod(v: PaymentMethod) {
        switch (v) {
            case PaymentMethod.BankCard: {
                // we should check if ClickToPay is available
                // if it is available we should show ClickToPay logo and functionality in the BankCard payment method
                const config = {
                    ...this.config,
                    paymentMethods: ConfigStore.activePaymentMethods().some(item => item.name === PaymentMethod.ClickToPay) ? [PaymentMethod.ClickToPay] : [PaymentMethod.BankCard]
                }
                this.parentStore.openPaymentIframeWidget(this.paymentData, config)
                break
            }
            case PaymentMethod.ClickToPay: {
                const config = {
                    ...this.config,
                    paymentMethods: [PaymentMethod.ClickToPay]
                }
                this.parentStore.openPaymentIframeWidget(this.paymentData, config)
                break
            }
            case PaymentMethod.Ecospend: {
                const config = {
                    ...this.config,
                    paymentMethods: [PaymentMethod.Ecospend]
                }
                this.parentStore.openPaymentIframeWidget(this.paymentData, config)
                break
            }
        }
    }
}
