import { useEffect, useState } from 'react'
import { useInterval } from './useInterval'

function useTimer(durationOfTimerInSeconds: number) {
    const [remainingTimeInMS, setRemainingTimeInMS] = useState<number>()
    const [count, setCount] = useState<number>()
    const [startTriggered, setStartTriggered] = useState<boolean>(false)

    useEffect(() => {
        if (startTriggered && remainingTimeInMS && !isRunning) {
            start()
        }
    }, [startTriggered, remainingTimeInMS])

    const { start, stop, isRunning } = useInterval(() => {
        const nextCount = Math.round((remainingTimeInMS - Date.now()) / 1000)
        if (nextCount > 0) {
            setCount(nextCount)
        } else {
            setCount(0)
            stop()
        }
    }, 1000)

    function startTimer() {
        if (!isRunning) {
            setRemainingTimeInMS(Date.now() + durationOfTimerInSeconds * 1000)
            setCount(durationOfTimerInSeconds)
            setStartTriggered(true)
        }
    }

    return { start: startTimer, count }
}

export { useTimer }
