import React from 'react'
import { ActionButton } from '~/code/components'
import { LogStore, StyleStore } from '~/code/config'
import translations from './translations'
import { ForbiddenProps } from './props'
import styles from './Forbidden.scss'

export default function (props: ForbiddenProps) {
    return (
        <div className={ styles.Forbidden }>
            <div className={ styles.Header }>
                403
            </div>
            <div>
                { translations().description }
            </div>
            {
                LogStore.errorCode && (
                    <div className={ styles.ErrorCode }>
                        { `#${ LogStore.errorCode }`}
                    </div>
                )
            }
            <ActionButton
                className={ styles.ToBack }
                colorStyle={ StyleStore.style }
                onClick={ () => props.close() }>
                { translations().close }
            </ActionButton>
        </div>
    )
}
