// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3kacl_hPyZWP609ZCXdOVH{font-family:\"Poppins\",sans-serif;color:#000;font-weight:200;display:flex;justify-content:center;align-items:center;flex-direction:column;padding:10px;box-sizing:border-box;text-align:center}._3kacl_hPyZWP609ZCXdOVH ._2UCC_zeJMB2XQUPhzPrEwb{font-size:80px;margin-top:60px}._3kacl_hPyZWP609ZCXdOVH .cQ5eomHonY7Vx6uG857E1{width:80px;height:80px}._3kacl_hPyZWP609ZCXdOVH ._3T0W8NgtlvUm7wYJEB5aLs{padding-left:65px;padding-right:65px;margin-top:50px;margin-bottom:30px}._3kacl_hPyZWP609ZCXdOVH ._2ZKC3F81AX5GkU4BXG6ZYU{margin-bottom:60px}._3kacl_hPyZWP609ZCXdOVH ._2ZKC3F81AX5GkU4BXG6ZYU._3tN2j3bn0zwTNuZDV8cCj8{margin-bottom:0}._3kacl_hPyZWP609ZCXdOVH ._3dmHAzAYqqMqfC1JFjQqHH{color:#9b9b9b;font-size:12px;font-family:\"Open Sans\",sans-serif;font-weight:400}", ""]);
// Exports
exports.locals = {
	"ErrorPage": "_3kacl_hPyZWP609ZCXdOVH",
	"Header": "_2UCC_zeJMB2XQUPhzPrEwb",
	"RejectIcon": "cQ5eomHonY7Vx6uG857E1",
	"ToBack": "_3T0W8NgtlvUm7wYJEB5aLs",
	"Description": "_2ZKC3F81AX5GkU4BXG6ZYU",
	"HasAction": "_3tN2j3bn0zwTNuZDV8cCj8",
	"ErrorCode": "_3dmHAzAYqqMqfC1JFjQqHH"
};
module.exports = exports;
