import { TLDs } from 'global-tld-list'

export const isValidTLD = (tld: string) => {
    return TLDs.indexOf(tld) >= 0
}

export const extractTldFromDomain = (domain: string) => {
    const lastDotIndex = domain.lastIndexOf('.')
    if (lastDotIndex === -1) {
        return null
    }

    return domain.substr(lastDotIndex + 1)
}
