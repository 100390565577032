import React from 'react'
import { observer } from 'mobx-react'
import _ from 'lodash'

import { ConfigStore } from '~/code/config'
import { PageType } from '~/code/models'
import { isPayByLink } from '~/code/services'
import { CardPaymentButton } from '../CardPaymentButton'

import translations from './translations'
import { CardPaymentWrapperProps } from './props'


export const CardPaymentWrapper = observer((props: CardPaymentWrapperProps) => {

    const debouncedOnPay = React.useMemo(
        () => _.debounce((source: string) => props.onConfirm(source), 1000, { leading: true, trailing: false }),
        [props.onConfirm]
    )

    const renderConfirmButtonText = () => {
        if (props.hasNextPage) return translations().next
        else if (ConfigStore.pageType === PageType.TOKENIZATION || [PageType.VERIFICATION, PageType.PAYBYLINK_VERIFICATION].includes(ConfigStore.pageType)) {
            return translations().submit
        }
        return translations().pay
    }

    return (
        <div>
            { props.children }            
            <CardPaymentButton
                buttonText={renderConfirmButtonText()}
                showAgreeCheckbox={isPayByLink()}
                onPay={() => debouncedOnPay('CardPaymentWrapper.Button.onClick')}
            />
        </div>
    )
})
