import translations from './translations'

translations.add('es', {
    lookupCards: 'Busque sus tarjetas',
    pleaseEnterEmailOrPhone: 'Introduzca el correo electrónico con el que se registró en Click To Pay',
    pleaseEnterEmail: 'Introduzca un correo electrónico',
    pleaseEnterValidEmail: 'Introduzca un correo electrónico válido',
    emailMustNotBeGreaterThan256: 'El correo electrónico no puede tener más de 256 caracteres',
    pleaseEnterPhone: 'Introduzca un número de teléfono móvil',
    mobilePhoneMustNotBeGreaterThan16: 'El número de teléfono móvil no puede tener más de 16 caracteres',
    mobilePhoneMustNotBeLessThan8: 'El número de teléfono móvil no puede tener menos de 8 caracteres',
    phone: 'Teléfono móvil',
    email: 'Correo electrónico',
    back: 'VOLVER',
    signOut: 'DESCONECTAR',
    lookup: 'BUSCAR'
})
