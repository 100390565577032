import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Donation, Header, SupportCompany } from '~/code/components'
import { ConfigStore, StyleStore } from '~/code/config'
import { PaymentFormProps } from './props'
import { Page, PageType, PaymentMethod } from '~/code/models'
import styles from './PaymentForm.scss'
import { IssueFeedback } from '~/code/pages/ThreeDSecure/components/IssueFeedback/IssueFeedback'
import { PaymentFormSide, PaymentMethodsLink, TimeoutCountdown } from './components'
import { error, log } from '~/code/services/logger'
import md5 from 'md5'
import classNames from 'classnames'
import translations from './translations'
import { TranslationBuilder } from '~/code/components/Translation'
import { PAYMENT_FORM } from '~/code/constants/playwright-ids'

export const PaymentForm = observer((props: PaymentFormProps) => {
    const isOrderInformationBlockHidden = props.isOrderInformationBlockHidden || props.appStore?.clickToPayStore?.isPaymentProcessing

    useEffect(() => {
        const scriptTag = document.createElement('script')
        try {
            const sessionId = md5(ConfigStore?.auth?.access_token)
            scriptTag.setAttribute('defer', '')
            scriptTag.setAttribute('type', 'text/javascript')
            scriptTag.setAttribute('id', 'sig-api')
            scriptTag.setAttribute('data-order-session-id', sessionId)
            scriptTag.setAttribute('src', `https://cdn-scripts.signifyd.com/api/script-tag.js`)
            window.document.head.appendChild(scriptTag)
        } catch (err) {
            error('Could not add signifyd script tag', err)
        }

        if (props.isClickToPayAvailable) {
            props.appStore?.clickToPayStore.loadClickToPayScript()
        }

        return () => {
            try {
                window.document.head.removeChild(scriptTag)
            } catch (e) {
                log('The script has already been removed')
            }
        }
    }, [])

    const isVisibleDonation = !props.isDonationHidden &&
        ConfigStore.isEnableDonation &&
        ConfigStore.donation &&
        ConfigStore.donation.amount > 0 &&
        !ConfigStore.fixedDonationAmount &&
        props.orderCurrency === 'GBP'

    const activePaymentMethods = ConfigStore.activePaymentMethods()
    const bankCardAndClickToPayAvailable = activePaymentMethods.some(item => item.name === PaymentMethod.BankCard) &&
        activePaymentMethods.some(item => item.name === PaymentMethod.ClickToPay)
    const isVisiblePaymentMethodsLink = !props.isPaymentMethodsLinkHidden 
        && (
            activePaymentMethods.length > 1 
            && !(activePaymentMethods.length === 2 && bankCardAndClickToPayAvailable)
        )
        && ![PageType.VERIFICATION, PageType.PAYBYLINK_VERIFICATION].includes(ConfigStore.pageType) 
        && ConfigStore.pageType !== PageType.TOKENIZATION

    return (
        <div className={ classNames(styles.PaymentForm, 'payment-form') } key={`pf-${TranslationBuilder.lang}`}>
            {
                !props.isHeaderHidden && (
                    <Header close={ props.close }/>
                )
            }
            {
                !props.isTimeoutCountdownHidden &&
                <TimeoutCountdown
                    timeOut={props.paymentTimeoutInSeconds}
                    onCancelSession={ ConfigStore.pageType === PageType.PAYBYLINK ? () => location.reload() : () => props.close(true) }
                    shouldShow={!props.isPaymentInProgress && !props.isPaymentSuccessful}
                    timedOutMessageModalCloseButtonText={ConfigStore.pageType === PageType.PAYBYLINK ? translations().tryAgain : null}
                />
            }
            {
                ConfigStore.isTdsFeedbackEnabled && props.currentPageName === Page.THREE_D_SECURE && StyleStore.style.showIssueFeedbackBlock &&
                <>
                    { props.renderIssueFeedBackModal() }
                    <IssueFeedback
                        open={ props.openIssueFeedbackModal }
                        tryAgain={ () => {
                            props.closeIssueFeedbackModal()
                            props.tryAgain()
                        } }
                    />
                </>
            }
            {
                isVisibleDonation && (
                    <Donation
                        currency={ props.orderCurrency }
                    />
                )
            }
            <div className={ styles.Main } data-test-id={PAYMENT_FORM}>
                <div className={ `${ styles.Content } ${ isOrderInformationBlockHidden ? styles.ContentFullWidth : '' }` }>
                    {
                        isVisiblePaymentMethodsLink && (
                            <PaymentMethodsLink onClick={() => props.redirectInitialPaymentMethodsPage()} />
                        )
                    }
                    { props.children }
                </div>
                {
                    !isOrderInformationBlockHidden && (
                        <PaymentFormSide
                            {...props}
                            className={styles.side}
                            isVisibleDonation={isVisibleDonation}
                        />
                    )
                }

                {
                    ConfigStore.isTestMode && StyleStore.style?.showTestLabel && (
                        <div className={ styles.TestLabel }>
                            <div className={ styles.TestLabelContent }>
                                <div>T</div>
                                <div>E</div>
                                <div>S</div>
                                <div>T</div>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                !props.isFooterHidden && (StyleStore.style.showPoweredByBlock || StyleStore.style.showPaymentSecuredByBlock) && <SupportCompany />
            }
        </div>
    )
})
