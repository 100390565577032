import { PaymentMethodsSettings } from '~/code/models'

export const mergePaymentMethodsSettings = (mainPaymentMethodsSettings: PaymentMethodsSettings, overridingPaymentMethodsSettings: PaymentMethodsSettings): PaymentMethodsSettings => {
    let result = {...mainPaymentMethodsSettings}
    for (const paymentMethodsSettingsKey in overridingPaymentMethodsSettings) {
        if (result.hasOwnProperty(paymentMethodsSettingsKey)) {
            result = {
                ...result,
                [paymentMethodsSettingsKey]: {
                    ...result[paymentMethodsSettingsKey],
                    ...overridingPaymentMethodsSettings[paymentMethodsSettingsKey]
                }
            }
        } else {
            result = {
                ...result,
                [paymentMethodsSettingsKey]: overridingPaymentMethodsSettings[paymentMethodsSettingsKey]
            }
        }
    }
    return result
}
