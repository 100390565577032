import React, { useEffect, useState } from 'react'

import { ConfigStore } from '~/code/config'
import { CheckboxBlock, Modal } from '~/code/components'
import { PaymentMethodStatus } from '~/code/models/PaymentMethodStatus'
import { getCreditCardBrandNameByType } from '~/code/pages/CardData/services/getters'

import translations from './translations'
import { ClickToPayAgreeCheckboxProps } from './props'
import styles from './styles.scss'

export const ClickToPayAgreeCheckbox = (props: ClickToPayAgreeCheckboxProps) => {
    
    const {
        selectedCardType, hasAgreedToShareWithClickToPay, onAgreeToShareWithClickToPay, cannotChangeAgreement
    } = props
    const [showClickToPayLearnMoreModal, setShowClickToPayLearnMoreModal] = useState<boolean>(false)

    useEffect(() => {
        window.addEventListener('close', () => {
            setShowClickToPayLearnMoreModal(false)
        })
    }, [])

    useEffect(() => {
        document.addEventListener('ok', handleC2PLearnMoreClose)
    }, [])

    const handleC2PLearnMoreClose = () => {
        setShowClickToPayLearnMoreModal(false)
    }

    if (ConfigStore.paymentMethodsSettings?.clickToPay?.status !== PaymentMethodStatus.ACTIVE) {
        return null
    }

    return (
        <CheckboxBlock
            checked={ cannotChangeAgreement || hasAgreedToShareWithClickToPay }
            onChange={ () => {onAgreeToShareWithClickToPay()}}
            disabled={cannotChangeAgreement}
            className={styles.clickToPayBlock}
        >
            <>
                {translations().iAgreeToShareWithClickToPay(getCreditCardBrandNameByType(selectedCardType))}
            </>
            <a className={styles.learnMoreLink} onClick={() => setShowClickToPayLearnMoreModal(true)}>{translations().learnMore}</a>
            <Modal
                classNames={{
                    content: styles.learnMoreModal
                }}
                isOpen={showClickToPayLearnMoreModal}
                onClose={() => setShowClickToPayLearnMoreModal(false)}
            >
                <src-learn-more display-close-button='false' display-ok-button='true'></src-learn-more>
            </Modal>
        </CheckboxBlock>
    )
}
