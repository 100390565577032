// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2lFT8k73_X7Ssaa9lKclyj{display:flex;justify-content:flex-end}", ""]);
// Exports
exports.locals = {
	"footer": "_2lFT8k73_X7Ssaa9lKclyj"
};
module.exports = exports;
