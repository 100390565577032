// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3riuVIRqYlo-083EfxDsew ._1Ripc6svj42Wo1jQ4fHvRK{font-family:\"Open Sans\",sans-serif;font-size:16px;color:#000}._3riuVIRqYlo-083EfxDsew ._1Ripc6svj42Wo1jQ4fHvRK ._2bV-1IYwKbjUhbbCp3y7Jf{font-weight:700}._3riuVIRqYlo-083EfxDsew ._3UQKdTuK6HFn_ZftSGCIrs{margin-top:auto;display:flex;justify-content:space-between;align-items:stretch}@media(min-width: 768px){._3riuVIRqYlo-083EfxDsew ._3UQKdTuK6HFn_ZftSGCIrs{justify-content:flex-end}}._3riuVIRqYlo-083EfxDsew ._3UQKdTuK6HFn_ZftSGCIrs ._351Z6D-YfPIxr-ywqEXB8S{padding:10px 25px;margin-right:0px}@media(min-width: 768px){._3riuVIRqYlo-083EfxDsew ._3UQKdTuK6HFn_ZftSGCIrs ._351Z6D-YfPIxr-ywqEXB8S{margin-right:30px;padding:12px 30px}}._3riuVIRqYlo-083EfxDsew ._3UQKdTuK6HFn_ZftSGCIrs ._3z8165zJ1Uke1ea5l3dh8-{padding:9px 29px}@media(min-width: 768px){._3riuVIRqYlo-083EfxDsew ._3UQKdTuK6HFn_ZftSGCIrs ._3z8165zJ1Uke1ea5l3dh8-{padding:9px 34px}}._1pUJUa1oK8FuU2KL12CVF{width:100%;max-width:420px;min-height:150px;padding:20px;box-sizing:border-box}@media(min-width: 768px){._1pUJUa1oK8FuU2KL12CVF{width:420px}}._3riuVIRqYlo-083EfxDsew{min-height:110px;display:flex;flex-direction:column}._1aLTMchjKY1o7l9oRsAFrP{z-index:2147000100}", ""]);
// Exports
exports.locals = {
	"Content": "_3riuVIRqYlo-083EfxDsew",
	"Message": "_1Ripc6svj42Wo1jQ4fHvRK",
	"Header": "_2bV-1IYwKbjUhbbCp3y7Jf",
	"Actions": "_3UQKdTuK6HFn_ZftSGCIrs",
	"CancelButton": "_351Z6D-YfPIxr-ywqEXB8S",
	"OkButton": "_3z8165zJ1Uke1ea5l3dh8-",
	"ModalContent": "_1pUJUa1oK8FuU2KL12CVF",
	"Overlay": "_1aLTMchjKY1o7l9oRsAFrP"
};
module.exports = exports;
