import React from 'react'
import { Image } from '~/code/components'
import { CreditCardType } from '~/code/pages/CardData/models'
import styles from './CreditCardIcon.scss'
import { CreditCardIconProps } from './props'

export default function (props: CreditCardIconProps) {
    const getIconPath = () => {
        switch (props.type) {
            case CreditCardType.VISA:
                return 'visa.svg'
            case CreditCardType.MASTERCARD:
                return 'mastercard.svg'
            case CreditCardType.AMERICAN_EXPRESS:
                return 'american-express.svg'
            case CreditCardType.DINERS_CLUB:
                return 'diners-club.svg'
            case CreditCardType.DISCOVER:
                return 'discover.svg'
            case CreditCardType.JCB:
                return 'jcb.svg'
            case CreditCardType.UNIONPAY:
                return 'union-pay.svg'
            case CreditCardType.MAESTRO:
                return 'maestro.svg'
            case CreditCardType.ELO:
                return 'elo.svg'
            case CreditCardType.MIR:
                return 'mir.svg'
            case CreditCardType.HIPER:
                return '/hiper.png'
            case CreditCardType.HIPERCARD:
                return '/hipercard.svg'
            case CreditCardType.FLEXECASH:
                return '/flexecash.jpeg'
            default:
                return null
        }
    }

    const renderIcon = () =>  {
        const path = getIconPath()
        return path && <Image className={ `${ styles.Icon }`} src={ path }/>
    }

    return (
        <span
            className={ `${ styles.CreditCardIcon }
            ${ props.className ? props.className : '' }` }>
                { renderIcon() }
        </span>
    )
}
