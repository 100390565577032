import React from 'react'
import { observer } from 'mobx-react'
import { createUseStyles } from 'react-jss'
import { ConfigStore, StyleStore } from '~/code/config'
import classNames from 'classnames'
import { Image } from '~/code/components'
import styles from './Header.scss'
import { HeaderProps } from './props'
import CloseButton from '~/code/components/Header/components/CloseButton/CloseButton'
import { CLOSE_BUTTON } from '~/code/constants/playwright-ids'

const useStyles = createUseStyles({
    Logo: {
        width: () => `${ StyleStore.style.headerLogoDesktopWidth }px`,
        height: () => `${ StyleStore.style.headerLogoDesktopHeight }px`
    },
    SecondaryLogo: {
        width: () => `${ StyleStore.style.headerSecondaryLogoDesktopWidth }px`,
        height: () => `${ StyleStore.style.headerSecondaryLogoDesktopHeight }px`
    },
    '@media screen and (max-width: 768px)': {
        Logo: {
            width: () => `${ StyleStore.style.headerLogoMobileWidth }px`,
            height: () => `${ StyleStore.style.headerLogoMobileHeight }px`
        },
        SecondaryLogo: {
            width: () => `${ StyleStore.style.headerSecondaryLogoMobileWidth }px`,
            height: () => `${ StyleStore.style.headerSecondaryLogoMobileHeight }px`
        }
    }
})

export default observer( ({ close }: HeaderProps)  => {
    const classes = useStyles()

    const renderCloseButton = () => {
        if (!ConfigStore.hasReturnUrl()) {
            return null
        }
        return (
            <span data-test-id={CLOSE_BUTTON} className={ styles.Close } data-id={'close-form-button'} onClick={ () => close() }>
                <CloseButton />
            </span>
        )
    }

    const getSecondaryPosition = () => {
        if (StyleStore.style.headerSecondaryLogoPosition === 'right') {
            return styles.Right
        } else if (StyleStore.style.headerSecondaryLogoPosition === 'middle-right') {
            return styles.MiddleRight
        } else {
            return ''
        }
    }

    return (
        <div
            className={ styles.Header }
            style={{
                backgroundImage: `linear-gradient(${StyleStore.style.headerGradientLinearDegree},${ StyleStore.style.headerStartColor } 0%, ${ StyleStore.style.headerFinishColor } 100%)`
            }}
        >
            { renderCloseButton() }
            <Image
                className={ classNames(styles.Logo, classes.Logo) }
                src={ StyleStore.style.headerLogoUrl }
            />
            {
                StyleStore.style.headerLogoText && (
                    <span className={ styles.LogoText }
                        style={{
                            color: StyleStore.style.headerLogoTextColor,
                            fontSize: `${ StyleStore.style.headerLogoTextFontSize }px`
                        }}
                    >
                        <div>
                            { StyleStore.style.headerLogoText }
                        </div>
                        {
                            StyleStore.style.headerLogoTextSecondary && (
                                <div>
                                    { StyleStore.style.headerLogoTextSecondary }
                                </div>
                            )
                        }
                    </span>
                )
            }
            {
                StyleStore.style.headerSecondaryLogoUrl && (
                    <Image
                        className={ classNames(styles.SecondaryLogo, classes.SecondaryLogo, getSecondaryPosition()) }
                        src={ StyleStore.style.headerSecondaryLogoUrl }
                    />
                )
            }
            {
                StyleStore.style.showHeaderBottomLine && (
                    <div className={ styles.BottomLine }
                         style={{
                             height:  `${ StyleStore.style.headerBottomLineHeight }px`,
                             background: StyleStore.style.headerBottomLineBackgroundColor
                         }}
                    />
                )
            }
        </div>

    )
})
