import { action, observable } from 'mobx'
import constants from '~/code/config/constants'
import { Style } from '~/code/models/Style'

class StyleStore {
    @observable
    public style: Style = constants.defaultStyle

    @action
    setStyles(newStyle: Style = {} as Style) {
        this.style = {
            ...constants.defaultStyle,
            ...newStyle,
            ...(newStyle.embeddedWidget ? {
                embeddedWidget: {
                    ...constants.defaultStyle.embeddedWidget,
                    ...newStyle.embeddedWidget
                }
            } : {})
        }

        const rootElement = document.documentElement
        rootElement.style.setProperty('--primary-color', this.style.primaryColor)
    }

    public getDefaultStyle(): Style {
        return constants.defaultStyle as Style
    }
}

const StyleStoreInstance = new StyleStore()

export { StyleStoreInstance as StyleStore }
