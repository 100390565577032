// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3fwt4PD3s7VkEudcIbHWD{justify-content:center !important;color:#000}._2zereUHAaYlQM1sEK9lIVP{width:100px;height:28px}.HkdfgRCzff_dhgpQYumAf{height:46px}", ""]);
// Exports
exports.locals = {
	"root": "_3fwt4PD3s7VkEudcIbHWD",
	"image": "_2zereUHAaYlQM1sEK9lIVP",
	"astroPay": "HkdfgRCzff_dhgpQYumAf"
};
module.exports = exports;
