import translations from './translations'

translations.add('pt', {
    moreDetails: 'Apenas mais alguns dados',
    pleaseEnterEmail: 'Por favor insira um e-mail',

    pleaseEnterValidEmail: 'Por favor insira um e-mail válido',
    emailMustNotBeGreaterThan256: 'O e-mail não deve ser superior a 256 caracteres',
    pleaseEnterFirstName: 'Por favor insira o primeiro nome',
    firstNameMustNotBeGreaterThan32: 'O primeiro nome não deve ser superior a 32 caracteres',
    pleaseEnterLastName: 'Por favor insira o apelido',
    lastNameMustNotBeGreaterThan32: 'O apelido não deve ser superior a 32 caracteres',
    pleaseSelectCountry: 'Por favor selecione o país',
    countryMustNotBeGreaterThan2: 'O país não deve ser superior a 2 caracteres',
    pleaseEnterStreet: 'Por favor insira a morada',
    streetMustNotBeGreaterThan50: 'A morada não deve ser superior a 50 caracteres',
    pleaseEnterCity: 'Por favor insira a cidade',
    cityMustNotBeGreaterThan50: 'A cidade não deve ser superior a 50 caracteres',
    pleaseEnterPostalCode: 'Por favor insira o código postal',
    postalCodeMustNotBeGreaterThan13: 'O código postal não deve ser superior a 13 caracteres',
    pleaseEnterMobilePhone: 'Por favor insira o telemóvel',
    mobilePhoneMustNotBeGreaterThan16: 'O telemóvel não deve ser superior a 16 caracteres',
    mobilePhoneMustNotBeLessThan8: 'O telemóvel não deve ser inferior a 8 caracteres',

    mustNotBeGreaterThan: 'não deve ser maior do que',
    mustNotBeLessThan: 'não deve ser inferior a',
    characters: 'caracteres',
    billingAddress: 'Morada de faturação',
    deliveryAddress: 'Morada de entrega',
    country: 'País',
    city: 'Cidade',
    street: 'Morada',
    email: 'E-mail',
    firstName: 'Primeiro nome',
    lastName: 'Apelido',
    postalCode: 'Código postal',
    mobilePhone: 'Telemóvel',
    mobilePhoneInInternationalFormat: 'Formato internacional',
    pay: 'PAGAR',
    submit: 'ENVIAR',

    pleaseEnterAllRequiredFields: 'Por favor insira todos os campos obrigatórios'
})
