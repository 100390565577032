import React from 'react'
import { observer } from 'mobx-react'
import translations from './translations'
import { ConfigStore, StyleStore } from '~/code/config'
import { CardListProps } from './props'
import styles from './CardList.scss'
import { CreditCardIcon, Link } from '~/code/components'
import { getTypeInfo } from 'credit-card-type'

export default observer((props: CardListProps) => {
    const showItems = ConfigStore.cards.slice(0, 4)

    const renderItem = (item) => {
        return (
            <div className={ styles.CardItem } key={ item.panStar + item.cardName + item.expiryDate } onClick={ () => props.onSelectItem(item) }>
                <div className={ styles.CardItemContent }>
                    <div className={ styles.CardName }>
                        { item.cardName }
                    </div>
                    <div className={ styles.CardNumber }>
                        { item.panStarPretty }
                    </div>
                    <div className={ styles.CardItemFooter }>
                        <div className={ styles.CardExpiryDate }>
                            { item.expiryDate }
                        </div>
                        <div>
                            <CreditCardIcon type={ item?.creditCardType }/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={ styles.CardList }>
            <div className={ styles.CardListContent }>
                {
                    showItems.map((el) => renderItem(el))
                }
            </div>
            <Link className={ styles.PayNewCardLink } onClick={ () => props.onPayNewCard() }> { translations().payNewCard } + </Link>
        </div>
    )
})
