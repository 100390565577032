import translations from './translations'

translations.add('pt', {
    successTitle: 'Parabéns',
    successMessage: 'O seu pagamento foi enviado com sucesso.',
    cancelMessage: 'O seu pagamento foi cancelado.',
    failMessage: 'O seu pagamento falhou.',
    rejectMessage: 'O seu pagamento foi rejeitado.',
    transactionId: 'ID da transação',
    autoRedirectAfter: 'Será redirecionado automaticamente para o site após',
    seconds: 'segundo',
    tryAgain: 'Tente novamente',
    returnToBack: 'Voltar ao site'
})
